import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel,
  QueryColumn, QueryColumnSortOrder, TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { DxContextMenuComponent } from 'devextreme-angular';
import { DefaultIcons } from '../measure/measure-detail/implementation-plan/implementation-plan.component';

@Component({
  selector: 'ubt-geods-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {
  @ViewChild('rolesGrid') rolesGrid: GridComponent;
  @ViewChild('contextMenu') contextMenu: DxContextMenuComponent;

  public availableRoles: Role[] = []
  public isUpdating = false;
  public showLoader = false;
  public columns: QueryColumn[] = [];
  private rowIndex: number;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Ubt-GridColumn-Name'),
      dataField: 'Value',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      dataField: '',
      visibleIndex: 1,
      minWidth: 50,
      width: '50px',
    }
  ];
  public role: Role = new Role(null);
  private subscriptions: Subscription[] = [];
  private OPath = "ref:ParentId='Personenfunktion/";
  private parentId: string;
  public noDataText = "Keine Daten";

  public contextMenuItems: any[] = [
    {
      text: this.dataService.res('Ubt-Roles-Edit-Role'),
      onClick: () => {
        this.openEditDialog();
      },
    },
    {
      text: this.dataService.res('Ubt-Roles-Delete-Role'),
      onClick: () => {
        this.openRemoveDialog()
      },
    },
  ];

  constructor(
    public dataService: DataService,
    private persistenceService: GeoDsPersistenceService,
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService
  ) { }

  public ngOnInit(): void {
    this.dataService.floatingButtonConfig();
    this.showLoader = true;
    this.columns.push(
      this.coreDataService.createQueryColumn('ParentId', 'ParentId', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Value', 'Value', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.None)
    );

    // this.OPath = this.OPath + localStorage.getItem('fixedCulture') === 'en' ? "1033/'" : "1031/'";
    this.OPath = this.OPath + "1031/'";

    this.subscriptions.push(this.dataService.readObjects<Role[]>(ObjectKeys.PICKLISTITEM, this.columns, this.OPath).subscribe(data => {
      this.availableRoles = data;
      this.parentId = data[0]['ParentId'];
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR })
      console.error(error);
    }, () => {
      this.showLoader = false;
    }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe(role => {
      this.availableRoles.pop();
      this.availableRoles.push(role);
      this.rolesGrid.refreshGrid();
    }));
  }
  public onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === '') {
        e.cellElement.style.cursor = 'pointer';
        return (e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU);
      }
    }
  }

  public onCellClick = (e) => {
    this.role = e.data;
    this.rowIndex = e.rowIndex
    if (e.columnIndex === 1) {
      e.cellElement.className = 'context-menu-icon' + e.rowIndex;
      this.contextMenu.target = '.context-menu-icon' + e.rowIndex;
      e.cellElement.innerHTML = this.contextMenu.visible ? DefaultIcons.CLOSE : DefaultIcons.CONTEXTMENU;
      this.contextMenu.instance.show();
    }
  }

  public openEditDialog(): void {
    this.rolesGrid.dxDataGrid.instance.editRow(this.rowIndex);
  }

  public openRemoveDialog(): void {
    this.rolesGrid.dxDataGrid.instance.deleteRow(this.rowIndex)
  }
  public assignNewRole(): void {
    this.isUpdating = false;
    this.rolesGrid.addRow();
  }

  public saveRow(e): void {
    if (e.changes[0].type !== 'remove') {
      if (e.changes[0].data.Value) {
        this.role = new Role(null);
        if (e.changes[0].key['Id']) this.role.Id = e.changes[0].key['Id'];
        this.role.name = e.changes[0].data['Value'];
        this.role.parentId = this.parentId;
        this.isUpdating = e.changes[0].type === 'update' ? true : false;
        if (!this.isUpdating) {
          e.cancel = false;
        }
        this.persistRole();
      } else {
        this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Empty"), type: ToastType.INFO });
        e.cancel = true;
      }
    }
  }

  private persistRole(): void {
    const rolePersistQuery: TargetObjectData = new TargetObjectData();
    rolePersistQuery.ObjectKey = new ObjectKey();
    rolePersistQuery.ObjectKey.ObjectType = ObjectKeys.PICKLISTITEM;
    const roleColumns: TargetColumnValue[] = [
      { Name: 'Value', Value: this.role.name },
      { Name: 'Description', Value: this.role.name },
      { Name: 'ParentId', Value: this.role.parentId }
    ];
    if (this.isUpdating === true) {
      rolePersistQuery.Mode = PersistMode.Update;
      rolePersistQuery.ObjectKey.Id = this.role.Id;
    } else {
      rolePersistQuery.Mode = PersistMode.Insert;
    }
    rolePersistQuery.TargetColumns = roleColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = rolePersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      this.getRoleById(this.columns, data.Id);
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  private getRoleById(columns: QueryColumn[], id: string) {
    const opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(this.dataService.readObjects(ObjectKeys.PICKLISTITEM, columns, opath).subscribe((data) => {
      if (this.isUpdating === true) {
        const selectedIndex = this.availableRoles.findIndex((item) => {
          item.Id == id;
        });
        const clonedItem = { ...this.role };
        this.availableRoles[selectedIndex] = clonedItem;
      } else {
        this.dataService.updateGridData(data[0]);
      }
      this.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }));
  }

  public removeRow(event): void {
    this.isUpdating = true;
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.PICKLISTITEM, this.role.Id).subscribe(() => {
      this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    }, error => {
      console.error(error);
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe() });
  }
}

export class Role {
  Id: string;
  parentId: string;
  name: string;
  constructor(data) {
    Object.assign(this, data);
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoriteListComponent } from './favorite-list.component';
import { FavoritesModule } from '@wissenswerft/favorites';

@NgModule({
  declarations: [FavoriteListComponent],
  imports: [CommonModule, FavoritesModule],
  exports: [FavoriteListComponent]
})
export class FavoriteListModule { }

import { Injectable } from "@angular/core";
import { CoreConfigService } from "@wissenswerft/core/configuration";
import { GeoDsWebApiServiceURLs } from "libs/core/configuration/src/lib/models/config.model";
import { Observable, of, Subject } from "rxjs";
import { concatMap, tap } from "rxjs/operators";
import { CompareHelper } from "../helpers";
import { ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel, PersistObjectsPackage, TargetColumnValue, TargetObjectData } from "../models/query.model";
import { DataService } from "./data.service";

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private persistObjectDataServiceUri: string;
    private persistObjectsDataServiceUri: string;
    public afterPersistPendingChanges$: Observable<IPersistable[]>;
    private pendingObjects: {} = {};
    private persistPendingChangesSubject: Subject<PersistPendingChangesPackage> =
        new Subject<PersistPendingChangesPackage>();
    private pendingObjectsChangedSubject: Subject<PendingObjectsChangedPackage> =
        new Subject<PendingObjectsChangedPackage>();
    private afterPersistPendingChangesSubject: Subject<IPersistable[]> = new Subject<IPersistable[]>();
    constructor(private dataService: DataService, private configService: CoreConfigService) {
        const serviceUrls: GeoDsWebApiServiceURLs = <GeoDsWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
        this.persistObjectDataServiceUri = serviceUrls.PersistObject;
        this.persistObjectsDataServiceUri = serviceUrls.PersistObjects;
        this.afterPersistPendingChanges$ = this.afterPersistPendingChangesSubject.asObservable();

        this.persistPendingChangesSubject
            .asObservable()
            .pipe(
                concatMap((ppcp) =>
                    this.createPersistObjectsModel(ppcp).pipe(
                        concatMap((model) => {
                            if (model) {

                                return this.executePersistObjectsQuery(model.persistObjectsModel).pipe(
                                    tap((x) => {
                                        this.notifyPersisted(ppcp, model);
                                    })
                                );
                            } else {
                                this.clearPendingObjects(ppcp.category);
                            }
                        })
                    )
                )
            )
            .subscribe(() => { });

    }

    public clearPendingObjects(category?: string) {
        if (category === undefined) {
            this.pendingObjects = {};
        } else {
            this.pendingObjects[category] = {};
        }
    }

    private notifyPersisted(ppcp: PersistPendingChangesPackage, model: PersistObjectsPackage) {
        this.afterPersistPendingChangesSubject.next(model.treatedObjects);
        this.clearPendingObjects(ppcp.category);
        this.firePendingObjectsChanged(this.pendingObjects, ppcp.category);
    }


    public addObjectForDelete(object: IPersistable) {
        const objectKey = object.objectType + '_' + object.id;

        if (this.pendingObjects[object.persistenceCategory] && this.pendingObjects[object.persistenceCategory][objectKey]) {
            const pendingObject = this.pendingObjects[object.persistenceCategory][objectKey];
            if (pendingObject.persistMode === PersistMode.Insert) {
                delete this.pendingObjects[object.persistenceCategory][objectKey];
                this.firePendingObjectsChanged(this.pendingObjects, object.persistenceCategory);
            } else if (pendingObject.persistMode === PersistMode.Update) {
                pendingObject.persistMode = PersistMode.Delete;
                pendingObject.fieldValueHistories = {};
            }
        } else {
            if (!this.pendingObjects[object.persistenceCategory]) {
                this.pendingObjects[object.persistenceCategory] = {};
            }
            const pack: PendingObjectPackage = new PendingObjectPackage();
            pack.object = object;
            pack.persistMode = PersistMode.Delete;
            this.pendingObjects[object.persistenceCategory][objectKey] = pack;
            this.firePendingObjectsChanged(this.pendingObjects, object.persistenceCategory);
        }

    }
    public executePersistObjectQuery(query: PersistObjectModel): Observable<any> {
        return this.dataService.executeWebApiPOSTMethod(this.persistObjectDataServiceUri, query);
    }

    public executePersistObjectsQuery(query: PersistObjectsModel): Observable<ArrayBuffer> {
        return this.dataService.executeWebApiPOSTMethod(this.persistObjectsDataServiceUri, query);
    }
    public insertObject(object: IPersistable) {
        const pack: PendingObjectPackage = this.ensurePendingObject(object);
        this.ensureFieldValueHistory(pack, 'Id', object.id, null);
        pack.persistMode = PersistMode.Insert;
    }

    public deleteObject(object: IPersistable) {
        const objectKey = object.objectType + '_' + object.id;

        if (this.pendingObjects[object.persistenceCategory] && this.pendingObjects[object.persistenceCategory][objectKey]) {
            const pendingObject = this.pendingObjects[object.persistenceCategory][objectKey];
            if (pendingObject.persistMode === PersistMode.Insert) {
                delete this.pendingObjects[object.persistenceCategory][objectKey];
                this.firePendingObjectsChanged(this.pendingObjects, object.persistenceCategory);
            } else if (pendingObject.persistMode === PersistMode.Update) {
                pendingObject.persistMode = PersistMode.Delete;
                pendingObject.fieldValueHistories = {};
            }
        } else {
            if (!this.pendingObjects[object.persistenceCategory]) {
                this.pendingObjects[object.persistenceCategory] = {};
            }
            const pack: PendingObjectPackage = new PendingObjectPackage();
            pack.object = object;
            pack.persistMode = PersistMode.Delete;
            this.pendingObjects[object.persistenceCategory][objectKey] = pack;
            this.firePendingObjectsChanged(this.pendingObjects, object.persistenceCategory);
        }
    }
    private ensurePendingObject(object: IPersistable): PendingObjectPackage {
        const objectKey = object.objectType + '_' + object.id;

        if (!this.pendingObjects[object.persistenceCategory]) {
            this.pendingObjects[object.persistenceCategory] = {};
        }
        if (!this.pendingObjects[object.persistenceCategory][objectKey]) {
            const pack: PendingObjectPackage = new PendingObjectPackage();
            pack.object = object;
            this.pendingObjects[object.persistenceCategory][objectKey] = pack;
        }

        return this.pendingObjects[object.persistenceCategory][objectKey];
    }
    private ensureFieldValueHistory(pack: PendingObjectPackage, fieldName: string, newValue: any, beforeValue: any) {
        let fieldHistory = pack.fieldValueHistories[fieldName];
        if (!fieldHistory) {
            fieldHistory = new FieldValueHistory();
            fieldHistory.originalValue = beforeValue;
            pack.fieldValueHistories[fieldName] = fieldHistory;
        }
        if (!newValue && pack.persistMode === PersistMode.Insert) {
            //avoid NULL inputs in query
            newValue = undefined;
        }
        fieldHistory.newValue = newValue;
    }
    private firePendingObjectsChanged(pendingObjects: {}, category?: string) {
        let poc: PendingObjectsChangedPackage = new PendingObjectsChangedPackage();
        poc.category = category;
        poc.hasAnyChanges =
            category === undefined
                ? Object.values(pendingObjects).some((x) => (x as PendingObjectPackage).hasAnyChanges)
                : Object.values(pendingObjects[category]).some((x) => (x as PendingObjectPackage).hasAnyChanges);
        this.pendingObjectsChangedSubject.next(poc);
    }

    public addObjectForInsert(object: IPersistable) {

        const pack: PendingObjectPackage = this.ensurePendingObject(object);

        this.ensureFieldValueHistory(pack, 'Id', object.id, null);

        pack.persistMode = PersistMode.Insert;

    }
    private addValueForUpdateOrInsert(
        object: IPersistable,
        fieldName: string,
        newValue: any,
        beforeValue: any
    ): PendingObjectPackage {
        const pack: PendingObjectPackage = this.ensurePendingObject(object);
        this.ensureFieldValueHistory(pack, fieldName, newValue, beforeValue);
        this.firePendingObjectsChanged(this.pendingObjects, object.persistenceCategory);
        return pack;
    }
    public addObjectForUpdate(object: IPersistable) {
        const pack: PendingObjectPackage = this.ensurePendingObject(object);
        this.ensureFieldValueHistory(pack, 'Id', object.id, object.id);
        pack.persistMode = PersistMode.Update;
    }
    public addObjectValue(object: IPersistable, fieldName: string, newValue: any, beforeValue?: any) {
        newValue = newValue === '' ? null : newValue;
        const pack: PendingObjectPackage = this.addValueForUpdateOrInsert(object, fieldName, newValue, beforeValue);
        if (pack.persistMode !== PersistMode.Insert) {
            pack.persistMode = PersistMode.Update;
        }
    }

    public persistPendingChanges(category?: string) {
        if (!CompareHelper.isEmptyObject(this.pendingObjects) &&
            (category === undefined || !CompareHelper.isEmptyObject(this.pendingObjects[category]))
        ) {
            const ppcp: PersistPendingChangesPackage = new PersistPendingChangesPackage();
            ppcp.category = category;
            ppcp.pendingObjects = this.pendingObjects;
            this.persistPendingChangesSubject.next(ppcp);

        }
    }

    private createPersistObjectsModel(ppcp: PersistPendingChangesPackage): Observable<PersistObjectsPackage> {
        let result: PersistObjectsPackage;
        const targetObjectDatas: TargetObjectData[] = new Array<TargetObjectData>();
        const treatedObjects: IPersistable[] = new Array<IPersistable>();
        const pendingObjects: PendingObjectPackage[] = new Array<PendingObjectPackage>();
        if (ppcp.category === undefined) {
            for (let strCategory in ppcp.pendingObjects) {
                for (let strObjectKey in ppcp.pendingObjects[strCategory]) {
                    pendingObjects.push(ppcp.pendingObjects[strCategory][strObjectKey]);
                }
            }
        } else {
            for (let strObjectKey in ppcp.pendingObjects[ppcp.category]) {
                pendingObjects.push(ppcp.pendingObjects[ppcp.category][strObjectKey]);
            }
        }
        for (let pack of pendingObjects) {
            let columnsToPersist: TargetColumnValue[];
            if (pack.persistMode !== PersistMode.Delete) {
                columnsToPersist = [];
                for (let fieldName in pack.fieldValueHistories) {
                    const fieldHistory: FieldValueHistory = pack.fieldValueHistories[fieldName];
                    if (fieldHistory.hasChanged) {
                        const tcv: TargetColumnValue = new TargetColumnValue();
                        tcv.Name = fieldName;
                        tcv.Value = fieldHistory.newValue;
                        columnsToPersist.push(tcv);
                    }
                }
            }
            if (pack.persistMode === PersistMode.Delete || (columnsToPersist && columnsToPersist.length > 0)) {
                const objectKey: ObjectKey = new ObjectKey();
                if (pack.persistMode !== PersistMode.Insert) {
                    objectKey.Id = pack.object.id;
                }
                objectKey.ObjectType = pack.object.objectType as string;
                const tod: TargetObjectData = new TargetObjectData();
                tod.ObjectKey = objectKey;
                tod.Mode = pack.persistMode;
                if (pack.persistMode !== PersistMode.Delete) {
                    tod.TargetColumns = columnsToPersist;
                }
                targetObjectDatas.push(tod);
                treatedObjects.push(pack.object);
            }
        }

        if (targetObjectDatas.length > 0) {
            const query: PersistObjectsModel = new PersistObjectsModel();
            query.Objects = [targetObjectDatas];

            result = new PersistObjectsPackage();
            result.persistObjectsModel = query;
            result.treatedObjects = treatedObjects;
        }

        return of(result);
    }
}

export interface IPersistable {
    id?: string;
    objectType: string | number;
    persistenceCategory?: string;
    changesPersisted(persistMode: PersistMode);
}
export class PendingObjectPackage {
    object: IPersistable;
    persistMode: PersistMode;
    fieldValueHistories: {} = {};

    get hasAnyChanges(): boolean {
        if (this.persistMode === PersistMode.Delete) {
            return true;
        } else {
            for (let fieldName in this.fieldValueHistories) {
                const fieldHistory: FieldValueHistory = this.fieldValueHistories[fieldName];
                if (fieldHistory.hasChanged) {
                    return true;
                }
            }
            return false;
        }
    }

    setOriginalFieldHistoryValue(fieldName: string, setter) {
        if (this.fieldValueHistories[fieldName]) {
            setter(this.fieldValueHistories[fieldName].originalValue);
        }
    }
}

export class PersistPendingChangesPackage {
    category: string;
    pendingObjects: {};
}
export class PendingObjectsChangedPackage {
    category: string;
    hasAnyChanges: boolean;
}

export class FieldValueHistory {
    originalValue: any;
    newValue: any;
    get hasChanged(): boolean {
        return this.originalValue !== this.newValue;
    }
}



import { SubProject, Event } from "@xmt-models";

export class SubProjectViewModel {
    private subProject: SubProject;

    get id(): string { return this.subProject.Id; }
    get label(): string { return this.subProject.Label; }
    set label(data: string) { this.subProject.Label = data; }
    get event(): Event { return this.subProject.Event; }
    set event(data: Event) { this.subProject.Event = data; }
    get highlights(): string { return this.subProject.Highlights; }
    set highlights(highlights: string) { this.subProject.Highlights = highlights; }
    get lowlights(): string { return this.subProject.Lowlights; }
    set lowlights(lowlights: string) { this.subProject.Lowlights = lowlights; }
    get startDate(): Date {
        if (this.subProject.StartDate) {
            return new Date(this.subProject.StartDate);
        }
    }
    get endDate(): Date {
        if (this.subProject.EndDate) {
            return new Date(this.subProject.EndDate);
        }
    }
    get responsible(): any {
        if (this.subProject?.IdRefResponsible) {
            return this.subProject?.IdRefResponsible || this.subProject?.IdRefResponsible;
        }
    }
    set responsible(data) { this.subProject.IdRefResponsible = data; }
    get responsibleSupport(): any {
        if (this.subProject?.IdRefResponsibleSupport) {
            return this.subProject?.IdRefResponsibleSupport || this.subProject?.IdRefResponsibleSupport;
        }
    }
    set responsibleSupport(data) { this.subProject.IdRefResponsibleSupport = data; }
    get implementationStatus(): string { return this.subProject.ImplementationStatus; }
    set implementationStatus(implementationStatus: string) { this.subProject.ImplementationStatus = implementationStatus; }

    constructor(data: SubProject) {
        this.subProject = data;
    }
}


export enum ProjectStateIcons {
    GRAY = '<svg id="uuid-d4900bfd-0e75-470d-9530-840165fa02e9" width="30px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.uuid-29b83086-9b6c-43d7-9fa9-1650b49d0949{fill:#a0a0a0;}.uuid-445aa89a-09dd-4d84-b1d3-6464edd6c6dc{fill:#a0a0a0;}</style></defs><circle class="uuid-445aa89a-09dd-4d84-b1d3-6464edd6c6dc" cx="200" cy="200" r="190"/><circle class="uuid-29b83086-9b6c-43d7-9fa9-1650b49d0949" cx="200" cy="200" r="150"/></svg>',
    GREEN = '<svg id="uuid-73745300-4b03-4813-bee1-1f392800853e" width="30px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.uuid-aabd77f3-071c-466b-9d38-14feff3c003a{fill:#009640;}.uuid-cfaefeb0-13b7-424b-94ee-d50b39444f36{fill:#009640;}</style></defs><circle class="uuid-cfaefeb0-13b7-424b-94ee-d50b39444f36" cx="200" cy="200" r="190"/><circle class="uuid-aabd77f3-071c-466b-9d38-14feff3c003a" cx="200" cy="200" r="150"/></svg>',
    YELLOW = '<svg id="uuid-4d847e32-a7be-4fef-a86b-cc8c0f9154dd" width="30px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.uuid-0101f693-3c33-43c8-84be-0ccce33cc32d{fill:#ffed00;}.uuid-826bb721-8a7a-452b-baea-3da94a40cc7b{fill:#ffed00;}</style></defs><circle class="uuid-826bb721-8a7a-452b-baea-3da94a40cc7b" cx="200" cy="200" r="190"/><circle class="uuid-0101f693-3c33-43c8-84be-0ccce33cc32d" cx="200" cy="200" r="150"/></svg>',
    RED = '<svg id="uuid-b7d586fc-ced1-4b71-9b35-e890cbe8f9df" width="30px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.uuid-fc0fd812-566a-419e-a32e-90353ef62936{fill:#e30613;}.uuid-7a089a3d-2554-43fc-921d-0567e17e7512{fill:#e30613;}</style></defs><circle class="uuid-7a089a3d-2554-43fc-921d-0567e17e7512" cx="200" cy="200" r="190"/><circle class="uuid-fc0fd812-566a-419e-a32e-90353ef62936" cx="200" cy="200" r="150"/></svg>'
}

export enum ProjectState {
    BEHINDSCHEDUEL = 'Behind schedule',
    NORISK = 'No Risk',
    CRITICAL = 'Critical',
    COUNTMEASURES = 'Counter measures necessary'
}
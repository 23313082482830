import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeoDsCoreDataService } from '@wissenswerft/core/data';
import { IAjaxHeader, IDashBoard } from '@wissenswerft/ww-library';
import { DataService } from '../services/data.service';

@Component({
  selector: 'ubt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public ubtDashboardConfig: IDashBoard;
  public isDashboard: boolean;
  constructor(
    public dataService: DataService,
    public coreDataService: GeoDsCoreDataService,
  ) { }

  public title = this.dataService.res('Ubt-Dashboard-No-Data');

  ngOnInit(): void {
    this.coreDataService.getCurrentProfile().subscribe(_profile => {
      const webAPI = localStorage.getItem('Base.API_URL');
      const profileId = _profile.Id;
      const currentCompanyId = sessionStorage.getItem('currentCompany');
      this.isDashboard = true;
      this.ubtDashboardConfig = new IDashBoard({
        endPoint: webAPI + '/api/Dashboards',
        dashboardId: '1e10b0b8-ba37-4f4c-954f-0f5d00800934',
        ajaxHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          ProfileId: profileId,
          P$CompanyId: currentCompanyId
        },
        allowExportDashboard: false,
        allowExportDashboardItems: false,
        class: 'Dashboard'
      })
    })
  }

  ngOnDestroy(): void { }

}

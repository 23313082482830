import { Address, Department, GeoDsMeta } from "@geods/base";
import { Event } from "./event.model";

export class SubProject extends GeoDsMeta {
    ParentId: string;
    Ident: string;
    Label: string;
    Event: Event;
    Highlights: string;
    Lowlights: string;
    IdRefResponsible: string;
    IdRefResponsibleSupport: string;
    ImplementationStatus: string;
    Company: Address;
    Department: Department;
    StartDate: Date;
    EndDate: Date

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TokenService } from './token.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    if (this.userService.isAuthenticated()) {
      return true;
    } else {
      localStorage.setItem('returnUrl',state.url);
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    }
  }
}

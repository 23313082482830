import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MeasureProgress } from '@xmt-models';
import { DxChartComponent } from 'devextreme-angular';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'ubt-geods-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnChanges {
  @ViewChild('chart') chart: DxChartComponent;
  @Input() progressChartData: MeasureProgress[] = [];
  public chartData: MeasureProgress[] = [];
  public maturityLevelCategories: Array<string> = ['HG1', 'HG2', 'HG3', 'HG4', 'HG5'];
  public weeksInterval: number = 1;
  public minorTickInterval: number = 1 / 7;
  private _isCalendarWeek: boolean = true;
  private _datepipe: DatePipe = new DatePipe('en-US');

  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnChanges(): void {
    if(this.progressChartData) {
      this.prepareChart();
    }
  }

  public prepareChart(): void {
    this.chartData = [];
    this.progressChartData.forEach((item, index) => {
      const currentItemWeek = this._datepipe.transform(item['ProgressDate'], 'w');
      if (index <= this.progressChartData.length) {
        const nextItemWeek = this._datepipe.transform(this.progressChartData[index + 1]?.ProgressDate, 'w');
        if (currentItemWeek !== nextItemWeek) {
          this.chartData.push(this.progressChartData[index]);
          if (parseInt(nextItemWeek) - parseInt(currentItemWeek) > 20) {
            this._isCalendarWeek = false;
            this.weeksInterval = 4;
            this.minorTickInterval = 1;
          }
        } 
      }
    });
    this.cdr.markForCheck();
  }

  public customizePercentText = (event) => {
    return event.value + '%';
  }

  public customizeDateText = (event) => {
    const format: string = this._isCalendarWeek == true ? 'w' : 'W';
    return this._datepipe.transform(event.value, format);
  }

  public getMaturityLevelDescription = (event) => {
    return this.dataService.res('Ubt-MeasureDetail-' + event.value);
  }
}

<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <dx-button class="side-by-side  return-button" stylingMode="text" icon="back" type="default"
                [hoverStateEnabled]="false" (onClick)="backToSubProjectList()">
            </dx-button>
            <h2 class="title-style">{{dataService.res('Ubt-SubProject-SubProjectReport')}}</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <div>
                <dx-button stylingMode="text" icon="chevronleft" type="default" (onClick)="goToNext(-1)"
                    class="btn-navigation" [disabled]="this.appService.currentSubProjectIndex === 0"
                    [hoverStateEnabled]="false">
                </dx-button>
            </div>
            <div>
                <dx-button stylingMode="text" class="btn-navigation" icon="chevronnext" type="default"
                    (onClick)="goToNext(1)"
                    [disabled]="this.appService.currentSubProjectIndex === this.appService.subProjectIds?.length - 1"
                    [hoverStateEnabled]="false">
                </dx-button>
            </div>
        </div>
    </div>
    <div class="content-block sub-project-container">
        <div class="dx-card responsive-paddings">
            <dx-gallery [dataSource]="reports" height="100%" width="100%" [loop]="true" [showIndicator]="false"
                [showNavButtons]="false">
                <div class="gallery-item-overflow" fxFill *dxTemplate="let galleryItem of 'item'">
                    <div fxLayoutAlign="space-between" class="gallery-item-label">
                        <span class="sub-title-style">
                            {{galleryItem.subProjectLabel}}
                        </span>

                        <dx-button [stylingMode]="'text'" [template]="'buttonTemplate'" [useSubmitBehavior]="false"
                            (click)="createPPt()" class="buttons-container active">
                            <div *dxTemplate="let tab of 'buttonTemplate'">
                                <div class="button-content">
                                    <img class="icon-style" [src]="'assets/images/Download_.svg'" alt="NotificationA" />
                                </div>
                            </div>
                        </dx-button>
                    </div>
                    <div fxLayout="row" class="gallery-item-height">
                        <div fxLayout="column" fxFlex="99">
                            <dx-data-grid #subProjectReport class="grid-group-by-Style custome-export-style"
                                [showRowLines]="true" (onCellClick)="onCellClick($event)"
                                (onCellPrepared)="onCellPrepared($event)" [columns]='columnsHeader' [showBorders]="true"
                                [dataSource]="reports" width="100%"
                                [noDataText]="dataService.res('Ubt-NoData-message')">
                                <dxo-summary>
                                    <dxi-total-item column="totalTime" summaryType="sum" valueFormat="#,##0 h"
                                        displayFormat="{{dataService.res('Ubt-Report-Total')}}: {0}">
                                    </dxi-total-item>
                                    <dxi-total-item column="executedTime" summaryType="sum" valueFormat="#,##0 h"
                                        displayFormat="{{dataService.res('Ubt-Report-Total')}}: {0}">
                                    </dxi-total-item>
                                </dxo-summary>
                                <div *dxTemplate="let progress of 'progressCellTemplate'">
                                    <div class="chart-cell">
                                        <dx-bullet [showTarget]="false" [showZeroLevel]="true" [startScaleValue]="0"
                                            [color]="'#00569c'" [endScaleValue]="100" [value]="progress.value">
                                            <dxo-size [width]="'auto'" [height]="26"></dxo-size>
                                            <dxo-tooltip [enabled]="true" [paddingTopBottom]="2"
                                                [customizeTooltip]="customizeTooltip">
                                                <dxo-font [size]="18"></dxo-font>
                                            </dxo-tooltip>
                                            <dxo-margin [top]="5" [bottom]="0" [left]="5"></dxo-margin>
                                        </dx-bullet>
                                    </div>
                                </div>
                            </dx-data-grid>
                        </div>
                    </div>
                    <div *ngIf="showReportsDetails == true" fxLayout="row" fxLayout.lt-md="column"
                        class="Reports-details">
                        <div fxFlex="49" fxLayout="column">
                            <h2 class="progress-title">
                                {{dataService.res('Ubt-MeasureDetail-Progress')}}</h2>
                            <div class="cyan"></div>
                            <div class="ellipsis" *ngFor="let item of reports">
                                <div *ngIf="item.progress">
                                    <div fxLayout="column" class="measure-title" fxFlex="35">
                                        {{item.measureTitle}}:
                                    </div>
                                    <div fxLayout="column" class="ellipsis" fxFlex="65">{{item.progress}}</div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="1"></div>
                        <div fxFlex="49" fxLayout="column">
                            <h2 class="progress-title">
                                {{dataService.res('Ubt-MeasureDetail-Next-Steps')}}</h2>
                            <div class=" cyan"></div>
                            <div class="ellipsis" *ngFor="let item of reports">
                                <div *ngIf="item.nextSteps">
                                    <div fxLayout="column" class="measure-title" fxFlex="35">
                                        {{item?.measureTitle}}:
                                    </div>
                                    <div fxLayout="column" class="ellipsis" fxFlex="65">{{item.nextSteps}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showReportsDetails == true" fxLayout="row" fxLayout.lt-md="column"
                        class="Reports-details">
                        <div fxFlex="49" fxLayout="column">
                            <h2 class="progress-title">
                                {{dataService.res('Ubt-MeasureDetail-Decision-Clarification')}}
                            </h2>
                            <div class=" cyan"></div>
                            <div class="ellipsis" *ngFor="let item of reports">
                                <div *ngIf="item.decision">
                                    <div fxLayout="column" class="measure-title" fxFlex="35">
                                        {{item?.measureTitle}}:
                                    </div>
                                    <div fxLayout="column" class="ellipsis" fxFlex="65">{{item.decision}}</div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="1"></div>
                        <div fxFlex="49" fxLayout="column">
                            <h2 class="progress-title">
                                {{dataService.res('Ubt-MeasureDetail-Risks-Opportunities')}}
                            </h2>
                            <div class="cyan"></div>
                            <div class="ellipsis" *ngFor="let item of reports">
                                <div *ngIf="item.riskAndChance">
                                    <div fxLayout="column" class="measure-title">
                                        {{item?.measureTitle}}:
                                    </div>
                                    <div fxLayout="column" fxFlex="90">{{item.riskAndChance}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dx-gallery>
        </div>
    </div>
</div>
<ubt-loader *ngIf="showLoader"></ubt-loader>
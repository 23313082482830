import { Injectable } from "@angular/core";
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, TargetColumnValue, TargetObjectData } from "@wissenswerft/core/data";
import { ObjectKeys } from "../../services/data.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AddressPerson, Person } from "@geods/base";

@Injectable({
    providedIn: 'root'
})
export class StaffMembersService {
    public isUpdating = false;

    public readonly personColumns: QueryColumn[] = [
        this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('FirstName', 'FirstName', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('LastName', 'LastName', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('EMail', 'EMail', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Login', 'Login', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Function', 'Function', QueryColumnSortOrder.None)
    ];

    public readonly documentsColumns: QueryColumn[] = [
        this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
        {
            Name: 'Object',
            OPath: "Object",
            Format: 'Base64Image[80x80]'
        }
    ];
    public readonly positionColumns: QueryColumn[] = [
        this.coreDataService.createQueryColumn('Id', 'Id'),
        this.coreDataService.createQueryColumn('Position', 'Position'),
    ];

    constructor(
        private persistenceService: GeoDsPersistenceService,
        private coreDataService: GeoDsCoreDataService,
    ) { }

    public getDocumentQuery(oPath?: string): QueryObjectsModel {
        const mainQuery: Query = new Query();
        mainQuery.ObjectType = ObjectKeys.PERSON;
        mainQuery.Columns = this.personColumns;
        mainQuery.OPath = 'ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'" + " && Not(LastName = 'Admin')";

        if (oPath) mainQuery.OPath = oPath;
        const subQuery: Query = new Query();
        subQuery.Name = 'Documents';
        subQuery.OPath = 'Documents';
        subQuery.Columns = this.documentsColumns;
        const positionQuery: Query = new Query();
        positionQuery.Name = 'AddressPerson';
        positionQuery.OPath = 'AddressPerson_IdRefPerson_Person';
        positionQuery.Columns = this.positionColumns;
        mainQuery.ObjectQueries = [subQuery, positionQuery];
        const queryDocument: QueryObjectsModel = new QueryObjectsModel();
        queryDocument.ObjectQueries = [mainQuery];
        return queryDocument;
    }

    public persistStafMemeberPosition = (
        position: AddressPerson,
        idCompany: string,
        idPerson: string
    ): Observable<any> => {
        const personInternalRoleInsertObjectData: TargetObjectData = new TargetObjectData();
        personInternalRoleInsertObjectData.ObjectKey = new ObjectKey();
        personInternalRoleInsertObjectData.ObjectKey.ObjectType = ObjectKeys.ADDRESSPERSON;
        const addressPersonObjectColumns: TargetColumnValue[] = [
            { Name: 'Position', Value: position.Position },
            { Name: 'ParentId', Value: idCompany },
            { Name: 'IdRefPerson', Value: idPerson }
        ];
        if (position?.Id !== undefined && position?.Id !== null) {
            personInternalRoleInsertObjectData.ObjectKey.Id = position?.Id;
            personInternalRoleInsertObjectData.Mode = PersistMode.Update;
        } else {
            personInternalRoleInsertObjectData.Mode = PersistMode.Insert;
        }
        personInternalRoleInsertObjectData.TargetColumns = addressPersonObjectColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = personInternalRoleInsertObjectData;
        return this.persistenceService.executePersistObjectQuery(persistObject);
    };

    public persistStaffMember = (staffMember: Person): Observable<any> => {
        const staffMemberPersistQuery: TargetObjectData = new TargetObjectData();
        staffMemberPersistQuery.ObjectKey = new ObjectKey();
        staffMemberPersistQuery.ObjectKey.ObjectType = ObjectKeys.PERSON;
        const staffMemberColumns: TargetColumnValue[] = [
            { Name: 'FirstName', Value: staffMember.FirstName },
            { Name: 'LastName', Value: staffMember.LastName },
            { Name: 'EMail', Value: staffMember.EMail },
            { Name: 'Login', Value: staffMember.Login },
            { Name: 'Function', Value: staffMember.Function },
            { Name: 'ParentId', Value: sessionStorage.getItem("currentCompany") },
        ];
        if (staffMember.Id) {
            this.isUpdating = true;
            staffMemberPersistQuery.Mode = PersistMode.Update;
            staffMemberPersistQuery.ObjectKey.Id = staffMember.Id;
        } else {
            this.isUpdating = false;
            staffMemberPersistQuery.Mode = PersistMode.Insert;
        }
        staffMemberPersistQuery.TargetColumns = staffMemberColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = staffMemberPersistQuery;
        return this.persistenceService.executePersistObjectQuery(persistObject)
    }
}
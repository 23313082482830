import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class AssetsManagerService {
    constructor(private httpClient: HttpClient) {
    };
    getFile(url: string): Observable<any> {
        return this.httpClient.get(url, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response.status === 200) {
                    return response.body;
                }
            })
        );
    }
}


<div fxLayout="column" fxFlexFill fxLayoutGap="10px" class="dx-card popup-card-paddings card-radius">
    <form action="post" (submit)="createMeasure($event)">
        <dx-form #form id="form" [(formData)]="measure" [colCount]="1">
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item [isRequired]="true">
                    <dx-select-box class="drop-down-style" valueExpr='Id' displayExpr="Label" [stylingMode]="'outlined'"
                        [labelMode]="'floating'" [(value)]="measure.SubProject" [dataSource]="subProjects"
                        (onValueChanged)="onValueChanged($event)"
                        [label]="dataService.res('Ubt-MeasureTask-SubProject')">
                    </dx-select-box>
                    <dxi-validation-rule type="required"
                        message="{{dataService.res('Ubt-validator-RequiredField')}}"></dxi-validation-rule>
                </dxi-item>
                <dxi-item>
                    <dx-text-box [stylingMode]="'outlined'" [width]="'100%'" [(value)]="measure.Title"
                        [label]="dataService.res('Ubt-Measure-List-Designation')" [labelMode]="'floating'">
                    </dx-text-box>
                    <dxi-validation-rule type="required" message="{{dataService.res('Ubt-validator-RequiredField')}}">
                    </dxi-validation-rule>
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item [isRequired]="true">
                    <dx-date-box class="date-box-style" [type]="'date'" [stylingMode]="'outlined'"
                        [(value)]="measure.StartDate" [label]="dataService.res('Ubt-Measure-List-StartDate')"
                        [labelMode]="'floating'">
                    </dx-date-box>
                    <dxi-validation-rule type="required"
                        message="{{dataService.res('Ubt-validator-RequiredField')}}"></dxi-validation-rule>
                </dxi-item>
                <dxi-item>
                    <dx-date-box class="date-box-style" [(value)]="measure.EndDate" [type]="'date'"
                        [stylingMode]="'outlined'" [label]="dataService.res('Ubt-Measure-List-EndDate')"
                        [labelMode]="'floating'">
                    </dx-date-box>
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item>
                    <dx-select-box class="drop-down-style" valueExpr='Id'
                        [displayExpr]="dataService.getResponsibleFullName" [stylingMode]="'outlined'"
                        [(value)]="measure.Responsible" [dataSource]="dataService.cachedResponsiblesResponse"
                        [label]="dataService.res('Ubt-CreateMeasure-Responsible')" [labelMode]="'floating'"
                        [searchEnabled]="true">
                    </dx-select-box>
                </dxi-item>
                <dxi-item>
                    <dx-select-box class="drop-down-style" valueExpr='Id'
                        [displayExpr]="dataService.getResponsibleFullName" [stylingMode]="'outlined'"
                        [(value)]="measure.AreaManager" [dataSource]="dataService.cachedResponsiblesResponse"
                        [label]="dataService.res('Ubt-CreateMeasure-AreaManager')" [labelMode]="'floating'">
                    </dx-select-box>
                </dxi-item>
            </dxi-item>
            <dxi-item>
                <dx-tag-box class="drop-down-style" valueExpr='Id' [(value)]="measure.MeasureResources"
                    [displayExpr]="dataService.getResponsibleFullName" [width]="'100%'" [stylingMode]="'outlined'"
                    [dataSource]="dataService.cachedResponsiblesResponse"
                    [label]="dataService.res('Ubt-CreateMeasure-Members')" [labelMode]="'floating'">
                </dx-tag-box>
            </dxi-item>
            <dxi-item>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span
                        class="html-editor-title-style">{{dataService.res('Ubt-Measure-List-InitialSituation')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(measure.InitialSituation,'InitialSituation')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='60px' [(value)]="measure.InitialSituation">
                </dx-html-editor>
            </dxi-item>
            <dxi-item>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="html-editor-title-style">{{dataService.res('Ubt-CreateMeasure-Objective')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(measure.Goal,'Goal')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='60px' [(value)]="measure.Goal">
                </dx-html-editor>
            </dxi-item>
            <dxi-item>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="html-editor-title-style">{{dataService.res('Ubt-Measure-List-Concept')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(measure.Concept,'Concept')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='60px' [(value)]="measure.Concept">
                </dx-html-editor>
            </dxi-item>

            <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                <dxi-item>
                    <dx-check-box class="check-box-create-another"
                        text="{{dataService.res('Ubt-CreateMeasure-Create-Another')}}" [(value)]="createSecondMeasure">
                    </dx-check-box>
                </dxi-item>
                <dxi-item itemType="button" [cssClass]="'submit-button'" [buttonOptions]="createButtonOptions">
                </dxi-item>
                <dxi-item itemType="button" [cssClass]="'submit-button'"
                    [buttonOptions]="createAndOpenDetailsButtonOptions"></dxi-item>
                <dxi-item itemType="button" [cssClass]="'cancel-button'" [buttonOptions]="cancelButtonOptions">
                </dxi-item>
            </dxi-item>
        </dx-form>
    </form>
</div>

<dx-popup [height]="540" [width]="'50%'" [(visible)]="toogleTitleVisible" [resizeEnabled]="true"
    title="{{dataService.res('Ubt-Measure-Detail-Html-Editor-Title')}}">
    <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="95">
                {{dataService.res('Ubt-Html-Editor-'+propertyName)}}
            </div>
            <div fxLayout="column" fxFlex="5">
                <dx-button class="edit-text" type="default" [icon]="'save'" [hint]="'Save'"
                    (onClick)="saveChanges(propertyName, fieldValue)">
                </dx-button>
            </div>
        </div>
        <div fxLayout="row">
            <dx-html-editor #htmlEditor height="350px" [width]="'100%'" [(value)]="fieldValue"
                class="html-editor-style">
                <dxo-toolbar [multiline]="false">
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']">
                    </dxi-item>
                    <dxi-item name="font" [acceptedValues]="[
                      'Arial',
                      'Courier New',
                      'Georgia',
                      'Impact',
                      'Lucida Console',
                      'Tahoma',
                      'Times New Roman',
                      'Verdana'
                    ]"></dxi-item>
                    <dxi-item name="color"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="insertTable"></dxi-item>
                    <dxi-item name="deleteTable"></dxi-item>
                    <dxi-item name="insertRowBelow"></dxi-item>
                </dxo-toolbar>
            </dx-html-editor>
        </div>
    </div>
</dx-popup>
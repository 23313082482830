import { Component } from '@angular/core';

@Component({
    selector: 'ubt-loader',
    template: `<div class="fond">
            <div class="contener_general" style="margin-inline: auto;">
                <div class="contener_mixte">
                    <div class="ballcolor ball_1">&nbsp;</div>
                </div>
                <div class="contener_mixte">
                    <div class="ballcolor ball_2">&nbsp;</div>
                </div>
                <div class="contener_mixte">
                    <div class="ballcolor ball_3">&nbsp;</div>
                </div>
                <div class="contener_mixte">
                    <div class="ballcolor ball_4">&nbsp;</div>
                </div>
            </div>
            </div>`,
    styleUrls: ['./ubt-loader.component.scss']
})
export class UbtLoaderComponent {

}
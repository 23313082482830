<dx-accordion class="accordion-style" [collapsible]="true" [selectedIndex]="0" [width]="'100%'"
    [animationDuration]="300" [visible]="true" [multiple]="false" [dataSource]="['Test']">
    <div *dxTemplate="let description of 'title'" class="dx-accordion-item-title">
        {{this.dataService.res("Ubt-Collaborator-Total-Time")}}
    </div>

    <div fxLayout="column" class="card-paddings container-card" fxLayoutGap="15px"
        *dxTemplate="let description of 'item'">
        <ww-grid [groupable]="false" [noDataText]="noDataText" [enableStateStorage]="false"
            [columnsHeader]="measureService.collaboratorsTotalTimeColumns" [gridData]="collaborators"
            [searchPanel]="false" [enableColumnChooser]="false" [enableExport]="false" [showfilterrow]="false"
            [allowAdding]="false" [selectionMode]="'single'" [columnFilter]="false" [showRowLines]="false"
            [editingMode]="'row'" [showColumnLines]="false" [allowDeleting]="false" [allowUpdating]="false"
            [linkProperty]="'text'" [filterPanel]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false">
        </ww-grid>
    </div>
</dx-accordion>
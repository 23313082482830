import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '@geods/base';
import { CompaniesService, IProjectDependency } from '../companies.service';

@Component({
  selector: 'wissenswerft-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent {
  @Input() companies: Address[];
  @Input() filterActive = false;
  @Input() clonedCompanies: Address[];
  @Input() comapnyNumber = 0;
  @Input() navigateUrl: string;
  @Input() companyIdDependency: IProjectDependency;
  @Input() companyNameDependency: IProjectDependency;
  @Input() configDependencies: IProjectDependency[];
  @Output() clickCompany = new EventEmitter<Address>();

  constructor(
    public companiesService: CompaniesService
  ) { }

  public selectCompany(company: Address) {
    var companyWithProject = document.getElementById("companyWithProject");
    var companies = document.getElementById("companies");
    var titleCompany = document.getElementById("title-company");
    var titleProject = document.getElementById("title-project");
    var titleIcon = document.getElementById("title-icon");
    var searchBloc = document.getElementById("searchBloc");

    if (companyWithProject.style.display === "flex") {
      companyWithProject.style.display = "none";
      companies.style.display = "block";
      titleCompany.style.display = "block";
      titleProject.style.display = "none";
      titleIcon.style.display = "none";
      searchBloc.style.display = "none";
    } else {
      companyWithProject.style.display = "block";
      companies.style.display = "none";
      titleCompany.style.display = "none";
      titleProject.style.display = "block";
      titleIcon.style.display = "block";
      searchBloc.style.display = "none";
    }
    sessionStorage.setItem("currentCompany", company.Id);
    sessionStorage.setItem("companyName", company.Name);
    this.companiesService.selectedCompanyData(true);
    this.clickCompany.emit(company);
  }

  public updateContent(event): void {    
    if (!this.filterActive) {
      this.companies = this.clonedCompanies.slice(0, this.comapnyNumber + 100);
      this.comapnyNumber += 100;
    }
    event.component.release();
  }

  public validationFunction(company): boolean {
    return (company.HouseNumber || company.Street || company.ZIP || company.City || company.Country) ? true : false;
  }
}
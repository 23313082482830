import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxContextMenuComponent, DxFileUploaderComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Observable, Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../services/data.service';
import { DocumentKind, GeoDsDocument } from '@geods/base';
import { GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { loadMessages } from 'devextreme/localization';
import { Project } from '@xmt-models';
import { DocumentService } from './document.service';
import { switchMap } from 'rxjs/operators';
import { DefaultIcons } from '../../customer-space/measure/measure-detail/implementation-plan/implementation-plan.component';

@Component({
  selector: 'ubt-geods-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('documentsGrid', { static: false }) documentsGrid: GridComponent;
  @ViewChild('addDocumentSelectBox') addDocumentSelectBox: DxSelectBoxComponent;
  @ViewChild('addDocumentPopup') addDocumentPopup: DxPopupComponent;
  @ViewChild('filterProjectList') filterProjectList: DxSelectBoxComponent;
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;
  @ViewChild('deleteDocumentConfirmationPopup') deleteConfirmationPopup: DxPopupComponent;
  @ViewChild('contextMenu') contextMenu: DxContextMenuComponent;

  @Input() showAllDocuments = true;
  @Input() documentOpath = false;
  @Input() parentId: string;
  @Input() designation: string;
  @Input() documents: GeoDsDocument[] = [];
  public uploadDocument: GeoDsDocument = new GeoDsDocument(null);
  public document: GeoDsDocument = new GeoDsDocument(null);
  public documentsColumnsHeader: Column[] = [];
  public columns: QueryColumn[] = [];
  public showLoader = false;
  public projectList: Project[] = [];
  private subscriptions: Subscription[] = [];
  private opath = "";
  public noDataText = "Keine Daten";
  public isReset: boolean = false;
  public allDocuments: GeoDsDocument[] = [];
  public contextMenuItems: any[] = [
    {
      text: this.dataService.res('Ubt-Documents-Preview-Document'),
      onClick: (event) => {
        this.dataService.previewDocument(this.documents);
      },
    },
    {
      text: this.dataService.res('Ubt-Documents-Download-Document'),
      onClick: () => {
        this.downloadDocument(this.documents['Id']);

      },
    },
    {
      text: this.dataService.res('Ubt-Documents-Delete-Document'),
      onClick: () => {
        this.openDeleteConfirmationDialog();
      },
    },
  ];

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    private documentService: DocumentService,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.showLoader = true;
    this.dataService.floatingButtonConfig();
    loadMessages({
      en: {
        'Ubt-Measure': 'Object type',
        'Ubt-Measure-List-Title': 'Title',
        'Ubt-Document-Date': 'Date',
        "Ubt-Documents-Preview-Document": "Preview Document",
        "Ubt-Documents-Download-Document": "Download Document",
        "Ubt-Documents-Delete-Document": "Delete Document",
        'Ubt-NoData-message': 'No Data'
      },
      de: {
        'Ubt-Measure': 'Objekttyp',
        'Ubt-Measure-List-Title': 'Titel',
        'Ubt-Document-Date': 'Datum',
        "Ubt-Documents-Preview-Document": "Vorschau Dokument",
        "Ubt-Documents-Download-Document": "Dokument herunterladen",
        "Ubt-Documents-Delete-Document": "Dokument löschen",
        'Ubt-NoData-message': 'Keine Daten'
      }
    });

    this.documentsColumnsHeader = [
      {
        caption: this.dataService.res('Ubt-Measure'),
        dataField: 'MeasureDesignation',
        visible: this.parentId === undefined,
        dataType: 'string',
        width: '80%',
        visibleIndex: 0
      },
      {
        caption: this.dataService.res('Ubt-Measure-List-Title'),
        dataField: 'FileName',
        dataType: 'string',
        width: '80%',
        visibleIndex: 1
      },
      {
        caption: this.dataService.res('Ubt-Document-Date'),
        dataField: 'Date',
        dataType: 'date',
        format: 'dd.MM.yyyy',
        minWidth: 100,
        visibleIndex: 2
      },
      {
        type: 'buttons',
        caption: '',
        minWidth: 50,
        width: '50px',
        dataField: '',
        visibleIndex: 3,
        buttons: [
          {
            icon: 'find',
            onClick: (event) => {
              this.dataService.previewDocument(event.row.data);
            }
          },
          {
            icon: 'assets/images/Download_.svg',
            onClick: (event) => {
              this.downloadDocument(event.row.data.Id);
            }
          },
          {
            cssClass: 'delete',
            icon: 'delete',
            onClick: () => {
              this.openDeleteConfirmationDialog();
            }
          }
        ]
      }
    ];

    this.dataService.uploadData$.subscribe((uploadData: GeoDsDocument) => {
      this.uploadDocument = uploadData;
    });

    this.dataService.updateGridData$.subscribe((document: GeoDsDocument) => {
      this.getDocumentByIdAndUpdate(this.documentService.documentColumns, document.Id);
    });
  }

  public onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === '') {
        e.cellElement.style.cursor = 'pointer';
        return (e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU);
      }
    }
  }

  public onCellClick = (e) => {
    this.document = e.data;
    if (e.columnIndex === 3) {
      e.cellElement.className = 'context-menu-icon' + e.rowIndex;
      this.contextMenu.target = '.context-menu-icon' + e.rowIndex;
      e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU;
      this.contextMenu.instance.show();
    }
  }

  private refreschDocuments(): Observable<GeoDsDocument[]> {
    return this.documentService.getMeasureWithDocuments().pipe(
      switchMap((measureData) => {
        const measures = measureData.map((element) => element.Id);
        measures.push(sessionStorage.getItem('currentCompany'));
        this.opath = 'ParentId in ' + `(${measures.map((_documents) => "'" + _documents + "'")})`;
        return this.documentService.getAllDocument(this.opath);
      })
    );
  }


  public prepareProjectList = (): void => {
    this.documentService
      .getProjectList()
      .subscribe((_project) => {
        if (Array.isArray(_project) && _project.length > 0) {
          this.projectList = _project;
          this.cdr.markForCheck();
        }
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR })
        console.error(error);
      }, () => {
        this.showLoader = false;
      });
  }

  public filterDocumentByProject = (event) => {
    const projecId = event.selectedItem.Id;
    this.documentService.getDocumentsByProjectId(projecId).subscribe((_projects) => {
      if (Array.isArray(_projects) && _projects.length > 0) {
        this.documents = _projects;
        this.isReset = true
        this.cdr.markForCheck();
      }
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR })
      console.error(error);
    }, () => {
      this.showLoader = false;
    })
  }

  public ngAfterViewInit(): void {
    if (sessionStorage.getItem("currentCompany")) {
      if (this.parentId != null) {
        this.loadDocuments();
      } else if (this.parentId === undefined) {
        this.refreschDocuments().subscribe((_documents: GeoDsDocument[]) => {
          if (Array.isArray(_documents) && _documents.length > 0) {
            this.documents = _documents.map(_document => {
              if (_document['SubProjectDesignation']) {
                _document['MeasureDesignation'] = _document['SubProjectDesignation'] + '/' + _document['MeasureDesignation'];
              }
              return _document;
            });
            this.allDocuments = this.documents
            this.documentsGrid.refreshGrid();
            this.cdr.markForCheck();
          }
        });
      }
    } else {
      this.dataService.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      this.showLoader = false;
      this.cdr.detectChanges();
    }
  }

  private loadDocuments = (): void => {
    const opath = 'ParentId=' + "'" + this.parentId + "'";
    this.documentService.getAllDocument(opath).subscribe((documents) => {
      if (Array.isArray(documents) && documents.length > 0) {
        this.documents = documents;
        this.documentsGrid.refreshGrid();
        this.cdr.markForCheck();
      }
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR })
      console.error(error);
    }, () => {
      this.showLoader = false;
    });
  }

  private getDocumentByIdAndUpdate(columns: QueryColumn[], id: string) {
    const opath = 'Id=' + "'" + id + "'";
    this.documentService.getAllDocument(opath).subscribe((data) => {
      if (Array.isArray(data) && data.length > 0) {
        this.documents.push(data[0]);
      }
      this.documentsGrid.refreshGrid();
      this.cdr.markForCheck();
      this.dataService.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    });
  }

  public addDocument = () => {
    this.addDocumentPopup.instance.show();
  }

  public closeDocumentPopUp = () => {
    this.fileUploader.instance.reset();
    this.addDocumentPopup.instance.hide();
  }

  public onFileUpload(event, document): void {
    this.dataService.onFileChanged(event, document);
  }

  public uploadFile = (file) => {
    if (this.uploadDocument.Object.length !== 0) {
      this.uploadDocument.ParentId = this.parentId ? this.parentId.toString() : sessionStorage.getItem("currentCompany");
      this.uploadDocument.Designation = file.name.split('.')[0];
      this.uploadDocument.FileName = file.name;
      this.uploadDocument.Kind = DocumentKind.Datei;
      this.uploadDocument.ParentObjectType = ObjectKeys.DOCUMENT;
      this.dataService.persistUploadDocument(this.uploadDocument);
      this.showLoader = true;
    }
  }

  public assginDocumentToMeasure(): void {
    //TODO: Assign doc to measure (parentId)
    // if (event.value) {
    //   this.documents.push(event.value);
    //   this.measureService.updateMeasure('MeasureDocuments', event.value);
    //   this.addDocumentSelectBox.instance.reset();
    // }
  }

  public downloadDocument = (id: string): void => {
    this.showLoader = true;
    const opath = 'Id=' + "'" + id + "'";
    this.documentService.getAllDocument(opath).subscribe((data) => {
      this.dataService.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
      this.dataService.downloadFile(new GeoDsDocument(data[0]));
      this.showLoader = false;
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.documentsGrid.dxDataGrid.instance.updateDimensions();
    });
  }

  public removeRow(): void {
    this.documentService.deleteDocument(this.document.Id).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.deleteConfirmationPopup.instance.hide();
      const index = this.documents.findIndex(item => item.Id === this.document.Id);
      this.documents.splice(index, 1);
      this.documentsGrid.refreshGrid();
    }, error => {
      console.error(error)
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public resetData(): void {
    this.documents = this.allDocuments;
    this.filterProjectList.value = null;
    this.showLoader = false;
    this.isReset = false;
    this.cdr.markForCheck();
  }

  public openDeleteConfirmationDialog(): void {
    this.deleteConfirmationPopup.instance.show();
  }

  public abortDelete() {
    this.deleteConfirmationPopup.instance.hide();
  }

  public ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@wissenswerft/core/authentication';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel, QueryColumnSortOrder, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { Project } from '@xmt-models';
import { DxContextMenuComponent, DxFormComponent, DxPopupComponent, DxTextBoxComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { AppService } from '../services/app.service';
import { DataService, ObjectKeys } from '../services/data.service';
import DataSource from 'devextreme/data/data_source';
import { Column } from 'devextreme/ui/data_grid';
import { DatePipe } from '@angular/common';
import { DefaultIcons } from '../customer-space/measure/measure-detail/implementation-plan/implementation-plan.component';
@Component({
  selector: 'ubt-geods-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('createProjectpopup') createProjectpopup: DxPopupComponent;
  @ViewChild('deleteProjectpopup') deleteProjectpopup: DxPopupComponent;
  @ViewChild('contextMenu') contextMenu: DxContextMenuComponent;

  public userName: string;
  private subscriptions: Subscription[] = [];
  public projectList: Project[] = [];
  public isUpdating: boolean;
  public filtredSubprojects: DataSource;
  public project = new Project(null);
  private selectedProjectId: string;
  private projectToDeleteIndex: number;
  public showLoader = false;
  public oldValueOfStartDate = new Date;
  public oldValueOfEndDate = new Date;
  public oldValueOfDesignation = '';
  public oldValueOfState = '';
  public oldValueOfDescription = '';
  public title = this.dataService.res('Ubt-Project-Create');
  public noDataText = "Keine Daten";
  public projectColumns = [
    this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('ref:ParentId', 'ParentAddress', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('SysDateInsert', 'Date', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('State', 'State', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Count(subProjects)', 'subProjectsCount', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Count(subProjects.Measures)', 'measuresCount', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Count(subProjects.Measures.MeasureTasks)', 'tasksCount', QueryColumnSortOrder.None)
  ];
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Ubt-Project-Name'),
      dataField: 'Label',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-MeasureDetail-Description'),
      dataField: 'Description',
      dataType: 'string',
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Ubt-Measure-List-StartDate'),
      dataField: 'StartDate',
      dataType: 'date',
      format: 'dd.MM.yyyy',
      visibleIndex: 2
    },
    {
      caption: this.dataService.res('Ubt-MeasureTask-State'),
      dataField: 'State',
      dataType: 'number',
      alignment: 'left',
      visibleIndex: 3
    },
    {
      caption: this.dataService.res('Ubt-Measure-List-EndDate'),
      dataField: 'EndDate',
      dataType: 'date',
      format: 'dd.MM.yyyy',
      visibleIndex: 4
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      dataField: '',
      visibleIndex: 5,
      minWidth: 50,
      width: '50px',

    }
  ];

  public createButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Create'),
    useSubmitBehavior: true
  };
  public updateButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Update'),
    useSubmitBehavior: true
  };
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => {
      this.closeCreatePopUp()
    }
  };

  public contextMenuItems: any[] = [
    {
      text: this.dataService.res('Ubt-Roles-Edit-Project'),
      onClick: (event) => {

        this.openProjectDialog(event);
      },
    },
    {
      text: this.dataService.res('Ubt-Roles-Delete-Project'),
      onClick: (event) => {
        this.openDeleteProjectDialog(event)
      },
    },
  ];

  constructor(
    private coreDataService: GeoDsCoreDataService,
    private userService: UserService,
    public dataService: DataService,
    private appService: AppService,
    private persistenceService: GeoDsPersistenceService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(this.userService.getProfileInformations().subscribe(user => {
      this.userName = user.DisplayText;
    }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe(companyId => {
      this.getProjectsData(companyId);
    }));

    if (sessionStorage.getItem("currentCompany")) {
      this.getProjectsData(sessionStorage.getItem("currentCompany"));
    }
  }


  public onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === '') {
        e.cellElement.style.cursor = 'pointer';
        return (e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU);
      }
    }
  }

  public onCellClick = (e) => {
    this.project = e.data;
    if (e.columnIndex === 5) {
      e.cellElement.className = 'context-menu-icon' + e.rowIndex;
      this.contextMenu.target = '.context-menu-icon' + e.rowIndex;
      e.cellElement.innerHTML = this.contextMenu.visible ? DefaultIcons.CLOSE : DefaultIcons.CONTEXTMENU;
      this.contextMenu.instance.show();
    } else if (e.columnIndex !== 5) {
    }
  }

  private getProjectsData(companyId: string) {
    this.showLoader = true;
    const opath = 'ParentId=' + "'" + companyId + "'";
    this.dataService.readObjects<Project[]>(ObjectKeys.PROJECT, this.projectColumns, opath).subscribe(projects => {
      if (Array.isArray(projects) && projects.length > 0) {
        this.projectList = projects;
      } else {
        this.projectList = [];
        this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
      this.cdr.markForCheck();
    }, () => {
      this.showLoader = false;
      this.cdr.markForCheck();
    })
  }

  public openDeleteProjectDialog(event): void {
    this.selectedProjectId = event.row?.data.Id;
    this.deleteProjectpopup.instance.show();
  }

  public openProjectDialog(event) {
    this.isUpdating = true;
    this.title = this.dataService.res('Ubt-Project-Update');
    this.cdr.markForCheck();
    this.oldValue(true);
  }

  public oldValue(isUpdate?: boolean): void {
    this.isUpdating = isUpdate;
    this.oldValueOfDesignation = this.project.Label;
    this.oldValueOfStartDate = this.project.StartDate;
    this.oldValueOfEndDate = this.project.EndDate;
    this.oldValueOfState = this.project.State;
    this.oldValueOfDescription = this.project.Description;
    this.createProjectpopup.instance.show();
  }

  private closeCreatePopUp(persist?: boolean) {
    if (!persist) {
      if (this.isUpdating) {
        this.project.Label = this.oldValueOfDesignation;
        this.project.Description = this.oldValueOfDescription;
        this.project.State = this.oldValueOfState;
        this.project.StartDate = this.oldValueOfStartDate;
        this.project.EndDate = this.oldValueOfEndDate;
        this.isUpdating = false;
      }
    }
    this.createProjectpopup.instance.hide();
    this.project = new Project(null);
    this.isUpdating = false;
    this.form.instance.resetValues();
    this.title = this.dataService.res('Ubt-Project-Create');
    this.createProjectpopup.instance.hide();
  }

  public confirmRemoveProject(e): void {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.PROJECT, this.selectedProjectId).subscribe(() => {
      this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.projectList.splice(this.projectToDeleteIndex, 1);
      this.deleteProjectpopup.instance.hide();
    }, error => {
      console.error(error)
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      this.selectedProjectId = undefined;
    }, () => {
      this.selectedProjectId = undefined;
      this.cdr.markForCheck();
    }));
  }

  public abort(): void {
    this.selectedProjectId = undefined;
    this.deleteProjectpopup.instance.hide();
  }

  public openCreateProject() {
    this.isUpdating = false;
    this.createProjectpopup.instance.show();
    this.project = new Project(null);
  }

  public createProject(e) {
    if (this.isUpdating === false) {
      e.preventDefault();
      this.persistProject(PersistMode.Insert);
    }
    else {
      this.persistProject(PersistMode.Update);
      e.preventDefault();
    }
  }

  private persistProject(mode: PersistMode): void {
    const projectPersistQuery: TargetObjectData = new TargetObjectData();
    projectPersistQuery.ObjectKey = new ObjectKey();
    projectPersistQuery.ObjectKey.ObjectType = ObjectKeys.PROJECT;
    const projectColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: sessionStorage.getItem('currentCompany') },
      { Name: 'Designation', Value: this.project.Label },
      { Name: 'Description', Value: this.project.Description },
      { Name: 'StartDate', Value: this.datePipe.transform(this.project.StartDate, "yyyy-MM-dd") },
      { Name: 'EndDate', Value: this.datePipe.transform(this.project.EndDate, "yyyy-MM-dd") },
      { Name: 'State', Value: this.project.State },
    ];
    if (mode == PersistMode.Update) projectPersistQuery.ObjectKey.Id = this.project.Id;
    projectPersistQuery.Mode = mode;
    projectPersistQuery.TargetColumns = projectColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = projectPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      if (!this.isUpdating) {
        this.getLatestProject(data.Id);
      }
      this.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.closeCreatePopUp(true);
      this.project = new Project(null);
    }));
  }

  public getLatestProject(id: string): void {
    const opath = `Id='${id}'`;
    this.dataService.readObjects<Project[]>(ObjectKeys.PROJECT, this.projectColumns, opath).subscribe(projectData => {
      this.projectList.push(projectData[0]);
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}

import { Injectable } from "@angular/core";
import { CoreConfigService } from "@wissenswerft/core/configuration";
import { SpaceOneWebApiServiceURLs } from "libs/core/configuration/src/lib/models/config.model";
import { Observable } from "rxjs";
import { ConvertHelper } from "./helpers";
import { DataService, TypeKey } from "./spaceOne/data.service";

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private pack = [];
    private createItemUri: string;
    private updateItemUri: string;
    private deleteItemUri: string;
    private commentUri: string;
    private noteUri: string;
    private assetsUri: string;
    private deleteCommentUri: string;
    private deleteDocumentUri: string;


    constructor(private dataService: DataService, private configService: CoreConfigService) {
        const serviceUrls: SpaceOneWebApiServiceURLs = <SpaceOneWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
        this.createItemUri = serviceUrls.CreateItem;
        this.updateItemUri = serviceUrls.UpdateOrDeleteItem;
        this.deleteItemUri = serviceUrls.UpdateOrDeleteItem;
        this.deleteCommentUri = serviceUrls.CommentDeleteById;
        this.commentUri = serviceUrls.CommentsByItemId;
        this.noteUri = serviceUrls.NoteByItemId;
        this.assetsUri = serviceUrls.AssetsById;
        this.deleteDocumentUri = serviceUrls.DocumentById;
    }

    public addObjectValue(object: any, fieldName: string, newValue: any) {
        this.pack.push({
            [fieldName]: newValue
        });
    }

    public addObjectForInsert(name: TypeKey, query): Observable<any> {
        return this.dataService.executeWebApiPostMethod(ConvertHelper.resolveStringPlaceholders(this.createItemUri, localStorage.getItem('scopeKey') || this.dataService.scope, name), query);
    }

    public addObjectForUpdate(id: number, query): Observable<any> {
        return this.dataService.executeWebApiPutMethod(ConvertHelper.resolveStringPlaceholders(this.updateItemUri, localStorage.getItem('scopeKey') || this.dataService.scope, id), query);
    }

    public addObjectForDelete(id: number): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod(ConvertHelper.resolveStringPlaceholders(this.deleteItemUri, localStorage.getItem('scopeKey') || this.dataService.scope, id));
    }

    public addComment(query, id): Observable<any> {
        return this.dataService.executeWebApiPostMethod(ConvertHelper.resolveStringPlaceholders(this.commentUri, localStorage.getItem('scopeKey') || this.dataService.scope, id), query);
    }

    public addNote(query, id): Observable<any> {
        return this.dataService.executeWebApiPostMethod(ConvertHelper.resolveStringPlaceholders(this.noteUri, localStorage.getItem('scopeKey') || this.dataService.scope, id), query);
    }

    public updateComment(id, query): Observable<any> {
        return this.dataService.executeWebApiPutMethod(ConvertHelper.resolveStringPlaceholders(this.commentUri, localStorage.getItem('scopeKey') || this.dataService.scope, id), query);
    }

    public deleteComment(postingId, itemId): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod(ConvertHelper.resolveStringPlaceholders(this.deleteCommentUri, localStorage.getItem('scopeKey') || this.dataService.scope, itemId, postingId));
    }

    public deleteDocument(id: number): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod(ConvertHelper.resolveStringPlaceholders(this.deleteDocumentUri, localStorage.getItem('scopeKey') || this.dataService.scope, id));
    }

}
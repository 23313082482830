import { AssetsManagerService } from './abc-core-assets-manager.service';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { HttpClient, HttpXhrBackend } from "@angular/common/http";

export const loadedResources: Map<string, object> = new Map<string, object>();
export class ResourceManager {
    static assetsManagerService: AssetsManagerService;
    public static readonly DEV_EXTREME = 'DevExtreme';

    constructor(private resourceSet: string) {
        // ResourceManager.assetsManagerService = new AssetsManagerService(this.httpClient);
        if (resourceSet) {
            ResourceManager.loadResource(`assets/resources/${resourceSet}.${ResourceManager.getLanguage()}.json`, this.resourceSet);
        }
        // Load devExtreme localisation if needed
        this.loadDevExtremeResources();
    }

    /**
     * @static
     * @param {string} resourceSet Ex: 'abc-messages-messageBox' 
     * @description The german json file should be named this way: abc-messages-messageBox.de.json
     * 
     * @returns function formatMessage(key: string, value?: string | string[])
     * @example 
     * const res = ResourceManager.getResources('abc-messages-messageBox');
     * const yesInGerman = res('YES'); // returns Ja
     * @memberof ResourceManager
     */
    public static getResources(resourceSet: string): (key: string, ...value: string[]) => string {
        if (!loadedResources) {
            new ResourceManager(resourceSet);
        } else {
            ResourceManager.loadResource(`assets/resources/${resourceSet}.${ResourceManager.getLanguage()}.json`, resourceSet);
        }
        return ResourceManager.formatMessage;
    }

    private static formatMessage(key: string, ...value: Array<string>) {
        return formatMessage(key, value.toString());
    }


    public static getLanguage(): string {
        // Get lang from sessionStorage || index.html 
        // const language = document.documentElement.lang;
        const language = ResourceManager.getLanguageCode(sessionStorage.getItem('fixedCulture') ?? document.documentElement.lang);
        locale(language);
        return language;
    }

    private static loadResource(url: string, resourceSet: string, fallback?: boolean): Promise<void> {
        if (!ResourceManager.assetsManagerService) {
            ResourceManager.assetsManagerService = new AssetsManagerService(
                new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }))
            );
        }
        return new Promise((resolve) => {
            if (loadedResources.has(resourceSet)) {
                resolve(loadMessages(loadedResources.get(resourceSet)));
            } else {
                ResourceManager.assetsManagerService.getFile(url).subscribe({
                    next: (file) => {
                        loadedResources.set(resourceSet, file);
                        resolve(loadMessages(file));
                    },
                    error: (err) => {
                        if (!fallback) {
                            const fallbackUrl = `assets/resources/${resourceSet}.de.json`;
                            locale('de');
                            ResourceManager.loadResource(fallbackUrl, resourceSet, true);
                        } else {
                            // In case even the 'de' resource is not found
                            console.log('Resource url ' + url + ' not found');
                            console.log(err?.message);
                        }
                    }
                });
            }
        });
    }

    private loadDevExtremeResources(): void {
        // this.loadResource(`devextreme/localization/messages/${this.getLanguage()}.json`, 'DevExtreme');
        if (!loadedResources.has(ResourceManager.DEV_EXTREME)) {
            import(`devextreme/localization/messages/${ResourceManager.getLanguage()}.json`).then((messages => {
                loadedResources.set(ResourceManager.DEV_EXTREME, messages['default']);
                loadMessages(messages[ResourceManager.getLanguage()]);
            }));
        }
    }

    private static getLanguageCode(ln: string): string {
        switch (ln) {
            case 'de-DE':
            case 'de':
                return 'de';
            case 'en-US':
            case 'en':
                return 'en';
            default:
                return 'de';
        }
    }
}
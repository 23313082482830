import { Injectable } from '@angular/core'
import {
  GeoDsCoreDataService as DataService,
  IPersistable,
  GeoDsPersistenceService as PersistenceService,
  PersistMode,
  Query,
  QueryColumn,
  QueryObjectsModel,
  QueryColumnSortOrder,
} from '@wissenswerft/core/data';
import { GUID } from '@wissenswerft/core/data';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FavoriteDataSource } from './favorite-dataSource';
import { BoxDetailViewModel } from './Model/box.viewModel';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  public allFavorites: FavoriteDataSource;
  private boxIdFavoriten: string;
  public appObjectTypesKeys: string[] = [];
  constructor(
    private dataService: DataService,
    private persistenceService: PersistenceService
  ) { }

  public getDataSource(): Observable<FavoriteDataSource> {
    if (this.allFavorites) {
      return of(this.allFavorites);
    } else {
      return this.dataService.executeReadObjectsQuery(this.prepareQueryFavorites()).pipe(
        map((data: any) => {
          this.allFavorites = new FavoriteDataSource(data);
          for (const key in this.allFavorites.favoritesByObjectType) {
            if (Object.prototype.hasOwnProperty.call(this.allFavorites.favoritesByObjectType, key) && this.appObjectTypesKeys.includes(key.toString())) {
              const boxDetailViewModel: BoxDetailViewModel[] = this.allFavorites.favoritesByObjectType[key];
              let id: string = '( ';
              boxDetailViewModel.forEach((favorite) => {
                id = id + " '" + favorite.ParentId + "' ,";
              });
              id = id.slice(0, -1) + ')';
              this.getDisplayText(key, id);
            }
          }

          return this.allFavorites;
        })
      );
    }
  }

  private prepareQueryFavorites(): QueryObjectsModel {
    const queryColumns: Array<QueryColumn> = [
      this.dataService.createQueryColumn('ParentObjectType', 'ParentObjectType'),
      this.dataService.createQueryColumn('ParentId', 'ParentId'),
      this.dataService.createQueryColumn('Id', 'Id'),
      this.dataService.createQueryColumn('gebId', 'gebId')
    ];
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = 'BoxDetail';
    mainQuery.OPath = "Box.ParentId = '" + localStorage.getItem('profileId') + "' AND Box.Designation = 'Favoriten'";
    mainQuery.Columns = queryColumns;

    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [mainQuery];

    return queryDocument;
  }

  private getDisplayText(objectType: string, ids: string) {
    return this.dataService
      .executeReadObjectsQuery(this.prepareQueryToGetDisplayText(objectType, ids))
      .subscribe((data) => {
        this.allFavorites.setDisplayText(objectType, data);
      });
  }

  private prepareQueryToGetDisplayText(ObjectType: string, ids: string): QueryObjectsModel {
    const queryColumns: Array<QueryColumn> = [
      this.dataService.createQueryColumn('Id', 'Id'),
      this.dataService.createQueryColumn('ref:Id', 'DisplayText', QueryColumnSortOrder.Ascending)
    ];

    const mainQuery: Query = new Query();
    mainQuery.ObjectType = ObjectType;

    mainQuery.OPath = 'Id IN ' + ids;
    mainQuery.Columns = queryColumns;

    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [mainQuery];

    return queryDocument;
  }

  private getBoxId(): Observable<any> {
    return this.dataService.executeReadObjectsQuery(this.prepareBoxId()).pipe(
      map((data: any) => {
        if (data && data.length > 0) {
          this.boxIdFavoriten = data[0]?.Id;

          return this.boxIdFavoriten;
        } else {
          return this.persistBoxFavorite(GUID.newGUID());
        }
      })
    );
  }

  private prepareBoxId(): QueryObjectsModel {
    const queryColumns: Array<QueryColumn> = [this.dataService.createQueryColumn('Id', 'Id')];
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = 'Box';
    mainQuery.OPath = "ParentId = '" + localStorage.getItem('profileId') + "' AND Designation = 'Favoriten'";
    mainQuery.Columns = queryColumns;
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [mainQuery];
    return queryDocument;
  }

  public deleteFavoriten(id: string) {
    const objectKey: IPersistable = {
      id: id,
      objectType: 'BoxDetail',
      persistenceCategory: null,
      changesPersisted: (persistMode: PersistMode) => persistMode = PersistMode.Delete
    };

    this.persistenceService.clearPendingObjects();
    this.persistenceService.addObjectForDelete(objectKey);
    this.allFavorites?.deleteFavoriteFromList(id);
    this.persistenceService.persistPendingChanges();
  }

  private persistBoxFavorite(boxIdFavoriten: string): string {
    const objectKey: IPersistable = {
      id: boxIdFavoriten,
      objectType: 'Box',
      changesPersisted: (persistMode) => (persistMode = PersistMode.Insert)
    };
    this.persistenceService.clearPendingObjects();
    this.persistenceService.addObjectForInsert(objectKey);
    this.persistenceService.addObjectValue(objectKey, 'Designation', 'Favoriten');
    this.persistenceService.addObjectValue(objectKey, 'ParentId', localStorage.getItem('profileId'));
    this.persistenceService.addObjectValue(objectKey, 'Number', 1);
    this.persistenceService.addObjectValue(objectKey, 'Type', '0');
    this.persistenceService.persistPendingChanges();
    return boxIdFavoriten;

  }

  public persistFavoriten(id: string, parentObjectType: string | number, parentId: string, designation: string): Observable<BoxDetailViewModel> {
    const objectKey: IPersistable = {
      id: id,
      objectType: 'BoxDetail',
      changesPersisted: (persistMode) => (persistMode = PersistMode.Insert)
    };
    this.persistenceService.addObjectForInsert(objectKey);
    this.persistenceService.addObjectValue(objectKey, 'ParentObjectType', parentObjectType);
    this.persistenceService.addObjectValue(objectKey, 'ParentId', parentId);

    if (this.boxIdFavoriten) {
      this.persistenceService.addObjectValue(objectKey, 'gebId', this.boxIdFavoriten);
      this.persistenceService.persistPendingChanges();
    } else {

      this.getBoxId()
        .subscribe((data: string) => {
          this.boxIdFavoriten = data;
          this.persistenceService.addObjectValue(objectKey, 'gebId', data);
          this.persistenceService.persistPendingChanges();
        });
    }
    return this.persistenceService.afterPersistPendingChanges$.pipe(first()).pipe(map(() => {
      const BoxDetail = {
        Id: id,
        ParentId: parentId,
        ParentObjectType: parentObjectType,
        gebId: this.boxIdFavoriten,
        DisplayText: designation
      };
      this.allFavorites?.favorites?.set(id, new BoxDetailViewModel(BoxDetail));
      return new BoxDetailViewModel(BoxDetail);
    }));
  }
}

export class TargetObjectData {
  ObjectKey: ObjectKey;
  Mode: PersistMode;
  TargetColumns: TargetColumnValue[];
}

export class TargetColumnValue {
  Name: string;
  Value: any;
}

export class ObjectKey {
  Id: string;
  ObjectType: string;
}
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService, ObjectKeys } from "../../services/data.service";
import { GeoDsDocument } from "@geods/base";
import { GeoDsCoreDataService, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel } from "@wissenswerft/core/data";
import { Measure, Project } from "@xmt-models";

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    public projectColumns: QueryColumn[] = [
        this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
    ]

    public documentColumns: QueryColumn[] = [
        this.coreDataService.createQueryColumn('Id', 'Id'),
        this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
        this.coreDataService.createQueryColumn('FileName', 'FileName'),
        this.coreDataService.createQueryColumn('Designation', 'Designation'),
        this.coreDataService.createQueryColumn('Kind', 'Kind'),
        this.coreDataService.createQueryColumn('ParentObjectType', 'ParentObjectType'),
        this.coreDataService.createQueryColumn('SysDateInsert', 'Date'),
        this.coreDataService.createQueryColumn('!.Cast(xmtMeasure).Designation', 'MeasureDesignation'),
        this.coreDataService.createQueryColumn('!.Cast(xmtMeasure).SubProject.Designation', 'SubProjectDesignation')
    ]

    public measureColumns = [
        this.coreDataService.createQueryColumn('Id', 'Id'),
        this.coreDataService.createQueryColumn('Designation', 'Designation'),
    ]

    constructor(private dataService: DataService,
        private coreDataService: GeoDsCoreDataService
    ) { }

    public getProjectList = (): Observable<Project[]> => {
        const filterOpath = 'ParentId=' + "'" + sessionStorage.getItem("currentCompany") + "'";
        return this.dataService.readObjects<Project[]>(
            ObjectKeys.PROJECT,
            this.projectColumns,
            filterOpath
        );
    }

    public getDocumentsByProjectId = (projectId: string): Observable<GeoDsDocument[]> => {
        const filterOpath = '!.Cast(xmtMeasure).SubProject.ParentId=' + "'" + projectId + "'";
        return this.dataService.readObjects<GeoDsDocument[]>(
            ObjectKeys.DOCUMENT,
            this.documentColumns,
            filterOpath
        );
    }

    public getAllDocument = (opath: string): Observable<GeoDsDocument[]> => {
        return this.dataService.readObjects<GeoDsDocument[]>(ObjectKeys.DOCUMENT, this.documentColumns, opath);
    }

    public getMeasureWithDocuments = (): Observable<Measure[]> => {
        const filterOpath = '!.!.ParentId=' + "'" + sessionStorage.getItem("currentCompany") + "'" + 'AND  Count(Documents) > 0';
        return this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, this.measureColumns, filterOpath);
    }

    public deleteDocument = (documentId: string): Observable<GeoDsDocument> => {
        return this.dataService.deleteObject(ObjectKeys.DOCUMENT, documentId);
    }
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import {
  ObjectHelper, GeoDsCoreDataService, Query, QueryColumn, QueryObjectsModel, QueryColumnSortOrder, TargetObjectData,
  ObjectKey, PersistMode, TargetColumnValue, PersistObjectModel, GeoDsPersistenceService, PersistObjectsModel
} from '@wissenswerft/core/data';
import { ScrollMode, ToastType, ValidationStatus } from '@wissenswerft/ww-library';
import { Measure, MeasureProgress, MeasureTask, Resource, SubProject } from '@xmt-models';
import { DxNumberBoxComponent, DxPopupComponent, DxSelectBoxComponent, DxTextBoxComponent, DxFormComponent, DxSpeedDialActionComponent, DxTagBoxComponent } from 'devextreme-angular';
import { Comment } from 'libs/core/comments/src/lib/models/comment.model';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MeasureTaskViewModel } from '../../../view-models/measure-task.view-model';
import { MeasureService } from '../measure.service';
import { ChartComponent } from './chart/chart.component';
import { ImplementationPlanComponent } from './implementation-plan/implementation-plan.component';
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import { StatusReportComponent } from './status-report/status-report.component';
@Component({
  selector: 'ubt-geods-measure-detail',
  templateUrl: './measure-detail.component.html',
  styleUrls: ['./measure-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureDetailComponent implements OnInit, OnDestroy {
  @ViewChild('addDocumentSelectBox') addDocumentSelectBox: DxSelectBoxComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('confirmTaskPopup') confirmTaskPopup: DxPopupComponent;
  @ViewChild('milestonePopup') milestonePopup: DxPopupComponent;
  @ViewChild('taskPopup') taskPopup: DxPopupComponent;
  @ViewChild('milestoneForm') milestoneForm: DxFormComponent;
  @ViewChild('taskForm') taskForm: DxFormComponent;
  @ViewChild('efficiencyField') efficiencyField: DxNumberBoxComponent;
  @ViewChild('measureTitle') measureTitle: DxTextBoxComponent;
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('commentsPopup') commentsPopup: DxSpeedDialActionComponent;
  @ViewChild('assignedMembers') assignedMembers: DxTagBoxComponent;
  @ViewChild('statusReport') statusReport: StatusReportComponent;

  public implementationPlan: ImplementationPlanComponent;
  public showTaskParents = false;
  public showLoader = false;
  public isNewTask: boolean;
  public subProjects: SubProject[] = [];
  public measure: Measure = new Measure(null);
  public isNewMeasureTask = false;
  public lastMeasureProgress: MeasureProgress = new MeasureProgress(null);
  public allComments: Comment;
  public workOnLastStatus = false;
  public toogleTitleVisible = false;
  public toogleInformations = false;
  public createSecondMeasureTask = false;
  public updateTaskMode = false;
  public navigationMaximumIndex: number;
  public measureComments: Comment[];
  public allowedParentTasks: MeasureTask[] = [];
  public assignedMembersIds: string[] = [];
  public assignedResource: Resource = new Resource(null);
  public documents = [];
  public responsibles: ProfileInfo[] = [];
  public status = ValidationStatus;
  public isAutoAdjust = false;
  public measureTasks: MeasureTaskViewModel[] = [];
  public scrollMode: ScrollMode = ScrollMode.Standard;
  public submitButtonText: string;
  public measureId: string;
  public collaboratorsList = [];
  public collaboratorsTotalTime = [];
  private returnPath: string;
  private subscriptions: Subscription[] = [];
  private measureProgressColumns: QueryColumn[] = [];
  private measureColumns: QueryColumn[] = [];
  public allowUpdating = false;
  public nextMeasureDesignation: string;
  public previousMeasureDesignation: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public measureService: MeasureService
  ) {
    this.measureId = this.activatedRoute.snapshot.params.id;
    measureService.currentMeasureId = this.measureId;
    sessionStorage.setItem('measureId', this.measureId);
    this.returnPath = this.activatedRoute.snapshot.params.from;
  }

  public ngOnInit(): void {
    this.dataService.favoriteMeasureId$.subscribe((measureId: string) => {
      this.measureId = measureId;
      this.prepareCurrentMeasure();
    })
    this.showLoader = true;
    config({
      floatingActionButtonConfig: {
        icon: 'rowfield',
        shading: true,
        position: {
          of: '#grid',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16',
        },
      },
    });
    repaintFloatingActionButton();
    const subProjectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None)
    ];
    const subProjectOPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    this.subscriptions.push(this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, subProjectColumns, subProjectOPath).subscribe(subProjects => {
      this.subProjects = subProjects.sort((a, b) => a.Label.localeCompare(b.Label));
    }));

    this.prepareCurrentMeasure();

    if (this.appService.measureIds.length === 0) {
      this.prepareMeasureIds();
    } else {
      this.prepareMeasureDesignation();
      this.prepareNavigationIndex();
    }
  }

  private prepareCurrentMeasure() {
    forkJoin([this.dataService.prepareStaffMembers(),
    this.coreDataService.executeReadObjectsQuery(this.prepareMeasureDetailColumns(this.measureId)),
    ]).pipe(first()).subscribe(data => {

      this.measure = data[1][0];
      if (!this.measure.MeasureResources) {
        //TODO IdRefMember column datasource needs to be initialized to empty array if it was undefined 
        this.measure.MeasureResources = [];
      }


      this.measureService.collaboratorsColumns = [
        {
          caption: this.dataService.res('Ubt-CreateMeasure-Members'),
          dataField: 'IdRefMember',
          lookup: { valueExpr: 'Id', displayExpr: 'Person', dataSource: data[1][0]?.MeasureResources?.sort((a, b) => a.Person.localeCompare(b.Person)) }
        },
        {
          caption: this.dataService.res('Ubt-Collaborator-Total-Time-Planned'),
          dataField: 'TimePlanned',
          dataType: 'number',
          format: '#,##0 h',
          alignment: 'center',
          width: '25%',
        },
        {
          caption: this.dataService.res('Ubt-Collaborator-Total-Time-Executed'),
          dataField: 'TimeExecuted',
          dataType: 'number',
          format: '#,##0 h',
          alignment: 'center',
          width: '25%',
        }
      ];

      this.measureService.collaboratorsTotalTimeColumns = [
        {
          caption: this.dataService.res('Ubt-CreateMeasure-Members'),
          dataField: 'name',
          lookup: { valueExpr: 'Id', displayExpr: 'Person', dataSource: data[1][0].MeasureResources }
        },
        {
          caption: this.dataService.res('Ubt-Collaborator-Total-Time-label'),
          dataField: 'sum',
          dataType: 'number',
          format: '#,##0 h',
          alignment: 'right',
          width: '40%',
        }
      ];
      if (
        Array.isArray(data) &&
        data.length > 0 &&
        Array.isArray(data[0]) &&
        data[0].length > 1
      ) {
        this.dataService.cachedResponsiblesResponse = data[0]?.sort((a, b) => a.LastName.localeCompare(b.LastName));
      }

      this.appService.callMeasureName(this.measure.Title);
      this.assignedMembersIds = [];
      if (!this.measure.MeasureResources) {
        this.measure.MeasureResources = [];
      } else {
        this.measure.MeasureResources?.forEach(resource => {
          const member = this.dataService.cachedResponsiblesResponse.find(responsible => responsible.Id === resource.Member);
          this.assignedMembersIds.push(member?.Id);
        });
      }
      this.loadMeasureProgress();
      this.collaboratorsTotalTime = this.measureService.calculateOverAllTime(this.measure);
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }, () => {
      this.showLoader = false;
      this.cdr.markForCheck();
    });
  }

  public verifyChanges(event, columnName): void {
    if (columnName === 'Designation') {
      if (event.value !== '') {
        this.measureService.updateMeasure('Designation', event.value, this.allowUpdating);
      } else {
        this.measureTitle.value = event.previousValue;
      }
    } else if (columnName === 'EndDate') {
      this.measureService.updateMeasure('EndDate', this.measure.EndDate, this.allowUpdating);
      this.allowUpdating = true;
    }
  }

  public addDeleteResource(event): void {
    if ((event.addedItems.length > 0)) {

      const areTheyNew = event.addedItems.every((newMember) => {
        const index = this.measure.MeasureResources.findIndex(item => item.Member === newMember.Id);
        return index === -1
      })

      if (areTheyNew) {
        this.persistResource(event.addedItems).subscribe(persistedResources => {
          if (Array.isArray(persistedResources) && persistedResources.length > 0) {
            if (Array.isArray(persistedResources[0]) && persistedResources[0].length > 0)
              persistedResources[0]?.forEach((persistedResource, index) => {
                const member = event.addedItems[index]
                const resource = new Resource(null);

                resource.Id = persistedResource.Id;
                resource.Member = member.Id;
                resource['Person'] = member.FirstName + ', ' + member.FirstName;
                this.measure.MeasureResources.push(resource);
              })
          }
        }, error => {
        }, () => {
          this.cdr.markForCheck();
        });
      }
    }

    if (event.removedItems.length > 0 && this.measure.MeasureResources.length > 0) {
      this.subscriptions.push(
        this.deleteResources(event.removedItems).subscribe((removedData) => {

          event.removedItems.forEach((removedItem) => {
            const index = this.measure.MeasureResources.findIndex(item => item.Member === removedItem.Id);
            this.measure.MeasureResources.splice(index, 1);
          })
        }
        )
      )
    }
  }

  private persistResource(members): Observable<any> {
    const query: PersistObjectsModel = new PersistObjectsModel();
    const resourcePersistQuery: TargetObjectData[][] = [[]];
    members.forEach((newMember) => {
      const resourcePersistObjectData: TargetObjectData = new TargetObjectData();
      resourcePersistObjectData.Mode = PersistMode.Insert;
      resourcePersistObjectData.ObjectKey = new ObjectKey();
      resourcePersistObjectData.ObjectKey.ObjectType = ObjectKeys.RESOURCE;
      const collaboratorColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: this.measure.Id },
        { Name: 'IdRefPerson', Value: newMember.Id }
      ];
      resourcePersistObjectData.TargetColumns = collaboratorColumns;
      resourcePersistQuery[0].push(resourcePersistObjectData);
    });

    query.Objects = resourcePersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query)
  }

  private deleteResources(removedMembers: any[]): Observable<any> {
    const query: PersistObjectsModel = new PersistObjectsModel();
    const resourcePersistQuery: TargetObjectData[][] = [[]];
    removedMembers.forEach((removedMember) => {

      const item = this.measure.MeasureResources?.find(item => item.Member === removedMember.Id)
      const resourceIdToBeDeleted = item?.Id;

      if (resourceIdToBeDeleted) {
        const resourcePersistObjectData: TargetObjectData = new TargetObjectData();
        resourcePersistObjectData.Mode = PersistMode.Delete;
        resourcePersistObjectData.ObjectKey = new ObjectKey();
        resourcePersistObjectData.ObjectKey.ObjectType = ObjectKeys.RESOURCE;
        resourcePersistObjectData.ObjectKey.Id = resourceIdToBeDeleted;
        resourcePersistQuery[0].push(resourcePersistObjectData);
      }
    });

    query.Objects = resourcePersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query)
  }

  public toggleTitle = (): void => {
    this.toogleTitleVisible = !this.toogleTitleVisible;
  }

  public toggleInformations = (): void => {
    this.toogleInformations = !this.toogleInformations;
  }

  public setResponsible(e) {
    return e ? e.id : null;
  }

  private prepareMeasureIds = (): void => {
    this.subscriptions.push(this.measureService.prepareMeasuresByProjectId(this.measureColumns).subscribe((measures) => {
      if (Array.isArray(measures) && measures.length > 0) {
        measures.forEach(item => {
          this.appService.measureIds.push(item.Id);
        })
        this.prepareMeasureDesignation();
      }
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      this.showLoader = false;
      console.error(error);
    }, () => {
      this.prepareNavigationIndex();
      this.showLoader = false;
    }));
  }

  private prepareMeasureDesignation = (): void => {
    const measureIdIndex = this.appService.measureIds.findIndex(_id => _id === this.measureId);
    const previousNextMeasureIds = [this.appService.measureIds[measureIdIndex - 1], this.appService.measureIds[measureIdIndex + 1]]
    const measureDesignationOpath = ` Id In (${previousNextMeasureIds.map((ids) => "'" + ids + "'")})`;
    this.dataService.readObjects(ObjectKeys.MEASURE, this.measureService.measureDesignationColumns, measureDesignationOpath).subscribe(_data => {
      this.previousMeasureDesignation = _data[0].Designation;
      this.nextMeasureDesignation = _data[1]?.Designation;
      this.cdr.markForCheck();
    })
  }

  public getComments(): void {
    this.commentsPopup.visible = true;
  }

  private loadMeasureProgress = () => {
    if (this.measure.MeasureProgress?.length > 0) {
      if (this.measure.MeasureProgress?.length > 1) { this.appService.currentMeasureProgressIndex = this.measure.MeasureProgress?.length - 1; }
      else { this.appService.currentMeasureProgressIndex = -1; }
      this.lastMeasureProgress.Id = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].Id;
      this.lastMeasureProgress.Progress = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].Progress;
      this.lastMeasureProgress.Decision = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].Decision;
      this.lastMeasureProgress.NextSteps = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].NextSteps;
      this.lastMeasureProgress.MaturityLevel = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].MaturityLevel;
      this.lastMeasureProgress.RiskAndChance = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].RiskAndChance;
      this.lastMeasureProgress.ProgressPercentage = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].ProgressPercentage;
      this.lastMeasureProgress.ProgressDate = this.measure.MeasureProgress[this.measure.MeasureProgress.length - 1].ProgressDate;
      this.statusReport.lastMeasureProgress = this.lastMeasureProgress;
    } else {
      this.appService.currentMeasureProgressIndex = -1;
      this.measure.MeasureProgress = [];
      this.lastMeasureProgress = new MeasureProgress(null);
      this.lastMeasureProgress.ProgressPercentage = 0;
      this.lastMeasureProgress.MaturityLevel = this.measureService.maturityLevel[0].name;
    }
    this.showLoader = false;
    this.cdr.detectChanges();
  }

  public backToMeasureList(): void {
    this.dataService.appService.showMeasureIcon = false;
    if (this.returnPath === "subProject") {
      this.router.navigate(["subProjectDetail", this.measure.ParentId]);
    } else {
      this.router.navigateByUrl(this.returnPath);
    }
  }

  public clearProgressFileds = (): void => {
    if (this.workOnLastStatus == false) {
      this.lastMeasureProgress = new MeasureProgress(null);
    } else {
      delete this.lastMeasureProgress['meta'];
      delete this.lastMeasureProgress.Id;
    }
    this.isNewMeasureTask = true;
    this.lastMeasureProgress.ProgressDate = new Date();
  }

  public navigateToMeasure = (index: number): void => {
    this.showLoader = true;
    this.measureService.currentMeasureIndex += index;
    this.measureService.currentMeasureId = this.appService.measureIds[this.measureService.currentMeasureIndex];
    this.measureId = this.measureService.currentMeasureId;
    this.router.navigate(['measureDetail', 'measures', this.measureService.currentMeasureId]);
    this.prepareCurrentMeasure();
    this.prepareMeasureDesignation();

  }

  private prepareNavigationIndex() {
    this.measureService.currentMeasureIndex = this.measureService.selectedMeasures ?
      this.measureService.selectedMeasures.findIndex(measure => measure.Id == this.measureId) :
      this.appService.measureIds.findIndex(measureId => measureId == this.measureId);
    this.navigationMaximumIndex = this.measureService.selectedMeasures ? this.measureService.selectedMeasures.length - 1 : this.appService.measureIds.length - 1;
    this.cdr.markForCheck();
  }

  private prepareMeasureDetailColumns(measureId: string): QueryObjectsModel {
    const measure: Query = new Query();
    this.measureColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('Designation', 'Title'),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate'),
      this.coreDataService.createQueryColumn('Status', 'Status'),
      this.coreDataService.createQueryColumn('IdRefPersonResponsible', 'Responsible'),
      this.coreDataService.createQueryColumn('IdRefPersonAreaManager', 'AreaManager'),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate'),
      this.coreDataService.createQueryColumn('InitialSituation', 'InitialSituation'),
      this.coreDataService.createQueryColumn('ExpectedResults', 'ExpectedResults'),
      this.coreDataService.createQueryColumn('Goal', 'Goal'),
      this.coreDataService.createQueryColumn('Dependencies', 'Dependencies'),
      this.coreDataService.createQueryColumn('Concept', 'Concept'),
      this.coreDataService.createQueryColumn('SysDateInsert', 'ReleaseDate'),
      this.coreDataService.createQueryColumn('DefenseMeasures', 'DefenseMeasures')
    ];
    measure.ObjectType = ObjectKeys.MEASURE;
    measure.Columns = this.measureColumns;
    measure.OPath = 'Id=' + "'" + measureId + "'";



    const measureTask: Query = new Query();
    measureTask.Name = 'MeasureTasks';
    measureTask.OPath = 'MeasureTasks';
    const measureTaskColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('Subject', 'Label'),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate'),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate'),
      this.coreDataService.createQueryColumn('IdRefResponsible', 'Responsible'),
      this.coreDataService.createQueryColumn('TargetMaturityLevel', 'TargetMaturityLevel'),
      this.coreDataService.createQueryColumn('DegreeOfImplementation', 'DegreeOfImplementation'),
      this.coreDataService.createQueryColumn('TotalTimeRequired', 'TotalTimeRequired'),
      this.coreDataService.createQueryColumn('Type', 'Type'),
    ];

    measureTask.Columns = measureTaskColumns;
    measureTask.Columns.push(this.coreDataService.createQueryColumn('IdRefxmtMeasureTask', 'Task'));

    const measureProgress: Query = new Query();
    measureProgress.Name = 'MeasureProgress';
    measureProgress.OPath = 'MeasureProgresses';
    this.measureProgressColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Decision', 'Decision'),
      this.coreDataService.createQueryColumn('MaturityLevel', 'MaturityLevel'),
      this.coreDataService.createQueryColumn('NextSteps', 'NextSteps'),
      this.coreDataService.createQueryColumn('Progress', 'Progress'),
      this.coreDataService.createQueryColumn('ProgressDate', 'ProgressDate', QueryColumnSortOrder.Ascending),
      this.coreDataService.createQueryColumn('SysTimeInsert', 'SysTimeInsert', QueryColumnSortOrder.Ascending),
      this.coreDataService.createQueryColumn('ProgressPercentage', 'ProgressPercentage'),
      this.coreDataService.createQueryColumn('RiskAndChance', 'RiskAndChance'),
    ];
    measureProgress.Columns = this.measureProgressColumns;

    const measureResources: Query = new Query();
    measureResources.Name = 'MeasureResources';
    measureResources.OPath = 'MeasureResources';
    const measureResourcesColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefPerson', 'Member'),
      this.coreDataService.createQueryColumn('ref:IdRefPerson', 'Person')
    ];
    measureResources.Columns = measureResourcesColumns;

    const taskCollaborators: Query = new Query();
    taskCollaborators.Name = 'Collaborators';
    taskCollaborators.OPath = 'collaborators';
    const taskCollaboratorsColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('IdRefMeasureResource', 'IdRefMember'),
      this.coreDataService.createQueryColumn('TimeExecuted', 'TimeExecuted'),
      this.coreDataService.createQueryColumn('TimePlanned', 'TimePlanned'),
    ];
    taskCollaborators.Columns = taskCollaboratorsColumns;

    measureTask.ObjectQueries = [taskCollaborators];
    measure.ObjectQueries = [measureTask, measureProgress, measureResources];
    const measureDetailColumns = new QueryObjectsModel();
    measureDetailColumns.ObjectQueries = [measure];
    return measureDetailColumns;
  }

  public assignReportToMeasure(reportId: string) {
    const opath = 'Id=' + "'" + reportId + "'";
    this.subscriptions.push(this.dataService.readObjects(ObjectKeys.MEASUREPROGRESS, this.measureProgressColumns, opath).subscribe((data) => {
      this.measure.MeasureProgress.push(data[0]);
      this.loadMeasureProgress();
      this.chart.prepareChart();
      this.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }));
  }

  public updateReportData(report: MeasureProgress) {
    this.measure.MeasureProgress.forEach((item, index) => {
      if (item.Id === report.Id) {
        this.measure.MeasureProgress[index] = report;
        this.chart.prepareChart();
      }
    });
  }

  public deleteReport(reportId: string) {
    const index = this.measure.MeasureProgress.findIndex(item => item.Id === reportId);
    this.measure.MeasureProgress.splice(index, 1);
    this.chart.prepareChart();
  }

  public ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}

export interface CollaboratorPlannedTime {
  collaborator: string;
  totalPlannedTime: number;
}
<div class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row">
        <h2 class="title-style ">Gantt</h2>
    </div>
    <div class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <dx-gantt #gantt class="gantt-chart gantt-style tree-list-style" style="border-radius: 10px;"
                taskListWidth="500" [scaleType]="scaleType" height="100%" (onContentReady)="contentReady($event)"
                (onContextMenuPreparing)="contextMenuPreparing($event)" [showDependencies]="false"
                (onTaskMoving)="$event.cancel = true" (onTaskDblClick)="$event.cancel = true">
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-tasks [dataSource]="ganttDataSource" keyExpr="id" parentIdExpr="parentId"></dxo-tasks>
                <dxo-resources [dataSource]="resources"></dxo-resources>
                <dxo-resource-assignments [dataSource]="resourceAssignments"></dxo-resource-assignments>
                <dxi-column dataField="title" format="dd.MM.yyyy"
                    caption="{{dataService.res('Ubt-Gantt-Subject')}}"></dxi-column>
                <dxi-column dataField="start" format="dd.MM.yyyy"
                    caption="{{dataService.res('Ubt-Measure-List-StartDate')}}" [width]="110"
                    dataType="date"></dxi-column>
                <dxi-column dataField="end" caption="{{dataService.res('Ubt-Measure-List-EndDate')}}" [width]="110"
                    dataType="date"></dxi-column>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-toolbar>
                    <dxi-item name="collapseAll"></dxi-item>
                    <dxi-item name="expandAll"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="zoomIn"></dxi-item>
                    <dxi-item name="zoomOut"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="showResources"></dxi-item>
                    <dxi-item widget="dxButton" [options]="exportOptions"></dxi-item>
                </dxo-toolbar>
                <dxo-validation [autoUpdateParentTasks]="false"></dxo-validation>
                <dxo-editing [enabled]="false"></dxo-editing>
                <dxo-context-menu>
                    <dxi-item text="{{dataService.res('Ubt-MeasureTask-Create-Milestone-Task')}}" name="add" icon="add"
                        [disabled]="disableAddButton" [onClick]="openDialogMilestone">
                    </dxi-item>
                    <dxi-item name="deleteTask"></dxi-item>
                </dxo-context-menu>
            </dx-gantt>
        </div>

        <dx-popup #milestonePopup [showTitle]="true" [height]="'auto'"
            [title]="dataService.res('Ubt-MeasureTask-Create-Milestone')" [width]="'500px'" [showCloseButton]="true">
            <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxFlexFill fxLayoutGap="10px">
                <form action="post" (submit)="persistMeasureTaskAndSubTask($event)">
                    <dx-form #milestoneForm id="form" [(formData)]="ganttService.measureTask" [colCount]="1">
                        <dxi-item [isRequired]="true">
                            <dx-text-box [stylingMode]="'outlined'" [(value)]="ganttService.measureTask.Label"
                                [width]="'100%'" [labelMode]="'floating'"
                                [label]="dataService.res('Ubt-MeasureTask-Label')">
                            </dx-text-box>
                            <dxi-validation-rule type="required"
                                message="{{dataService.res('Ubt-validator-RequiredField')}}">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item>
                            <dx-select-box fxFill class="drop-down-style" valueExpr="Id"
                                [(value)]="ganttService.measureTask.Responsible"
                                [displayExpr]="dataService.getResponsibleFullName"
                                [dataSource]="dataService.cachedResponsiblesResponse"
                                [label]="dataService.res('Ubt-CreateMeasure-Responsible')" [labelMode]="'floating'"
                                [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                            </dx-select-box>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="3" [cssClass]="'form-fields-row'">
                            <dxi-item>
                                <dx-select-box class="drop-down-style" [width]="'100%'"
                                    [(value)]="ganttService.measureTask.TargetMaturityLevel"
                                    [dataSource]="this.ganttService.maturityLevel" displayExpr="name" valueExpr="value"
                                    [stylingMode]="'outlined'" [labelMode]="'floating'"
                                    [label]="dataService.res('Ubt-MeasureDetail-Target-Hardness')">
                                </dx-select-box>
                            </dxi-item>
                            <dxi-item [isRequired]="true">
                                <dx-date-box class="date-box-style" [stylingMode]="'outlined'"
                                    [(value)]="ganttService.measureTask.StartDate" [width]="'100%'"
                                    [labelMode]="'floating'" [min]="ganttService.measureTask.StartDate"
                                    [max]="measure.EndDate" [min]="measure.StartDate"
                                    [label]="dataService.res('Ubt-Measure-List-StartDate')">
                                </dx-date-box>
                            </dxi-item>
                            <dxi-item [isRequired]="true">
                                <dx-date-box class="date-box-style" [stylingMode]="'outlined'"
                                    [(value)]="ganttService.measureTask.EndDate" [min]="measure.StartDate"
                                    [width]="'100%'" [min]="ganttService.measureTask.StartDate" [max]="measure.EndDate"
                                    [labelMode]="'floating'" [label]="dataService.res('Ubt-Measure-List-EndDate')">
                                </dx-date-box>
                            </dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                            <dxi-item>
                                <dx-number-box #efficiencyField [stylingMode]="'outlined'"
                                    [(value)]="ganttService.measureTask.DegreeOfImplementation" [width]="'100%'"
                                    [min]="0" [max]="100" [format]="dataService.customizePercentageText"
                                    [labelMode]="'floating'"
                                    [label]="dataService.res('Ubt-MeasureTask-DegreeOfImplementation')">
                                </dx-number-box>
                            </dxi-item>
                            <dxi-item>
                                <dx-number-box [(value)]="ganttService.measureTask.TotalTimeRequired"
                                    [label]="dataService.res('Ubt-CreateMeasureTask-Total-Time')" format="#,##0 h">
                                </dx-number-box>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                            <dxi-item itemType="button" [cssClass]="'submit-button'"
                                [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Ubt-Form-'+submitButtonText) }">
                            </dxi-item>
                            <dxi-item itemType="button" [cssClass]="'cancel-button'"
                                [buttonOptions]="cancelButtonOptions">
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                </form>
            </div>
        </dx-popup>

        <dx-popup #taskPopup [showTitle]="true" [height]="'auto'" [width]="'40%'" [showCloseButton]="true"
            [title]="dataService.res('Ubt-MeasureTask-Create-Task')" [width]="'500px'">
            <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxFlexFill fxLayoutGap="10px">
                <form action="post" (submit)="persistMeasureTaskAndSubTask($event)">
                    <dx-form #taskForm id="form" [colCount]="1">
                        <dxi-item [isRequired]="true">
                            <dx-select-box class="drop-down-style" valueExpr="Id" [displayExpr]="'Label'"
                                [dataSource]="tasksById" [(value)]="ganttService.taskParentId"
                                [label]="dataService.res('Ubt-MeasureDetail-Parent')" [labelMode]="'floating'">
                            </dx-select-box>
                            <dxi-validation-rule type="required"
                                message="{{dataService.res('Ubt-validator-RequiredField')}}">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item [isRequired]="true">
                            <dx-text-box [stylingMode]="'outlined'" [width]="'100%'" [labelMode]="'floating'"
                                [(value)]="ganttService.measureTask.Label"
                                [label]="dataService.res('Ubt-MeasureTask-Label')">
                            </dx-text-box>
                            <dxi-validation-rule type="required"
                                message="{{dataService.res('Ubt-validator-RequiredField')}}">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                            <dxi-item [isRequired]="true">
                                <dx-date-box class="date-box-style" [stylingMode]="'outlined'" [width]="'100%'"
                                    [(value)]="ganttService.measureTask.StartDate" [labelMode]="'floating'"
                                    [min]="task.StartDate" [max]="task.EndDate"
                                    [label]="dataService.res('Ubt-Measure-List-StartDate')">
                                </dx-date-box>
                                <dxi-validation-rule type="required"
                                    message="{{dataService.res('Ubt-validator-RequiredField')}}">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item [isRequired]="true">
                                <dx-date-box class="date-box-style" [stylingMode]="'outlined'"
                                    [(value)]="ganttService.measureTask.EndDate" [max]="task.EndDate" [width]="'100%'"
                                    [labelMode]="'floating'" [min]="ganttService.measureTask.StartDate"
                                    [min]="task.StartDate" [label]="dataService.res('Ubt-Measure-List-EndDate')">
                                </dx-date-box>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                            <dxi-item>
                                <dx-select-box fxFill class="drop-down-style" valueExpr="Id"
                                    [displayExpr]="dataService.getResponsibleFullName"
                                    [(value)]="ganttService.measureTask.Responsible"
                                    [dataSource]="dataService.cachedResponsiblesResponse"
                                    [label]="dataService.res('Ubt-CreateMeasure-Responsible')" [labelMode]="'floating'"
                                    [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                                </dx-select-box>
                            </dxi-item>
                            <dxi-item>
                                <dx-select-box class="drop-down-style" [width]="'100%'"
                                    [(value)]="ganttService.measureTask.TargetMaturityLevel"
                                    [dataSource]="this.ganttService.maturityLevel" displayExpr="name" valueExpr="value"
                                    [stylingMode]="'outlined'" [labelMode]="'floating'"
                                    [label]="dataService.res('Ubt-MeasureDetail-Target-Hardness')">
                                </dx-select-box>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                            <dxi-item>
                                <dx-number-box #efficiencyField [stylingMode]="'outlined'" [width]="'100%'" [min]="0"
                                    [(value)]="ganttService.measureTask.DegreeOfImplementation" [max]="100"
                                    [format]="dataService.customizePercentageText" [labelMode]="'floating'"
                                    [label]="dataService.res('Ubt-MeasureTask-DegreeOfImplementation')">
                                </dx-number-box>
                            </dxi-item>
                            <dxi-item>
                                <dx-number-box [label]="dataService.res('Ubt-CreateMeasureTask-Total-Time')"
                                    format="#,##0 h" [(value)]="ganttService.measureTask.TotalTimeRequired">
                                </dx-number-box>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item>
                            <div *dxTemplate fxLayout="column">
                                <div fxLayout="row">
                                    <div>
                                        <div fxLayout="row" class="collaborators-title">
                                            <div fxLayout="column" fxFlex="86">
                                                {{dataService.res('Ubt-CreateMeasureTask-Collaborators')}}
                                            </div>
                                            <div fxLayout="column" fxFlex="7">
                                                <dx-button [hoverStateEnabled]="false" class="icon-style"
                                                    [icon]="'assets/images/Aktualisierung.svg'"
                                                    (onClick)="calculateOverallTime()"></dx-button>
                                            </div>
                                            <div fxLayout="column" fxFlex="7">
                                                <dx-button [icon]="'assets/images/Add.svg'" class="icon-style"
                                                    (onClick)="addCollaborator()">
                                                </dx-button>
                                            </div>
                                        </div>
                                        <ww-grid #collaboratorGrid [id]="'collaboratorGrid'" [noDataText]="noDataText"
                                            class="grid-group-by-Style gant-collaborators-grid save-icon-style"
                                            [groupable]="false" [height]="'150px'" [searchPanel]="false"
                                            [enableStateStorage]="false" [gridData]="collaboratorsData"
                                            [columnsHeader]="collaboratorsColumns" [enableColumnChooser]="false"
                                            [enableExport]="false" [showfilterrow]="false" [allowAdding]="false"
                                            [selectionMode]="'single'" [columnFilter]="false" [showRowLines]="false"
                                            [editingMode]="'row'" [showColumnLines]="false" [allowDeleting]="true"
                                            [allowUpdating]="true" [linkProperty]="'text'" [filterPanel]="false"
                                            [enableHeaderFilter]="false" [rowAlternationEnabled]="false"
                                            (onRowRemoved)="removeCollaborator($event)"
                                            (onCellPrepared)="cellPrepared($event)"
                                            (onSaved)="saveCollaborator($event)">
                                        </ww-grid>
                                    </div>


                                </div>
                            </div>
                        </dxi-item>

                        <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                            <dxi-item itemType="button" [cssClass]="'submit-button'"
                                [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Ubt-Form-'+submitButtonText) }">
                            </dxi-item>
                            <dxi-item itemType="button" [cssClass]="'cancel-button'"
                                [buttonOptions]="cancelButtonOptions">
                            </dxi-item>
                        </dxi-item>

                    </dx-form>
                </form>
            </div>
        </dx-popup>
    </div>
</div>

<ubt-loader *ngIf="showLoader"></ubt-loader>
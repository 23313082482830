import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GeoDsCoreDataService, Query, QueryObjectsModel } from '@wissenswerft/core/data';
import { loadMessages } from 'devextreme/localization';
import { CompaniesService, IProjectDependency, WebStorageType } from 'libs/companies/src/lib/companies.service';
import { Subscription } from 'rxjs';
import { AppService } from '../services/app.service';
import { DataService, ObjectKeys } from '../services/data.service';

@Component({
  selector: 'ubt-geods-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompaniesComponent implements OnInit, AfterViewInit, OnDestroy {
  public navigateUrlPath: string = "dashboard";
  public configDependencies: IProjectDependency[] = [
    {
      name: 'projectId',
      storageType: WebStorageType.LocalStorage
    },
    {
      name: 'projectName',
      storageType: WebStorageType.LocalStorage
    }
  ];
  public companyIdDependency: IProjectDependency =
    {
      name: 'currentCompany',
      storageType: WebStorageType.SessionStorage
    };
  public companyNameDependency: IProjectDependency =
    {
      name: 'companyName',
      storageType: WebStorageType.SessionStorage
    };
  public filterOptions = [];
  private subscriptions: Subscription[] = [];

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    private companiesService: CompaniesService,
    private appService: AppService
  ) { }

  public ngOnInit(): void {
    loadMessages({
      en: {
        "Ubt-Filter-Company-with-Projects": "Company with projects",
        "Ubt-Filter-All": "All"
      },
      de: {
        "Ubt-Filter-Company-with-Projects": "Unternehmen mit Projekten",
        "Ubt-Filter-All": "Alle"
      }
    });

    this.filterOptions = [
      { id: 0, name: this.dataService.res('Ubt-Filter-All') },
      { id: 1, name: this.dataService.res('Ubt-Filter-Company-with-Projects') },
    ];
  }

  public ngAfterViewInit(): void {
    this.companiesService.filterCompanyData(1);
  }

  public clickProject(project) {
    this.appService.switchTreeViewToProject(true);
    sessionStorage.setItem('projectId', project.Id);
    sessionStorage.setItem('projectName', project.Label);
  }

  public clickCompany(company) {
    this.appService.switchTreeViewToCompany(true);
    sessionStorage.setItem("currentCompany", company.Id);
    sessionStorage.setItem("companyName", company.Name);
    this.companiesService.prepareTitleCompany(company.Name);
    this.companiesService.selectedCompanyData(true);
    sessionStorage.removeItem('projectName');
  }

  public selectLastProjectUpdated(project) {
    sessionStorage.setItem("companyName", project['ParentAddress']);
    sessionStorage.setItem("currentCompany", project['ParentId']);
    sessionStorage.setItem("projectName", project.Label);
    sessionStorage.setItem("projectId", project.Id);
    this.companiesService.prepareTitleCompany(project['ParentAddress']);
    this.appService.switchTreeViewToProject(true);
    this.companiesService.selectedProjectData(project);
  }

  public prepareFiltredData(columns) {
    const query: Query = new Query();
    query.ObjectType = ObjectKeys.ADDRESS;
    query.Columns = columns;
    query.OPath = 'Count(xmtProjects) > 0';
    const queryCompany: QueryObjectsModel = new QueryObjectsModel();
    queryCompany.ObjectQueries = [query];

    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(queryCompany).subscribe(companiesData => {
      this.companiesService.filterCompanyData(companiesData);
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

import { GeoDsDocument } from "./document.model";
import { GeoDsMeta } from "./meta-data.model";
import { PhoneNumber } from "./phone-number.model";

export class Person extends GeoDsMeta {
    FirstName: string;
    LastName: string;
    Address: string;
    Birthday: Date
    City: string;
    EMail: string;
    Country: string;
    Department: string;
    PrivatePhone: string;
    Function: string;
    Login: string;
    PersonnelNumber: string;
    Title: string;
    ParentId: string;
    PhoneNumbers?: PhoneNumber[];
    ProfilePicture: GeoDsDocument;
    Documents: GeoDsDocument[];

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Address, MailAndWebAddress, PhoneNumber } from '@geods/base';
import { GeoDsCoreDataService, GUID, QueryColumn } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxContextMenuComponent, DxFormComponent, DxPopupComponent, DxScrollViewComponent, DxTextBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { ObjectTypeNames } from 'libs/companies/src/lib/companies.service';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataService, ObjectKeys } from '../../services/data.service';
import { AddressViewModel } from '../../view-models/address.view-model';
import { CompanyService } from './company.service';
import { DefaultIcons } from '../../customer-space/measure/measure-detail/implementation-plan/implementation-plan.component';

@Component({
  selector: 'ubt-geods-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompaniesListComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild("formCompanyPopup") formCompanyPopup: DxPopupComponent;
  @ViewChild("deleteCompanyPopup") deleteCompanyPopup: DxPopupComponent;
  @ViewChild('form') form: DxFormComponent;
  @ViewChild("companyGrid") companyGrid: GridComponent;
  @ViewChild('emailTextBox') emailTextBox: DxTextBoxComponent;
  @ViewChild('phoneTextBox') phoneTextBox: DxTextBoxComponent;
  @ViewChild('faxTextBox') faxTextBox: DxTextBoxComponent;
  @ViewChild('contextMenu') contextMenu: DxContextMenuComponent;

  public companies: AddressViewModel[] = [];
  public company: Address = new Address(null);
  public phoneNumber: PhoneNumber = new PhoneNumber(null);
  public email: MailAndWebAddress = new MailAndWebAddress(null);
  public columns: QueryColumn[] = [];
  public cachedStatus = [];
  public cachedCompaniesTitles: string[] = [];
  public rowIndex: number;
  public selectedCompanyId: string;
  public showLoader = false;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Ubt-Company-Column-Name'),
      dataField: "name",
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Organization'),
      dataField: 'companyTitle',
      visibleIndex: 1,
      lookup: { dataSource: this.cachedCompaniesTitles, displayExpr: 'Description', valueExpr: 'Value' }
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Match-Code'),
      dataField: "matchCode",
      visibleIndex: 2,
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Status'),
      dataField: 'status',
      visibleIndex: 3,
      lookup: { dataSource: this.cachedStatus, displayExpr: 'Description', valueExpr: 'Value' }
    },

    {
      caption: this.dataService.res('Ubt-Company-Column-Street'),
      dataField: "street",
      visibleIndex: 4,
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-City'),
      dataField: "city",
      visibleIndex: 5,
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Zip'),
      dataField: "zip",
      visibleIndex: 6,
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Phone'),
      dataField: "fullNumber",
      visibleIndex: 7,
      visible: false,
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Email'),
      dataField: "email",
      visibleIndex: 8,
      visible: false
    },
    {
      caption: this.dataService.res('Ubt-Company-Column-Customer-Number'),
      dataField: "customerNumber",
      visibleIndex: 9,
      visible: false
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      dataField: '',
      visibleIndex: 10,
      minWidth: 50,
      width: '50px',

    }
  ];
  public noDataText = "Keine Daten";
  private subscriptions: Subscription[] = [];
  private companyId: string;
  public contextMenuItems: any[] = [
    {
      text: this.dataService.res('Ubt-Company-Edit-Company'),
      onClick: (event) => {

        this.openCompanyDetail(event);
      },
    },
    {
      text: this.dataService.res('Ubt-Company-Delete-Company'),
      onClick: (event) => {
        this.openDeleteCompanyDialog(event)
      },
    },
  ];
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-Button-Cancel'),
    onClick: () => this.closePopup()
  }
  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    public favoritesService: FavoritesService,
    public companyService: CompanyService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.prepareFormDataSource();
    this.phoneNumber.Type = 0;
  }

  public onCellClick = (e) => {
    this.company = e.data.address;
    this.rowIndex = e.row.dataIndex;
    if (e.columnIndex === 7) {
      this.getCompanyFields(e);
      this.companyService.isUpdating = true;
      if (this.company.Phone) this.formatNumber('Phone');
      if (this.company.Fax) this.formatNumber('Fax');
      e.cellElement.className = 'context-menu-icon' + e.rowIndex;
      this.contextMenu.target = '.context-menu-icon' + e.rowIndex;
      e.cellElement.innerHTML = this.contextMenu.visible ? DefaultIcons.CLOSE : DefaultIcons.CONTEXTMENU;
      this.contextMenu.instance.show();
    }
  }

  public onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === '') {
        e.cellElement.style.cursor = 'pointer';
        return (e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU);
      }
    }
  }

  private prepareFormDataSource(): void {
    forkJoin([
      this.dataService.loadListDataSource('Address', 'Status'),
      this.dataService.loadListDataSource('Address', 'CompanyTitle'),
    ]).subscribe((data: any) => {
      this.cachedStatus = data[0].Data;
      this.cachedCompaniesTitles = data[1].Data;
      this.getCompaniesData();
      this.showLoader = false;
      this.cdr.markForCheck();
    });
  }


  public getCompaniesData() {
    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(this.companyService.prepareCompanyColumns()).subscribe((companies) => {
      companies.forEach(company => {
        const companyData = new AddressViewModel(company);
        this.companies.push(companyData);
      });
    }))
  }

  public validTextBoxStatus(): boolean {
    if ((this.emailTextBox.validationStatus !== 'invalid')) {
      return true;
    } else return false;
  }

  private formatNumber(field: string) {
    this.company[field] = this.company[field].replace(' (', '');
    this.company[field] = this.company[field].replace(') ', '');
  }

  public openCompanyDialog(): void {
    this.company = new Address(null);
    this.companyService.isUpdating = false;
    if (this.company.Phone) this.formatNumber('Phone');
    if (this.company.Fax) this.formatNumber('Fax');
    this.cancelButtonOptions.text = this.dataService.res('ubt-Button-Cancel');
    this.formCompanyPopup.title = this.dataService.res('Ubt-Company-Title-Create-Company');
    this.formCompanyPopup.instance.show();
  }

  public onCreateCompany(event) {
    event.preventDefault();
    if (this.validTextBoxStatus()) {
      this.companyService.persistCompany(this.company).pipe(switchMap((company) => {
        this.company.Id = company.Id;
        this.companyId = company.Id;
        return this.createMailFaxPhone(company.Id);
      })).subscribe(
        (data) => {
          if (this.companyService.isUpdating === false) {
            this.getCompanyById(this.companyId);
          }
          const company = new AddressViewModel(this.company);
          company.email = this.email.Value;
          this.closePopup();
          this.companyGrid.refreshGrid();
          this.cdr.markForCheck();
        }, error => {
          console.error(error);
          this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        }, () => {
          this.showLoader = false;
          this.companyGrid.refreshGrid();
          this.cdr.markForCheck();
        }
      )

    }
  }

  private getCompanyById(companyId: string) {
    this.showLoader = true;
    const opath = 'Id=' + "'" + companyId + "'";
    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(this.companyService.prepareCompanyColumns(opath)).subscribe((company: Address) => {
      if (Array.isArray(company) && company.length > 0) {
        this.company = company[0];
        this.companies.push(new AddressViewModel(this.company));
        this.companyGrid.refreshGrid();
        this.cdr.markForCheck();
      } else {
        this.companies = [];
        this.dataService.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      this.showLoader = false;
      console.error(error);
      this.cdr.markForCheck();
    }, () => {
      this.cdr.markForCheck();
    }))
  }

  private createMailFaxPhone(companyId: string): Observable<any> {
    return forkJoin([
      this.createUpdatePhoneNumber(companyId),
      this.createUpdateMail(companyId)
    ])
  }

  private createUpdatePhoneNumber(companyId): Observable<any> {
    if (this.phoneNumber.toString().length > 0) {
      return this.companyService.persistPhoneNumber(this.phoneNumber, companyId, this.phoneNumber.Id);
    } else {
      return of([]);
    }
  }

  private createUpdateMail(companyId): Observable<any> {
    if (this.email.toString().length > 0) {
      return this.companyService.persistMailAddress(this.email, companyId, this.email.Id);
    } else {
      return of([]);
    }
  }

  public companiesUpdateState(event, state: string, favoriteData?): void {
    let selectedIndex = this.companies.findIndex((company) => company.id === event.row?.data.Id);
    if (state === 'add') {
      const clonedItem = { ...event.row?.data, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.companies[selectedIndex] = clonedItem;
    } else {
      const clonedItem = { ...event.row?.data, IsFavorite: 0 };
      this.companies[selectedIndex] = clonedItem;
    }
  }

  public openCompanyDetail(event): void {
    this.cancelButtonOptions.text = this.dataService.res('ubt-Button-Cancel');
    this.formCompanyPopup.title = this.dataService.res('Ubt-Company-Title-Edit-Company');
    this.formCompanyPopup.instance.show();
  }

  private getCompanyFields(event): void {
    this.company = event.row?.data['address'];
    if (this.company['MailAddress']) {
      this.email = new MailAndWebAddress(this.company['MailAddress'][0]);
    }
    if (this.company['PhoneNumbers']) {
      this.phoneNumber = this.company['PhoneNumbers'][0];
    }
  }

  public emptyData(): void {
    this.company = new Address(null);
    this.phoneNumber = new PhoneNumber(null);
    this.phoneNumber.Type = 0;
    this.email = new MailAndWebAddress(null);
    this.form.instance.resetValues();
  }

  public openDeleteCompanyDialog(event): void {
    this.selectedCompanyId = event.row?.data.id;
    this.deleteCompanyPopup.instance.show();
  }

  public deleteCompany(): void {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.ADDRESS, this.selectedCompanyId).subscribe(() => {
      this.companies = this.companies.filter((company) => company.id != this.selectedCompanyId);
    }, error => {
      console.error(error);
    }, () => {
      this.deleteCompanyPopup.instance.hide();
    }));
  }

  public closePopup(): void {
    this.formCompanyPopup?.instance.hide();
    this.deleteCompanyPopup?.instance.hide();
    this.emptyData();
  }

  public addFavorite(event): void {
    console.error('Not implemented yet!');
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.Company, event.row?.data.Id, event.row?.data.name).subscribe((data) => {
      this.companiesUpdateState(event, 'add', data);
    });
  }

  public deleteFavorite(event): void {
    this.favoritesService.deleteFavoriten(event.row?.data.BoxDetailId);
    this.companiesUpdateState(event, 'delete');
  }
}

import { Injectable, Input } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { Measure, MeasureTask } from '@xmt-models';
import { Column } from 'devextreme/ui/data_grid';
import { Observable } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { DataDefinitionOptions } from '../../models/home.model';


@Injectable({
    providedIn: 'root'
})
export class GanttService {
    public currentMeasure: Measure;
    public measureTask: MeasureTask = new MeasureTask(null);
    public collaboratorsColumns: Column[] = [];
    public isCreatingSubTask: boolean;
    public maturityLevel: DataDefinitionOptions[] = [
        { name: 'HG1', value: 'HG1' },
        { name: 'HG2', value: 'HG2' },
        { name: 'HG3', value: 'HG3' },
        { name: 'HG4', value: 'HG4' },
        { name: 'HG5', value: 'HG5' }
    ];
    private measureTaskId: string;
    public taskParentId = "";
    public measureParentId = "";

    constructor(
        private persistenceService: GeoDsPersistenceService,
        private appService: AppService,
        public dataService: DataService,
        private coreDataService: GeoDsCoreDataService
    ) { }

    public persistTask(mode: PersistMode): Observable<any> {
        const measureTaskPersistQuery: TargetObjectData = new TargetObjectData();
        measureTaskPersistQuery.ObjectKey = new ObjectKey();
        measureTaskPersistQuery.ObjectKey.ObjectType = ObjectKeys.TASK;
        const measureTaskColumns: TargetColumnValue[] = [
            { Name: 'ParentId', Value: this.measureParentId },
            { Name: 'Subject', Value: this.measureTask.Label },
            { Name: 'IdRefResponsible', Value: this.measureTask?.Responsible },
            { Name: 'TargetMaturityLevel', Value: this.measureTask?.TargetMaturityLevel },
            { Name: 'IdRefxmtMeasureTask', Value: this.taskParentId ? this.taskParentId : null },
            { Name: 'StartDate', Value: this.measureTask?.StartDate?.toDateString() },
            { Name: 'EndDate', Value: new Date(this.measureTask.EndDate).toDateString() },
            { Name: 'DegreeOfImplementation', Value: this.measureTask?.DegreeOfImplementation },
            { Name: 'TotalTimeRequired', Value: this.measureTask?.TotalTimeRequired },
            { Name: 'Type', Value: (this.measureTask.Task) ? 1 : 0 }
        ];
        measureTaskPersistQuery.Mode = mode;
        if (mode == PersistMode.Update) measureTaskPersistQuery.ObjectKey.Id = this.measureTaskId;
        measureTaskPersistQuery.TargetColumns = measureTaskColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = measureTaskPersistQuery;
        return this.persistenceService.executePersistObjectQuery(persistObject);
    }

    public prepareCollaborators(measureTaskId: string, collaboratorList): void {
        const query: PersistObjectsModel = new PersistObjectsModel();
        const collaborattorPersistQuery: TargetObjectData[][] = [[]];
        collaboratorList.forEach((newCollaborator) => {            
            const collaboratorsPersistObjectData: TargetObjectData = new TargetObjectData();
            collaboratorsPersistObjectData.Mode = PersistMode.Insert;
            collaboratorsPersistObjectData.ObjectKey = new ObjectKey();
            collaboratorsPersistObjectData.ObjectKey.ObjectType = ObjectKeys.COLLABORATOR;
            const collaboratorColumns: TargetColumnValue[] = [
                { Name: 'ParentId', Value: measureTaskId },
                { Name: 'IdRefMeasureResource', Value: newCollaborator['IdRefMember'] },
                { Name: 'TimeExecuted', Value: newCollaborator.TimeExecuted },
                { Name: 'TimePlanned', Value: newCollaborator.TimePlanned },
                { Name: 'Period', Value: new Date().toDateString() }
            ];
            collaboratorsPersistObjectData.TargetColumns = collaboratorColumns;
            collaborattorPersistQuery[0].push(collaboratorsPersistObjectData);
        });
        query.Objects = collaborattorPersistQuery;
        this.persistenceService.executePersistObjectsQuery(query).subscribe(data => {
            this.appService.callNotification({ message: this.dataService.res('Ubt-Collaborator-Assigned'), type: ToastType.SUCCESS });
        }, error => {
            this.appService.callNotification({ message: error, type: ToastType.ERROR });
        }, () => { });
    }

    public onCalculateOverallTime(event): void {
        this.measureTask.TotalTimeRequired = event;
    }
}
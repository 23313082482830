<div fxLayout="column" fxFlex="100%">
  <div fxLayout="row" fxFlex="80%">
    <dx-scroll-view class="comment" [showScrollbar]="'onScroll'">
      <dx-list #commentlist class="comments-list" [dataSource]="comments" [allowItemDeleting]="true" [focusStateEnabled]="false"
        [noDataText]="res('Comments-Library-NoComments')" [searchEnabled]="true" searchExpr="text"
        [itemDeleteMode]="deleteMode" (onItemClick)="updateComment($event)" (onItemDeleted)="deleteComment($event)">
        <dxo-search-editor-options placeholder="{{ res('Comments-Library-Placeholder') }}" [width]="200">
        </dxo-search-editor-options>
        <div *dxTemplate="let item of 'item'" style="cursor:default;">
          <img style="float: left;height: 20px;width: 20px" class="image-container"
            src="assets/images/User-white-background.svg" />
          <div class="fullname">{{ item.author.firstname}} {{
            item.author.lastname}}
          </div>
          <div class="date">
            <span *ngIf="calculateDuration(item.created) >= 24">
              &nbsp;{{ item.created | date: 'dd.MM.yyyy' }}
            </span>
            <span *ngIf="calculateDuration(item.created) < 24">
              &nbsp; {{ res('Comments-Library-Date') }}{{ calculateDuration(item.created) }}h
            </span>
          </div>
          <div class="message-box">
            {{ item.text }}
          </div>
        </div>
      </dx-list>
    </dx-scroll-view>
  </div>
  <div class="comment-class" fxLayout="row" fxFlex="20%">
    <dx-text-area #commentTextArea class="comment-textarea sideBySide" [(value)]="commentText"
      placeholder="{{ res('Comments-Library-Comment') }}" [autoResizeEnabled]="true" [width]="'90%'" [minHeight]="45"
      [maxHeight]="75" [maxLength]="250">
    </dx-text-area>
      <img src="assets/images/send-message.png"  class="addCommentButton" style="cursor: pointer;" (click)="addComment()">
  </div>
</div>
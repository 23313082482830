export class LoginInfo {
  constructor(
    public readonly accessToken: string,
    public readonly expiresIn: number,
    public readonly refreshToken: string,
    public readonly tokenType: string,
    public readonly expiryDate?: Date
  ) {
    const now = new Date();
    this.expiryDate = new Date(now.getTime() + this.expiresIn * 1000);
  }
}


export interface ILoginSettings {
  readonly isAnonymousLogin: boolean;
  readonly access_token: string;
  readonly refresh_token: string;
  readonly token_type: string;
  readonly expires: Date;
  readonly expires_in: number;
  readonly issued: Date;
  readonly userName: string;
  readonly isExpired: boolean;
  readonly isLoginRequired: boolean;

  toLoginInfo(id?: string, displayName?: string, isAdmin?: boolean): LoginInfo;
}

import { Component, Input, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { DashboardControl, ResourceManager } from 'devexpress-dashboard';
@Component({
  selector: 'ww-dashboard',
  template: `<div [class]="'dashboard-container ' + dashbordCongiguration?.class"></div>`,
  styleUrls: ['./ww-dashBoard.component.scss'],
})
export class DashBoardComponent implements AfterViewInit, OnDestroy {
  @Input() dashbordCongiguration: IDashBoard;
  private readonly defaultDashboardDemoUri = 'https://demos.devexpress.com/services/dashboard/api';
  private dashboardControl!: DashboardControl;

  constructor(private element: ElementRef) {
    ResourceManager.embedBundledResources();
  }

  ngAfterViewInit(): void {
    this.refreshControl();
  }

  public refreshControl(): void {
    this.dashboardControl?.dispose();
    const dashboardContainer: HTMLElement = this.element.nativeElement.querySelector('.dashboard-container');
    dashboardContainer.innerHTML = '';
    this.dashboardControl = new DashboardControl(dashboardContainer, {
      endpoint: this.dashbordCongiguration.endPoint,
      workingMode: this.dashbordCongiguration.workingMode,
      initialDashboardId: this.dashbordCongiguration.dashboardId,
      ajaxRemoteService: {
        headers: this.dashbordCongiguration.ajaxHeaders,
      },
      extensions: {
        'mobile-layout': {
          mobileLayoutEnabled: this.dashbordCongiguration.mobileLayoutMode,
        },
        'dashboard-export': {
          allowExportDashboard: this.dashbordCongiguration.allowExportDashboard,
          allowExportDashboardItems: this.dashbordCongiguration.allowExportDashboardItems,
        },
      },
    });
    this.dashboardControl.render();
  }

  public restoreDashboardItem() {
    this.dashboardControl.restoreDashboardItem();
  }

  ngOnDestroy(): void {
    this.dashboardControl?.dispose();
  }

}

export declare type WorkingMode = 'Designer' | 'Viewer' | 'ViewerOnly';

export declare type MobileLayoutMode = 'Auto' | 'Always' | 'Never';

export interface IAjaxHeader {
  Authorization: string;
  ProfileId: string;
}

export class IDashBoard {
  ajaxHeaders: IAjaxHeader;
  endPoint: string;
  dashboardId: string;
  workingMode?: WorkingMode = 'ViewerOnly';
  class?: string
  allowExportDashboard?: boolean = false;
  allowExportDashboardItems?: boolean = false;
  mobileLayoutMode?: MobileLayoutMode = 'Auto';

  constructor(data) {
    Object.assign(this, data);
  }
}


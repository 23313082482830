import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { first, take } from "rxjs/operators";
import { LoginInfo } from "../models/login-info.model";
import { TokenService } from "./token.service";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})
export class IdeTimerService {
    private loginInfo: LoginInfo;
    constructor(private tokenService: TokenService, private userService: UserService) {
    }

    tracker() {
        window.addEventListener("mousemove", e => {
            this.updateExpiredTime();
            e.stopImmediatePropagation();
        });
    }

    cleanUp() {
        window.removeEventListener("mousemove", e => { });
        window.removeEventListener("scroll", e => { });
        window.removeEventListener("keydown", e => { });
    };

    updateExpiredTime() {
        const timeForRefresh = new Date(this.tokenService.getExpiryDate()).getTime() - 900000;
        const expiredTime = new Date(this.tokenService.getExpiryDate()).getTime();
        if (expiredTime > Date.now() && Date.now() > timeForRefresh) {
            this.userService.refreshToken().pipe(take(1)).subscribe((res) => {
                this.loginInfo = new LoginInfo(res.access_token, res.expires_in, res.refresh_token, res.token_type);
                this.tokenService.saveToken(this.loginInfo);
                this.cleanUp();
            });
        }
    }
}
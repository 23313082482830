import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreConfigService, SpaceOneWebApiServiceURLs } from '@wissenswerft/core/configuration';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConvertHelper } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dynamicContentItemUri: string;
  private itemSocialMetaDataUri: string;
  private commentsUri: string;
  private commentsByItemIdUri: string;
  private notesUri: string;
  private noteByItemIdUri: string;
  private deleteNoteById: string;
  private itemsUri: string;
  private defaultDefinitionByTypeKeyUri: string;
  private accountsUri: string;
  private spoqlItemsUri: string;
  private enumPropertyUri: string;
  private chartUri: string;
  private assetssUri: string;
  private assetsByItemIdUri: string;
  private documentById: string;
  private downloadDocumentUri: string;
  private documents: string;
  private noteImagesById: string;
  private psApplicationsUri: string;
  public scope: string;
  private scopeName = new Subject()
  public scopeName$ = this.scopeName.asObservable();

  constructor(private http: HttpClient, private configService: CoreConfigService) {
    const serviceUrls: SpaceOneWebApiServiceURLs = <SpaceOneWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
    this.dynamicContentItemUri = serviceUrls.DynamicContentItem;
    this.itemSocialMetaDataUri = serviceUrls.ItemSocialMetaData;
    this.itemsUri = serviceUrls.Items;
    this.defaultDefinitionByTypeKeyUri = serviceUrls.DefaultDefinitionByTypeKey;
    this.accountsUri = serviceUrls.Accounts;
    this.spoqlItemsUri = serviceUrls.SPOQL_Items;
    this.commentsUri = serviceUrls.Comments;
    this.commentsByItemIdUri = serviceUrls.CommentsByItemId;
    this.notesUri = serviceUrls.Notes;
    this.noteByItemIdUri = serviceUrls.NoteByItemId;
    this.enumPropertyUri = serviceUrls.EnumProperty;
    this.chartUri = serviceUrls.Charts;
    this.assetssUri = serviceUrls.Assets;
    this.assetsByItemIdUri = serviceUrls.AssetsById;
    this.noteImagesById = serviceUrls.NoteImagesById;
    this.deleteNoteById = serviceUrls.DeleteNoteById;
    this.documents = serviceUrls.Documents;
    this.documentById = serviceUrls.DocumentById;
    this.downloadDocumentUri = serviceUrls.DownloadDocument;
    this.psApplicationsUri = serviceUrls.PsApplications;
  }

  public getAllComments<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.commentsUri, localStorage.getItem('scopeKey') || this.scope));
  }

  public getAllNotes<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.notesUri, localStorage.getItem('scopeKey') || this.scope));
  }

  public getCommentsByItemId<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.commentsByItemIdUri, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getNoteByItemId<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.noteByItemIdUri, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public deleteNoteByItemId<T>(id): Observable<T> {
    return this.executeWebApiDeleteMethod(ConvertHelper.resolveStringPlaceholders(this.deleteNoteById, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getNoteImagesByItemId<T>(postingid, attachmentuid): Observable<T> {
    return this.executeImageWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.noteImagesById, localStorage.getItem('scopeKey') || this.scope, postingid, attachmentuid));
  }

  public getAssetsByItemId<T>(id): Observable<T> {
    return this.executeImageWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.assetsByItemIdUri, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getDocumentByItemId<T>(id): Observable<T> {
    return this.executeImageWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.documentById, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getDocuments<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.documents, localStorage.getItem('scopeKey') || this.scope));
  }

  public getDynamicContentItemById<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.dynamicContentItemUri, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getSocialMetaDataByItemId<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.itemSocialMetaDataUri, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getItemsByName<T>(name: TypeKey): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.itemsUri, localStorage.getItem('scopeKey') || this.scope, name));
  }

  public getDefinitonByTypeKey<T>(name: TypeKey): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.defaultDefinitionByTypeKeyUri, localStorage.getItem('scopeKey') || this.scope, name));
  }

  public getAllApplications<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.psApplicationsUri));
  }
 
  public prepareDownloadUri<T>(id: number): string {
    return ConvertHelper.resolveStringPlaceholders(this.downloadDocumentUri, localStorage.getItem('scopeKey') || this.scope, id)
  }

  public getAllAccounts<T>(): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.accountsUri, localStorage.getItem('scopeKey') || this.scope)).pipe(
      map((users: any) => {
        users = users.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        return users.sort((a, b) => a.lastname.localeCompare(b.lastname));
      }));
  }

  public getItemsBySpoqlQuery<T>(queryTable, whereCondition): Observable<T> {
    return this.executeWebApiGetMethod<T>(this.spoqlItemsUri, { 'q': this.getSPOQLQuery(localStorage.getItem('scopeKey') || this.scope, queryTable, whereCondition) });
  }

  public getEnumProperty<T>(name: TypeKey, property: string): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.enumPropertyUri, localStorage.getItem('scopeKey') || this.scope, name, property));
  }

  private executeWebApiGetMethod<T>(url: string, params?: any): Observable<T> {
    if (sessionStorage.getItem('fixedCulture')) {
      const headers = new HttpHeaders().set('Accept-Language', sessionStorage.getItem('fixedCulture'));
      return this.http.get<T>(url, { headers: headers, params: params });
    } else {
      return this.http.get<T>(url, { params: params });
    }
  }

  private executeImageWebApiGetMethod<T>(url: string, params?: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'image/jpg');
    return this.http.get(url, { headers, responseType: 'arraybuffer' });
  }

  public getDocumentFormat<T>(id): Observable<any> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.documentById, localStorage.getItem('scopeKey') || this.scope, id));
  }

  public getBlobFile<T>(id): Observable<any> {
    return this.executeWebApiGetBlobMethod<T>(ConvertHelper.resolveStringPlaceholders(this.downloadDocumentUri, localStorage.getItem('scopeKey') || this.scope, id));
  }

  private executeWebApiGetBlobMethod<T>(url: string, params?: any): Observable<Blob> {
    return this.http.get(url, { params: params, responseType: 'blob' });
  }


  public insertAsserts(value, fileName) {
    const req = new FormData();
    req.append('assets', JSON.stringify({
      language: "de",
      title: fileName,
      hidden: false,
      source: {
        location: 'mpr:image',
        filename: fileName
      }
    }));
    req.append('image', value, fileName);
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("POST", "https://ibo-staging2.devdock.space.one/api/scope/ibo/assets");
    xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('access_token'));
    xhr.overrideMimeType("multipart/form-data");
    xhr.send(req);
    return xhr
  }

  public insertNote(id, text, value?, fileName?) {
    const data = new FormData();
    data.append('notes', new Blob([JSON.stringify({
      text: text,
      attachments: [{
        name: fileName,
        type: "IMAGE",
        source: "mpr:image"
      }]
    })], {
      type: "application/json"
    }));
    data.append("image", value);
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("POST", "https://ibo-staging2.devdock.space.one/api/scope/ibo/item/" + id + "/notes");
    xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('access_token'));
    xhr.overrideMimeType("multipart/form-data");
    xhr.send(data);
    return xhr
  }

  private getSPOQLQuery(scope, selectTable, whereCondition) {
    return `at '${scope}' select item from '${selectTable}' where { ${whereCondition} }`;
  }

  public setScope(scope): void {
    this.scope = scope;
    this.scopeName.next(scope);
  }

  public getScope(): string {
    return this.scope;
  }

  public executeWebApiPostMethod(url: string, body: any): Observable<any> {
    return this.http.post(url, body);
  }

  public prepareTitleScope(scope: string) {
    this.scopeName.next(scope);
  }

  public executeWebApiPatchMethod(url, body) {
    this.http.patch(url, body);
  }

  public executeWebApiDeleteMethod(url): Observable<any> {
    return this.http.delete(url);
  }

  public executeWebApiPutMethod(url, body): Observable<any> {
    return this.http.put(url, body);
  }

  public fetchChart<T>(chartKey: string, params): Observable<T> {
    return this.executeWebApiGetMethod<T>(ConvertHelper.resolveStringPlaceholders(this.chartUri, localStorage.getItem('scopeKey') || this.scope, chartKey),
      {
        ...params,
        'spaceKey': localStorage.getItem('scopeKey') || this.scope
      });
  }

  public importDocument(value, fileName) {
    const req = new FormData();
    req.append('assets', new Blob([JSON.stringify({
      language: "de",
      title: {
        de: fileName,
        en: fileName
      },
      hidden: false,
      typeKey: "document",
      source: {
        location: 'mpr:document',
        filename: fileName
      }
    })], {
      type: "application/json"
    }));
    req.append('document', value, fileName);
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("POST", "https://ubtng.devdock.space.one/api/scope/"+ localStorage.getItem('scopeKey') + "/assets");
    xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('access_token'));
    xhr.overrideMimeType("multipart/form-data");
    xhr.send(req);
    return xhr;
  }
}

export enum TypeKey {
  module = 'module',
  riskAssessment = 'riskIssue',
  riskAssessmentMeasure = 'riskAssessmentMeasure',
  subModule = 'subModule',
  measure = 'measure',
  measureBenefit = 'measureBenefit',
  measureType = 'typeOfMeasure',
  measureClassification = 'measureClassification',
  measureTask = 'measureTask',
  measureProgress = 'measureProgress',
  measureDefinition = 'measureDefinition',
  standard = 'standard',
  responsiblePlan = 'responsiblePlan',
  staffMember = 'staffMember',
  riskReduction = 'riskReduction',
  damageKind = 'damageKind',
  country = 'country',
  tag = 'tag',
  product = 'product',
  project = 'project',
  legalBasis = 'legalBasis',
  order = 'order',
  person = 'person',
  service = 'service',
  company = 'company',
  pest = 'pest',
  monotoring = 'monotoring',
  customer = 'customer',
  area = 'area',
  object = 'object',
  serviceCatalog = 'serviceCatalog',
  pestCatalog = 'pestCatalog',
  address = 'address',
  subProject = 'subProject',
  event = 'event',
  chance = 'chance',
  decision = 'decision',
  monitoring = 'monitoring',
  monitoringDefinition = 'monitoringDefinition',
  tour = "tour",
  resource = "resource",
  role = "role",
  collaborator = "collaborator"
}

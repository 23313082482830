import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ConvertHelper, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { MeasureProgress } from '@xmt-models';
import { DataService, ObjectKeys } from '../../../../services/data.service';
import { MeasureService } from '../../measure.service';
import { AppService } from '../../../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { Subscription } from 'rxjs';
import { DxNumberBoxComponent, DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'ubt-geods-status-report',
  templateUrl: './status-report.component.html',
  styleUrls: ['./status-report.component.scss']
})
export class StatusReportComponent implements OnDestroy {
  @ViewChild('progressField') progressField: DxNumberBoxComponent;
  @ViewChild('confirmDeletePopup') confirmDeletePopup: DxPopupComponent;
  @Input() lastMeasureProgress: MeasureProgress;
  @Input() measureProgress: MeasureProgress[];
  @Output() assignReportToMeasure: EventEmitter<string> = new EventEmitter();
  @Output() updateReportData: EventEmitter<MeasureProgress> = new EventEmitter();
  @Output() deleteReport: EventEmitter<string> = new EventEmitter();
  public convertHelper = ConvertHelper;
  public workOnLastStatus = false;
  public isNewMeasureTask = false;
  public date = new Date();
  public disableSave: boolean = true;
  private originalReportData: MeasureProgress;
  private subscriptions: Subscription[] = [];

  constructor(
    public measureService: MeasureService,
    public dataService: DataService,
    private persistenceService: GeoDsPersistenceService,
    public appService: AppService
  ) { }

  public navigateToMeasureProgress = (index: number): void => {
    this.appService.currentMeasureProgressIndex = this.appService.currentMeasureProgressIndex + index;
    this.lastMeasureProgress = this.measureProgress[this.appService.currentMeasureProgressIndex];
    this.originalReportData = new MeasureProgress({ ...this.lastMeasureProgress });
  }

  public persistStatusReport(): void {
    if (!this.lastMeasureProgress.Id) {
      this.addStatusReport();
    } else {
      this.updateStatusReport();
    }
  }

  public verifyChanges(event, fieldName: string): void {
    if (event.value != undefined) {
      if (!this.originalReportData) {
        this.originalReportData = new MeasureProgress({ ...this.lastMeasureProgress });
        this.originalReportData[fieldName] = event.previousValue;
      }

      if (fieldName === 'ProgressPercentage') {
        this.measureService.validateNumberField(this.progressField, 0, 100);
      }
      if (this.originalReportData[fieldName] !== event.value) {
        this.disableSave = false;
      } else {
        this.disableSave = (this.originalReportData.Progress == this.lastMeasureProgress.Progress
          && this.originalReportData.NextSteps == this.lastMeasureProgress.NextSteps
          && this.originalReportData.RiskAndChance == this.lastMeasureProgress.RiskAndChance
          && this.originalReportData.Decision == this.lastMeasureProgress.Decision
          && this.originalReportData.ProgressPercentage == this.lastMeasureProgress.ProgressPercentage
          && this.originalReportData.MaturityLevel == this.lastMeasureProgress.MaturityLevel
        );
      }
    }
  }

  public addStatusReport = () => {
    const reportPersistQuery: TargetObjectData = new TargetObjectData();
    reportPersistQuery.ObjectKey = new ObjectKey();
    reportPersistQuery.Mode = PersistMode.Insert;
    reportPersistQuery.ObjectKey.ObjectType = ObjectKeys.MEASUREPROGRESS;
    const progressDate = new Date();

    const reportColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.measureService.currentMeasureId },
      { Name: 'Progress', Value: this.lastMeasureProgress.Progress },
      { Name: 'NextSteps', Value: this.lastMeasureProgress.NextSteps },
      { Name: 'RiskAndChance', Value: this.lastMeasureProgress.RiskAndChance },
      { Name: 'Decision', Value: this.lastMeasureProgress.Decision },
      { Name: 'ProgressPercentage', Value: this.lastMeasureProgress.ProgressPercentage },
      { Name: 'MaturityLevel', Value: this.lastMeasureProgress.MaturityLevel },
      { Name: 'ProgressDate', Value: progressDate.toDateString() },
      { Name: 'SysTimeInsert', Value: progressDate.getHours() + ':' + progressDate.getMinutes() }
    ];
    reportPersistQuery.TargetColumns = reportColumns;
    const query: PersistObjectModel = new PersistObjectModel();
    query.Object = reportPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(query).subscribe(data => {
      this.assignReportToMeasure.emit(data.Id);
      this.isNewMeasureTask = false;
      this.appService.currentMeasureProgressIndex += 1;
      this.lastMeasureProgress.Id = data.Id;
      this.disableSave = true;
    }));
  }

  public updateStatusReport() {
    const reportPersistQuery: TargetObjectData = new TargetObjectData();
    reportPersistQuery.ObjectKey = new ObjectKey();
    reportPersistQuery.ObjectKey.ObjectType = ObjectKeys.MEASUREPROGRESS;
    reportPersistQuery.Mode = PersistMode.Update;
    reportPersistQuery.ObjectKey.Id = this.lastMeasureProgress.Id;
    const updateReportColumns: TargetColumnValue[] = [
      { Name: 'Progress', Value: this.lastMeasureProgress.Progress },
      { Name: 'NextSteps', Value: this.lastMeasureProgress.NextSteps },
      { Name: 'RiskAndChance', Value: this.lastMeasureProgress.RiskAndChance },
      { Name: 'Decision', Value: this.lastMeasureProgress.Decision },
      { Name: 'ProgressPercentage', Value: this.lastMeasureProgress.ProgressPercentage },
      { Name: 'MaturityLevel', Value: this.lastMeasureProgress.MaturityLevel },
    ];
    reportPersistQuery.TargetColumns = updateReportColumns;
    const query: PersistObjectModel = new PersistObjectModel();
    query.Object = reportPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(query).subscribe(() => {
      this.updateReportData.emit(this.lastMeasureProgress);
      this.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
      this.disableSave = true;
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }));
  }

  public clearProgressFileds = (): void => {
    if (this.workOnLastStatus == false) {
      this.lastMeasureProgress = {
        ...new MeasureProgress(null),
        ProgressDate: this.lastMeasureProgress.ProgressDate
      };
      this.originalReportData = null;
    } else {
      this.lastMeasureProgress = { ...this.lastMeasureProgress };
      delete this.lastMeasureProgress['meta'];
      delete this.lastMeasureProgress.Id;
    }
    this.disableSave = false;
    this.isNewMeasureTask = true;
  }

  public openConfirmTaskDialog(): void {
    this.confirmDeletePopup.instance.show();
  }

  public abortTaskDelete(): void {
    this.confirmDeletePopup.instance.hide();
  }

  public deleteStatusReport(): void {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.MEASUREPROGRESS, this.lastMeasureProgress.Id).subscribe(() => {
      this.deleteReport.emit(this.lastMeasureProgress.Id);
      this.confirmDeletePopup.instance.hide();
      if (this.measureProgress.length == 1) {
        this.lastMeasureProgress = this.measureProgress[0];
        this.originalReportData = new MeasureProgress({ ...this.lastMeasureProgress });
      } else if (this.measureProgress.length == 0) {
        this.appService.currentMeasureProgressIndex -= 1;
        this.lastMeasureProgress = new MeasureProgress(null);
        this.originalReportData = null;
        this.lastMeasureProgress.ProgressDate = new Date();
      } else if (this.measureProgress[this.appService.currentMeasureProgressIndex + 1]) {
        this.navigateToMeasureProgress(0);
      } else if (this.measureProgress[this.appService.currentMeasureProgressIndex - 1]) {
        this.navigateToMeasureProgress(-1);
      }
      this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    }, error => {
      console.error(error)
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <h2 class="title-style">
        {{ dataService.res('Ubt-TreeList-Milestones-Tasks') }}
      </h2>
    </div>
  </div>
  <div class="content-block">
    <div class="dx-card responsive-paddings card-radius">
      <dx-data-grid class="grid-group-by-Style custome-export-style" [rowAlternationEnabled]="false" [height]="'100%'"
        (onCellPrepared)="onCellPrepared($event)" [noDataText]="noDataText" [hoverStateEnabled]="false"
        [showRowLines]="false" [showBorders]="true" [columns]="columnsHeader" [dataSource]="measureTasks">
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-filter-row [visible]="true" [showAllText]="'Alle'"></dxo-filter-row>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"> </dxo-search-panel>
        <dxo-column-chooser [allowSearch]="true" [enabled]="true"> </dxo-column-chooser>
        <dxo-summary>
          <dxi-total-item column="TotalTimeRequired" summaryType="sum" valueFormat="#,##0 h"
            displayFormat="{{dataService.res('Ubt-Report-Total')}}: {0}">
          </dxi-total-item>
        </dxo-summary>
        <dxo-paging [enabled]="false"></dxo-paging>
      </dx-data-grid>
    </div>
  </div>
</div>
<ubt-loader *ngIf="showLoader"></ubt-loader>
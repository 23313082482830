import { Injectable } from '@angular/core';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectHelper, ObjectKey, PersistMode,
  PersistObjectModel, QueryColumn, QueryColumnSortOrder, TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { ValidationStatus } from '@wissenswerft/ww-library';
import { Measure, SubProject } from '@xmt-models';
import { DxNumberBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService, ObjectKeys } from '../../services/data.service';
import { DataDefinitionOptions } from '../../models/home.model';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  public selectedMeasures: Measure[];
  public cachedColumnsResponse: Column[];
  public collaboratorsColumns: Column[] = [];
  public collaboratorsTotalTimeColumns: Column[] = [];
  private calculateTotalTime = new BehaviorSubject<boolean>(false);
  public calculateTotalTime$ = this.calculateTotalTime.asObservable();
  public isCreatingSubTask: boolean;
  public degree = [{ id: 0, value: "0%" }, { id: 25, value: "25%" }, { id: 50, value: "50%" }, { id: 75, value: "75%" }, { id: 100, value: "100%" }];
  public currentMeasureId: string;
  public currentMeasureIndex: number;
  public measureStatus = [
    { name: 'Erledigt', value: 'Erledigt' },
    { name: 'In Arbeit', value: 'In Arbeit' },
    { name: 'Offen', value: 'Offen' }
  ];
  public maturityLevel: DataDefinitionOptions[] = [
    { name: 'HG1', value: 'HG1' },
    { name: 'HG2', value: 'HG2' },
    { name: 'HG3', value: 'HG3' },
    { name: 'HG4', value: 'HG4' },
    { name: 'HG5', value: 'HG5' }
  ];
  public statusPercentage: DataDefinitionOptions[] = [
    { name: '0%', value: 0 },
    { name: '20%', value: 20 },
    { name: '40%', value: 40 },
    { name: '60%', value: 60 },
    { name: '80%', value: 80 },
    { name: '100%', value: 100 }
  ];

  public readonly measureDesignationColumns = [
    this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
  ];

  constructor(
    private dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public calculateOverAllTime(measure) {
    const collaboratorsList = []
    if (Array.isArray(measure?.MeasureTasks) && measure.MeasureTasks.length > 0) {
      measure.MeasureTasks.forEach(task => {
        if (task.Collaborators?.length > 0) {
          task.Collaborators.forEach(collaborator => {
            collaboratorsList.push(collaborator);
          });
        }
      });
    }
    const unStructuredCollaboratorList = ObjectHelper.groupByListItems(collaboratorsList, 'IdRefMember', "TimePlanned");
    const structuredCollaboratorList = [];
    Object.keys(unStructuredCollaboratorList).map(function (key) {
      const sum = unStructuredCollaboratorList[key].reduce((a, b) => a + b, 0);
      unStructuredCollaboratorList[key] = { name: key, sum: sum }
      structuredCollaboratorList.push(unStructuredCollaboratorList[key]);
    });
    return structuredCollaboratorList;
  }

  public realTimecalculateTotalTime(data: boolean) {
    this.calculateTotalTime.next(data);
  }

  public validateNumberField(field: DxNumberBoxComponent, minVal: number, maxVal: number) {
    if (field && field.value) {
      if ((field.value >= minVal) && (field.value <= maxVal)) {
        field.validationStatus = ValidationStatus.VALID;
      } else {
        field.validationStatus = ValidationStatus.INVALID;
      }
    }
  }

  public updateMeasure(columnName: string, value, allowUpdate: boolean): void {
    if (allowUpdate === true) {
      const measurePersistQuery: TargetObjectData = new TargetObjectData();
      measurePersistQuery.ObjectKey = new ObjectKey();
      measurePersistQuery.ObjectKey.ObjectType = ObjectKeys.MEASURE;
      const measureColumns: TargetColumnValue[] = [
        { Name: columnName, Value: value }
      ];
      measurePersistQuery.Mode = PersistMode.Update;
      measurePersistQuery.ObjectKey.Id = this.currentMeasureId;
      measurePersistQuery.TargetColumns = measureColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = measurePersistQuery;
      this.persistenceService.executePersistObjectQuery(persistObject).subscribe();
    }
  }

  public prepareMeasuresByProjectId(measureColumns: QueryColumn[]) {
    return this.dataService.readObjects<[]>(ObjectKeys.MEASURE, [this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None)]).pipe(switchMap(() => {
      const subProjectColumns = [
        this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
      ];
      const subProjectOPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
      return this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, subProjectColumns, subProjectOPath).pipe(switchMap(() => {
        const measureOPath = '!.ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
        return this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, measureColumns, measureOPath).pipe(map(measures => (measures)));
      }))
    }))
  }
}
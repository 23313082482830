import { GeoDsMeta } from "@geods/base";

export class Resource extends GeoDsMeta {
    ParentId: string;
    Ident: string;
    Period: Date;
    TimePlanned: number;
    Member: string;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
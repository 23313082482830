<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="title-style ">{{dataService.res('Ubt-SubProject-Create-Company')}}</h2>
        </div>
    </div>
    <div fxLayout="row" class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <ww-grid #companyGrid class="grid-group-by-Style" [id]="'companyGrid'" [gridData]='companies'
                [noDataText]="noDataText" [enableExport]="false" [enableColumnChooser]="true" (onRowRemoved)="false"
                (onCellClick)="onCellClick($event)" [height]="'100%'" [rowAlternationEnabled]="false"
                [columnsHeader]="columnsHeader" (onCellPrepared)="onCellPrepared($event)"
                [allowColumnReordering]="false" [allowDeleting]="false" [enableLoadPanel]="true" [allowAdding]="false"
                [pageable]="false" [groupable]="false">
            </ww-grid>
            <dx-speed-dial-action icon="add" [index]="1" (onClick)="openCompanyDialog()">
            </dx-speed-dial-action>
        </div>
    </div>

    <dx-popup #formCompanyPopup class="popup-style" [width]="'50%'" [height]="'auto'" [showCloseButton]="true"
        fxLayout="column" fxLayoutAlign="center center" (onHiding)="emptyData()">
        <dx-scroll-view class="dx-card popup-card-paddings card-radius" [showScrollbar]="'onScroll'">
            <form action="post" (submit)="onCreateCompany($event)">
                <dx-form #form id="form" [(formData)]="company">
                    <dxi-item itemType="group" [colCount]="2">
                        <dxi-item [isRequired]="true" class="floating-label">
                            <dx-text-box dataField="Name" name="name" labelMode="floating"
                                [label]="dataService.res('Ubt-Company-Column-Name')+'*'" [(value)]="company.Name">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item [isRequired]="true" editorType="dxTextBox" class="floating-label">
                            <dx-text-box dataField="MatchCode" name="matchCode" labelMode="floating"
                                [label]="dataService.res('Ubt-Company-Column-Match-Code')+'*'"
                                [(value)]="company.MatchCode">
                            </dx-text-box>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="3">
                        <dxi-item [isRequired]="true" editorType="dxSelectBox">
                            <dx-select-box class="drop-down-style" [labelMode]="'floating'" [(value)]="company.Status"
                                displayExpr='Description' valueExpr='Value' [dataSource]="cachedStatus"
                                [label]="dataService.res('Ubt-Company-Column-Status')+'*'">
                            </dx-select-box>
                        </dxi-item>
                        <dxi-item editorType="dxSelectBox">
                            <dx-select-box class="drop-down-style" [(value)]="company.CompanyTitle"
                                displayExpr='Description' valueExpr='Value' [dataSource]="cachedCompaniesTitles"
                                [label]="dataService.res('Ubt-Company-Column-Organizations')+'*'"
                                [labelMode]="'floating'">
                            </dx-select-box>
                        </dxi-item>
                        <dxi-item class="floating-label">
                            <dx-text-box mode="number" [(value)]="company.CustomerNumber"
                                [label]="dataService.res('Ubt-Company-Column-Customer-Number')" name="customerNumber"
                                labelMode="floating">
                            </dx-text-box>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="1">
                        <dxi-item [cssClass]="'title-bloc'">
                            <p>{{dataService.res('Ubt-Company-Column-Address')}}</p>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="3">
                        <dxi-item class="floating-label">
                            <dx-text-box [(value)]="company.HouseNumber"
                                [label]="dataService.res('Ubt-Company-Column-House-Number')" name="houseNumber"
                                labelMode="floating">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item class="floating-label">
                            <dx-text-box dataField="Street" name="street" labelMode="floating"
                                [label]="dataService.res('Ubt-Company-Column-Street')" [(value)]="company.Street">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item class="floating-label">
                            <dx-text-box dataField="City" name="city" labelMode="floating"
                                [label]="dataService.res('Ubt-Company-Column-City')" [(value)]="company.City">
                            </dx-text-box>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="3">
                        <dxi-item class="floating-label">
                            <dx-text-box dataField="Country" name="country" labelMode="floating"
                                [label]="dataService.res('Ubt-Company-Column-Land')" [(value)]="company.Country">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item class="floating-label">
                            <dx-text-box dataField="ZIP" name="zip" labelMode="floating"
                                [label]="dataService.res('Ubt-Company-Column-Zip')" [(value)]="company.ZIP">
                            </dx-text-box>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="1">
                        <dxi-item [cssClass]="'title-bloc'">
                            <p>{{dataService.res('Ubt-Company-Column-Phone')}}</p>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="4" [cssClass]="'phone-bloc'">
                        <dxi-item [isRequired]="true" class="floating-label">
                            <dx-text-box #phoneTextBox [(value)]="phoneNumber.CountryCode" [stylingMode]="'outlined'"
                                [width]="'100%'" [label]="dataService.res('Ubt-Company-Column-Country-Code')"
                                mode="number" [labelMode]="'floating'">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item [isRequired]="true" class="floating-label">
                            <dx-text-box #phoneTextBox [(value)]="phoneNumber.LocalAreaCode" [stylingMode]="'outlined'"
                                [width]="'100%'" [label]="dataService.res('Ubt-Company-Column-Local-Area-Code')"
                                mode="number" [labelMode]="'floating'">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item [isRequired]="true" class="floating-label">
                            <dx-text-box #phoneTextBox [(value)]="phoneNumber.Number" [stylingMode]="'outlined'"
                                [width]="'100%'" [label]="dataService.res('Ubt-Company-Column-Number')" mode="number"
                                [labelMode]="'floating'">
                            </dx-text-box>
                        </dxi-item>
                        <dxi-item [isRequired]="true" class="floating-label">
                            <dx-text-box #phoneTextBox [(value)]="phoneNumber.Mediation" [stylingMode]="'outlined'"
                                [width]="'100%'" [label]="dataService.res('Ubt-Company-Column-Extention')" mode="number"
                                [labelMode]="'floating'">
                            </dx-text-box>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="1">
                        <dxi-item [cssClass]="'title-bloc'">
                            <p>{{dataService.res('Ubt-Company-Column-Email')}}</p>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [colCount]="1">
                        <dxi-item [isRequired]="true" class="floating-label">
                            <dx-text-box #emailTextBox dataField="EMail" name="eMail" labelMode="floating" type="email"
                                [label]="dataService.res('Ubt-Company-Column-Email')" [(value)]="email.Value">
                            </dx-text-box>
                            <dxi-validation-rule type="email"></dxi-validation-rule>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>

                    <dxi-item itemType="group" [cssClass]="'div-buttons'">
                        <dxi-item itemType="button" [cssClass]="'submit-button'"
                            [buttonOptions]="{ useSubmitBehavior: true, text: this.companyService.isUpdating === true ? this.dataService.res('Ubt-Company-Icon-Edit') : this.dataService.res('Ubt-Company-Button-Create') }">
                        </dxi-item>
                        <dxi-item itemType="button" [cssClass]="'cancel-button'"
                            [buttonOptions]="{ text: this.dataService.res('Ubt-Company-Button-Cancel'), onClick: cancelButtonOptions.onClick }">
                        </dxi-item>
                    </dxi-item>
                </dx-form>
            </form>
        </dx-scroll-view>
    </dx-popup>

    <dx-popup #deleteCompanyPopup class="popup-style" [width]="'25%'" fxLayout="column" fxLayoutAlign="center center"
        [height]="'auto'" [title]="dataService.res('Ubt-Company-Title-Confirm-Delete')" [showCloseButton]="true"
        (onHiding)="emptyData()">
        <div class="dx-card popup-card-paddings card-radius">
            <div class="delete-popup">{{dataService.res('Ubt-Company-Message-Delete')}}</div>
            <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
                <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
                    (onClick)="deleteCompany()">
                </dx-button>
                <dx-button class="cancel-button" [text]="dataService.res('Ubt-Company-Button-Cancel')"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>
        </div>
    </dx-popup>
</div>

<ubt-loader *ngIf="showLoader"></ubt-loader>
<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
    [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
import { Address } from "@geods/base";

export class AddressViewModel {
    protected address: Address;

    get id(): string { return this.address.Id; }
    set id(id: string) { this.address.Id = id; }

    get name(): string { return this.address.Name; }
    set name(name: string) { this.address.Name = name; }

    get matchCode(): string { return this.address.MatchCode; }
    set matchCode(matchCode: string) { this.address.MatchCode = matchCode; }

    get status(): string { return this.address.Status; }
    set status(status: string) { this.address.Status = status; }

    get companyTitle(): string { return this.address.CompanyTitle; }
    set companyTitle(companyTitle: string) { this.address.CompanyTitle = companyTitle; }

    get street(): string { return this.address.Street; }
    set street(street: string) { this.address.Street = street; }

    get customerNumber(): string { return this.address.CustomerNumber; }
    set customerNumber(customerNumber: string) { this.address.CustomerNumber = customerNumber; }

    get houseNumber(): string { return this.address.HouseNumber; }
    set houseNumber(houseNumber: string) { this.address.HouseNumber = houseNumber; }

    get zip(): string { return this.address.ZIP; }
    set zip(zip: string) { this.address.ZIP = zip; }

    get country(): string { return this.address.Country; }
    set country(country: string) { this.address.Country = country; }

    get city(): string { return this.address.City; }
    set city(city: string) { this.address.City = city; }

    get faxNumber(): string { if (this.address.FaxNumbers) return this.address.FaxNumbers[0].Number; }
    set faxNumber(number: string) { if (this.address.FaxNumbers) this.address.FaxNumbers[0].Number = number; }

    get phoneNumber(): string { if (this.address.PhoneNumbers) return this.address.PhoneNumbers[0].Number; }
    set phoneNumber(number: string) { if (this.address.PhoneNumbers) this.address.PhoneNumbers[0].Number = number; }

    get fullNumber(): string {
        if (this.address['PhoneNumbers']) {
            return this.formatPhoneNumber(this.address['PhoneNumbers'][0]);
        }
    }
    set fullNumber(number: string) {
        if (this.address['PhoneNumbers']) {
            this.address['PhoneNumbers'][0]['FullNumber'] = number;
        }
    }

    get countryCodePhone(): string { if (this.address.PhoneNumbers) return this.address.PhoneNumbers[0].CountryCode; }
    set countryCodePhone(countryCode: string) { if (this.address.PhoneNumbers) this.address.PhoneNumbers[0].CountryCode = countryCode; }

    get localAreaCodePhone(): string { if (this.address.PhoneNumbers) return this.address.PhoneNumbers[0].LocalAreaCode; }
    set localAreaCodePhone(localAreaCode: string) { if (this.address.PhoneNumbers) this.address.PhoneNumbers[0].LocalAreaCode = localAreaCode; }

    get numberPhone(): string { if (this.address.PhoneNumbers) return this.address.PhoneNumbers[0].Number; }
    set numberPhone(number: string) { if (this.address.PhoneNumbers) this.address.PhoneNumbers[0].Number = number; }

    get mediationPhone(): string { if (this.address.PhoneNumbers) return this.address.PhoneNumbers[0].Mediation; }
    set mediationPhone(mdiation: string) { if (this.address.PhoneNumbers) this.address.PhoneNumbers[0].Mediation = mdiation; }

    get email(): string {
        if (this.address['MailAddress']) {
            return this.address['MailAddress'][0].Value;
        }
    }
    set email(email: string) { if (this.address['MailAddress']) this.address['MailAddress'][0].Value = email; }


    private formatPhoneNumber(phone): string {
        if (phone) {
            return '+' + [phone.CountryCode,
            phone.LocalAreaCode,
            phone.Number,
            phone.Mediation].filter((number) => (number != null && number != undefined)).join(' ')
        }
    }
    
    constructor(address: Address) {
        this.address = address;
    }
}

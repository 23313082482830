import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, 
  Query, QueryColumn, QueryObjectsModel, ReadListDataSourceModel, TargetObjectData } from '@wissenswerft/core/data';
import { Address } from '@geods/base';
import { Observable, Subject } from 'rxjs';
import { Project } from '@xmt-models';

export enum ObjectTypeNames {
  Company = 111000100
}
export enum WebStorageType {
  SessionStorage = 'sessionStorage',
  LocalStorage = 'localStorage'
}
export interface IProjectDependency {
  name: string;
  value?: string;
  storageType: WebStorageType;
}

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private readonly objectSpaceName = 'ABC.Applications.KAOS.Data';
  private gridDataSubject = new Subject<any>();
  private companyNameSubject = new Subject();
  private filterCompanyDataSubject = new Subject();
  private selectedCompanyDataSubject = new Subject<boolean>();
  private selectedProjectDataSubject = new Subject<Project>();
  public updateGridData$ = this.gridDataSubject.asObservable();
  public companyName$ = this.companyNameSubject.asObservable();
  public filterCompanyData$ = this.filterCompanyDataSubject.asObservable();
  public selectedCompanyData$ = this.selectedCompanyDataSubject.asObservable();

  constructor(
    private router: Router,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public loadListDataSource(objectType: string, columnName: string): Observable<any> {
    const readListDataSourceQuery = new ReadListDataSourceModel();
    const objectKey = new ObjectKey();
    objectKey.ObjectType = objectType;
    readListDataSourceQuery.ObjectKey = objectKey;
    readListDataSourceQuery.OutputMode = 0;
    readListDataSourceQuery.ColumnName = columnName;
    return this.coreDataService.executeReadListDataSource(
      readListDataSourceQuery
    );
  }

  public readObjects<T>(objectType: string, columns: QueryColumn[], OPath?: string): Observable<T> {
    const readObjectQuery: QueryObjectsModel = new QueryObjectsModel();
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = objectType;
    mainQuery.Columns = columns;
    mainQuery.OPath = OPath;
    if (OPath) {
      mainQuery.OPath = OPath;
    }
    readObjectQuery.ObjectQueries = [mainQuery];
    return this.coreDataService.executeReadObjectsQuery(readObjectQuery);
  }

  public deleteObject(objectKey: ObjectKeys, id: string | number): Observable<any> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = PersistMode.Delete;
    targetObject.ObjectKey = new ObjectKey();
    targetObject.ObjectKey.Id = id.toString();
    targetObject.ObjectKey.ObjectType = objectKey;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }

  public updateGridData(data: any) {
    this.gridDataSubject.next(data);
  }

  public prepareTitleCompany(companyName: string): void {
    this.companyNameSubject.next(companyName);
  }

  public filterCompanyData(data: Address[] | number) {
    this.filterCompanyDataSubject.next(data);
  }

  public selectedCompanyData(data: boolean) {
    this.selectedCompanyDataSubject.next(data);
  }

  public selectedProjectData(data: Project) {
    this.selectedProjectDataSubject.next(data);
  }

  public goToCompanyProjects(configDep: IProjectDependency[][], url: string, ): void {
    configDep[1].forEach(dep => {
      window[dep.storageType].removeItem(dep.name);
    });
    window[configDep[0][0].storageType].setItem(configDep[0][0].name, configDep[0][0].value);
    window[configDep[0][1].storageType].setItem(configDep[0][1].name, configDep[0][1].value);
    this.prepareTitleCompany(configDep[0][1].value);
    this.router.navigateByUrl(url);
  }
}
export enum ObjectKeys {
  PROJECT = 'xmtProject',
  ADDRESS = 'Address'
}
export enum ToastType {
    CUSTOM = 'custom',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export interface IToast {
    message : string;
    type : ToastType;
    displayTime? : number;
}

export interface IToastAnimation {
    show?: IShowHide;
    hide?: IShowHide;
}

export enum Position {
    TOP="top",
    BOTTOM="bottom",
    RIGHT="right",
    LEFT="left",
    CENTER="center"
}

export interface PositionIValue {
    my:Position,
    at:Position,
    of?:any
}

export interface PositionI {
    position:PositionIValue,
}

export interface IShowHide {
    type: ToastAnimationShowType,
    duration: number,
    from?: number | PositionI,
    to?: number | PositionI
}


export enum ToastAnimationShowType {
    FADE = 'fade',
    SLIDE = 'slide'
}


<dx-accordion class="accordion-style implement-plain-container" [collapsible]="true" [selectedIndex]="0"
    [width]="'100%'" [animationDuration]="300" [visible]="true" [multiple]="false" [dataSource]="['Test']">
    <div *dxTemplate="let implementationPlan of 'title'" class="dx-accordion-item-title">
        {{ this.dataService.res("Ubt-MeasureDetail-Implementation-Plan") }}
    </div>
    <div *dxTemplate="let company of 'item'">
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="20px" class=" container-card">
                <div fxLayoutAlign="center start" class="accordionStyle ellipsis" fxLayout="column" fxFlex="25">
                    {{this.dataService.res("Ubt-TreeList-Milestones-Tasks") }}
                </div>
                <div fxLayoutAlign="center" class="accordionStyle ellipsis" fxLayout="column" fxFlex="30">
                    <div fxLayout="row">
                        <img class="date-start-style" src="assets/images/dateStart.svg">
                        <div>{{ currentMeasure.StartDate }}</div>
                    </div>
                </div>
                <div fxLayoutAlign="center" class="accordionStyle ellipsis" fxLayout="column" fxFlex="30">
                    <div fxLayout="row">
                        <img class="date-start-style" src="assets/images/dateEnd.svg">
                        <div>{{ currentMeasure.EndDate }}</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                    <dx-button [stylingMode]="'text'" [text]="dataService.res('Ubt-MeasureTask-Create-Task')"
                        icon="plus" [useSubmitBehavior]="false" [class.active]="isNotificationActive"
                        class="buttons-container active" (onClick)="addTask()">
                    </dx-button>
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                    <dx-button [stylingMode]="'text'" [text]="dataService.res('Ubt-MeasureTask-Create-Milestone')"
                        icon="plus" [useSubmitBehavior]="false" [class.active]="isNotificationActive"
                        class="buttons-container active" (onClick)="addMilestone()">
                    </dx-button>
                </div>
            </div>

            <dx-data-grid #meausreTaskTree id="MeasureAufgabenGrid"
                class="grid-group-by-Style tree-list-style tree-list-height" (onCellPrepared)="cellPrepared($event)"
                (onRowPrepared)="rowPrepared($event)" [dataSource]="currentMeasure.MeasureTasks" [columns]="taskColumns"
                [showBorders]="true">
                <dxo-paging [enabled]="false"></dxo-paging>
            </dx-data-grid>
        </div>
    </div>
</dx-accordion>

<dx-popup #milestonePopup [showTitle]="true" [height]="'auto'" [width]="'500px'" [showCloseButton]="true">
    <div class="dx-card popup-card-paddings card-radius">
        <form action="post" (submit)="persistMeasureTask($event)">
            <dx-form #milestoneForm id="form" [(formData)]="measureTask" [colCount]="1">
                <dxi-item [isRequired]="true">
                    <dx-text-box [stylingMode]="'outlined'" [(value)]="measureTask.Label" [width]="'100%'"
                        [labelMode]="'floating'" [label]="dataService.res('Ubt-MeasureTask-Label')">
                    </dx-text-box>
                    <dxi-validation-rule type="required" message="{{dataService.res('Ubt-validator-RequiredField')}}">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item [isRequired]="true">
                    <dx-select-box fxFill class="drop-down-style" valueExpr="Id" [(value)]="measureTask.Responsible"
                        [displayExpr]="dataService.getResponsibleFullName"
                        [dataSource]="dataService.cachedResponsiblesResponse"
                        [label]="dataService.res('Ubt-CreateMeasure-Responsible')" [labelMode]="'floating'"
                        [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                    </dx-select-box>
                </dxi-item>
                <dxi-item itemType="group" [colCount]="1" [cssClass]="'form-fields-row'">
                    <dxi-item [isRequired]="true">
                        <dx-date-box class="date-box-style" [stylingMode]="'outlined'" [(value)]="measureTask.EndDate"
                            [width]="'100%'" [labelMode]="'floating'"
                            [label]="dataService.res('Ubt-MeasureTask-Target-date')">
                        </dx-date-box>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                </dxi-item>

                <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                    <dxi-item itemType="button" [cssClass]="'submit-button'"
                        [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Ubt-Form-'+submitButtonText) }">
                    </dxi-item>
                    <dxi-item itemType="button" [cssClass]="'cancel-button'" [buttonOptions]="cancelButtonOptions">
                    </dxi-item>
                </dxi-item>
            </dx-form>
        </form>
    </div>
</dx-popup>

<dx-popup #taskPopup [showTitle]="true" [height]="'auto'" [width]="'40%'" [showCloseButton]="false">
    <div class="dx-card popup-card-paddings card-radius">
        <form action="post" (submit)="persistMeasureSubTask($event)">
            <dx-form #taskForm id="form" [colCount]="1">
                <dxi-item [isRequired]="true">
                    <dx-text-box [stylingMode]="'outlined'" [width]="'100%'" [labelMode]="'floating'"
                        [(value)]="measureTask.Label" [label]="dataService.res('Ubt-MeasureTask-Label')">
                    </dx-text-box>
                    <dxi-validation-rule type="required" message="{{dataService.res('Ubt-validator-RequiredField')}}">
                    </dxi-validation-rule>
                </dxi-item>

                <dxi-item itemType="group" [colCount]="3" [cssClass]="'form-fields-row'">
                    <dxi-item [isRequired]="true">
                        <dx-date-box class="date-box-style" [stylingMode]="'outlined'" [width]="'100%'"
                            [(value)]="measureTask.StartDate" [labelMode]="'floating'"
                            [label]="dataService.res('Ubt-Measure-List-StartDate')">
                        </dx-date-box>
                        <dxi-validation-rule type="required"
                            message="{{dataService.res('Ubt-validator-RequiredField')}}">
                        </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [isRequired]="true">
                        <dx-date-box class="date-box-style" [stylingMode]="'outlined'" [(value)]="measureTask.EndDate"
                            [width]="'100%'" [labelMode]="'floating'"
                            [label]="dataService.res('Ubt-Measure-List-EndDate')">
                        </dx-date-box>
                    </dxi-item>

                    <dxi-item [isRequired]="true">
                        <dx-date-box class="date-box-style" [stylingMode]="'outlined'" [(value)]="measureTask.EndDate"
                            [width]="'100%'" [labelMode]="'floating'"
                            [label]="dataService.res('Ubt-MeasureTask-Target-date')">
                        </dx-date-box>
                    </dxi-item>
                </dxi-item>

                <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                    <dxi-item [isRequired]="true">
                        <dx-select-box fxFill class="drop-down-style" valueExpr="Id"
                            [displayExpr]="dataService.getResponsibleFullName" [(value)]="measureTask.Responsible"
                            [dataSource]="dataService.cachedResponsiblesResponse"
                            [label]="dataService.res('Ubt-CreateMeasure-Responsible')" [labelMode]="'floating'"
                            [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                        </dx-select-box>
                    </dxi-item>
                    <dxi-item>
                        <dx-select-box class="drop-down-style" [width]="'100%'" [(value)]="measureTask.Type"
                            [dataSource]="measureTaskStatus" displayExpr="name" valueExpr="value"
                            [stylingMode]="'outlined'" [labelMode]="'floating'"
                            [label]="dataService.res('Ubt-Measure-Detail-Status-Label')">
                        </dx-select-box>
                    </dxi-item>
                </dxi-item>


                <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                    <dxi-item>
                        <dx-number-box #efficiencyField [stylingMode]="'outlined'" [width]="'100%'" [min]="0"
                            [(value)]="measureTask.DegreeOfImplementation" [max]="100"
                            [format]="dataService.customizePercentageText" [labelMode]="'floating'"
                            [label]="dataService.res('Ubt-MeasureTask-DegreeOfImplementation')">
                        </dx-number-box>
                    </dxi-item>
                    <dxi-item>
                        <dx-number-box [label]="dataService.res('Ubt-CreateMeasureTask-Total-Time')" format="#,##0 h"
                            [(value)]="measureTask.TotalTimeRequired">
                        </dx-number-box>
                    </dxi-item>
                </dxi-item>

                <dxi-item>
                    <div *dxTemplate fxLayout="column">
                        <div fxLayout="row">
                            <ubt-geods-collaborators #collaboratorGrid [dataSource]="measureTask.Collaborators"
                                [assignedResources]="currentMeasure.Resources" [currentMeasureTask]="measureTask"
                                (onCalculateOverallTime)="onCalculateOverallTime($event)">
                            </ubt-geods-collaborators>
                        </div>
                    </div>
                </dxi-item>

                <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                    <dxi-item itemType="button" [cssClass]="'submit-button'"
                        [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Ubt-Form-'+submitButtonText) }">
                    </dxi-item>
                    <dxi-item itemType="button" [cssClass]="'cancel-button'" [buttonOptions]="cancelButtonOptions">
                    </dxi-item>
                </dxi-item>

            </dx-form>
        </form>
    </div>
</dx-popup>

<dx-popup #confirmTaskPopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center" [height]="'auto'"
    [title]="dataService.res('Ubt-MeasureList-Confirm-Delete')" [showCloseButton]="true">
    <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"> {{
            dataService.res('Ubt-MeasureList-Delete-Message') }}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
            <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
                (onClick)="deleteTask($event)">
            </dx-button>
            <dx-button class="cancel-button" [text]="dataService.res('Ubt-Company-Button-Cancel')"
                (onClick)="abortTaskDelete()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>
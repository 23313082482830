import { IPersistable } from "../geoDs/persistence.service";

export enum QueryOutputMode {
  Json,
  GeoJson,
  Xml,
}

export class PathDefinition {
  Name: string;
  Path: string;
}

export class ModelBase {
  OutputMode: QueryOutputMode;
  Indent: boolean;
  OutputPathFilters?: PathDefinition[];
  constructor() {
    this.OutputMode = QueryOutputMode.Json;
  }
}
export class QueryModelBase extends ModelBase {
  ObjectSpaceName: string;
  constructor() {
    super();
  }
}

export enum QueryColumnSortOrder {
  None,
  Ascending,
  Descending,
}

export class QueryColumn {
  OPath: string;
  Name: string;
  SortOrder?: QueryColumnSortOrder | string;
  IsFilter?: boolean;
  IsHidden?: boolean;
  IsInvariant?: boolean;
  Format?: string;
  key?: number;
}

export class QueryBase {
  OPath: string;
  Name: string;
  LimitCount: number;
  LimitOffset: number;
  IsDistinct: boolean;
  DefaultIfEmpty: boolean;
  Columns: QueryColumn[];
  ObjectQueries: QuerySpan[];
}


export class Query extends QueryBase {
  ObjectType: string;

  constructor() {
    super();
  }
}

export class QuerySpan extends QueryBase {
  constructor() {
    super();
  }
}

export class QueryParameter {
  Name: string;
  Value?: object;

  constructor(name: string, value?: any) {
    this.Name = name;
    this.Value = value;
  }
}

export class QueryObjectsModel extends QueryModelBase {
  ObjectQueries: Query[];
  Parameters: QueryParameter[];

  constructor() {
    super();
  }
}

export class ObjectKey {
  Id: string;
  ObjectType: string;
}


export class ReadListDataSourceModel extends ModelBase {
  ObjectKey: ObjectKey;
  ColumnName: string;
  ObjectSpaceName: string;
  Parameters: QueryParameter[];
}

export class QueryValueModel extends QueryModelBase {
  OPath: string;
  Parameters: QueryParameter[];

  constructor() {
    super();
  }
}

export class ObjectReadInfoModel {
  ObjectTypeName: string;
  OutputMode?: QueryOutputMode;

  constructor() {
    this.OutputMode = QueryOutputMode.Json;
  }
}

export class PersistObjectModel extends QueryModelBase {
  Object: TargetObjectData;
}

export class PersistObjectsModel extends QueryModelBase {
  Objects: TargetObjectData[][];
}

export class TargetObjectData {
  ObjectKey: ObjectKey;
  Mode: PersistMode;
  TargetColumns: TargetColumnValue[];
}

export class TargetColumnValue {
  Name: string;
  Value: string | number | Date | boolean;
}

export class PersistObjectsPackage {
  persistObjectsModel: PersistObjectsModel;
  treatedObjects: IPersistable[];
}

export enum PersistMode {
  Insert,
  Update,
  Merge,
  Delete,
}
export enum SreachModeList {
  Contains = 'contains',
  Startswith = 'startswith',
  Equals = 'equals',
}
export enum SelectionModeList {
  All = 'all',
  Multiple = 'multiple',
  None = 'none',
  Single = 'single',
}
import { Injectable } from '@angular/core';
import { GeoDsCoreDataService } from '@wissenswerft/core/data';

@Injectable({
  providedIn: 'root'
})
export class SubProjectService {
  public subProjectReports;

  constructor(private coreDataService: GeoDsCoreDataService) { }


  public prepareEvents(): void {
  }
}
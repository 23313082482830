<div class="loginContent">
  <div *ngIf="appLogo && !showLogoOnTitle" class="appLogoStyle" [innerHTML]="sanitizer.bypassSecurityTrustHtml(appLogo)">
  </div>
  <div class="signInCardStyle">
    <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
      <div class=" content">
        <form class="login-form">
          <!-- <div class="company-name" style="color: #9595a3;">
        <span>
          ROTTHEGE WASSERMANN
        </span>
      </div> -->
      <div *ngIf="showLogoOnTitle" class="app-name" [innerHTML]="sanitizer.bypassSecurityTrustHtml(appLogo)">
      </div>
          <div class="app-message" style="color: #e6e6e6;">
            <span>
              {{appTitle}}
            </span>
          </div>
          <dx-form class="loginFields" [formData]="formData" [disabled]="loading">
            <dxi-item dataField="email" class="floatingLabel">
              <dx-text-box class="userIcon" height="50px" [(value)]="username" [placeholder]="res('Login-User-Name')" [stylingMode]="stylingMode" 
                mode="email" (onEnterKey)="onEditorEnterKey($event)">
                <dxi-button name="today" location="before" hoverStateEnabled="false" >
                </dxi-button>
              </dx-text-box>
              <dxi-validation-rule type="required" message="Bitte Benutzernamen eingeben"></dxi-validation-rule>
              <dxo-label [visible]="false"></dxo-label>
            </dxi-item>

            <dxi-item dataField="password" class="floatingLabel">
              <dx-text-box class="passwordIcon floatingLabel" hoverStateEnabled='false' [(value)]="password" height="50px" [placeholder]="res('Login-Password')"
                [stylingMode]="stylingMode" mode="password" (onEnterKey)="onEditorEnterKey($event)">
                <dxi-button name="today" location="before" hoverStateEnabled="false">
                </dxi-button>
              </dx-text-box>
              <dxi-validation-rule type="required" message="Bitte Passwort eingeben"></dxi-validation-rule>
              <dxo-label [visible]="false"></dxo-label>
            </dxi-item>

            <!-- <dxi-item dataField="rememberMe" editorType="dxCheckBox"
          [editorOptions]="{ text: 'Remember me', elementAttr: { class: 'form-text' } }">
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item> -->

            <dxi-item>
              <dx-button class="signInButton buttonStyle" width="'auto'" [text]="res('Login-Log-In')"
                (onClick)="onSubmit($event)"></dx-button>
              <div class="link" *ngIf="enableForgotPassword">
                <a routerLink="/reset-password" style="color: #e6e6e6; float: right; margin-top: 7px;">{{res('Login-Forgot-Password')}}</a>
              </div>
            </dxi-item>
            <!-- <dxi-item itemType="button">
          <dxo-button-options text="Create an account" width="100%"></dxo-button-options>
        </dxi-item> -->

            <ng-container *dxTemplate="let item of 'signInTemplate'">
              <span class="dx-button-text">
                <ng-container *ngIf="loading; else notLoading">
                  <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
                </ng-container>
                <ng-template #notLoading>{{res('Login-Password-Required')}}</ng-template>
              </span>
            </ng-container>

          </dx-form>

          <div *ngIf="hasInfoLinks" class="login-info-links">
            <a routerLink="/impressum">{{res('Login-Imprint')}}</a>
            <a routerLink="/impressum">{{res('Login-Privacy-Policy')}}</a>
          </div>
        </form>
      </div>
    </dx-scroll-view>
  </div>
</div>
<dx-toast #toast [type]="'error'" [message]="res('Login-Notification-Invalid')" [displayTime]="3000"
  [position]="toastPosition" [width]="'auto'">
</dx-toast>
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from '@geods/base';
import {
  GeoDsCoreDataService, Query,
  QueryColumn, QueryColumnSortOrder, QueryObjectsModel
} from '@wissenswerft/core/data';
import { ResourceManager } from '@wissenswerft/core/resources';
import { Project } from '@xmt-models';
import { DxFormComponent, DxScrollViewComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { loadMessages } from 'devextreme/localization';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CompaniesService, IProjectDependency, ObjectKeys } from '../companies.service';
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
@Component({
  selector: 'wissenswerft-companies-card-list',
  templateUrl: './companies-card-list.component.html',
  styleUrls: ['./companies-card-list.component.scss']
})
export class CompaniesCardListComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild('filterField') filterField: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;
  @Input() displayGrid: boolean = true;
  @Input() navigateUrl: string;
  @Input() companyIdDependency: IProjectDependency;
  @Input() companyNameDependency: IProjectDependency;
  @Input() configDependencies: IProjectDependency[];
  @Input() filterOptions: [] = [];
  @Output() filterData = new EventEmitter<QueryColumn[]>();
  @Output() clickProject = new EventEmitter<Project[]>();
  @Output() clickCompany = new EventEmitter<Address>();
  @Output() selectLastProjectUpdated = new EventEmitter<Project>();
  public searchTerm = new Subject<string>();
  public blurPage = true;
  public companies: Address[];
  public company: Address;
  public companySelected: Address = new Address(null);
  public originalCompanyData: Address;
  public cachedStatus = [];
  public cachedCompaniesTitles: string[] = [];
  public clonedCompanies: Address[];
  public columns: QueryColumn[] = [];
  public emptyCompany = false;
  public filterActive = false;
  public items = [];
  public isFavorite = false;
  public res = ResourceManager.getResources("companies");
  public projectList: Project[] = [];
  private projectColumns = [
    this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('ref:ParentId', 'ParentAddress', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('SysDateInsert', 'Date', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('State', 'State', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Count(subProjects)', 'subProjectsCount', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Count(subProjects.Measures)', 'measuresCount', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('Count(subProjects.Measures.MeasureTasks)', 'tasksCount', QueryColumnSortOrder.None),
    this.coreDataService.createQueryColumn('SysDateUpdate', 'SysDateUpdate', QueryColumnSortOrder.Descending),
    this.coreDataService.createQueryColumn("FormatTime(SysTimeUpdate, 'hh:mm:ss')", 'SysTimeUpdate', QueryColumnSortOrder.Descending),
  ];
  private comapnyNumber = 100;
  private subscriptions: Subscription[] = [];

  constructor(
    private coreDataService: GeoDsCoreDataService,
    private companiesService: CompaniesService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  public ngOnInit(): void {
    sessionStorage.setItem('fixedCulture', 'de');
    sessionStorage.getItem('fixedCulture') === 'de' ? loadMessages(deMessages) : loadMessages(enMessages);
    this.filterCompanyWithProject();
    this.company = new Address(null);
    loadMessages({
      en: {
        "Company-Column-Name": "Name",
        "Company-Column-Match-Code": "Match Code",
        "Company-Column-Status": "Status",
        "Company-Column-Organization": "Organization",
        "Company-Column-Organizations": "Organizations",
        "Company-Column-Address": "Address",
        "Company-Column-Customer-Number": "Customer Number",
        "Company-Column-Contact": "Contact",
        "Company-Column-House-Number": "House Number",
        "Company-Column-Street": "Street",
        "Company-Column-Phone": "Phone",
        "Company-Column-Fax": "Fax",
        "Company-Column-City": "City",
        "Company-Column-Email": "Email",
        "Company-Column-Land": "Land",
        "Company-Column-Zip": "PLZ",

        "Company-Button-Create": "Create",
        "Company-Button-Cancel": "Cancel",

        "Company-Icon-Edit": "Edit",
        "Company-Icon-Delete": "Delete",
        "Company-Icon-Add-Favorite": "Add Favorite",
        "Company-Icon-Remove-Favorite": "Remove Favorite",
        "Company-Icon-Cards": "Cards",
        "Company-Icon-Grid": "Grid",
        "Company-Icon-Navigate-To": "Navigate To",
        "Company-Icon-Search": "Search...",

        "Company-Title-Companies": "Companies",
        "Company-Title-Company": "Company",
        "Company-Title-Create-Company": "Create company",
        "Company-Title-Edit-Company": "Edit company",
        "Company-Title-Confirm-Delete": "Confirm Delete",

        "Company-Notification-Succees-Update": "Company updated successfully",
        "Company-Notification-Succees-Insert": "Company inserted successfully",

        "Company-Message-Delete": "Are you sure you want to delete this company ?",
        "Company-Message-No-Data": "No Data",
        "Company-My-Most-Recently-Worked-Projects": "Most recently edited projects",
        "Company-Sub-Project": "SubProject",
        "Company-Measures": "Measures",
        "Company-Milestones": "Milestones",
        "Company-Select-Project": "Select Project"
      },
      de: {
        "Company-Column-Name": "Name",
        "Company-Column-Match-Code": "Kundenkürzel",
        "Company-Column-Status": "Status",
        "Company-Column-Organization": "Organisation",
        "Company-Column-Organizations": "Organisationen",
        "Company-Column-Address": "Die Anschrift",
        "Company-Column-Customer-Number": "Kundennummer",
        "Company-Column-Contact": "Kontakt",
        "Company-Column-House-Number": "Hausnummer",
        "Company-Column-Street": "Straße",
        "Company-Column-Phone": "Telefon",
        "Company-Column-Fax": "Fax",
        "Company-Column-City": "Stadt",
        "Company-Column-Email": "Email",
        "Company-Column-Land": "Land",
        "Company-Column-Zip": "Postleitzahl",

        "Company-Button-Create": "Erstellen",
        "Company-Button-Cancel": "Abbrechen",
        "Company-Icon-Edit": "Bearbeiten",
        "Company-Icon-Delete": "Löschen",
        "Company-Icon-Add-Favorite": "Favorit hinzufügen",
        "Company-Icon-Remove-Favorite": "Favorit entfernen",
        "Company-Icon-Cards": "Karten",
        "Company-Icon-Grid": "Raster",
        "Company-Icon-Navigate-To": "Navigieren zu",
        "Company-Icon-Search": "Suche...",
        "Company-Title-Companies": "Unternehmen",
        "Company-Title-Company": "Unternehmen",
        "Company-Title-Create-Company": "Unternehmen erstellen",
        "Company-Title-Edit-Company": "Unternehmen bearbeiten",
        "Company-Title-Confirm-Delete": "Löschen bestätigen",

        "Company-Notification-Succees-Update": "Unternehmen erfolgreich aktualisiert",
        "Company-Notification-Succees-Insert": "Unternehmen erfolgreich eingefügt",

        "Company-Message-Delete": "Sind Sie sicher, dass Sie dieses Unternehmen löschen möchten?",
        "Company-Message-No-Data": "Keine Daten",
        "Company-Select-Project": "Projekt auswählen"

      }
    });

    this.prepareCompanyQueryColumns();

    this.blurPage = false;
    this.search(this.searchTerm).subscribe((results) => {
      this.companies = [];
      this.emptyCompany = false;
      if (results !== null) {
        this.filterActive = true;
        if (results.length === 0) {
          this.emptyCompany = true;
        } else {
          this.companies = results;
        }
      } else {
        this.companies = this.clonedCompanies.slice(0, this.comapnyNumber + 100);
      }
      this.cdr.markForCheck();
    });

    this.subscriptions.push(this.companiesService.updateGridData$.subscribe((company: Address) => {
      this.companies.push(company);
    }));

    this.subscriptions.push(this.companiesService.selectedCompanyData$.subscribe((selectedCompany) => {
      if (selectedCompany === true) {
        this.getProjectsData();
        this.getCompanySelected();
      }
    }));

  }

  public resetSearch(event): void {
    if (event.value === "") {
      this.searchTerm.next("");
    }
  }

  public filterCompanyWithProject() {
    this.subscriptions.push(this.companiesService.filterCompanyData$.subscribe((filteredCompanies: Address[] | number) => {
      if (typeof (filteredCompanies) === 'number') {
        this.filterField.value = this.filterOptions[filteredCompanies]['id'];
        this.filterData.emit(this.columns);
      } else {
        this.companies = filteredCompanies.slice(0, this.comapnyNumber);
        this.clonedCompanies = [...filteredCompanies];
        this.items = this.clonedCompanies.map((filteredCompanies) => {
          return { ...filteredCompanies, searchProperty: filteredCompanies.Name.toUpperCase() };
        });
        this.cdr.markForCheck();
      }
    }));
  }

  public prepareCompanyData() {
    this.subscriptions.push(this.readCompanyWithImages().subscribe(companiesData => {
      this.companies = companiesData.slice(0, this.comapnyNumber);
      this.clonedCompanies = [...companiesData];
      this.items = this.clonedCompanies.map((company) => {
        return { ...company, searchProperty: company.Name.toUpperCase() };
      });
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
    }));
  }

  public navigateToDashboard(project): void {
    this.clickProject.emit(project)
    this.router.navigate(['dashboard']);
  }

  public clickCompanyAction(company): void {
    this.clickCompany.emit(company);
  }

  public selectLastProjectUpdatedAction(project): void {
    this.selectLastProjectUpdated.emit(project);
  }

  private getCompanySelected() {
    const companyId = sessionStorage.getItem("currentCompany");
    const opath = 'Id=' + "'" + companyId + "'";
    this.companiesService.readObjects<Address[]>(ObjectKeys.ADDRESS, this.columns, opath).subscribe(company => {
      if (Array.isArray(company) && company.length > 0) {
        this.companySelected = company[0];
      } else {
        this.companySelected = new Address(null);
      }
    }, error => {
      console.error(error);
      this.cdr.markForCheck();
    }, () => {
      this.cdr.markForCheck();
    })
  }

  public redirectToCompanies() {
    if (document.getElementById("companyWithProject").style.display === "block") {
      document.getElementById("companyWithProject").style.display = "none";
      document.getElementById("companies").style.display = "block";
      document.getElementById("title-company").style.display = "block";
      document.getElementById("title-project").style.display = "none";
      document.getElementById("title-icon").style.display = "none";
      document.getElementById("searchBloc").style.display = "block";
    }
  }

  private getProjectsData() {
    const companyId = sessionStorage.getItem("currentCompany");
    const opath = 'ParentId=' + "'" + companyId + "'";
    this.companiesService.readObjects<Project[]>(ObjectKeys.PROJECT, this.projectColumns, opath).subscribe(projects => {
      if (Array.isArray(projects) && projects.length > 0) {
        this.projectList = projects;
      } else {
        this.projectList = [];
      }
    }, error => {
      console.error(error);
      this.cdr.markForCheck();
    }, () => {
      this.cdr.markForCheck();
    })
  }

  public search(terms: Observable<string>) {
    return terms.pipe(debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  public searchEntries(term: string) {
    if (!term) return of(null);
    term = term.toUpperCase();
    return of(this.items.filter((a) => a.searchProperty.indexOf(term) >= 0));
  }

  public selectFilterOption(event) {
    const filterAction = event.itemData.id;
    if (filterAction === 0) {
      this.prepareCompanyData();
    } else if (filterAction === 1) {
      this.filterCompanyWithProject();
      this.filterData.emit(this.columns);
    }
  }

  public prepareCompanyQueryColumns() {
    this.columns.push(
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Name', 'Name'),
      this.coreDataService.createQueryColumn('MatchCode', 'MatchCode'),
      this.coreDataService.createQueryColumn('Status', 'Status'),
      this.coreDataService.createQueryColumn('CompanyTitle', 'CompanyTitle'),
      this.coreDataService.createQueryColumn('Phone', 'Phone'),
      this.coreDataService.createQueryColumn('Fax', 'Fax'),
      this.coreDataService.createQueryColumn('EMail', 'EMail'),
      this.coreDataService.createQueryColumn('Street', 'Street'),
      this.coreDataService.createQueryColumn('CustomerNumber', 'CustomerNumber'),
      this.coreDataService.createQueryColumn('HouseNumber', 'HouseNumber'),
      this.coreDataService.createQueryColumn('ZIP', 'ZIP'),
      this.coreDataService.createQueryColumn('Country', 'Country'),
      this.coreDataService.createQueryColumn('City', 'City'),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      ),
      this.coreDataService.createQueryColumn('SysDateUpdate', 'SysDateUpdate', QueryColumnSortOrder.Descending),
      this.coreDataService.createQueryColumn("FormatTime(SysTimeUpdate, 'hh:mm:ss')", 'SysTimeUpdate', QueryColumnSortOrder.Descending),
    );
  }

  private readCompanyWithImages(): Observable<any> {
    const adressQuery: Query = new Query();
    const adressColumns: Array<QueryColumn> = this.columns;
    adressQuery.Columns = adressColumns;
    adressQuery.ObjectType = ObjectKeys.ADDRESS;

    const imageQuery: Query = new Query();
    imageQuery.Name = 'Image';
    imageQuery.OPath = "Documents";
    const imageColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Object', 'Object', QueryColumnSortOrder.None),
      {
        Name: 'Object',
        OPath: "Object",
        Format: 'Base64Image[80x80]'
      }
    ];
    imageQuery.Columns = imageColumns;
    imageQuery.OPath = "Documents[Kind='IMAGE']"
    adressQuery.ObjectQueries = [imageQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [adressQuery];
    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesCardListComponent } from './companies-card-list/companies-card-list.component';
import { CompanyCardComponent } from './company-card/company-card.component';
import { MatCardModule } from '@angular/material/card';
import { DxAccordionModule, DxButtonModule, DxFormModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CompaniesService } from './companies.service';
import { MatIconModule } from '@angular/material/icon';
import { ProjectCardComponent } from './project-card/project-card.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    DxScrollViewModule,
    DxAccordionModule,
    DxPopupModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxFormModule,
    WwLibraryModule
  ],
  declarations: [
    CompaniesCardListComponent, 
    ProjectCardComponent,
    CompanyCardComponent
  ], 
  exports : [
    CompaniesCardListComponent,
    ProjectCardComponent,
    CompanyCardComponent
  ],
  providers: [
    CompaniesService
  ],
})
export class CompaniesModule {}

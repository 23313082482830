export enum ValidationStatus  {
    VALID = 'valid',
    PENDING ='pending',
    INVALID ='invalid'
}

export enum StylingMode {
    Outlined = 'outlined',
    Filled = 'filled',
    Underlined = 'underlined'
}

export enum LabelMode {
    Floating = 'floating',
    Static = 'static',
    Hidden = 'hidden'
}

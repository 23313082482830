import { GeoDsMeta } from "@geods/base";

export class MeasureProgress extends GeoDsMeta {
    ParentId: string;
    Progress: string;
    NextSteps: string;
    Decision: string;
    Opportunities: string;
    RiskAndChance: string;
    ProgressDate: Date;
    MaturityLevel: string;
    ProgressPercentage: number;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

import { GeoDsMeta } from "./meta-data.model";

export class PhoneNumber extends GeoDsMeta{
    CountryCode: string;
    Designation: string;
    FrequentlyCall: boolean;
    LocalAreaCode: string;
    Mediation: string;
    Number: string;
    Show: boolean;
    Type: number;

    constructor(data: PhoneNumber) {
        super();
        Object.assign(this, data);
    }
}

<div *ngIf="showAllDocuments===true" class="content-style-class document-container">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="title-style ">{{dataService.res('Ubt-TreeList-Documents')}}</h2>
        </div>
        <div fxLayout="column" class="add-button-style">
            <dx-speed-dial-action icon="add" (onClick)="addDocument()">
            </dx-speed-dial-action>
        </div>
    </div>

    <div class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" style="margin-bottom: 20px;">
                <dx-button [visible]="isReset" icon="refresh" (onClick)="resetData()">
                </dx-button>
                <dx-select-box #filterProjectList [displayExpr]="'Designation'" [valueExpr]="'Id'" fxFlex="200px"
                    [label]="dataService.res('Ubt-Document-Filter-Projects')" [labelMode]="'floating'" [width]="'100%'"
                    [searchEnabled]="true" [dataSource]="projectList" (onOpened)="prepareProjectList()"
                    (onSelectionChanged)="filterDocumentByProject($event)">
                </dx-select-box>
            </div>
            <ww-grid #documentsGrid class="grid-group-by-Style custome-export-style staffMember-grid"
                [noDataText]="noDataText" [groupable]="false" [searchPanel]="false" [gridData]="documents"
                [enableStateStorage]="false" [maxHeight]="'100%'" [columnsHeader]="documentsColumnsHeader"
                [height]="'85%'" [enableColumnChooser]="false" [enableExport]="false" [showfilterrow]="true"
                [allowAdding]="false" [selectionMode]="'none'" [columnFilter]="false" [showRowLines]="false"
                (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)" [allowDeleting]="false"
                [allowUpdating]="false" [linkProperty]="'text'" [filterPanel]="false" [enableHeaderFilter]="false"
                [rowAlternationEnabled]="false">
            </ww-grid>
        </div>
    </div>
</div>

<dx-accordion *ngIf="showAllDocuments === false" class="accordion-style" [collapsible]="true" [selectedIndex]="0"
    [width]="'100%'" [animationDuration]="300" [visible]="true" [multiple]="false" [dataSource]="['Test']">
    <div *dxTemplate="let company of 'title'" class="dx-accordion-item-title">
        {{ dataService.res("Ubt-TreeList-Documents") }}
    </div>
    <div *dxTemplate="let company of 'item'">
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex="95"></div>
                <div fxLayout="column" fxLayoutGap="10px">
                    <dx-button class="buttons-container" fxLayoutAlign="end"
                        text="{{ dataService.res('Ubt-MeasureDetail-Upload-Document') }}" icon="add"
                        (onClick)="addDocument()">
                    </dx-button>
                </div>
            </div>
            <div fxLayout="row">
                <ww-grid #documentsGrid class="grid-group-by-Style save-icon-style" [noDataText]="noDataText"
                    [groupable]="false" [searchPanel]="false" [gridData]="documents" [enableStateStorage]="false"
                    [height]="'100%'" [columnsHeader]="documentsColumnsHeader" [enableColumnChooser]="false"
                    [enableExport]="false" [showfilterrow]="false" [allowAdding]="false" [selectionMode]="'single'"
                    [columnFilter]="false" [showRowLines]="false" [editingMode]="'row'" [showColumnLines]="false"
                    [allowDeleting]="true" [allowUpdating]="true" [linkProperty]="'text'" [filterPanel]="false"
                    [enableHeaderFilter]="false" [rowAlternationEnabled]="false">
                </ww-grid>
            </div>
        </div>
    </div>
</dx-accordion>

<dx-popup #addDocumentPopup [showTitle]="true" [height]="'auto'" [width]="'25%'" [position]="'center'"
    title="{{ dataService.res('Ubt-MeasureDetail-Upload-Document') }}" [deferRendering]="'false'">
    <dx-file-uploader #fileUploader class="dx-card popup-card-paddings card-radius" accept="*" uploadMode="useButtons"
        selectButtonText="{{ dataService.res('Ubt-MeasureDetail-Select-Document') }}" [uploadFile]="uploadFile"
        (onUploaded)="closeDocumentPopUp()" (onValueChanged)="onFileUpload($event,uploadDocument)">
    </dx-file-uploader>
</dx-popup>

<dx-popup #deleteDocumentConfirmationPopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center"
    [height]="'auto'" [title]="dataService.res('Ubt-MeasureList-Confirm-Delete')" [showCloseButton]="true">
    <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"> {{
            dataService.res('Ubt-MeasureList-Delete-Message') }}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
            <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
                (onClick)="removeRow()">
            </dx-button>
            <dx-button class="cancel-button" [text]="dataService.res('Ubt-Company-Button-Cancel')"
                (onClick)="abortDelete()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>

<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
    [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="title-style ">{{dataService.res('Ubt-TreeList-StaffMembers')}}</h2>
        </div>
        <div fxLayout="column" class="add-button-style">
            <dx-speed-dial-action icon="add" (onClick)="openStaffMemberDialog()">
            </dx-speed-dial-action>
        </div>
    </div>
    <div class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <dx-data-grid #staffMembersGrid class="grid-group-by-Style custome-export-style staffMember-grid"
                [id]="'staffMembers'" [dataSource]="data$ | async" [height]="'100%'" [showBorders]="true"
                [showRowLines]="true" [showColumnLines]="true" [columns]='columnsHeader'
                (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)">
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            </dx-data-grid>
        </div>

        <dx-popup #staffMemberPopup class="window-title-background" [showTitle]="true" [showCloseButton]="true"
            [width]="'50%'" [height]="'auto'">
            <div class="dx-card popup-card-paddings card-radius">
                <div fxLayout="column" fxFlexFill fxLayoutGap="10px">
                    <form action="post" (submit)="persistStaffMember($event,staffMember)">
                        <dx-form #form id="form" [(formData)]="staffMember" [colCount]="1">
                            <dxi-item [isRequired]="true">
                                <dx-text-box [(value)]="staffMember.FirstName" [stylingMode]="'outlined'"
                                    [labelMode]="'floating'"
                                    [label]="dataService.res('Ubt-CreateStaffMembers-FirstName')" [width]="'100%'">
                                </dx-text-box>
                                <dxi-validation-rule type="required"
                                    message="{{dataService.res('Ubt-validator-RequiredField')}}"></dxi-validation-rule>
                            </dxi-item>
                            <dxi-item [isRequired]="true">
                                <dx-text-box [(value)]="staffMember.LastName" [stylingMode]="'outlined'"
                                    [width]="'100%'" [label]="dataService.res('Ubt-CreateStaffMembers-LastName')"
                                    [labelMode]="'floating'">
                                </dx-text-box>
                                <dxi-validation-rule type="required"
                                    message="{{dataService.res('Ubt-validator-RequiredField')}}"></dxi-validation-rule>
                            </dxi-item>
                            <dxi-item>
                                <dx-text-box [(value)]="staffMember.EMail" [stylingMode]="'outlined'" [width]="'100%'"
                                    [label]="dataService.res('Ubt-CreateStaffMembers-EMail')" [labelMode]="'floating'">
                                </dx-text-box>
                            </dxi-item>
                            <dxi-item [isRequired]="true">
                                <dx-select-box class="drop-down-style" valueExpr='Value' [displayExpr]="'Value'"
                                    [label]="dataService.res('Ubt-TreeList-Roles')" [labelMode]="'floating'"
                                    [stylingMode]="'outlined'" [(value)]="staffMember.Function"
                                    [dataSource]="availableRoles">
                                </dx-select-box>
                                <dxi-validation-rule type="required"
                                    message="{{dataService.res('Ubt-validator-RequiredField')}}"></dxi-validation-rule>
                            </dxi-item>
                            <dxi-item [isRequired]="false">
                                <dx-text-box [(value)]="staffMember.Login" [stylingMode]="'outlined'"
                                    [labelMode]="'floating'" [label]="dataService.res('Ubt-StaffMembers-Login')"
                                    [width]="'100%'">
                                </dx-text-box>
                            </dxi-item>
                            <dxi-item [isRequired]="false">
                                <dx-select-box class="drop-down-style" valueExpr='Value' [displayExpr]="'Value'"
                                    [label]="dataService.res('Ubt-StaffMembers-Project-Role')" [labelMode]="'floating'"
                                    [stylingMode]="'outlined'" [(value)]="staffMemberPosition.Position"
                                    [dataSource]="availablePosition">
                                </dx-select-box>
                            </dxi-item>
                            <dxi-item>
                                <dx-file-uploader #fileUpload
                                    selectButtonText="{{dataService.res('Ubt-StaffMember-Upload-Picture')}}"
                                    class="buttons-container" labelText="" accept="image/*" uploadMode="useForm"
                                    [allowCanceling]="true" (onValueChanged)="onFileUpload($event, uploadDocument)">
                                </dx-file-uploader>
                            </dxi-item>

                            <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                                <dxi-item itemType="button" [cssClass]="'submit-button'"
                                    [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Ubt-Form-'+submitText) }">
                                </dxi-item>
                                <dxi-item itemType="button" [cssClass]="'cancel-button'"
                                    [buttonOptions]="cancelButtonOptions">
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </form>
                </div>
            </div>
        </dx-popup>

        <dx-popup #confirmDeletePopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center" [height]="'auto'"
            [title]="dataService.res('Ubt-MeasureList-Confirm-Delete')" [showCloseButton]="true">
            <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutAlign="center center"
                fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"> {{
                    dataService.res('Ubt-MeasureList-Delete-Message') }}</div>
                <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
                    <dx-button class="submit-button" [text]="dataService.res('Ubt-MeasureList-Yes')"
                        (onClick)="removeStaffMember()">
                    </dx-button>
                    <dx-button class="cancel-button" [text]="dataService.res('Ubt-MeasureList-No')"
                        (onClick)="abortDelete()">
                    </dx-button>
                </dxi-item>
            </div>
        </dx-popup>
    </div>
</div>
<ubt-loader *ngIf="showLoader"></ubt-loader>

<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
    [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { catchError, concatMap, first, map, take, tap } from 'rxjs/operators';
import { BACKEND_SYSTEM, ConfigService } from '../../../../configuration/src/lib/config.service';
import { ILoginSettings, LoginInfo } from '../models/login-info.model';
import { ProfileInfo, GeoDsLoginInfo } from '../models/profile-info.model';
import { ConvertHelper } from '@wissenswerft/core/data';
import { GeoDsWebApiServiceURLs, SpaceOneWebApiServiceURLs } from 'libs/core/configuration/src/lib/models/config.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private tokenService: TokenService, public configService: ConfigService) {

  }
  private loginInfo: LoginInfo | ILoginSettings;
  public profileInfo: ProfileInfo | GeoDsLoginInfo;
  private _currentUser = new Subject<any>();
  public currentUser$ = this._currentUser.asObservable();
  public currentProfileAvatar: string;

  public currentUserLogged(val: string) {
    this._currentUser.next(val);
  }

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('client_id', this.configService.configuration.OAuth2?.ClientId)
      .set('username', loginData.email)
      .set('password', loginData.password)
      .set('grant_type', 'password');
    return this.http.post<any>(this.configService.configuration.WebApi.ServiceURLs.Account_Generate_Token, body)
      .pipe(
        tap((res) => {
          if (this.configService.backEndSystem === BACKEND_SYSTEM.SPACEONE) {
            this.loginInfo = new LoginInfo(res.access_token, res.expires_in, res.refresh_token, res.token_type);
          } else {
            this.loginInfo = <ILoginSettings>res;
          }
          this.tokenService.saveToken(this.loginInfo);
        }),
        catchError(UserService.handleError)
      );
  }

  getProfileInformations(): Observable<any> {
    if (this.configService.backEndSystem === BACKEND_SYSTEM.SPACEONE) {
      if (!this.profileInfo) {
        return this.http.get<ProfileInfo>(this.configService.configuration.WebApi.ServiceURLs.Account_Profile).pipe(tap((profile) => {
          this.profileInfo = new ProfileInfo(profile);
        }));
      } else {
        return of(this.profileInfo);
      }
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.tokenService.getToken()
      });
      return this.http.post(this.configService.configuration.WebApi.ServiceURLs.Account_Profile, { OutputMode: 'Json' }, { headers: headers });
    }
  }

  public getAvatar(): Observable<ArrayBuffer> {
    const options: any = {
      observe: 'body',
      responseType: 'arraybuffer'
    };
    const serviceUrls = <SpaceOneWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
    return this.http.get(serviceUrls.Account_Avatar, options);
  }

  public getAvatarById(id): Observable<ArrayBuffer> {
    const options: any = {
      observe: 'body',
      responseType: 'arraybuffer'
    };
    const serviceUrls = <SpaceOneWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
    return this.http.get(ConvertHelper.resolveStringPlaceholders(serviceUrls.AccountAvatarById, id), options);
  }

  public isAuthenticated(): boolean {
    if (this.tokenService.getToken() && !this.isExpired()) {
      return true;
    }
    return false;
  }

  public isExpired(): Boolean {
    let isExpired: Boolean;
    isExpired = new Date() > new Date(this.tokenService.getExpiryDate()) ? true : false;
    return isExpired;
  }

  refreshToken(): Observable<any> {
    const body = new HttpParams()
      .set('refresh_token', localStorage.getItem("refresh_token"))
      .set('grant_type', 'refresh_token');
    const serviceUrls: SpaceOneWebApiServiceURLs = <SpaceOneWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
    return this.http.post(serviceUrls.Account_Refresh_Token, body).pipe();
  }

  clearLoginInfo() {
    this.tokenService.clearLoginInfo();
  }

}
import { GeoDsMeta } from "@geods/base";
import { SubProject } from "./sub-project.model";

export class Project extends GeoDsMeta {
    Ident: string;
    Label: string;
    Description: string;
    SubProjects: SubProject[];
    StartDate: Date;
    EndDate: Date;
    State: string;
    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoDsCoreDataService, Query, QueryColumn, QueryObjectsModel } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { MeasureTask } from '@xmt-models';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MeasureTaskService } from './measure-task.service';

@Component({
  selector: 'ubt-geods-measure-task',
  templateUrl: './measure-task.component.html',
  styleUrls: ['./measure-task.component.scss']

})

export class MeasureTaskComponent implements OnInit, OnDestroy {
  private readonly VISIBLE_TASKS_COLUMNS = ['Subject', 'Description', 'IdRefResponsible', 'StartDate', 'EndDate', 'TotalTimeRequired', 'Collaborators'];
  private readonly HIDDEN_TASKS_COLUMNS = ['Type', 'IdRefxmtMeasureTask', 'FinishedOn', 'TargetMaturityLevel', 'DegreeOfImplementation'];
  private subscriptions: Subscription[] = [];
  public measureTasks: MeasureTask[] = [];
  public columnsHeader: Column[] = [];
  public showLoader = false;
  public noDataText = "Keine Daten";

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    public appService: AppService,
    public measureTaskService: MeasureTaskService) { }

  public ngOnInit(): void {
    this.showLoader = true;
    this.subscriptions.push(this.dataService.prepareStaffMembers().pipe(switchMap(staffMembers => {
      if (Array.isArray(staffMembers) && staffMembers.length > 0) {
        this.dataService.cachedResponsiblesResponse = staffMembers;
      }
      return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.TASK }).pipe(switchMap(layoutStructure => {
        layoutStructure.Columns.forEach(column => {
          this.columnsHeader.push({
            dataField: column.Name,
            caption: this.dataService.res('Ubt-MeasureTask-' + column.DisplayText),
            dataType: this.dataService.getDataType(column.DataType),
            format: this.dataService.getDataType(column.DataType) === 'number' ? '#,##0 h' : null,
            visibleIndex: this.VISIBLE_TASKS_COLUMNS.indexOf(column.Name),
            visible: (this.VISIBLE_TASKS_COLUMNS.includes(column.Name)) ? true : false,
            showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name) && !this.HIDDEN_TASKS_COLUMNS.includes(column.Name)) ? true : false,
            lookup: (this.getLookupColumn(column.Name))
          });
        });

        this.columnsHeader.push({
          dataField: 'Collaborators',
          caption: this.dataService.res('Ubt-CreateMeasureTask-Collaborators'),
          visible: true,
          width: '200px'
        })
        this.columnsHeader.push({
          dataField: 'SubProject',
          caption: this.dataService.res('Ubt-Measure-List-SubProject'),
          visible: true,
          groupIndex: 0,
          autoExpandGroup: false
        })
        return this.coreDataService.executeReadObjectsQuery(this.prepareMeasureTaskColumns()).pipe(map(tasks => (tasks)));
      }))
    })).subscribe((tasks: MeasureTask[]) => {
      if (Array.isArray(tasks) && tasks.length > 0) {
        this.measureTasks = tasks;
      } else {
        this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      this.showLoader = false;
      console.error(error);
    }, () => {
      this.showLoader = false;
    }));
  }

  private getLookupColumn(key: string) {
    if (key === 'IdRefResponsible') {
      return { valueExpr: 'Id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.dataService.cachedResponsiblesResponse.sort((a, b) => a.LastName.localeCompare(b.LastName)) };
    }
    if (key === 'TargetMaturityLevel') {
      return { valueExpr: 'name', displayExpr: 'value', dataSource: this.measureTaskService.maturityLevel };
    }
  }

  public onCellPrepared = (e): void => {
    if (e.rowType === 'header') {
      e.cellElement.style.textAlign = 'left';
    }
    if (e.rowType === 'data') {
      if (e.column.dataField === "Subject") {
        e.cellElement.style.height = 100;
        e.cellElement.style.whiteSpace = "normal";
        e.cellElement.style.overflowWrap = 'break-word';
        e.cellElement.style.fontWeight = 700;
      }
      if (e.column.dataField === 'Collaborators') {
        if (e.data?.Task === null) {

          e.cellElement.style.backgroundColor = '#D3D3D3';
        }
        let index = 0;
        e.cellElement.textContent = '';
        if (e.data?.Collaborators) {
          const div = document.createElement('div')
          const collaborators = e.data?.Collaborators;
          collaborators.forEach((collaborator) => {
            const span = document.createElement('span');
            div.append(span);
            div.style.display = 'flex';
            div.style.flexWrap = 'wrap';
            index = index + 1;
            if (index === collaborators.length) {
              span.append(collaborator.MeasureResources[0].Person);
            } else {
              span.append(collaborator.MeasureResources[0].Person + '  |  ');
            }
            span.style.color = '#00569c';
            span.style.fontWeight = 'bold';
            span.style.marginLeft = '2px';
            e.cellElement.append(div);
          })
        }
      }
      if (e.column.dataField === 'DegreeOfImplementation') {
        this.textTransform(e);
        e.cellElement.append('%');
      }
      if (e.column.dataField === 'Description') {
        if (e.data?.Type === 0) {
          e.cellElement.innerText = this.dataService.res('Ubt-MeasureDetail-Milestone');
        } else {
          e.cellElement.innerText = this.dataService.res('Ubt-MeasureDetail-Task');
        }
      }
    }
  }

  public textTransform(e): void {
    if (e.text === '') {
      e.cellElement.append(0);
    }
  }
  private prepareMeasureTaskColumns(): QueryObjectsModel {
    const measureTask: Query = new Query();
    const measureTaskColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('!.Designation', 'ParentLabel'),
      this.coreDataService.createQueryColumn('Subject', 'Subject'),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate'),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate'),
      this.coreDataService.createQueryColumn('TotalTimeExecuted', 'TotalTimeExecuted'),
      this.coreDataService.createQueryColumn('DegreeOfImplementation', 'DegreeOfImplementation'),
      this.coreDataService.createQueryColumn('IdRefResponsible', 'IdRefResponsible'),
      this.coreDataService.createQueryColumn('TargetMaturityLevel', 'TargetMaturityLevel'),
      this.coreDataService.createQueryColumn('TotalTimeRequired', 'TotalTimeRequired'),
      this.coreDataService.createQueryColumn('Type', 'Type'),
      this.coreDataService.createQueryColumn('!.!.Designation', 'SubProject')
    ];
    measureTask.ObjectType = ObjectKeys.TASK;
    measureTask.Columns = measureTaskColumns;
    measureTask.OPath = '!.!.ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    measureTask.Columns.push(this.coreDataService.createQueryColumn('IdRefxmtMeasureTask', 'Task'));

    const measureResources: Query = new Query();
    measureResources.Name = 'MeasureResources';
    measureResources.OPath = 'MeasureResource';
    const measureResourcesColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefPerson', 'Member'),
      this.coreDataService.createQueryColumn('ref:IdRefPerson', 'Person')
    ];
    measureResources.Columns = measureResourcesColumns;


    const taskCollaborators: Query = new Query();
    taskCollaborators.Name = 'Collaborators';
    taskCollaborators.OPath = 'collaborators';
    const taskCollaboratorsColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('TimeExecuted', 'TimeExecuted'),
      this.coreDataService.createQueryColumn('IdRefMeasureResource', 'IdRefMember')
    ];
    taskCollaborators.Columns = taskCollaboratorsColumns;
    taskCollaborators.ObjectQueries = [measureResources];
    measureTask.ObjectQueries = [taskCollaborators];
    const measureDetailColumns = new QueryObjectsModel();
    measureDetailColumns.ObjectQueries = [measureTask];
    return measureDetailColumns;
  }


  public ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
}

import { Component, Input, OnInit, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { DxPivotGridComponent } from 'devextreme-angular';

@Component({
    selector: 'ww-matrix',
    template: `<dx-pivot-grid #matrixGrid
                [allowSortingBySummary]="allowSortingBySummary"
                [allowSorting]="allowSorting"
                [allowFiltering]="allowFiltering"
                [allowExpandAll]="allowExpandAll"
                [showColumnGrandTotals]="showColumnGrandTotals"
                [showRowGrandTotals]="showRowGrandTotals"
                (onContentReady)="onContentReady($event)"
                (onCellPrepared)="cellPrepared($event)"
                [showBorders]="showBorders"
                [dataSource]="matrixData">
                <dxo-field-chooser [enabled]="fieldChooser"></dxo-field-chooser>
            </dx-pivot-grid>`,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./matrix-grid.component.scss']
})

export class MatrixComponent implements OnInit {
    @ViewChild('matrixGrid', { static: false }) matrixGrid: DxPivotGridComponent;
    @Input() allowSortingBySummary: boolean = false;
    @Input() allowSorting: boolean = false;
    @Input() allowFiltering: boolean = false;
    @Input() allowExpandAll: boolean = false;
    @Input() showColumnGrandTotals: boolean = false;
    @Input() showRowGrandTotals: boolean = false;
    @Input() showBorders: boolean = false;
    @Input() fieldChooser: boolean = false;
    @Input() columnHeaderScale: boolean = false;
    @Output() onCellPrepared = new EventEmitter();

    private _matrixData;
    @Input()
    get matrixData() { return this._matrixData; }
    set matrixData(matrixData) {
        this._matrixData = matrixData;
    }

    constructor() { }

    ngOnInit(): void {
    }

    public onContentReady(event) {
        if (this.columnHeaderScale) {
            const elements = event.component.element().querySelectorAll(".dx-pivotgrid-border , .dx-word-wrap");
            elements[0].children[2].style.display = 'table-footer-group';
        }
    }
    public cellPrepared(event) {
        this.onCellPrepared.emit(event);
    }
}

<div class="favorites-list-box">

  <dx-list *ngIf="dataSource" [dataSource]="dataSource" [grouped]="true" [collapsibleGroups]="true"
    [hoverStateEnabled]="false" [searchEnabled]="true" [searchMode]="sreachModeList" [selectionMode]="selectionModeList"
    (onContentReady)="contentReady()" (onItemClick)="itemClicked($event)" noDataText="{{res('Favorites-No-Data')}}">
    <dxo-search-editor-options placeholder="{{res('Favorites-Search')}}">
    </dxo-search-editor-options>

    <div *dxTemplate="let item of 'item'">
      <div matTooltip="{{item['DisplayText']}}">{{ item['DisplayText'] || ''}}
      </div>
    </div>
  </dx-list>

  <div class="loader-container">
    <dx-load-indicator [visible]="showLoader"></dx-load-indicator>
  </div>

</div>
import { GeoDsMeta } from "@geods/base";
import { Collaborator } from "./collaborator.model";

export class MeasureTask extends GeoDsMeta {
    ParentId: string;
    Label: string;
    Ident: string;
    Notes: string;
    DegreeOfImplementation: number;
    Efficiency: number;
    Responsible: string;
    TotalTimeRequired: number;
    TotalTimeExecuted: number;
    TargetDate: Date;
    StartDate: Date;
    EndDate: Date;
    TargetMaturityLevel: string;
    FinishedOn?: Date;
    Collaborators: Collaborator[];
    Task: string;
    Type: number;
    constructor(data) {
        super();
        Object.assign(this, data);
    }
}



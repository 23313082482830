import { GeoDsMeta } from './meta-data.model';

export class AddressPerson extends GeoDsMeta {
  Position: string;
  IdRefPerson: string;
  ParentId: string;
  MainContact: boolean;

  constructor(data) {
    super();
    Object.assign(this, data);
  }
}


export enum Gender {
  Male = 'Male',
  Female = 'Female',
}
export class ProfileInfo {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  userlanguage: string;
  gender: Gender;
  config: {};
  id: number;
  apiAuthKey: string;
  hasAvatar: boolean;
  authorities: any[];
  accessibleScopes: AccessibleScopes[];

  constructor(data: any) {
    Object.assign(this, data);
    this.accessibleScopes = [];
    if (data?.accessibleScopes) {
      for (let accessibleScope of data.accessibleScopes) {
        this.accessibleScopes.push(new AccessibleScopes(accessibleScope));
      }
    }
  }
}

export class GeoDsLoginInfo {
  public  isAnonymous: boolean
    public  userName: string
    public  issued: Date
    public  expires: Date
    public  userId?: string
    public  displayName?: string
    public  isAdmin?: boolean
  constructor(data ) {
    Object.assign(this, data);

    if (!this.displayName) {
      this.displayName = this.isAnonymous ? '<Anonym>' : this.userName;
    }
  }
}

export class AccessibleScopes {
  urlKey: string;
  scopeKey: string;
  name: string;
  description: string;
  defaultLanguage: string;
  visible: boolean;
  publicApi: boolean;
  features: string[];
  isProxySendAuthHeader: boolean;
  tileIconUrl: string;
  constructor(data: any) {
    Object.assign(this, data);
  }
}
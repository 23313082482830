export class BoxDetailViewModel {
  Id: string;
  ObjectType?: string | number;
  IsFavorite?: boolean;
  ParentId: string;
  DisplayText?: string;
  ParentObjectTypeName: string | number;
  ParentObjectType: string | number;
  NavigationId?: string;
  gebId?: string;
  constructor(data: any) {
    Object.assign(this, data);
  }

  public getDisplayText(): string {
    return this.DisplayText;
  }
}

export enum TypeFavorite {
  Company = 'company',
  AudAudit = 'audAudit',
  AudBibMeasure = 'audBibMeasure',
  AudBibResponse = 'audBibResponse',
  AudBibQuestion = 'audBibQuestion',
  AudLibrary = 'audLibrary',
  AudBibCategory = 'audBibCategory',
  SubProject = 'subProject',
  Measure = 'measure'

}

<div class="layout-body">
    <div fxLayout.xs="row" fxLayout="row wrap" fxLayoutAlign="space-between" id="company-card">
        <div class="company-card" fxFlex="49" *ngFor="let company of companies">
            <div (click)="selectCompany(company)">
                <div class="company-container">
                    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between stretch" class="company-name-bloc">
                        <div fxFlex="150px" class="image-bloc">
                            <img class="image-style"
                                [src]="company.Image ? 'data:image/png;base64,'+ company.Image[0].Object : 'assets/images/company.svg'">
                        </div>
                        <div fxLayout="column" fxFlex="calc(100% - 150px)" class="info-bloc"
                            fxLayoutAlign="space-between">
                            <div>
                                <div class="company-name">
                                    <span>
                                        {{company.Name}}
                                    </span>
                                </div>
                                <div class="company-address">
                                    <span *ngIf="company.HouseNumber">{{company.HouseNumber}} {{company.Street}}</span>
                                </div>
                                <div class="company-address">
                                    <span *ngIf="company.ZIP">{{company.ZIP}}</span>
                                    <span *ngIf="company.City"> {{company.City}}</span>
                                </div>
                                <div class="company-address"> <span>{{company.Country}}</span></div>
                            </div>
                            <div fxLayout="row" class="arrow-right-style" fxLayoutAlign="end"> <img
                                    class="img-arrow-right" src="assets/images/ArrowRight.svg" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { MeasureService } from '../../measure.service';

@Component({
  selector: 'ubt-geods-total-time',
  templateUrl: './total-time.component.html',
  styleUrls: ['./total-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalTimeComponent implements OnChanges {
  @Input('collaborators') collaborators = [];
  @Input('taskList') taskList = [];
  public  noDataText = "Keine Daten";

  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    public measureService: MeasureService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public ngOnInit(): void {
    this.measureService.calculateTotalTime$.subscribe(() => {
      this.calculateTotalTime()
    })
  }

  public calculateTotalTime(): void {
    this.collaborators = this.measureService.calculateOverAllTime(this.taskList);
    this.cdr.markForCheck();
  }

}


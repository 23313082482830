import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Configuration } from './models/config.model';
import { forkJoin } from 'rxjs';
import { CompareHelper, ObjectHelper } from '@wissenswerft/core/data'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _configuration;
  public backEndSystem: string;

  get configuration() {
    return this._configuration as Configuration;
  }
  set configuration(conf) {
    if (CompareHelper.isEmptyObject(this._configuration)) {
      this._configuration = {};
    }
    ObjectHelper.mergeDeep(this._configuration, conf);
  }

  constructor(private http: HttpClient) { }

  public loadConfiguration(...configUrls: string[]): Promise<Configuration> {
    this.backEndSystem = configUrls[1];
    return new Promise((resolve) => {
      (configUrls ??= []).push(configUrls[1]);
      this.getConfig(...configUrls).subscribe((config) => {
        if (this.configuration.API_URL || this.configuration.CLIENT_ID || this.configuration.CLIENT_SECRET) {
          localStorage.setItem('Base.API_URL', this.configuration.API_URL);
          this.setEnvVariables();
        }
        this.setWebApiURLs(this._configuration, this._configuration.WebApi.BaseURL);

        resolve(this._configuration);
      });
    });
  }

  private getConfig(...configUrls: string[]) {
    let queries = [];
    for (const element of configUrls) {
      queries.push(
        this.http.get(element).pipe(
          tap(
            (config: Configuration) => {
              this.configuration = config;
            },
            (err) => console.log(err)
          )
        )
      );
    }
    return forkJoin(queries);
  }

  private setWebApiURLs(configContainer: any, webApiBaseURL: string): void {
    for (const settingKey of Object.keys(configContainer)) {
      const settingValue: any = configContainer[settingKey];
      if (typeof settingValue === 'string' && !['BaseURL', 'API_URL', 'CLIENT_ID', 'CLIENT_SECRET'].includes(settingKey)) {
        if (webApiBaseURL) {
          configContainer[settingKey] = `${webApiBaseURL}/${settingValue}`;
        }
      } else if (typeof settingValue === 'object' && settingValue !== null) {
        if (settingKey !== 'OAuth2') {
          this.setWebApiURLs(settingValue, webApiBaseURL);
        }
      }
    }
  }

  private setEnvVariables() {
    if (this.configuration?.API_URL) {
      this.configuration.WebApi.BaseURL = this.configuration.API_URL;
    }
    if (this.configuration.CLIENT_ID) {
      this.configuration.OAuth2.ClientId = this.configuration.CLIENT_ID;
    }
    if (this.configuration.CLIENT_SECRET) {
      this.configuration.OAuth2.ClientSecret = this.configuration.CLIENT_SECRET;
    }
  }
}

export enum BACKEND_SYSTEM {
  SPACEONE = "assets/config/spaceone.global.config.json",
  GEODS = "assets/config/geods.global.config.json"
}

import { Time } from "@angular/common";

export class GeoDsMeta {
    Id: string;
    InsertedDate: Date;
    UpdatedDate: Date;
    SysDateInsert: Date;
    SysDateUpdate: Date
    SysTimeInsert: Time;
    SysTimeUpdate: Time;
    SysUserNameInsert: string;
    SysUserNameUpdate: string;
}
import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

interface ICountryChange {
  areaCodes: string,
  dialCode: string,
  flagClass: string,
  htmlId: string,
  iso2: CountryISO,
  name: string,
  placeHolder: string,
  priority: number
}
@Component({
  selector: 'ww-phone',
  templateUrl: './ww-phone.component.html',
  styleUrls: ['./ww-phone.component.scss']
})
export class PhoneComponent implements OnInit, OnChanges {
  @Output() onValueChangedPhone: EventEmitter<any> = new EventEmitter();
  @Input() formGroup = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });
  @Input() isReady = false;
  @Output() isReadyChange = new EventEmitter<boolean>();
  @Input() key: string = '';
  @Input() dialCode: string;
  @Input() countryISO: CountryISO;
  @Output() countryISOChange = new EventEmitter<CountryISO>();
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  public separateDialCode = false;
  public searchCountryField = SearchCountryField;
  public preferredCountries: CountryISO[] = [CountryISO.Germany];

  constructor() { }
  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReady?.currentValue) {
      this.addChangeEvent()
    }

    if (changes.isReady?.currentValue && changes.isReady?.currentValue === false) {
      const form = document.querySelector('.phoneform');
      form?.removeEventListener('focusout', (event) => {
        this.onValueChangedPhone.emit(event.target['value']);

      });
      this.isReadyChange.emit(false);
    }
  }

  public countryChange(event: ICountryChange): void {
    if (this.isReady) {
      this.countryISO = event.iso2;
      this.countryISOChange.emit(this.countryISO);

      this.dialCode = event.dialCode;
    }
  }

  private addChangeEvent(): void {
    const id = '#phoneform-' + this.key
    const form = document.querySelector(id);

    form?.addEventListener('focusout', (event) => {
      this.value = event.target['value'];
      const GermanyDialCode = '49'
      const valueWithDialCode = `${this.dialCode ?? GermanyDialCode} ${this.value}`

      this.onValueChangedPhone.emit(valueWithDialCode);
      this.valueChange.emit(valueWithDialCode);
    });
  }
}

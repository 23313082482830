<div fxLayout="row" class="collaborator-bloc">
    <div fxLayout="column" fxFlex="86">
        {{dataService.res('Ubt-CreateMeasureTask-Collaborators')}}
    </div>
    <div fxLayout="column" fxFlex="7">
        <dx-button [hoverStateEnabled]="false" [icon]="'assets/images/history.svg'" stylingMode="text"
            (onClick)="calculateOverallTime()" [width]="'100%'"></dx-button>
    </div>
    <div fxLayout="column" fxFlex="7">
        <dx-button [icon]="'assets/images/plus.svg'" [stylingMode]="'text'" (onClick)="addCollaborator()">
        </dx-button>
    </div>
</div>
<ww-grid #collaboratorGrid [id]="'collaboratorGrid'" [noDataText]="noDataText"
    class="grid-group-by-Style save-icon-style collaborator-grid " [groupable]="false" [height]="'150px'"
    [searchPanel]="false" [gridData]="collaboratorsData" (onCellPrepared)="cellPrepared($event)"
    (onSaved)="saveCollaborator($event)" [columnsHeader]="measureService.collaboratorsColumns"
    [enableStateStorage]="false" [enableColumnChooser]="false" [enableExport]="false" [showfilterrow]="false"
    (onRowRemoved)="removeCollaborator($event)" [allowAdding]="false" [selectionMode]="'single'" [columnFilter]="false"
    [showRowLines]="false" [editingMode]="'row'" [showColumnLines]="false" [allowDeleting]="true" [allowUpdating]="true"
    [linkProperty]="'text'" [filterPanel]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false">
</ww-grid>
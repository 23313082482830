import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppService } from './app.service';
import { Address, Department, DocumentKind, GeoDsDocument, Person } from '@geods/base';
import { ResourceManager } from '@wissenswerft/core/resources';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel,
  Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, ReadListDataSourceModel,
  TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { Measure } from '@xmt-models';
import { ToastType } from '@wissenswerft/ww-library';
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
export enum ObjectKeys {
  ADDRESS = 'Address',
  MAILADDRESS = 'MailAddress',
  PHONENUMBER = 'PhoneNumber',
  COLLABORATOR = 'xmtMeasureTaskCollaborator',
  DOCUMENT = 'Document',
  EVENT = 'xmtSubProjectEvent',
  MEASURE = 'xmtMeasure',
  MEASUREPROGRESS = 'xmtMeasureProgress',
  PERSON = 'Person',
  PICKLISTITEM = 'PicklistItem',
  RESOURCE = 'xmtMeasureResource',
  RESPONSIBLEPLAN = 'xmtResponsiblePlan',
  SUBPROJECT = 'xmtSubProject',
  PROJECT = 'xmtProject',
  STAFFMEMBER = 'xmtStaffMember',
  TAG = 'xmtMeasureTag',
  TASK = 'xmtMeasureTask',
  DEPARTMENT = 'Department',
  ADDRESSPERSON = 'AddressPerson'
}
@Injectable()
export class DataService {
  private measureDetailSubject = new BehaviorSubject<Measure>(null);
  private readonly objectSpaceName = 'ABC.Applications.KAOS.Data';
  private gridDataSubject = new Subject<any>();
  private uploadData: Subject<GeoDsDocument> = new Subject();
  public updateGridData$ = this.gridDataSubject.asObservable();
  public uploadData$: Observable<GeoDsDocument> = this.uploadData.asObservable();
  public cachedResponsiblesResponse: Person[] = [];
  public cachedResponsiblesResponseWithImages: Person[] = [];
  public cachedProfileAvatar: string[] = [];
  public cachedCompany: Address[] = [];
  public cachedDepartement: Department[] = [];
  public favoriteMeasureId = new Subject<string>();
  public favoriteMeasureId$ = this.favoriteMeasureId.asObservable();
  public res = ResourceManager.getResources("ubt-geods");

  constructor(
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public readObjects<T>(objectType: string, columns: QueryColumn[], OPath?: string): Observable<T> {
    const readObjectQuery: QueryObjectsModel = new QueryObjectsModel();
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = objectType;
    mainQuery.Columns = columns;
    mainQuery.OPath = OPath;
    if (OPath) {
      mainQuery.OPath = OPath;
    }
    readObjectQuery.ObjectQueries = [mainQuery];
    return this.coreDataService.executeReadObjectsQuery(readObjectQuery);
  }

  public deleteObject(objectKey: ObjectKeys, id: string | number): Observable<any> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = PersistMode.Delete;
    targetObject.ObjectKey = new ObjectKey();
    targetObject.ObjectKey.Id = id.toString();
    targetObject.ObjectKey.ObjectType = objectKey;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }

  public updateGridData(data: any) {
    this.gridDataSubject.next(data);
  }

  public getMeasureDetail(): Observable<Measure> {
    return this.measureDetailSubject.asObservable();
  }

  public sendMeasureDetail(measureAssessment: Measure) {
    this.measureDetailSubject.next(measureAssessment);
  }

  public checkGridGroupByColumns(key: string, groupByColumnIndex) {
    if (localStorage.getItem(key)) {
      const gridState = JSON.parse(localStorage.getItem(key));
      gridState.columns.map(item => {
        if (item.groupIndex) {
          groupByColumnIndex[item.dataField] = item.groupIndex;
          return groupByColumnIndex;
        }
      })
    }
  }
  public prepareStaffMembersWithDocuments(): QueryObjectsModel {
    const personColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('FirstName', 'FirstName', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('LastName', 'LastName', QueryColumnSortOrder.None)
    ];
    const documentsColumns = [
      {
        Name: 'Object',
        OPath: "Object",
        Format: 'Base64Image[30x30]'
      }
    ];
    const staffMemberOpath = 'ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'" + " && Not(LastName = 'Admin')";
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = ObjectKeys.PERSON;
    mainQuery.OPath = staffMemberOpath;
    mainQuery.Columns = personColumns;

    const subQuery: Query = new Query();
    subQuery.Name = 'Documents';
    subQuery.OPath = 'Documents';
    subQuery.Columns = documentsColumns;
    mainQuery.ObjectQueries = [subQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [mainQuery];
    return queryDocument;
  }

  public prepareStaffMembers(): Observable<Person[]> {

    const columns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('FirstName', 'FirstName', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('LastName', 'LastName', QueryColumnSortOrder.None)
    ];
    const staffMemberOpath = 'ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'" + " && Not(LastName = 'Admin')";
    return this.readObjects<Person[]>(ObjectKeys.PERSON, columns, staffMemberOpath);
  }

  public getDataType(dataType) {
    switch (dataType) {
      case 'String':
        return 'string';
      case 'Integer32':
        return 'number';
      case 'Date':
        return 'date';
      default:
        return 'string';
    }
  }
  public readonly documentbyIdColumns: QueryColumn[] = [
    this.coreDataService.createQueryColumn('Id', 'Id'),
    this.coreDataService.createQueryColumn('Object', 'Object'),
  ];

  public previewDocument = (document) => {
    const documentId = document.Id;
    const _documentOpath = `Id = '${documentId}'`;
    const documentDesignation = document.Designation;
    const pdfViewer = window.open('');
    pdfViewer.document.write('<title>' + documentDesignation + '</title>');
    this.readObjects(ObjectKeys.DOCUMENT, this.documentbyIdColumns, _documentOpath)
      .subscribe(
        (_document) => {
          pdfViewer.document.write(
            `<iframe width='100%' height='100%' src='data:${this.getMimeType(document)};base64,${encodeURI(_document[0].Object)}'></iframe>`
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public getResponsibleFullName(account: Person): string {
    return account ? account?.LastName + ', ' + account?.FirstName : '';
  }

  public customizePercentageText(event) {
    return event ? event + '%' : 0 + '%';
  }

  public loadListDataSource(objectType: string, columnName: string): Observable<any> {
    const readListDataSourceQuery = new ReadListDataSourceModel();
    const objectKey = new ObjectKey();
    objectKey.ObjectType = objectType;
    readListDataSourceQuery.ObjectKey = objectKey;
    readListDataSourceQuery.OutputMode = 0;
    readListDataSourceQuery.ColumnName = columnName;
    return this.coreDataService.executeReadListDataSource(
      readListDataSourceQuery
    );
  }

  public persistUploadDocument(document: GeoDsDocument): void {
    const documentPersistQuery: TargetObjectData = new TargetObjectData();
    documentPersistQuery.ObjectKey = new ObjectKey();
    documentPersistQuery.ObjectKey.ObjectType = ObjectKeys.DOCUMENT;
    const documentColumns: TargetColumnValue[] = [
      { Name: 'Object', Value: this.getBinaryStringFromUrl(document?.Object) },
      { Name: 'Designation', Value: document?.Designation },
      { Name: 'FileName', Value: document?.FileName },
      { Name: 'ParentId', Value: document?.ParentId },
      { Name: 'Kind', Value: document?.Kind },
      { Name: 'ParentObjectType', Value: document?.ParentObjectType }
    ];
    if (document?.Id !== undefined && document?.Id !== null) {
      documentPersistQuery.ObjectKey.Id = document?.Id;
      documentPersistQuery.Mode = PersistMode.Update;
    } else {
      documentPersistQuery.Mode = PersistMode.Insert;
    }
    documentPersistQuery.TargetColumns = documentColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = documentPersistQuery;
    this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      this.updateGridData(data);
      this.appService.callNotification({ message: this.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  private getBinaryStringFromUrl(url: string): string {
    const img = url?.split(',')[1];
    if (img) {
      return img;
    } else {
      return url;
    }
  }

  public onFileChanged(event, document: GeoDsDocument): void {
    let documentSource = "";
    let fileName = "";
    if (event?.value !== null) {
      const file = event?.value[0];
      if (file?.name !== undefined) {
        fileName = file.name;
      }
      const reader = new FileReader();
      reader.readAsDataURL(new Blob([file]));
      reader.onloadend = () => {
        documentSource = reader.result as string;
        document.Object = documentSource;
        document.FileName = fileName;
        this.uploadData.next(document);
      };
    }
  }

  public downloadFile(doc: GeoDsDocument) {
    const a = document.createElement('a');
    a.href = `data:${this.getMimeType(doc)};base64,${doc.Object}`;
    a.download = doc.FileName;
    a.click();
  }

  private getMimeType(doc: GeoDsDocument, ext?: string) {
    const _extension = doc.FileName.split('.').pop();
    const extension = (ext || _extension).toLowerCase();
    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'JPG':
        return 'image/jpeg';
      case 'pdf' || 'zip':
        return 'application/' + extension;
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'txt':
        return 'text/plain';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'csv':
        return 'text/csv';
      default:
        const fileNameExt = doc.FileName?.split('.').pop();
        if (fileNameExt && doc.FileName?.indexOf('.') > -1) {
          return this.getMimeType(doc, fileNameExt);
        } else if (doc.FileName.indexOf('.') === -1) {
          if (doc.Kind) {
            switch (doc.Kind) {
              case DocumentKind.Bild:
                return 'image/jpeg';
              default:
                return 'application/octet-stream';
            }
          }
        } else {
          return 'application/octet-stream';
        }
    }
  }

  public floatingButtonConfig() {
    config({
      floatingActionButtonConfig: {
        icon: 'rowfield',
        shading: true,
        direction: 'down',
        position: {
          of: '#grid',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16',
        },
      },
    });
    repaintFloatingActionButton();
  }

}
import { BoxDetailViewModel } from './Model/box.viewModel';

export class FavoriteDataSource {
  public favorites: Map<string, any> = new Map<string, any>();
  public favoritesByObjectType: BoxDetailViewModel[][] = [];
  constructor(data: any) {
    if (data.length) {
      data.forEach((favorite: any) => {
        const favoriteViewModel = new BoxDetailViewModel(favorite);
        this.favorites.set(favoriteViewModel.Id, favoriteViewModel);

        if (this.favoritesByObjectType[favoriteViewModel.ParentObjectType]) {
          this.favoritesByObjectType[favoriteViewModel.ParentObjectType].push(favoriteViewModel);
        } else {
          this.favoritesByObjectType[favoriteViewModel.ParentObjectType] = [favoriteViewModel];
        }
      });
    }
  }

  public setDisplayText(ObjectType: string, data: any): void {
    this.favorites.forEach((favorite) => {
      if (favorite.ParentObjectType == ObjectType) {
        data.forEach((eleme: BoxDetailViewModel) => {
          if (favorite.ParentId === eleme.Id) {
            favorite.DisplayText = eleme.DisplayText;
          }
        });
      }
    });
  }

  public deleteFavoriteFromList(id: string): void {
    this.favorites.delete(id);
  }
}

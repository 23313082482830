import { EventEmitter, Injectable, Output } from '@angular/core';
import { AccessibleScopes } from '@wissenswerft/core/authentication';
import { BehaviorSubject, Subject } from 'rxjs';
import { IToast } from '@wissenswerft/ww-library';
import { ResourceManager } from '@wissenswerft/core/resources';
import { QueryObjectsModel } from '@wissenswerft/core/data';
import { SubProject } from '@xmt-models';
import { Address } from '@geods/base';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  @Output() selectItem = new EventEmitter();
  private notificationSubject: Subject<IToast> = new Subject();
  private measureNameSubject: Subject<string> = new Subject();
  public measureName$ = this.measureNameSubject.asObservable();
  public notification$ = this.notificationSubject.asObservable();
  public reportIndex: number;
  public measureIds = [];
  public showMeasureIcon: boolean = false;
  public readonly CONFIG_PROPERTIES = ['Id', 'ParentId', 'SysTimeInsert', 'SysTimeUpdate', 'SysUserNameInsert', 'SysDateUpdate', 'SysUserNameUpdate', 'SeqNr', 'ObjectType'];
  public res = ResourceManager.getResources("ubt-geods");
  public subProjectRequestColumns = new QueryObjectsModel();
  public subProjects: SubProject[] = [];
  public currentSubProjectIndex :number;
  public currentMeasureProgressIndex: number;
  public subProjectIds: string[] = [];
  public cachedCompanies: Address[] = [];
  private treeViewSwitchToCompany = new BehaviorSubject<boolean>(false);
  public treeViewSwitchToCompany$ = this.treeViewSwitchToCompany.asObservable();
  private treeViewSwitchToProject = new BehaviorSubject<boolean>(false);
  public treeViewSwitchToProject$ = this.treeViewSwitchToProject.asObservable();

  constructor() { }

  public callNotification(notification: IToast): void {
    this.notificationSubject.next(notification);
  }

  public callMeasureName(measureName: string): void {
    this.measureNameSubject.next(measureName);
  }

  public selectTreeItem = (selectedNode: number) => {
    this.selectItem.emit(selectedNode);
  }

  public switchTreeViewToCompany(data: boolean) {
    sessionStorage.setItem('switchToCompany',data.toString());    
    this.treeViewSwitchToCompany.next(data);
  }

  public switchTreeViewToProject(data: boolean) {
    sessionStorage.setItem('switchToProject',data.toString());
    this.treeViewSwitchToProject.next(data);
  }

}
import { MailAndWebAddress } from "./mail-and-web-address.model";
import { GeoDsMeta } from "./meta-data.model";
import { Person } from "./person.model";
import { PhoneNumber } from "./phone-number.model";

export class Address extends GeoDsMeta {
    Name: string;
    Name2: string;
    CustomerNumber: string;
    Street: string;
    City: string;
    HouseNumber: string;
    Country: string;
    ZIP: string;
    Status: string;
    Phone: string;
    PhoneNumbers: PhoneNumber[];
    Phone2: string;
    Fax: string;
    FaxNumbers: PhoneNumber[];
    CompanyTitle: string;
    Homepage: string;
    EMail: string;
    Emails: MailAndWebAddress[];
    MatchCode: string;
    Persons: Person[];

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ResourceManager } from '@wissenswerft/core/resources';
export enum nameClassIcon { }
@Component({
  selector: 'favorite-filter-list',
  template: `<mat-list fxLayout="row" fxFill fxLayoutGap="10px" fxLayoutAlign="center center" class="filter-mat-list">
    <div
      *ngFor="let item of filterList"
      class="item-list-favorite"
      fxLayout="column"
      [ngStyle]="{ 'background-color': item.Name === 'All' ? '#00569c' : '','cursor':'pointer' }"
      fxLayoutAlign="center center"
      (click)="onChangeFilter(item.Name)"
    >
      <mat-icon class="icon-style-{{ item.Name }}" svgIcon="{{ item.Icon }}"></mat-icon>
      <span class="item-span-button" [ngStyle]="{ color: item.Name === 'All' ? '#fff' : '' }">{{ res('Favorites-'+item.Name) }}</span>
    </div>
  </mat-list> `,
  styleUrls: ['./favorite-filter-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FavoriteFilterListComponent implements OnInit {
  @Input('filteredBy') filteredBy: string;
  @Input('filterList') filterList: any;
  @Output() onButtonClick = new EventEmitter();
  public res = ResourceManager.getResources('favorites');
  constructor() { }

  ngOnInit(): void {
    this.filterList = Array.from(this.filterList.values());

  }

  public onChangeFilter(listFilter: string) {
    const favoritesLayout = document.getElementsByClassName('filter-mat-list')[0] as HTMLElement;
    const favoritesList = favoritesLayout.childNodes as NodeListOf<HTMLElement>;
    favoritesList.forEach((fList) => {
      if (fList instanceof HTMLElement) {
        this.changeIconsColor(fList.querySelector('mat-icon') as HTMLElement, '#bdc1c9', '#fff');
      }
    });
    this.changeIconsColor(document.querySelector('.icon-style-' + listFilter) as HTMLElement, '#fff', '#00569c');
    this.onButtonClick.emit(listFilter);
  }

  private changeIconsColor(element: HTMLElement, iconColor: string, backgroundColor: string) {
    element.nextElementSibling?.setAttribute('style', 'color : ' + iconColor);
    element.parentElement!.style.backgroundColor = backgroundColor;
    const svgIcon = element.firstChild as HTMLElement;
    if (svgIcon) {
      const svgChildNodes = svgIcon.lastChild?.childNodes as NodeListOf<SVGElement>;
      if (svgChildNodes.length > 0) {
        svgChildNodes.forEach((pathSvgIcon) => {
          if (pathSvgIcon instanceof SVGElement) {
            pathSvgIcon.style.fill = iconColor;
          }
        });
      } else {
        const svgChildNodes = svgIcon.childNodes as NodeListOf<SVGElement>;
        svgChildNodes.forEach((pathSvgIcon) => {
          if (pathSvgIcon instanceof SVGElement) {
            pathSvgIcon.style.fill = iconColor;
          }
        });
      }
    }
  }

}

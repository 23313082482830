import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn,
  QueryColumnSortOrder, QueryObjectsModel, TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { DxFormComponent, DxGanttComponent, DxPopupComponent } from 'devextreme-angular';
import { exportGantt } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DataService, ObjectKeys } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { Subscription } from 'rxjs';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { GanttDateRange, GanttExportDocumentFormat, GanttExportMode, GanttTaskColor, IGanttDataSource, IResource, ResourceAssignment, ScaleType } from './gantt.model';
import { Collaborator, Measure, MeasureTask, Resource } from '@xmt-models';
import { GanttService } from './gantt.service';
import { Column } from 'devextreme/ui/data_grid';

@Component({
  selector: 'ubt-geods-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnInit, OnDestroy {
  @ViewChild('gantt') gantt: DxGanttComponent;
  @ViewChild('milestonePopup') milestonePopup: DxPopupComponent;
  @ViewChild('taskPopup') taskPopup: DxPopupComponent;
  @ViewChild('milestoneForm') milestoneForm: DxFormComponent;
  @ViewChild('collaboratorGrid') collaboratorGrid: GridComponent;
  @Output() onCalculateOverallTime: EventEmitter<number> = new EventEmitter();
  public collaboratorsData: Collaborator[] = [];
  public resourcesData: Resource[] = [];
  public disableAddButton = false;
  public isTask = false;
  public showLoader = false;
  public ganttDataSource: IGanttDataSource[] = [];
  public resources: IResource[] = [];
  public resourceAssignments: ResourceAssignment[] = [];
  public measureTask: MeasureTask = new MeasureTask(null);
  public measure: Measure = new Measure(null);
  public task: MeasureTask = new MeasureTask(null);
  public collaboratorsColumns: Column[] = [];
  public tasksById: MeasureTask[] = [];
  public submitButtonText: string;
  public scaleType = ScaleType.Quarters;
  public ganttStartDate = new Date();
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };
  public exportOptions = {
    hint: 'Export to PDF',
    icon: 'exportpdf',
    stylingMode: 'text',
    onClick: () => this.exportToPDF(),
  };
  public showResourceDetails = false;
  private measureTaskDs: IGanttDataSource[] = [];
  private subscriptions: Subscription[] = [];
  private ganttColumns = new QueryObjectsModel();
  private tasksIds: string[] = [];
  private measuresIds: string[] = [];
  public noDataText = "Keine Daten";

  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    private appService: AppService,
    public dataService: DataService,
    public ganttService: GanttService,
    public cdr: ChangeDetectorRef
  ) {
    this.openDialogMilestone = this.openDialogMilestone.bind(this);
    this.addCollaborator = this.addCollaborator.bind(this);
  }

  public ngOnInit(): void {
    if (this.dataService.cachedResponsiblesResponse.length === 0) {
      this.coreDataService.executeReadObjectsQuery(this.dataService.prepareStaffMembersWithDocuments())
        .subscribe(staffMembers => {
          if (Array.isArray(staffMembers) && staffMembers.length > 0) {
            this.dataService.cachedResponsiblesResponse = staffMembers;
          }
        })
    }

    this.showLoader = true;
    this.prepareGantt();
  }

  public exportToPDF() {
    exportGantt({
      component: this.gantt.instance,
      createDocumentMethod: (args?: any) => new jsPDF(args),
      format: GanttExportDocumentFormat.Auto,
      landscape: true,
      exportMode: GanttExportMode.All,
      dateRange: GanttDateRange.All,
    }).then((document) => document.save('gantt.pdf'));
  }

  public prepareGantt(): void {
    this.resources = [];
    this.resourceAssignments = [];
    this.ganttDataSource = [];
    const subProject: Query = new Query();
    const subProjectColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('SysDateInsert', 'SysDateInsert', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('SysDateUpdate', 'SysDateUpdate', QueryColumnSortOrder.None),
    ];
    subProject.ObjectType = ObjectKeys.SUBPROJECT;
    subProject.Columns = subProjectColumns;
    subProject.OPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    const measure: Query = new Query();
    measure.Name = 'xmtMeasure';
    measure.OPath = 'measures';
    const measureColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('Designation', 'Title'),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate'),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate'),
    ];
    measure.Columns = measureColumns;

    const measureTask: Query = new Query();
    measureTask.Name = 'xmtMeasureTask';
    measureTask.OPath = 'MeasureTasks';
    const measureTaskColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
      this.coreDataService.createQueryColumn('Subject', 'Label'),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate'),
      this.coreDataService.createQueryColumn('EndDate', 'TargetDate'),
      this.coreDataService.createQueryColumn('DegreeOfImplementation', 'DegreeOfImplementation'),
      this.coreDataService.createQueryColumn('Type', 'Type'),
      this.coreDataService.createQueryColumn('IdRefxmtMeasureTask', 'Task')
    ];
    measureTask.Columns = measureTaskColumns;

    const taskCollaborators: Query = new Query();
    taskCollaborators.Name = 'xmttaskCollaborators';
    taskCollaborators.OPath = 'collaborators';
    const taskCollaboratorsColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
    ];
    taskCollaborators.Columns = taskCollaboratorsColumns;

    const taskResources: Query = new Query();
    taskResources.Name = 'MeasureResource';
    taskResources.OPath = 'MeasureResource';
    const taskResourcesColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefPerson', 'Member'),
      this.coreDataService.createQueryColumn('ref:IdRefPerson', 'Person')
    ];
    taskResources.Columns = taskResourcesColumns;
    taskCollaborators.ObjectQueries = [taskResources];

    measureTask.ObjectQueries = [taskCollaborators];
    measure.ObjectQueries = [measureTask];
    subProject.ObjectQueries = [measure];
    this.ganttColumns.ObjectQueries = [subProject];
    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(this.ganttColumns).subscribe(data => {
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((subProject) => {
          const subProjectDs: IGanttDataSource = {
            id: subProject.Id,
            title: this.dataService.res('Ubt-Measure-List-SubProject') + ": " + subProject.Label,
            parentId: 0,
            start: subProject.SysDateInsert,
            end: subProject.SysDateUpdate,
            color: GanttTaskColor.Green
          };
          this.ganttDataSource.push(subProjectDs);
          if (subProject.xmtMeasure?.length > 0) {
            subProject.xmtMeasure.forEach(measure => {
              this.measuresIds.push(measure.Id);
              const measureDs: IGanttDataSource = {
                id: measure.Id,
                parentId: measure.ParentId,
                title: this.dataService.res('Ubt-CreateMeasureTask-Measure') + ": " + measure.Title,
                start: measure.StartDate,
                end: measure.EndDate,
                color: GanttTaskColor.LightGreen
              };
              this.ganttDataSource.push(measureDs);
              if (measure.xmtMeasureTask?.length > 0) {
                measure.xmtMeasureTask.forEach(task => {
                  if (!task.Task) {
                    this.tasksIds.push(task.Id);
                    this.tasksById.push(new MeasureTask({
                      Id: task.Id,
                      Label: task.Label,
                      ParentId: task.ParentId
                    }));
                  }
                  const measureTaskDs: IGanttDataSource = {
                    id: task.Id,
                    title: (task.Type === 0 ? this.dataService.res('Ubt-MeasureDetail-Milestone') : this.dataService.res('Ubt-MeasureDetail-Task')) + ": " + task.Label,
                    parentId:  task.ParentId ,
                    start: task?.StartDate,
                    end: task.TargetDate,
                    progress: task.DegreeOfImplementation,
                    color: GanttTaskColor.LightBlue
                  };
                  this.measureTaskDs.push(measureTaskDs);
                  this.ganttDataSource.push(measureTaskDs);
                  if (task.xmttaskCollaborators?.length > 0) {
                    task.xmttaskCollaborators.forEach(collaboratior => {
                      if (collaboratior.MeasureResource?.length > 0) {
                        const collaboratorResource: IResource = {
                          id: collaboratior.Id,
                          text: collaboratior.MeasureResource[0].Person
                        };
                        this.resources.push(collaboratorResource);
                      }
                      const assignments: ResourceAssignment = {
                        id: this.resourceAssignments.length != 0 ? this.resourceAssignments.length : 0,
                        taskId: task.Id,
                        resourceId: collaboratior.Id
                      };
                      this.resourceAssignments.push(assignments);
                    })
                  }
                })
              };
            });
          }
        });
      } else {
        this.showLoader = false;
        this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.ganttDataSource = [...new Map(this.ganttDataSource.map(v => [v.id, v])).values()];
      this.gantt.instance.repaint();
      this.gantt.instance.refresh();
      this.showLoader = false;
    }));
  }

  public contentReady = (e): void => {
    setTimeout(() => {
      const ganttStartDate = (this.ganttStartDate.getUTCFullYear() - 1).toString()
      this.gantt.instance.scrollToDate(ganttStartDate);
      this.gantt.instance.zoomIn();
    }, 0)
  }


  public contextMenuPreparing(event): void {
    if (this.measuresIds.find((item) => item === event.data.id)) {
      this.ganttService.measureParentId = event.data.id;
      this.ganttService.taskParentId = null;
      this.disableAddButton = false;
      this.getMeasureById(this.ganttService.measureParentId)
      this.isTask = false;
    } else if (this.tasksIds.find((item) => item === event.data.id)) {
      this.ganttService.taskParentId = event.data.id;
      this.ganttService.measureParentId = event.data.parentId;
      this.disableAddButton = false;
      this.isTask = true;
      this.getTaskById(this.ganttService.taskParentId)
    } else {
      this.disableAddButton = true;
    }
    this.cdr.markForCheck();
  }

  private getMeasureById = (measureId: string) => {
    const subProjectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None)
    ];
    const measureOPath = 'Id=' + "'" + measureId + "'";
    this.subscriptions.push(this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, subProjectColumns, measureOPath).subscribe(measures => {
      this.measure = measures[0];
    }));
  }


  private getTaskById = (milestionId: string) => {
    const measureTaskIdColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None)
    ];
    const measureTaskIdOPath = 'Id=' + "'" + milestionId + "'";
    this.subscriptions.push(this.dataService.readObjects<MeasureTask[]>(ObjectKeys.TASK, measureTaskIdColumns, measureTaskIdOPath).subscribe(task => {
      this.task = task[0];
    }));
  }

  public openDialogMilestone(): void {
    if (this.disableAddButton === false && this.isTask === false) {
      this.milestonePopup.title = this.dataService.res('Ubt-MeasureTask-Create-Milestone');
      this.milestonePopup.instance.show();
      this.submitButtonText = 'Create';
      this.cdr.markForCheck();
    } else {
      const resourceColumns = [
        this.coreDataService.createQueryColumn('Id', 'Id'),
        this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
        this.coreDataService.createQueryColumn('IdRefPerson', 'Member'),
        this.coreDataService.createQueryColumn('ref:IdRefPerson', 'Person')
      ];

      const opath = 'ParentId=' + "'" + this.ganttService.measureParentId + "'";
      this.subscriptions.push(this.dataService.readObjects<Resource[]>(ObjectKeys.RESOURCE, resourceColumns, opath).subscribe((data) => {
        this.collaboratorsColumns = [];
        this.resourcesData = data;
        this.collaboratorsColumns.push(
          {
            caption: this.dataService.res('Ubt-CreateMeasure-Members'),
            dataField: 'IdRefMember',
            lookup: { valueExpr: 'Id', displayExpr: 'Person', dataSource: data }
          },
          {
            caption: this.dataService.res('Ubt-Collaborator-Total-Time-Planned'),
            dataField: 'TimePlanned',
            dataType: 'number',
            format: '#,##0 h',
            alignment: 'center',
            width: '25%',
          },
          {
            caption: this.dataService.res('Ubt-Collaborator-Total-Time-Executed'),
            dataField: 'TimeExecuted',
            dataType: 'number',
            format: '#,##0 h',
            alignment: 'center',
            width: '25%',
          }
        )
      }));
      this.taskPopup.instance.show();
      this.taskPopup.title = this.dataService.res('Ubt-MeasureTask-Create-Task');
      this.submitButtonText = 'Create';
      this.cdr.markForCheck();
    }
  }

  public persistMeasureTaskAndSubTask(event) {
    event.preventDefault()
    this.subscriptions.push(this.ganttService.persistTask(PersistMode.Insert).subscribe((task) => {
      if (this.collaboratorsData?.length > 0) this.ganttService.prepareCollaborators(task.Id, this.collaboratorsData);
      this.ganttService.measureTask.Id = task.Id;
      this.prepareGantt();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.clearWindow();
    }));
  }

  public clearWindow(): void {
    this.ganttService.measureTask = new MeasureTask(null);
    this.milestonePopup.instance.hide();
    this.taskPopup.instance.hide();
    this.resourcesData = [];
    this.collaboratorsData = [];
    this.collaboratorGrid.dxDataGrid.instance.cancelEditData();
  }

  public cellPrepared = (e) => {
    if (e.column.dataField === 'TimeExecuted' && e.rowType === 'data') {
      if (e.row.data.TimeExecuted > e.row.data.TimePlanned) {
        e.cellElement.style.color = 'red';
      } else {
        e.cellElement.style.color = 'green';
      }
    }
  }

  public removeCollaborator(e) {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.COLLABORATOR, e.data.Id).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.cdr.markForCheck();
    }, error => {
      console.error(error)
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public saveCollaborator(e) {
    if (e.changes[0].type !== 'remove' && this.ganttService.isCreatingSubTask === false) {
      const newCollaborator = e.changes[0].data;
      const collaboratorPersistQuery: TargetObjectData = new TargetObjectData();
      collaboratorPersistQuery.ObjectKey = new ObjectKey();
      collaboratorPersistQuery.ObjectKey.ObjectType = ObjectKeys.COLLABORATOR;
      const collaboratorColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: this.measureTask.Id },
        { Name: 'IdRefMeasureResource', Value: newCollaborator.IdRefMember },
        { Name: 'TimeExecuted', Value: newCollaborator.TimeExecuted },
        { Name: 'TimePlanned', Value: newCollaborator.TimePlanned },
        { Name: 'Period', Value: new Date().toDateString() }
      ];
      if (e.changes[0].type === 'update') {
        collaboratorPersistQuery.Mode = PersistMode.Update;
        collaboratorPersistQuery.ObjectKey.Id = newCollaborator.Id;
      } else {
        collaboratorPersistQuery.Mode = PersistMode.Insert;
      }
      collaboratorPersistQuery.TargetColumns = collaboratorColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = collaboratorPersistQuery;
      this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((collaborator) => {
        this.cdr.markForCheck();
        if (!this.collaboratorsData[this.collaboratorsData.length - 1]?.Id) {
          this.collaboratorsData[this.collaboratorsData.length - 1].Id = collaborator.Id;
        }
        this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
      }));
    } else if (e.changes[0].type !== 'remove' && this.ganttService.isCreatingSubTask === true) {
      this.measureTask.Collaborators = this.collaboratorsData;
    }
  }

  public addCollaborator(): void {
    this.collaboratorGrid.addRow();
  }

  public calculateOverallTime(): void {
    const sumWithInitial = this.collaboratorsData.reduce(
      (previousValue, currentValue) => previousValue + parseInt(currentValue.TimePlanned.toString()),
      0
    );
    this.onCalculateOverallTime.emit(sumWithInitial);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

<dx-chart #chart id="chart" [dataSource]="chartData">
    <dxi-series axis="maturityLevelAxis" type="stackedbar" valueField="MaturityLevel" color="#00569c"
        name="{{dataService.res('Ubt-MeasureDetail-Chart-Maturity-Level')}}">
    </dxi-series>
    <dxi-series axis="percentageAxis" type="spline" valueField="ProgressPercentage" color="#008fd8"
        name="{{dataService.res('Ubt-MeasureDetail-Chart-Progress')}}">
    </dxi-series>
    <dxo-common-series-settings argumentField="ProgressDate"></dxo-common-series-settings>

    <dxo-tooltip [enabled]="true" contentTemplate="content"></dxo-tooltip>
    <div *dxTemplate="let info of 'content'" class="state-tooltip">
        <h4 class="state">{{dataService.res('Ubt-MeasureDetail-Information')}}</h4>
        <div>
            {{dataService.res('Ubt-MeasureDetail-Assign-Progress')}}: {{info.point.data.ProgressPercentage ?
            info.point.data.ProgressPercentage : 0}}%
        </div>
        <div>{{dataService.res('Ubt-MeasureDetail-Maturity-Level')}}: {{ info.point.data.MaturityLevel }}</div>
    </div>

    <dxo-argument-axis argumentType="datetime" type="discrete" displayMode="points" [valueMarginsEnabled]="true">
        <dxo-tick-interval [weeks]="weeksInterval"></dxo-tick-interval>
        <dxo-minor-tick-interval [weeks]="minorTickInterval"></dxo-minor-tick-interval>
        <dxo-tick [visible]="true"></dxo-tick>
        <dxo-minor-tick [visible]="true"></dxo-minor-tick>
        <dxo-label [customizeText]="customizeDateText"></dxo-label>
    </dxo-argument-axis>

    <dxi-value-axis name="maturityLevelAxis" [categories]="maturityLevelCategories" valueField="MaturityLevel"
        position="left">
        <dxo-label [customizeHint]="getMaturityLevelDescription"></dxo-label>
    </dxi-value-axis>
    <dxi-value-axis name="percentageAxis" maxValueMargin="1/10" position="right">
        <dxo-label [customizeText]="customizePercentText"></dxo-label>
    </dxi-value-axis>

    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" [visible]="true"></dxo-legend>
</dx-chart>
<div
  class="content-class"
  [style.filter]="blurPage == true ? 'blur(0.2rem)' : 'blur(0)'"
>
  <div class="content-block">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" class="tap-bloc-style">
        <img
          id="company-icon"
          (click)="redirectToCompanies()"
          class="title-icon"
          src="assets/images/company.svg"
        />
        <span id="title-company" class="title-style">{{
          res('Company-Title-Companies')
        }}</span>
        <img
          id="title-icon"
          class="title-icon"
          src="assets/images/project.svg"
          style="display: none"
        />
        <span id="title-project" class="title-style" style="display: none">{{
          res('Company-Select-Project')
        }}</span>
      </div>
    </div>
    <div
      fxFill
      fxLayout="row wrap"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between none"
    >
      <div
        fxFill
        fxLayout="column"
        fxFlex="68"
        fxFlex.sm="100"
        fxLayoutAlign="space-between none"
      >
        <div id="searchBloc" fxLayout="column" fxFlex="15" class="filter-card">
          <div class="card-container">
            <div
              fxFill
              fxLayout="row"
              fxLayoutAlign="space-evenly stretch"
              fxLayoutGap="5px"
            >
              <div fxLayout="column" fxFlex="60">
                <dx-text-box
                  class="search"
                  placeholder="{{ res('Company-Icon-Search') }}"
                  [mode]="'search'"
                  stylingMode="outlined"
                  labelMode="floating"
                  valueChangeEvent="keyup"
                  (onValueChanged)="resetSearch($event)"
                  (input)="searchTerm.next($event.target.value)"
                >
                </dx-text-box>
              </div>
              <div fxLayout="column" fxFlex="40">
                <dx-select-box
                  #filterField
                  class="select-box-filter"
                  [stylingMode]="'outlined'"
                  [labelMode]="'floating'"
                  displayExpr="name"
                  valueExpr="id"
                  [items]="filterOptions"
                  (onItemClick)="selectFilterOption($event)"
                >
                </dx-select-box>
              </div>
            </div>
          </div>
        </div>
        <div class="card-container" fxFlex="85">
          <wissenswerft-company-card
            id="companies"
            style="display: block"
            [companies]="companies"
            [filterActive]="filterActive"
            [navigateUrl]="navigateUrl"
            [companyIdDependency]="companyIdDependency"
            [companyNameDependency]="companyNameDependency"
            [configDependencies]="configDependencies"
            (clickCompany)="clickCompanyAction($event)"
          >
          </wissenswerft-company-card>

          <div id="companyWithProject" style="display: none">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between none"
              class="card-heigh"
            >
              <div class="company-card-with-project" fxFlex="50">
                <div class="company-container">
                  <div
                    *ngIf="companySelected"
                    fxLayout="row"
                    fxFlex="100"
                    fxLayoutAlign="space-between stretch"
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="space-between stretch"
                      class="company-name-bloc"
                    >
                      <div fxFlex="150px" class="image-bloc">
                        <img
                          class="image-style"
                          [src]="
                            company.Image
                              ? 'data:image/png;base64,' +
                                company.Image[0].Object
                              : 'assets/images/company.svg'
                          "
                        />
                      </div>
                      <div
                        fxLayout="column"
                        fxFlex="calc(100% - 150px)"
                        class="info-bloc"
                      >
                        <div>
                          <div class="company-name">
                            <span>
                              {{ companySelected.Name }}
                            </span>
                          </div>

                          <div class="company-address">
                            <span *ngIf="companySelected.HouseNumber"
                              >{{ companySelected.HouseNumber }}
                              {{ companySelected.Street }}</span
                            >
                          </div>
                          <div class="company-address">
                            <span *ngIf="companySelected.ZIP">{{
                              companySelected.ZIP
                            }}</span>
                            <span *ngIf="companySelected.City">
                              {{ companySelected.City }}</span
                            >
                          </div>
                          <div class="company-address">
                            <span>{{ companySelected.Country }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="48" fxLayout="column">
                <div fxLayout="column" fxLayoutGap="10px">
                  <div
                    class="project-card project-of-company"
                    fxLayoutAlign="space-between none"
                    (click)="navigateToDashboard(project)"
                    *ngFor="let project of projectList"
                  >
                    <div fxLayout="column" fxFlex="100">
                      <div fxFill fxLayout="column" fxFlex="65">
                        <div fxFill fxLayout="row">
                          <div fxLayout="column" fxFlex="70">
                            <div fxLayout="row">
                              <div class="project-title">
                                <span> {{ project.Label }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        fxLayout="row"
                        fxFlex="40"
                        fxLayoutAlign="space-between none"
                      >
                        <div
                          fxLayout="row"
                          fxFlex="33"
                          fxLayoutAlign="center center"
                        >
                          <div class="item-style">
                            SubProject {{ project.subProjectsCount }}
                          </div>
                        </div>
                        <div
                          fxLayout="row"
                          fxFlex="33"
                          fxLayoutAlign="center center"
                        >
                          <div class="item-style">
                            Measures {{ project.measuresCount }}
                          </div>
                        </div>
                        <div
                          fxLayout="row"
                          fxFlex="33"
                          fxLayoutAlign="center center"
                        >
                          <div class="item-style">
                            Milestones {{ project.tasksCount }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFill fxLayout="row" fxFlex="30" fxFlex.sm="100" fxFlex.xs="100">
        <div class="card-container">
          <dx-accordion
            class="accordion-style implement-plain-container"
            [collapsible]="true"
            [selectedIndex]="1"
            [width]="'100%'"
            [animationDuration]="300"
            [visible]="true"
            [multiple]="false"
            [dataSource]="['Test']"
          >
            <div
              *dxTemplate="let implementationPlan of 'title'"
              class="dx-accordion-item-title"
            >
              {{ res('Company-My-Most-Recently-Worked-Projects') }}
            </div>
            <div *dxTemplate="let company of 'item'">
              <wissenswerft-project-card
                (selectLastProjectUpdated)="
                  selectLastProjectUpdatedAction($event)
                "
              >
              </wissenswerft-project-card>
            </div>
          </dx-accordion>
        </div>
      </div>
    </div>
  </div>
</div>


import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { SubProjectService } from '../sub-project.service';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { DxDataGridComponent, DxGalleryComponent } from 'devextreme-angular';
import { AppService } from '../../../services/app.service';
import { SubProject } from '@xmt-models';
import { ObjectKeys } from "../../../services/data.service";
import { GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder, Query } from '@wissenswerft/core/data';
import pptxgen from 'pptxgenjs';
import { Person } from '@geods/base';
import { Subscription } from 'rxjs';
import { SubProjectViewModel } from '../../../view-models/sub-project.view-model';

@Component({
  selector: 'ubt-geods-sub-project-detail',
  templateUrl: './sub-project-detail.component.html',
  styleUrls: ['./sub-project-detail.component.scss']
})
export class SubProjectDetailComponent implements OnInit, OnDestroy {
  @ViewChild(DxGalleryComponent, { static: false }) gallery: DxGalleryComponent;
  @ViewChild('subProjectReport', { static: false }) subProjectReport: DxDataGridComponent;
  public showLoader = false;
  public disablePtxExport = false;
  public subProjectTitle: string;
  private subProjectLabel: string
  public subprojectsId = [];
  public showReportsDetails: boolean;
  public columnsHeader: Column[] = [
    {
      dataField: 'measureTitle',
      caption: this.dataService.res('Ubt-CreateMeasureTask-Measure'),
      width: "40%"
    },
    {
      dataField: 'progressPercentage',
      cellTemplate: "progressCellTemplate",
      caption: this.dataService.res('Ubt-MeasureDetail-Chart-Progress'),
      width: "15%"
    },
    {
      dataField: 'maturityLevel',
      alignment: "left",
      caption: this.dataService.res('Ubt-MeasureDetail-Maturity-Level'),
      width: "15%"
    },

    {
      dataField: 'totalTime',
      dataType: 'number',
      format: '#,##0 h',
      caption: this.dataService.res('Ubt-CreateMeasureTask-Total-Time'),
      width: "15%",
    },
    {
      dataField: 'executedTime',
      dataType: 'number',
      format: '#,##0 h',
      width: "15%",
      caption: this.dataService.res('Ubt-CreateMeasureTask-Executed-Time'),
    }
  ];
  private measureTasks = [];
  private subProjectId: string;
  public measureColumns: QueryColumn[] = [];
  public measureTaskColumns: QueryColumn[] = [];
  public reports = [];
  public subProjectColumns: QueryColumn[] = [];
  private subProjectResponsible: Person;
  public subProjectsViewModel: SubProjectViewModel[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    public subProjectService: SubProjectService,
    private router: Router,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
  ) {
    this.subProjectId = this.activatedRoute.snapshot.params.id;
    sessionStorage.setItem('subProjectId', this.subProjectId);
  }

  public customizeTooltip = (pointsInfo) => ({ text: `${parseInt(pointsInfo.originalValue)}%` });

  public ngOnInit(): void {
    this.showLoader = true;
    this.subscriptions.push(this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd && e.url.startsWith('/subProjectDetail')) {
        this.prepareSubProjectDetailReport();
      }
    }));
    if (this.appService.subProjectIds.length === 0) {
      this.prepareAllSubProjects();
    }
    this.prepareSubProjectDetailReport();

    if (this.dataService.cachedResponsiblesResponse.length === 0) {
      this.subProjectColumns.push(
        this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('ParentId', 'ParentId', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('IdRefresponsible', 'IdRefResponsible', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('IdRefResponsibleSupport', 'IdRefResponsibleSupport', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Highlights', 'Highlights', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Lowlights', 'Lowlights', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('ImplementationStatus', 'ImplementationStatus', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('SysDateInsert', 'SysDateInsert', QueryColumnSortOrder.None)
      )
      const oPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
      this.dataService.prepareStaffMembers().subscribe(data =>
        this.dataService.cachedResponsiblesResponse = data
      );
    }
  }

  public onCellPrepared = (e) => {
    if (e.rowType === 'header') {
      e.cellElement.style.textAlign = 'left';
    }
  }

  private prepareSubProjectDetailReport(): void {
    this.showLoader = true;

    this.prepareSubProjectDetailColumns();
    this.coreDataService.executeReadObjectsQuery(this.appService.subProjectRequestColumns).subscribe(subProject => {
      this.subProjectService.subProjectReports = [];
      this.reports = [];
      const subProjectreport = new SubProjectReport(null);
      subProjectreport.implementationStatus = subProject[0].ImplementationStatus;
      subProjectreport.subProjectLabel = subProject[0].Label;
      this.subProjectLabel = subProject[0].Label;

      if (subProject[0].xmtMeasure) {
        this.disablePtxExport = false;
        subProject[0].xmtMeasure.forEach(measure => {
          const report = new SubProjectReport(null);
          report.implementationStatus = subProject[0].ImplementationStatus;
          report.subProjectLabel = subProject[0].Label;
          report.measureId = measure.Id;
          report.measureTitle = measure.Title;
          if (
            Array.isArray(this.dataService.cachedResponsiblesResponse) &&
            this.dataService.cachedResponsiblesResponse.length > 0 &&
            Array.isArray(subProject) &&
            subProject.length > 0
          ) {
            this.subProjectResponsible = this.dataService.cachedResponsiblesResponse?.find(item => item.Id === subProject[0].IdRefResponsible);
          }
          if (measure.xmtMeasureProgress) {
            report.maturityLevel = measure.xmtMeasureProgress[0].MaturityLevel || 'N/A';
            report.progressPercentage = measure.xmtMeasureProgress[0].ProgressPercentage;
            report.decision = measure.xmtMeasureProgress[0].Decision;
            report.nextSteps = measure.xmtMeasureProgress[0].NextSteps;
            report.progress = measure.xmtMeasureProgress[0].Progress;
            report.riskAndChance = measure.xmtMeasureProgress[0].RiskAndChance;
          }
          if (measure.xmtMeasureTask) {
            const measureTasks = measure.xmtMeasureTask;
            measureTasks.forEach(measureTask => {
              report.totalTime = report.totalTime + measureTask.TotalTimeRequired || report.totalTime + 0;
              report.executedTime = report.executedTime + measureTask.TotalTimeExecuted || report.executedTime + 0;
            });
          }
          this.subProjectService.subProjectReports.push(report);
        });
      }
      else {
        this.disablePtxExport = true;
        this.subProjectService.subProjectReports.push(subProjectreport);
      }
      this.subProjectService.subProjectReports = Object.entries(this.subProjectService.subProjectReports);
      this.subProjectService.subProjectReports.forEach(data => {
        this.reports.push(data[1]);
        this.displayReport();
      });
    }, error => {
      console.error(error);
    }, () => {
      this.showLoader = false;
    });
  }

  private displayReport() {
    switch (this.reports.length) {
      case 0:
        this.showReportsDetails = false;
        break;
      case 1:
        if (!this.reports[0].progress
          && !this.reports[0].nextSteps
          && !this.reports[0].decision
          && !this.reports[0].riskAndChance
        ) {
          this.showReportsDetails = false;
        } else {
          this.showReportsDetails = true;
        }
        break;
      default:
        this.showReportsDetails = true;
    }
  }

  private prepareSubProjectDetailColumns(): void {
    const project: Query = new Query();
    const projectColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('IdRefResponsible', 'IdRefResponsible', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('IdRefResponsibleSupport', 'IdRefResponsibleSupport', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Highlights', 'Highlights', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Lowlights', 'Lowlights', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('ImplementationStatus', 'ImplementationStatus', QueryColumnSortOrder.None),
    ];
    project.ObjectType = ObjectKeys.SUBPROJECT;
    project.Columns = projectColumns;
    project.OPath = 'Id=' + "'" + sessionStorage.getItem('subProjectId') + "'";

    const measure: Query = new Query();
    measure.Name = 'xmtMeasure';
    measure.OPath = 'measures';
    const measureColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Title'),
    ];

    measure.Columns = measureColumns;

    const measureProgress: Query = new Query();
    measureProgress.Name = 'xmtMeasureProgress';
    measureProgress.OPath = 'MeasureProgresses';
    const measureProgressColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Decision', 'Decision'),
      this.coreDataService.createQueryColumn('MaturityLevel', 'MaturityLevel'),
      this.coreDataService.createQueryColumn('NextSteps', 'NextSteps'),
      this.coreDataService.createQueryColumn('Opportunities', 'Oppotunities'),
      this.coreDataService.createQueryColumn('Progress', 'Progress'),
      this.coreDataService.createQueryColumn('ProgressDate', 'ProgressDate'),
      this.coreDataService.createQueryColumn('ProgressPercentage', 'ProgressPercentage'),
      this.coreDataService.createQueryColumn('RiskAndChance', 'RiskAndChance'),
    ];
    measureProgress.Columns = measureProgressColumns;

    const measureTask: Query = new Query();
    measureTask.Name = 'xmtMeasureTask';
    measureTask.OPath = 'MeasureTasks';
    const measureTaskColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('TotalTimeRequired', 'TotalTimeRequired'),
      this.coreDataService.createQueryColumn('TotalTimeExecuted', 'TotalTimeExecuted')
    ];
    measureTask.Columns = measureTaskColumns;
    measure.ObjectQueries = [measureProgress, measureTask]
    project.ObjectQueries = [measure];
    this.appService.subProjectRequestColumns.ObjectQueries = [project];
  }

  private prepareAllSubProjects(): void {
    const subProjectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None)
    ]
    const oPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, subProjectColumns, oPath).subscribe((subProjects) => {
      this.appService.subProjectIds = [];
      if (Array.isArray(subProjects) && subProjects.length > 0) {
        subProjects.forEach((subProject, index) => {
          if (this.subProjectId === subProject.Id) this.appService.currentSubProjectIndex = index;
          this.appService.subProjectIds.push(subProject.Id)
        });
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    });
  }


  public backToSubProjectList(): void {
    this.router.navigateByUrl('subProject');
  }


  public goToNext(index: number): void {
    this.showLoader = true;
    this.appService.currentSubProjectIndex = this.appService.currentSubProjectIndex + index;
    sessionStorage.setItem('subProjectId', this.appService.subProjectIds[this.appService.currentSubProjectIndex]);
    this.router.navigate(['subProjectDetail', this.appService.subProjectIds[this.appService.currentSubProjectIndex]]);
    (index === 1) ? this.gallery.instance.nextItem(true) : this.gallery.instance.prevItem(true);
    this.displayReport();
    this.showLoader = false;
  }

  public prepareContextMenu(event): void {
    if (event.row.rowType === "data") {
      event.items = [{
        text: this.dataService.res('Ubt-measure-Go-To') + ' ' + event.row.data?.measureTitle,
        icon: 'assets/images/measure-context-menu.svg',
        onItemClick: (e) => {
          this.appService.reportIndex = this.gallery.selectedIndex;
          this.router.navigate(['measureDetail', 'subProject', event.row.data?.measureId]);
        }
      }];
    }
  }

  public onCellClick = (e) => {
    if (e.columnIndex !== 5 && e.rowType === "data") {
      this.router.navigate(['measureDetail', 'subProject', e.row.data?.measureId]);
    }
  }

  public ngOnDestroy(): void {
    sessionStorage.removeItem('subProjectId');
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public createPPt(): void {
    const ppt = new pptxgen();
    ppt.layout = 'LAYOUT_WIDE';
    ppt.defineSlideMaster({
      title: "MASTER_SLIDE",
      background: { color: "FFFFFF" },
      objects: [
        { rect: { x: 0.0, y: 7, w: "100%", h: 0.6, fill: { color: "00569c" } } },
        { image: { x: "80%", y: "1%", w: 1.67, h: 1, path: "assets/images/i-unit.png" } },
      ],
      slideNumber: { x: 0.3, y: "90%" },
    });
    const detailSlide = ppt.addSlide();
    detailSlide.addText(this.dataService.res('Ubt-PowerPoint-Subproject-Name') + ': ' + this.subProjectLabel,
      {
        bold: true,
        x: "30%",
        y: "50%",
        color: "ffffff",
        fontSize: 24
      }
    );

    detailSlide.addText("i-unit group + 49 531 180 59 300 info(at)i-unit.de Steintorwall 3 38100 Braunschweig", {
      x: "33%",
      y: 7,
      w: "100%",
      color: "ffffff",
      h: 0.6
    })

    const responsible = [
      [
        { text: this.dataService.res('Ubt-MeasureDetail-Responsible'), options: { fontSize: 16 } },
        { text: ':', options: { fontSize: 16 } },
        { text: this.subProjectResponsible?.FirstName + ', ' + this.subProjectResponsible?.LastName, options: { fontSize: 16 } }
      ],
      [
        { text: this.dataService.res('Ubt-SubProject-Detail-Export-Date'), options: { fontSize: 16 } },
        { text: ':', options: { fontSize: 14 } },
        { text: new Date().toDateString(), options: { fontSize: 16 } }
      ]
    ]

    detailSlide.background = { color: "00569c" }
    detailSlide.addTable(responsible, {
      w: 7,
      x: "60%",
      y: "5%",
      bold: true,
      color: "ffffff",
      colW: [1.66, 0.228, 4.672],
    });
    detailSlide.addImage({ x: "5%", y: "5%", w: "15%", h: "15%", path: "assets/images/i-unit.png" })
    const slide = ppt.addSlide({ masterName: "MASTER_SLIDE" });
    slide.slideNumber = { x: "5%", y: "95%", fontFace: "Courier", fontSize: 14, color: "ffffff" };

    slide.addText(this.subProjectLabel,
      {
        bold: true,
        align: 'left',
        w: "90%",
        x: "4%",
        y: "5%"
      }
    );

    const subProjectReportColumn = [];
    subProjectReportColumn.push([
      {
        text: this.dataService.res('Ubt-CreateMeasureTask-Measure'),
        options: { align: 'left', fontSize: 14, bold: true },
      },
      {
        text: this.dataService.res('Ubt-MeasureDetail-Chart-Progress'),
        options: { align: 'center', fontSize: 14, bold: true },
      },
      {
        text: this.dataService.res('Ubt-MeasureDetail-Maturity-Level'),
        options: { align: 'center', fontSize: 14, bold: true },
      },
      {
        text: this.dataService.res('Ubt-CreateMeasureTask-Total-Time'),
        options: { align: 'center', fontSize: 14, bold: true },
      },
      {
        text: this.dataService.res('Ubt-CreateMeasureTask-Executed-Time'),
        options: { align: 'center', fontSize: 14, bold: true },
      },
    ]);

    const subProjectReport = this.reports;
    subProjectReport.forEach((subProjectReport) => {
      subProjectReportColumn.push([
        { text: subProjectReport.measureTitle, options: { align: 'left', fontSize: 14 } },
        { text: (subProjectReport.progressPercentage) ? subProjectReport.progressPercentage + ' %' : 0 + ' %', options: { align: 'center', fontSize: 14 } },
        { text: (subProjectReport.maturityLevel) ? subProjectReport.maturityLevel : 'HG1', options: { align: 'center', fontSize: 14 } },
        { text: (subProjectReport.totalTime) ? subProjectReport.totalTime : ' 0 h', options: { align: 'center', fontSize: 14 } },
        { text: (subProjectReport.executedTime) ? subProjectReport.executedTime : ' 0 h', options: { align: 'center', fontSize: 14 } },
      ]);
    });

    slide.addTable(subProjectReportColumn, {
      w: "90%",
      x: "5%",
      y: "12%",
      autoPage: true,
      autoPageSlideStartY: 1.5,
      border: { type: 'solid', pt: 1 },
    });

    const slideProgress = ppt.addSlide({ masterName: "MASTER_SLIDE" });
    slideProgress.slideNumber = { x: "5%", y: "95%", fontFace: "Courier", fontSize: 14, color: "ffffff" };
    slideProgress.addText(this.dataService.res('Ubt-MeasureDetail-Progress'), {
      bold: true,
      align: 'left',
      w: "90%",
      x: "4%",
      y: "5%"
    });

    slideProgress.addText(this.reports, {
      valign: 'top',
      w: "90%",
      x: "5%",
      y: "8%"
    });

    const progress = [];
    this.reports.forEach((item) => {
      const cells = [
        { text: item.measureTitle, options: { fontSize: 14 } },
        { text: item.progress, options: { fontSize: 14 } }
      ];
      progress.push(cells);
    });

    slideProgress.addTable(progress, {
      w: "90%",
      x: "5%",
      y: "12%",
      autoPage: true,
      autoPageSlideStartY: 1.5,
      border: { type: 'solid', pt: 1 },
    });


    const slideDecisionClarification = ppt.addSlide({ masterName: "MASTER_SLIDE" });
    slideDecisionClarification.slideNumber = { x: "5%", y: "95%", fontFace: "Courier", fontSize: 14, color: "ffffff" };
    slideDecisionClarification.addText(this.dataService.res('Ubt-MeasureDetail-Decision-Clarification'), {
      bold: true,
      align: 'left',
      w: "90%",
      x: "5%",
      y: "5%"
    });

    slideDecisionClarification.addText(this.reports, {
      valign: 'top',
      w: "90%",
      x: "5%",
      y: "8%"
    });

    const decisionClarification = [];
    this.reports.forEach((item) => {
      const cellsrDecision = [
        { text: item.measureTitle, options: { fontSize: 14 } },
        { text: item.decision, options: { fontSize: 14 } }
      ];
      decisionClarification.push(cellsrDecision);
    });

    slideDecisionClarification.addTable(decisionClarification, {
      w: "90%",
      x: "5%",
      y: "12%",
      autoPage: true,
      autoPageSlideStartY: 1.5,
      border: { type: 'solid', pt: 1 },
    });

    const slideNextSteps = ppt.addSlide({ masterName: "MASTER_SLIDE" });
    slideNextSteps.slideNumber = { x: "5%", y: "95%", fontFace: "Courier", fontSize: 14, color: "ffffff" };
    slideNextSteps.addText(this.dataService.res('Ubt-MeasureDetail-Next-Steps'), {
      bold: true,
      align: 'left',
      w: "90%",
      x: "4%",
      y: "5%",
    });

    slideNextSteps.addText(this.reports, {
      valign: 'top',
      w: "90%",
      x: "5%",
      y: "8%",
    });

    const nextSteps = [];
    this.reports.forEach((item) => {
      const cellsrDecision = [
        { text: item.measureTitle, options: { fontSize: 14 } },
        { text: item.nextSteps, options: { fontSize: 14 } }
      ];
      nextSteps.push(cellsrDecision);
    });

    slideNextSteps.addTable(nextSteps, {
      w: "90%",
      x: "5%",
      y: "12%",
      autoPage: true,
      autoPageSlideStartY: 1.5,
      border: { type: 'solid', pt: 1 }
    });

    const slideRisksOpportunities = ppt.addSlide({ masterName: "MASTER_SLIDE" });
    slideRisksOpportunities.slideNumber = { x: "5%", y: "95%", fontFace: "Courier", fontSize: 14, color: "ffffff" };
    slideRisksOpportunities.addText(this.dataService.res('Ubt-MeasureDetail-Risks-Opportunities'), {
      bold: true,
      align: 'left',
      w: "90%",
      x: "4%",
      y: "5%",
    });

    slideRisksOpportunities.addText(this.reports, {
      valign: 'top',
      w: "90%",
      x: "5%",
      y: "8%",
    });

    const risksOpportunities = [];
    this.reports.forEach((item) => {
      const cellsrDecision = [
        { text: item.measureTitle, options: { fontSize: 14 } },
        { text: item.nextSteps, options: { fontSize: 14 } }
      ];
      risksOpportunities.push(cellsrDecision);
    });

    slideRisksOpportunities.addTable(risksOpportunities, {
      w: "90%",
      x: "5%",
      y: "12%",
      autoPage: true,
      autoPageSlideStartY: 1.5,
      border: { type: 'solid', pt: 1 }
    });

    ppt.writeFile({ fileName: 'SubProject Report.pptx' });
  }
}
export class SubProjectReport {
  measureId?: string;
  implementationStatus?: string;
  measureTitle?: string;
  progress?: string;
  nextSteps?: string;
  riskAndChance?: string;
  decision?: string;
  progressPercentage?: number;
  maturityLevel?: string;
  totalTime?: number = 0;
  executedTime?: number = 0;
  subProjectLabel?: string;
  responsible?: Person;

  constructor(data: SubProjectReport) {
    Object.assign(this, data);
  }
}

export enum StatePath {
  GRAY = "assets/images/gray.svg",
  GREEN = "assets/images/green.svg",
  YELLOW = "assets/images/yellow.svg",
  RED = "assets/images/red.svg"
}
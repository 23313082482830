import { GeoDsMeta } from "@geods/base";
import { Measure } from "./measure.model";
import { RiskIssue } from "./risk-issue.model";


export class RiskAssesmentMeasure extends GeoDsMeta {
    Risk: RiskIssue;
    Measure: Measure;
    Value: number;
    Type: RiskAssesmentMeasureType;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

export enum RiskAssesmentMeasureType {
    ASSIGNED = 'Assigned',
    SUGGESTION = 'Suggestion'
}
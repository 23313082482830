<ng-container *ngIf="isAuthenticated(); else unauthenticated">
  <dx-drawer fxFill fxLayout="column" class="drawer" position="before" [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode" [revealMode]="menuRevealMode" [minSize]="minMenuSize" [shading]="shaderEnabled"
    [(opened)]="menuOpened" [ngClass]="menuOpened">
    <!-- SideNav -->
    <div fxLayout="column" id="menu" *dxTemplate="let dataMenu of 'panel'">
      <dx-toolbar fxLayout="row" id="navigation-header">
        <dxi-item location="left" cssClass="header-logo" html={{ubtWhiteLogo}}></dxi-item>
      </dx-toolbar>
      <div fxLayout="row" class="menu-container">
        <dx-tooltip [target]="target" [(visible)]="isVisible">
          <div *dxTemplate="let data = data of 'content'">
            <span>{{tooltipContent}}</span>
          </div>
        </dx-tooltip>
        <div fxLayout="column" fxFlex="calc(100% - 150px)">
          <div class="menu-container">
            <dx-tree-view #treeview [items]="navigation" keyExpr="path" selectionMode="single"
              [focusStateEnabled]="false" expandEvent="click" (onInitialized)="onContentReady($event)"
              (onItemClick)="onMenuItemClick($event)" width="auto" class="tree-view-height"
              [ngClass]="!menuOpened ? 'close-menu-tree-list' : 'open-menu-tree-list'">
            </dx-tree-view>
          </div>
          <dxi-item [items]="contextMenuItems">
            <div fxLayout="row" fxLayoutAlign="none center" (click)="logout()"
              [ngClass]="!menuOpened ? 'logout-bloc-close-menu' : 'logout-bloc-open-menu'">
              <img class="logout-icon" src="assets/images/Logout_.svg" />
              <span> {{dataService.res('Ubt-Menu-Logout')}} </span>
            </div>
          </dxi-item>

        </div>
      </div>
    </div>
    <!-- End SideNav -->
    <div fxFlex="100" fxLayout="column" class="container" id="container">
      <header fxLayout="row" id="header">
        <dx-toolbar class="header-toolbar">
          <dxi-item location="before" widget="dxButton" widget="dxButton">
            <button mat-button class="menu-button" (click)="toggleMenu($event)">
              <mat-icon class="menu-open-icon">
                {{ 'menu' }}</mat-icon>
            </button>
          </dxi-item>
          <dxi-item location="before">
            <div class="border-left"></div>
          </dxi-item>
          <dxi-item location="before">
            <div [ngClass]="!isTitleSelected ? 'menu-title-selected' : 'menu-title'">
              {{title}}
            </div>
          </dxi-item>
          <dxi-item location="before">
            <div Class="menu-company-name">
              {{localStorageItem()}}
              <i class="dx-icon-chevronright" *ngIf="measureName"></i>
              <span class="company-name-position"> {{measureName}}</span>
            </div>
          </dxi-item>
          <dxi-item location="after">
            <button mat-button class="menu-button favorite" (click)="openFavoriteSideBar($event)">
              <mat-icon class="favorite-button-icon" svgIcon="favorite"></mat-icon>
            </button>
          </dxi-item>
          <dxi-item location="after">
            <img class="notification" src="assets/images/NotificationB.svg" />
          </dxi-item>
          <dxi-item location="after">
            <div class="user-info">
              <div class="image-container" *ngIf="currentUser?.hasAvatar"></div>
              <div *ngIf="!currentUser?.hasAvatar">
                <img class="user-logo " src="assets/images/Avatar.svg" />
              </div>
              <div class="user-name">
                {{ currentUser?.displayName }}
              </div>
            </div>
          </dxi-item>

          <dxi-item location="after" menuItemTemplate="menuItem">
            <img class="contextMenuHeader" src="assets/images/accordion-open.svg" />
          </dxi-item>
        </dx-toolbar>
        <dx-context-menu class="settings-context-menu" target=".contextMenuHeader" showEvent="dxclick"
          [items]="contextMenuItems" [position]="{ my: 'top right', at: 'bottom right' }">
        </dx-context-menu>
      </header>
      <!-- Body -->
      <router-outlet fxFill fxLayout="row" (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>
    <ubt-geods-favorite-list #favorites *ngIf="isFavoriteOpen" (closebtn)="closeFavorites()">
    </ubt-geods-favorite-list>
  </dx-drawer>

</ng-container>
<ww-toast #toast></ww-toast>
<ng-template #unauthenticated>
  <core-authentication-login #login appLogo="{{iunitlogo}}" [stylingMode]="'underlined'" [enableForgotPassword]="false"
    [hasInfoLinks]="false">
  </core-authentication-login>
</ng-template>
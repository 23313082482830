import { ProfileInfo } from '@wissenswerft/core/authentication';

export class Comment {
    id: number;
    additionalContexts?: string[];
    attachments?: [];
    author: ProfileInfo;
    created: Date;
    lastEdited?: Date;
    mainContext?: string;
    mainContextLabel?: string;
    state?: any; 
    text: string;
    avatar? : string;
    
    constructor(data) {
        Object.assign(this, data);
    }
}

export enum DeleteMode {
    Static = 'static',
    Toggle = 'toggle',
    SlideButton = 'slideButton',
    SlideItem = 'slideItem',
    Swipe = 'swipe',
    Context = 'context'
}

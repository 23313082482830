import { GeoDsMeta, Person } from "@geods/base";
import { MeasureBenefit } from "./measure-benefit.model";
import { MeasureDefinition } from "./measure-definition.model";
import { MeasureProgress } from "./measure-progress.model";
import { MeasureTask } from "./measure-task.model";
import { Resource } from "./resource.model";
import { SubProject } from "./sub-project.model";
import { Tag } from "./tag.model";

export class Measure extends GeoDsMeta {
    ParentId: string;
    AreaManager: Person;
    CompletionDate: Date;
    Concept: string;
    CurrentGrade: string;
    CurrentState: string;
    DefenseMeasures: string;
    Dependencies: string;
    EndDate: Date;
    ExpectedResults: string;
    Goal: string;
    InitialSituation: string;
    MeasureDefinition: MeasureDefinition[];
    MeasureProgress: MeasureProgress[];
    MeasureBenefits: MeasureBenefit[];
    MeasureDocuments: Document[];
    MeasureResources: Resource[];
    MeasureTags: Tag[];
    MeasureTasks: MeasureTask[];
    OriginalTargetDate: Date;
    PrevDect: PrevDect;
    OrgaTech: OrgaTech;
    Responsible: Person;
    ReleaseDate: Date;
    ResponsibleConsultant: Person;
    Status: Status;
    StartDate: Date;
    SubProject: SubProject;
    Title: string;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

export enum Status {
    Open = 'Open',
    InProgress = 'InProgress',
    Done = 'Done'
}

export enum PrevDect {
    PREVENTIVE = 'Preventive',
    DETECTIVE = 'Detective'
}

export enum OrgaTech {
    ORGANIZATIONAL = 'Organizational',
    TECHNICAL = 'Technical'
}

import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ConvertHelper, GeoDsCoreDataService, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel } from '@wissenswerft/core/data';
import { Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../services/data.service';
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { exportDataGrid as exportDataGridPDF } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridExcel } from 'devextreme/excel_exporter';
import { jsPDF } from 'jspdf';
import { DatePipe, formatDate } from '@angular/common';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
@Component({
  selector: 'ubt-geods-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  public allReports: CustomReport[] = [];
  public showAllReports = true;
  public convertHelper = ConvertHelper;
  public showLoader = false;
  private reportColumns = new QueryObjectsModel();
  private measures = [];
  private subscriptions: Subscription[] = [];
  public tabs = [
    {
      id: 0,
      text: this.dataService.res('Ubt-Report-All'),
      icon: 'assets/images/Termine.svg',
    },
    {
      id: 1,
      text: this.dataService.res('Ubt-Report-Actual'),
      icon: 'assets/images/measure.svg',
      content: 'Comment tab content',
    }
  ];

  constructor(
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    public appService: AppService,
    private router: Router,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) public locale: string,
  ) {

  }

  public ngOnInit(): void {
    this.showLoader = true;
    this.prepareMeasuresReport();
  }

  public cellPrepared(event) {
    if (event.column.dataField === "progress" || event.column.dataField === "nextSteps"
      || event.column.dataField === "riskAndChance" || event.column.dataField === "decision") {
      event.cellElement.style.height = 100
      event.cellElement.style.whiteSpace = "normal"
      event.cellElement.style.overflowWrap = 'break-word'
    }
    if (event.rowType === 'header') {
      event.cellElement.style.textAlign = 'left';
    }
  }

  public openTab = (event): void => {
    switch (event.itemData.id) {
      case 0:
        this.prepareReports(false);
        break;
      case 1:
        this.prepareReports(true);
        break;
    }
  }

  private prepareReports(loadLatestReports: boolean): void {
    this.allReports = [];
    if (Array.isArray(this.measures) && this.measures.length > 0) {
      this.measures.forEach(item => {
        if (Array.isArray(item?.MeasureProgress) && item.MeasureProgress.length > 0) {
          if (loadLatestReports === false) {
            item.MeasureProgress.sort((a, b) => new Date(b.ProgressDate).valueOf() - new Date(a.ProgressDate).valueOf()).forEach((meausreProgress, index) => {
              const progress: CustomReport = new CustomReport();
              const currentState: ReportStatus = new ReportStatus();
              progress.subProject = item.SubProjectLabel;
              progress.measure = item.Label;
              progress.measureId = item.Id;
              progress.nextSteps = meausreProgress.NextSteps;
              progress.progress = meausreProgress.Progress;
              progress.decision = meausreProgress.Decision;
              progress.opportunities = meausreProgress.Opportunities;
              progress.riskAndChance = meausreProgress.RiskAndChance;
              progress.progressPercentage = meausreProgress.ProgressPercentage;
              progress.maturityLevel = meausreProgress.MaturityLevel;
              progress.date = this.convertHelper.toDate(meausreProgress?.ProgressDate.toString());
              currentState.date = formatDate(meausreProgress.ProgressDate?.toString(), 'MM.dd.yyyy', this.locale).toString();
              currentState.maturity = meausreProgress.MaturityLevel;
              currentState.progress = meausreProgress.ProgressPercentage;
              currentState.state = (item.MeasureProgress[index + 1] !== undefined) ?
                ((meausreProgress.ProgressPercentage >= item.MeasureProgress[index + 1].ProgressPercentage) ? true : false) : true;
              progress.status = currentState;
              this.allReports.push(progress);
            });
            this.showLoader = false;
          }
          else {
            const progress: CustomReport = new CustomReport();
            const currentState: ReportStatus = new ReportStatus();
            progress.subProject = item.SubProjectLabel;
            progress.measure = item.Label;
            progress.measureId = item.Id;
            item.MeasureProgress.sort((a, b) => new Date(b.ProgressDate).valueOf() - new Date(a.ProgressDate).valueOf());
            item.MeasureProgress.sort((a, b) => new Date(a.ProgressDate).valueOf() - new Date(b.ProgressDate).valueOf());
            progress.nextSteps = item.MeasureProgress[item.MeasureProgress.length - 1].NextSteps;
            progress.progress = item.MeasureProgress[item.MeasureProgress.length - 1].Progress;
            progress.decision = item.MeasureProgress[item.MeasureProgress.length - 1].decision;
            progress.riskAndChance = item.MeasureProgress[item.MeasureProgress.length - 1].RiskAndChance;
            progress.opportunities = item.MeasureProgress[item.MeasureProgress.length - 1].Opportunities;
            progress.progressPercentage = item.MeasureProgress[item.MeasureProgress.length - 1].ProgressPercentage;
            progress.date = this.convertHelper.toDate(item.MeasureProgress[item.MeasureProgress.length - 1]?.ProgressDate.toString());
            progress.maturityLevel = item.MeasureProgress[item.MeasureProgress.length - 1].MaturityLevel;
            currentState.date = item.MeasureProgress[item.MeasureProgress.length - 1].ProgressDate;
            currentState.maturity = item.MeasureProgress[item.MeasureProgress.length - 1].MaturityLevel;
            currentState.progress = item.MeasureProgress[item.MeasureProgress.length - 1].ProgressPercentage;
            currentState.state = ((item.MeasureProgress[item.MeasureProgress.length - 1].ProgressPercentage
              >= item.MeasureProgress[item.MeasureProgress.length - 2].ProgressPercentage) ? true : false);
            progress.status = currentState;
            this.allReports.push(progress);
          }
        } else {
          this.showLoader = false;
          this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-Measure-Message'), type: ToastType.INFO });
        }
      })
    } else {
      this.showLoader = false;
      this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
    }
  }

  public openMeasureDetail = (event): void => {
    //this.appService.callNotification({ message: 'Not Yet Implemented', type: ToastType.INFO });
    this.dataService.appService.showMeasureIcon = true;
    this.router.navigate(['measureDetail', 'reports', event.row.data.measureId]);
  }

  public prepareMeasuresReport(): void {
    const measure: Query = new Query();
    const measureColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('!.Designation', 'SubProjectLabel'),
    ];
    measure.ObjectType = ObjectKeys.MEASURE;
    measure.OPath = '!.ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    measure.Columns = measureColumns;
    const measureProgress: Query = new Query();
    measureProgress.Name = 'MeasureProgress';
    measureProgress.OPath = 'MeasureProgresses';
    const measureProgressColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Decision', 'Decision'),
      this.coreDataService.createQueryColumn('MaturityLevel', 'MaturityLevel'),
      this.coreDataService.createQueryColumn('NextSteps', 'NextSteps'),
      this.coreDataService.createQueryColumn('Opportunities', 'Oppotunities'),
      this.coreDataService.createQueryColumn('Progress', 'Progress'),
      this.coreDataService.createQueryColumn('ProgressDate', 'ProgressDate'),
      this.coreDataService.createQueryColumn('ProgressPercentage', 'ProgressPercentage'),
      this.coreDataService.createQueryColumn('RiskAndChance', 'RiskAndChance'),
    ];
    measureProgress.Columns = measureProgressColumns;
    measure.ObjectQueries = [measureProgress]
    this.reportColumns.ObjectQueries = [measure];
    this.coreDataService.executeReadObjectsQuery(this.reportColumns).subscribe(data => {
      this.measures = data;
      this.prepareReports(false);
    }, error => {
      console.error(error);
      this.showLoader = false;
    })
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe() });
  }

  public onExporting(e): void {
    const myDate = this.datePipe.transform(new Date(), '/yyyy-MM-dd');
    if (e.format == 'pdf') {
      const doc = new jsPDF();
      exportDataGridPDF({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      }).then(() => {
        doc.save('report/' + myDate + '.pdf');
      });
    }
    else {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGridExcel({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'report/' + myDate + '.xlsx');
        });
      });
      e.cancel = true;
    }
  }
}

@Pipe({ name: 'gridCellData' })
export class GridCellDataPipe implements PipeTransform {
  transform(gridData: any) {
    return gridData.data['status'];
  }
}

export class CustomReport {
  measureId: string;
  measure: string;
  subProject: string;
  nextSteps: string;
  opportunities: string;
  progress: string;
  decision: string;
  maturityLevel: string
  progressPercentage: number
  date: string;
  riskAndChance: string;
  status: ReportStatus;
}

export class ReportStatus {
  date: string | Date;
  progress: number;
  maturity: string;
  state: boolean;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FavoriteFilterListComponent } from './favorite-filter-list/favorite-filter-list.component';
import { FavoritesComponent } from './favorites.component';
import { DxListModule, DxLoadIndicatorModule } from "devextreme-angular";
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  imports: [CommonModule, MatListModule, MatIconModule, FlexLayoutModule, DxListModule, DxLoadIndicatorModule, MatTooltipModule],
  declarations: [FavoritesComponent, FavoriteFilterListComponent],
  exports: [FavoritesComponent]
})
export class FavoritesModule { }

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DxToastComponent } from 'devextreme-angular';
import { IdeTimerService } from '../services/idleTimer.service';
import { UserService } from '../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ResourceManager } from '@wissenswerft/core/resources';
import { BACKEND_SYSTEM } from '@wissenswerft/core/configuration';
import { map, switchMap } from 'rxjs/operators';
import { ProfileInfo } from '../models/profile-info.model';

@Component({
  selector: 'core-authentication-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('toast') dxToast: DxToastComponent;
  @Input() appTitle: string;
  @Input() hasInfoLinks: boolean;
  @Input() stylingMode: string = 'filled';
  @Input() appLogo: string;
  @Input() enableForgotPassword: boolean = true;
  @Input() showLogoOnTitle: boolean = false;
  @Output() onProfileReady = new EventEmitter<ProfileInfo>();
  toastPosition = { at: 'top center', my: 'top center', offset: '0 10' };
  public username: string;
  public password: string;
  public res = ResourceManager.getResources('login');
  loading = false;
  formData: any = {};
  Form: FormGroup;
  errorExist = false;
  public readonly usernameIcon = {
    icon: `<svg id="Calque_18" data-name="Calque 18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.62 112"><defs><style>.cls-1{fill:gray;}</style></defs><path class="cls-1" d="M72.93,85.89V78.78c9.49-5.34,17.23-18.67,17.23-32C90.16,25.36,90.16,8,64.31,8S38.46,25.36,38.46,46.77c0,13.34,7.74,26.67,17.24,32v7.11C26.47,88.29,4,102.64,4,120H124.62C124.62,102.64,102.16,88.29,72.93,85.89Z" transform="translate(-4 -8)"/></svg>`,
    type: 'normal',
    stylingMode: "text",
    hoverStateEnabled: false,
    disabled: true,
  };

  public readonly passwordIcon = {
    icon: `<svg id="Calque_19" data-name="Calque 19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.62 127.56">
  <defs><style>.password{fill:gray;}</style></defs>
  <path class="password" d="M87.92,38.09C87.92,26,77.21,16.17,64,16.17S40.08,26,40.08,38.09V56H87.92Zm27.9,89.69H12.18a4,4,0,0,1-4-4V60a4,4,0,0,1,4-4h12v-16a39.86,39.86,0,0,1,79.72,0V56h12a4,4,0,0,1,4,4V123.8a4,4,0,0,1-4,4" transform="translate(-8.19 -0.22)"/></svg>`,
    type: 'normal',
    stylingMode: "text",
    hoverStateEnabled: false,
    disabled: true,
  };
  constructor(private userService: UserService, private router: Router, private ideTimerService: IdeTimerService, public sanitizer: DomSanitizer, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    // this.userService.clearLoginInfo();
  }

  public onEditorEnterKey(e): void {
    if (e.event.key == 'Enter') {
      this.onSubmit(e);
    }
  }

  onSubmit(e) {
    this.userService.login({ email: this.username, password: this.password }).pipe(switchMap((token) => {
      return this.userService.getProfileInformations()
        .pipe(map(profile => ({ token, profile })));
    }))
      .subscribe(({ token, profile }) => {
        localStorage.setItem('userLogin', this.username);
        if (this.userService.configService.backEndSystem === BACKEND_SYSTEM.GEODS) {
          this.userService.currentUserLogged(profile.DisplayText);
        } else {
          this.onProfileReady.emit(profile);
          this.userService.currentUserLogged(profile.lastname + ', ' + profile.firstname);
        }
        this.router.navigate([localStorage.getItem('returnUrl') || '/']).then(_ => console.log('You are secure now!'));
      }, error => {
        console.error(error);
        this.dxToast.visible = true;
      }, () => {
        localStorage.removeItem('returnUrl');
      });
  }

}
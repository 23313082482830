<div fxLayout="column" class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <h2 class="title-style ">{{dataService.res('Ubt-TreeList-Project')}}</h2>
    </div>
    <div fxLayout="column" class="add-button-style">
      <dx-speed-dial-action icon="add" (onClick)="openCreateProject()">
      </dx-speed-dial-action>
    </div>
  </div>
  <div class="content-block project-container">
    <div class="dx-card responsive-paddings card-radius">
      <ww-grid #projectGrid class="grid-group-by-Style chech-box-Column  custome-export-style"
        [enableStateStorage]="false" [noDataText]="noDataText" [allowExportSelectedData]="false"
        [hoverStateEnabled]="false" [showRowLines]="false" [allowAdding]="false" [columnsHeader]='columnsHeader'
        [gridData]='projectList' [allowExportSelectedData]="true" [enableColumnChooser]="false"
        [enableHeaderFilter]="true" (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)">
      </ww-grid>
    </div>
  </div>
</div>
<dx-popup #deleteProjectpopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center" [height]="'auto'"
  [title]="dataService.res('Ubt-Company-Title-Confirm-Delete')" [showCloseButton]="true">
  <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"> {{
      dataService.res('Ubt-Project-Remove-Message') }}</div>
    <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
      <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
        (onClick)="confirmRemoveProject($event)">
      </dx-button>
      <dx-button class="cancel-button" [text]="dataService.res('Ubt-Company-Button-Cancel')" (onClick)="abort()">
      </dx-button>
    </dxi-item>
  </div>
</dx-popup>
<dx-popup #createProjectpopup [showTitle]="true" [title]="title" [position]="'center'" [deferRendering]="'false'"
  [height]="'auto'" [width]="'50%'">
  <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxFlexFill fxLayoutGap="10px">
    <form action="post" (submit)="createProject($event)">
      <dx-form #form id="form" [(formData)]="project" [colCount]="1">
        <dxi-item [isRequired]="true">
          <dx-text-box [stylingMode]="'outlined'" [width]="'100%'" [(value)]="project.Label"
            [label]="dataService.res('Ubt-MeasureTask-Label')" [labelMode]="'floating'">
          </dx-text-box>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="3" [cssClass]="'form-fields-row'">
          <dxi-item>
            <dx-date-box class="date-box-style" fxFlex="100" [label]="dataService.res('Ubt-MeasureTask-Start-Date')"
              [(value)]="project.StartDate" type="date" [labelMode]="'floating'">
            </dx-date-box>
          </dxi-item>
          <dxi-item>
            <dx-date-box class="date-box-style" fxFlex="100" [label]="dataService.res('Ubt-MeasureTask-End-Date')"
              [(value)]="project.EndDate" type="date" [labelMode]="'floating'" [min]="project.StartDate">
            </dx-date-box>
          </dxi-item>
          <dxi-item>
            <dx-text-box [stylingMode]="'outlined'" [width]="'100%'" [(value)]="project.State"
              [label]="dataService.res('Ubt-MeasureTask-State')" [labelMode]="'floating'">
            </dx-text-box>
          </dxi-item>
        </dxi-item>
        <dxi-item>
          <dx-text-area [height]="'200px'" [stylingMode]="'outlined'" [width]="'100%'" [(value)]="project.Description"
            [label]="dataService.res('Ubt-MeasureDetail-Description')" [labelMode]="'floating'">
          </dx-text-area>
        </dxi-item>

        <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
          <dxi-item *ngIf="!isUpdating" itemType="button" [cssClass]="'submit-button'"
            [buttonOptions]="createButtonOptions">
          </dxi-item>
          <dxi-item *ngIf="isUpdating" itemType="button" [cssClass]="'submit-button'"
            [buttonOptions]="updateButtonOptions">
          </dxi-item>
          <dxi-item itemType="button" [cssClass]="'cancel-button'" [buttonOptions]="cancelButtonOptions">
          </dxi-item>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
<ubt-loader *ngIf="showLoader"></ubt-loader>

<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
  [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
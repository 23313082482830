<div class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="title-style ">{{dataService.res('Ubt-TreeList-Roles')}}</h2>
        </div>
        <div fxLayout="column" class="add-button-style">
            <dx-speed-dial-action icon="add" (onClick)="assignNewRole()">
            </dx-speed-dial-action>
        </div>
    </div>
    <div class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <ww-grid #rolesGrid class="grid-group-by-Style" [enableStateStorage]="false" [gridData]="availableRoles"
                [enableColumnChooser]="false" [noDataText]="noDataText" (onSaving)="saveRow($event)"
                (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)"
                (onRowRemoved)="removeRow($event)" [allowExportSelectedData]="false" [rowAlternationEnabled]="false"
                [columnsHeader]='columnsHeader' [showRowLines]="false" [editingMode]="'row'" [allowAdding]="false">
            </ww-grid>
        </div>
    </div>
</div>
<ubt-loader *ngIf="showLoader"></ubt-loader>

<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
    [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CoreAuthenticationModule } from '@wissenswerft/core/authentication';
import {
  BACKEND_SYSTEM,
  CoreConfigService,
  CoreConfigurationModule
} from '@wissenswerft/core/configuration';
import { DataService } from '../app/core/services/data.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxDrawerModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxToolbarModule,
  DxTooltipModule,
  DxTreeViewModule
} from 'devextreme-angular';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import { FavoriteListModule } from './core/favorite-list/favorite-list.module';
import { CompaniesComponent } from './core/companies/companies.component';
import { CompaniesModule } from 'libs/companies/src/lib/companies.module';
import { ScreenService } from './core/services/screen.service';

@NgModule({
  declarations: [AppComponent, CompaniesComponent],
  imports: [
    AppRoutingModule,
    CompaniesModule,
    BrowserModule,
    CommonModule,
    WwLibraryModule,
    CommonModule,
    CoreAuthenticationModule,
    CoreConfigurationModule,
    DxTooltipModule,
    DxToolbarModule,
    DxDrawerModule,
    DxTreeViewModule,
    DxContextMenuModule,
    DxSwitchModule,
    DxScrollViewModule,
    MatIconModule,
    DxPopupModule,
    DxButtonModule,
    DxSelectBoxModule,
    FavoriteListModule
  ],
  providers: [
    DatePipe,
    ScreenService,
    CoreConfigService,
    DataService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig
        .loadConfiguration(environment.API_URL, BACKEND_SYSTEM.GEODS)
        .then((config) => {
          resolve(config);
        });
    });
  };
}

import { Injectable } from "@angular/core";
import { Address, MailAndWebAddress, PhoneNumber } from "@geods/base";
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, TargetColumnValue, TargetObjectData } from "@wissenswerft/core/data";
import { Observable } from "rxjs";
import { ObjectKeys } from "../../services/data.service";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    public isUpdating: boolean = false;
    public companyColumns: QueryColumn[] = [
        this.coreDataService.createQueryColumn('Id', 'Id'),
        this.coreDataService.createQueryColumn('Name', 'Name'),
        this.coreDataService.createQueryColumn('MatchCode', 'MatchCode'),
        this.coreDataService.createQueryColumn('Status', 'Status'),
        this.coreDataService.createQueryColumn('CompanyTitle', 'CompanyTitle'),
        this.coreDataService.createQueryColumn('Street', 'Street'),
        this.coreDataService.createQueryColumn('CustomerNumber', 'CustomerNumber'),
        this.coreDataService.createQueryColumn('HouseNumber', 'HouseNumber'),
        this.coreDataService.createQueryColumn('ZIP', 'ZIP'),
        this.coreDataService.createQueryColumn('Country', 'Country'),
        this.coreDataService.createQueryColumn('City', 'City'),
        this.coreDataService.createQueryColumn('SysDateInsert', 'SysDateInsert', QueryColumnSortOrder.Descending),
        this.coreDataService.createQueryColumn('SysTimeInsert', 'SysTimeInsert', QueryColumnSortOrder.Descending),
        this.coreDataService.createQueryColumn(
            "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
            'IsFavorite'
        ),
        this.coreDataService.createQueryColumn(
            "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
            'BoxDetailId'
        )
    ];

    constructor(
        private coreDataService: GeoDsCoreDataService,
        private persistenceService: GeoDsPersistenceService
    ) { }

    public prepareCompanyColumns(oPath?: string) {
        const address: Query = new Query();
        address.ObjectType = ObjectKeys.ADDRESS;
        address.Columns = this.companyColumns;

        if (oPath) address.OPath = oPath;

        const mailAddressColumns = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('MainAdress', 'MainAddress'),
            this.coreDataService.createQueryColumn('Value', 'Value'),
            this.coreDataService.createQueryColumn('Type', 'Type')
        ];

        const mailAddress: Query = new Query();
        mailAddress.ObjectType = ObjectKeys.MAILADDRESS;
        mailAddress.Name = 'MailAddress';
        mailAddress.OPath = 'MailAddresses';
        mailAddress.Columns = mailAddressColumns;

        const phoneNumberColumns: Array<QueryColumn> = [
            this.coreDataService.createQueryColumn('Id', 'Id'),
            this.coreDataService.createQueryColumn('Designation', 'Designation'),
            this.coreDataService.createQueryColumn('CountryCode', 'CountryCode'),
            this.coreDataService.createQueryColumn('LocalAreaCode', 'LocalAreaCode'),
            this.coreDataService.createQueryColumn('Mediation', 'Mediation'),
            this.coreDataService.createQueryColumn('Number', 'Number'),
            this.coreDataService.createQueryColumn('Show', 'Show'),
            this.coreDataService.createQueryColumn('Type', 'Type'),
            this.coreDataService.createQueryColumn('ParentId', 'ParentId'),
            this.coreDataService.createQueryColumn("Concat(CaseTrue(CountryCode LIKE '+%', CountryCode, Concat('+', CountryCode)), ' ', LocalAreaCode, ' ', Number, CaseTrue(Not(IsNull(Mediation)), Concat('-', Mediation), ''))", 'FullNumber')
        ];

        const phone: Query = new Query();
        phone.ObjectType = ObjectKeys.PHONENUMBER;
        phone.Name = 'PhoneNumbers';
        phone.OPath = 'PhoneNumbers[Type=0]';
        phone.Columns = phoneNumberColumns;

        address.ObjectQueries = [mailAddress, phone];
        const companyDetailsColumns = new QueryObjectsModel();
        companyDetailsColumns.ObjectQueries = [address];
        return companyDetailsColumns;
    }

    public persistCompany(company: Address): Observable<any> {
        const companyColumns: TargetColumnValue[] = [
            { Name: 'Name', Value: company.Name },
            { Name: 'Status', Value: company.Status },
            { Name: 'CustomerNumber', Value: company.CustomerNumber },
            { Name: 'Country', Value: <string>company.Country },
            { Name: 'City', Value: company.City },
            { Name: 'Street', Value: company.Street },
            { Name: 'MatchCode', Value: company.MatchCode },
            { Name: 'HouseNumber', Value: company.HouseNumber },
            { Name: 'ZIP', Value: company.ZIP },
            { Name: 'CompanyTitle', Value: company.CompanyTitle }
        ];
        const companyPersistQuery: TargetObjectData = new TargetObjectData();
        companyPersistQuery.ObjectKey = new ObjectKey();
        companyPersistQuery.ObjectKey.ObjectType = ObjectKeys.ADDRESS;
        companyPersistQuery.TargetColumns = companyColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = companyPersistQuery;
        if (company.Id) {
            companyPersistQuery.Mode = PersistMode.Update;
            companyPersistQuery.ObjectKey.Id = company.Id;
        } else {
            companyPersistQuery.Mode = PersistMode.Insert;
            
        }

        return this.persistenceService.executePersistObjectQuery(persistObject);
    }

    public persistPhoneNumber(phone: PhoneNumber, companyId: string, phoneFaxId?: string): Observable<any> {
        const numberColumns: TargetColumnValue[] = [
            { Name: 'CountryCode', Value: phone.CountryCode },
            { Name: 'LocalAreaCode', Value: phone.LocalAreaCode },
            { Name: 'Number', Value: phone.Number },
            { Name: 'Mediation', Value: phone.Mediation },
        ];
        const numberPersistQuery: TargetObjectData = new TargetObjectData();
        numberPersistQuery.ObjectKey = new ObjectKey();
        numberPersistQuery.ObjectKey.ObjectType = ObjectKeys.PHONENUMBER;
        numberPersistQuery.TargetColumns = numberColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = numberPersistQuery;
        if (phoneFaxId) {
            numberPersistQuery.Mode = PersistMode.Update;
            numberPersistQuery.ObjectKey.Id = phoneFaxId;
        } else {
            numberPersistQuery.Mode = PersistMode.Insert;
            numberColumns.push(
                { Name: 'ParentId', Value: companyId },
                { Name: 'Type', Value: phone.Type },
                { Name: 'Show', Value: true }
            );
        }

        return this.persistenceService.executePersistObjectQuery(persistObject)
    }

    public persistMailAddress(email: MailAndWebAddress, companyId: string, emailId?: string): Observable<any> {
        const mailColumns: TargetColumnValue[] = [
            { Name: 'Value', Value: email.Value },
        ];
        const mailPersistQuery: TargetObjectData = new TargetObjectData();
        mailPersistQuery.ObjectKey = new ObjectKey();
        mailPersistQuery.ObjectKey.ObjectType = ObjectKeys.MAILADDRESS;
        mailPersistQuery.TargetColumns = mailColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = mailPersistQuery;
        if (emailId) {
            mailPersistQuery.Mode = PersistMode.Update;
            mailPersistQuery.ObjectKey.Id = emailId;
        } else {
            mailPersistQuery.Mode = PersistMode.Insert;
            mailColumns.push(
                { Name: 'MainAdress', Value: true },
                { Name: 'ParentId', Value: companyId },
            );
        }
        return this.persistenceService.executePersistObjectQuery(persistObject);
    }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { Project } from '@xmt-models';
import { CompaniesService, IProjectDependency, ObjectKeys } from '../companies.service';
import { ResourceManager } from '@wissenswerft/core/resources';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'wissenswerft-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent {
  @Output() selectLastProjectUpdated = new EventEmitter<Project>();

    public projectList: Project[] = [];
    public res = ResourceManager.getResources("companies");
    private subscriptions: Subscription[] = [];
    public projectColumns = [
        this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('!.name', 'ParentAddress', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('ParentId', 'ParentId', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('SysDateInsert', 'Date', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('State', 'State', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Count(subProjects)', 'subProjectsCount', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Count(subProjects.Measures)', 'measuresCount', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('Count(subProjects.Measures.MeasureTasks)', 'tasksCount', QueryColumnSortOrder.None),
        this.coreDataService.createQueryColumn('SysDateUpdate', 'SysDateUpdate', QueryColumnSortOrder.Descending),
        this.coreDataService.createQueryColumn("FormatTime(SysTimeUpdate, 'hh:mm:ss')", 'SysTimeUpdate', QueryColumnSortOrder.Descending),
    ];

    constructor(
        private coreDataService: GeoDsCoreDataService,
        private companiesService: CompaniesService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) { }

    public ngOnInit(): void {
        this.getProjectsData();
    }

    private getProjectsData() {
        this.companiesService.readObjects<Project[]>(ObjectKeys.PROJECT, this.projectColumns).subscribe(projects => {           
            if (Array.isArray(projects) && projects.length > 0) {
                this.projectList = projects;
            } else {
                this.projectList = [];
            }
        }, error => {
            console.error(error);
            this.cdr.markForCheck();
        }, () => {
            this.cdr.markForCheck();
        })
    }

    public selectProject(project: Project) {
        this.router.navigate(['dashboard']);
        this.companiesService.selectedProjectData(project);
        this.selectLastProjectUpdated.emit(project);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
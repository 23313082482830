<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="title-style ">{{dataService.res('Ubt-TreeList-Measures')}}</h2>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" class="add-button-style">
            <span #myMeasuresButton fxLayoutAlign="end" class="buttons-container " (click)="loadMyMeasures()">
                {{myMeasureTitle}} </span>
        </div>
    </div>
    <div fxLayout="row" class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <ww-grid #measureGrid class="grid-group-by-Style chech-box-Column custome-export-style"
                [enableStateStorage]="false" [exportFormat]="['pdf', 'excel']" [rowAlternationEnabled]="false"
                [noDataText]="noDataText" (onCellHoverChanged)="hoverTooltip($event)"
                (onCellPrepared)="measureCellTemplate($event)" (onExporting)="exporting($event)"
                [fileComponent]="componentName" [fileDate]="fileDate" [allowExportSelectedData]="false"
                [showRowLines]="false" [allowAdding]="false" [showfilterrow]="{ visible: true }"
                [selectionMode]="selectionMode" [columnsHeader]='columnsHeader' [gridData]='measures'
                [allowExportSelectedData]="true" (onCellPrepared)="onCellPrepared($event)"
                (onContentReady)="onContentReady($event)" (onRowDblClick)="openMeasureDetails($event)"
                (onCellClick)="onCellClick($event)" [enableHeaderFilter]="true">
            </ww-grid>
            <dx-tooltip [target]="TooltipTarget" [visible]="isVisible">
                <div *dxTemplate="let data of 'content'">
                    <div class="tooltip-target">
                        {{ ToolTipText }}
                    </div>
                </div>
            </dx-tooltip>
            <dx-speed-dial-action icon="add" [index]="1" (onClick)="openCreationPopup()">
            </dx-speed-dial-action>
        </div>
    </div>
    <dx-popup #measureCreationForm fxLayout="column" fxLayoutAlign="center center" [width]="'60%'" [height]="'auto'"
        [showTitle]="true" title="{{dataService.res('Ubt-MeasureList-Create-Measure')}}" [showCloseButton]="true">
        <ubt-geods-create-measure (closePopup)="closeCreationPopup()"></ubt-geods-create-measure>
    </dx-popup>
    <dx-popup #confirmDeletePopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center" [height]="'auto'"
        [title]="dataService.res('Ubt-MeasureList-Confirm-Delete')" [showCloseButton]="true">
        <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutGap="20px" fxFlexFill>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"> {{
                dataService.res('Ubt-MeasureList-Delete-Message') }}</div>
            <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
                <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
                    (onClick)="removeMeasure()">
                </dx-button>
                <dx-button class="cancel-button" [text]="dataService.res('Ubt-Company-Button-Cancel')"
                    (onClick)="abortDelete()">
                </dx-button>
            </dxi-item>
        </div>
    </dx-popup>
</div>

<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
    [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
<ubt-loader *ngIf="showLoader"></ubt-loader>
<dx-accordion class="accordion-style container" [collapsible]="true" [selectedIndex]="-1" [width]="'100%'"
    [animationDuration]="300" [visible]="true" [multiple]="false" [dataSource]="['Test']">
    <div *dxTemplate="let company of 'title'" class="dx-accordion-item-title">
        {{this.dataService.res("Ubt-MeasureDetail-Status-Report")}}
    </div>

    <div fxLayout="column" fxLayoutGap="15px" class="card-paddings container-card" *dxTemplate="let company of 'item'">

        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div fxLayoutAlign="start">
                <ng-container>
                    <div fxLayout="row">
                        <img class="date-start-style" src="assets/images/dateStart.svg">
                        <div *ngIf="lastMeasureProgress.ProgressDate">
                            {{convertHelper.toDate(lastMeasureProgress?.ProgressDate?.toString())}}</div>
                        <div *ngIf="!lastMeasureProgress.ProgressDate">{{convertHelper.toDate(date)}}</div>
                    </div>
                </ng-container>
            </div>
            <div fxLayoutAlign="end">
                <dx-check-box class="check-box-style" [(value)]="workOnLastStatus" [height]="'100%'" [width]="'100%'"
                    [disabled]="isNewMeasureTask || measureProgress.length === 0"
                    text="{{dataService.res('Ubt-MeasureDetail-Duplicate-Content')}}">
                </dx-check-box>
                <div>
                    <dx-button [stylingMode]="'text'" [icon]="'assets/images/plus.svg'" [type]="'normal'"
                        [hint]="dataService.res('Ubt-MeasureDetail-New-Status')" (onClick)="clearProgressFileds()"
                        [disabled]="isNewMeasureTask || measureProgress.length === 0" [height]="'100%'">
                    </dx-button>
                </div>

                <dx-button [stylingMode]="'text'" [icon]="'assets/images/Delete.svg'" [type]="'normal'"
                    [hint]="dataService.res('Ubt-MeasureDetail-Delete')" class="assgin-progress-button"
                    (onClick)="openConfirmTaskDialog()"
                    [disabled]="measureProgress.length==0 || !lastMeasureProgress.Id" [height]="'100%'">
                </dx-button>
                <dx-button [stylingMode]="'text'" [icon]="'assets/images/save.svg'" [type]="'normal'"
                    class="assgin-progress-button" [hint]="dataService.res('Ubt-MeasureDetail-Save')" [height]="'100%'"
                    (onClick)="persistStatusReport()" [disabled]="disableSave">
                </dx-button>
                <dx-button stylingMode="text" [icon]="'assets/images/navigate_left.svg'" type="default"
                    [hint]="dataService.res('Ubt-Navigation-Previous')" [disabled]="this.appService.currentMeasureProgressIndex === 0 || appService.currentMeasureProgressIndex === -1 
                    || measureProgress.length === 1 || measureProgress.length === 0 || isNewMeasureTask"
                    (onClick)="navigateToMeasureProgress(-1)" [hoverStateEnabled]="false">
                </dx-button>
                <dx-button stylingMode="text" [icon]="'assets/images/navigate_right.svg'" type="default"
                    [hint]="dataService.res('Ubt-Navigation-Next')" [disabled]="this.appService.currentMeasureProgressIndex === measureProgress?.length - 1||
                     this.appService.currentMeasureProgressIndex === -1 || measureProgress.length === 1 ||
                    measureProgress.length === 0 || isNewMeasureTask" (onClick)="navigateToMeasureProgress(1)"
                    [hoverStateEnabled]="false">
                </dx-button>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
            <dx-text-area fxFlex="50" height='150px' [(value)]="lastMeasureProgress.Progress"
                (onValueChanged)="verifyChanges($event, 'Progress')"
                [label]="dataService.res('Ubt-MeasureDetail-Progress')" [labelMode]="'floating'">
            </dx-text-area>
            <dx-text-area fxFlex="50" height='150px' [(value)]="lastMeasureProgress.NextSteps"
                (onValueChanged)="verifyChanges($event, 'NextSteps')"
                [label]="dataService.res('Ubt-MeasureDetail-Next-Steps')" [labelMode]="'floating'">
            </dx-text-area>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
            <dx-text-area fxFlex="50" height='150px' [(value)]="lastMeasureProgress.RiskAndChance"
                (onValueChanged)="verifyChanges($event, 'RiskAndChance')"
                [label]="dataService.res('Ubt-MeasureDetail-Risks-Opportunities')" [labelMode]="'floating'">
            </dx-text-area>
            <dx-text-area fxFlex="50" height='150px' [(value)]="lastMeasureProgress.Decision"
                (onValueChanged)="verifyChanges($event, 'Decision')"
                [label]="dataService.res('Ubt-MeasureDetail-Decision-Clarification')" [labelMode]="'floating'">
            </dx-text-area>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div fxFlex="33">
                <dx-number-box #progressField [(value)]="lastMeasureProgress.ProgressPercentage"
                    [stylingMode]="'outlined'" [format]="dataService.customizePercentageText"
                    [label]="dataService.res('Ubt-MeasureDetail-Chart-Progress')" [labelMode]="'floating'"
                    (onValueChanged)="verifyChanges($event, 'ProgressPercentage')">
                </dx-number-box>
            </div>
            <div fxFlex="33" fxLayoutAlign="start center">
                <dx-select-box class="drop-down-style" [stylingMode]="'outlined'" [width]="'100%'" [height]="'100%'"
                    [(value)]="lastMeasureProgress.MaturityLevel" [dataSource]="measureService.maturityLevel"
                    [label]="dataService.res('Ubt-MeasureDetail-Maturity-Level')" [labelMode]="'floating'"
                    displayExpr="name" valueExpr="value" (onValueChanged)="verifyChanges($event, 'MaturityLevel')">
                </dx-select-box>
            </div>
            <div fxFlex="33">
                <h4 *ngIf="lastMeasureProgress.MaturityLevel">{{lastMeasureProgress.MaturityLevel}}:
                    {{dataService.res('Ubt-MeasureDetail-'+lastMeasureProgress.MaturityLevel)}}
                </h4>
            </div>
        </div>
    </div>
</dx-accordion>

<dx-popup #confirmDeletePopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center" [height]="'auto'"
    [title]="dataService.res('Ubt-MeasureList-Confirm-Delete')" [showCloseButton]="true">

    <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            {{dataService.res('Ubt-MeasureList-Delete-Message')}}</div>

        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
            <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
                [text]="dataService.res('Ubt-MeasureList-Yes')" (onClick)="deleteStatusReport()">
            </dx-button>
            <dx-button class="cancel-button" [text]="dataService.res('Ubt-MeasureList-No')"
                (onClick)="abortTaskDelete()">
            </dx-button>
        </dxi-item>
    </div>



</dx-popup>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Collaborator, MeasureTask, Resource } from '@xmt-models';
import { DataService, ObjectKeys } from 'apps/ubt-geods/src/app/core/services/data.service';
import { Subscription } from 'rxjs';
import { MeasureService } from '../../../measure.service';

@Component({
  selector: 'ubt-geods-collaborators',
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaboratorsComponent implements OnChanges {
  @ViewChild('collaboratorGrid') collaboratorGrid: GridComponent;
  @Input('dataSource') collaboratorsData: Collaborator[] = [];
  @Input('currentMeasureTask') measureTask: MeasureTask = new MeasureTask(null);
  @Input('assignedResources') assignedResources: Resource[] = [];
  @Input() totalTimePerCollaboratorPerMonth: Map<string, number>;
  @Output() onCalculateOverallTime: EventEmitter<number> = new EventEmitter();
  
  public  noDataText = "Keine Daten";
  private subscriptions: Subscription[] = [];


  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public measureService: MeasureService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public ngOnChanges(): void {
    if (this.collaboratorsData === undefined) this.collaboratorsData = [];
    this.cdr.markForCheck();
  }

  public ngOnInit(): void {
  }

  public cellPrepared = (e) => {
    if (e.column.dataField === 'TimeExecuted' && e.rowType === 'data') {
      if (e.row.data.TimeExecuted > e.row.data.TimePlanned) {
        e.cellElement.style.color = 'red';
      } else {
        e.cellElement.style.color = 'green';
      }
    }
  }

  public removeCollaborator(e) {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.COLLABORATOR, e.data.Id).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.cdr.markForCheck();
    }, error => {
      console.error(error)
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public saveCollaborator(e) {
    if (e.changes[0].type !== 'remove' && this.measureService.isCreatingSubTask === false) {
      const newCollaborator = e.changes[0].data;
      const collaboratorPersistQuery: TargetObjectData = new TargetObjectData();
      collaboratorPersistQuery.ObjectKey = new ObjectKey();
      collaboratorPersistQuery.ObjectKey.ObjectType = ObjectKeys.COLLABORATOR;
      const collaboratorColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: this.measureTask.Id },
        { Name: 'IdRefMeasureResource', Value: newCollaborator.IdRefMember },
        { Name: 'TimeExecuted', Value: newCollaborator.TimeExecuted },
        { Name: 'TimePlanned', Value: newCollaborator.TimePlanned },
        { Name: 'Period', Value: new Date().toDateString() }
      ];
      if (e.changes[0].type === 'update') {
        collaboratorPersistQuery.Mode = PersistMode.Update;
        collaboratorPersistQuery.ObjectKey.Id = newCollaborator.Id;
      } else {
        collaboratorPersistQuery.Mode = PersistMode.Insert;
      }
      collaboratorPersistQuery.TargetColumns = collaboratorColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = collaboratorPersistQuery;
      this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((collaborator) => {
        this.cdr.markForCheck();
        if (!this.collaboratorsData[this.collaboratorsData.length - 1]?.Id) {
          this.collaboratorsData[this.collaboratorsData.length - 1].Id = collaborator.Id;
        }
        this.measureService.realTimecalculateTotalTime(true);
        this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
      }));
    } else if (e.changes[0].type !== 'remove' && this.measureService.isCreatingSubTask === true) {
      this.measureTask.Collaborators = this.collaboratorsData;
    }
  }

  public addCollaborator(): void {
    this.collaboratorGrid.addRow();
  }


  public calculateOverallTime(): void {
    const sumWithInitial = this.collaboratorsData.reduce(
      (previousValue, currentValue) => previousValue + parseInt(currentValue.TimePlanned.toString()),
      0
    );
    this.onCalculateOverallTime.emit(sumWithInitial);
  }


  public ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}

export const VISIBLE_PROPERTIES = [
  'name', 'timePlanned', 'timeExecuted'
];
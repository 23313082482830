<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <h2 class="title-style">
        {{ dataService.res('Ubt-MeasureTask-SubProject') }}
      </h2>
    </div>
    <div fxLayout="column" class="add-button-style">
      <dx-speed-dial-action icon="add" (onClick)="openSubProjectDialog()">
      </dx-speed-dial-action>
    </div>
  </div>
  <div class="content-block">
    <div class="dx-card responsive-paddings card-radius">
      <ww-grid #subProjectGrid class="grid-group-by-Style" [gridData]="subProjectsViewModel" [noDataText]="noDataText"
        [enableExport]="false" [enableColumnChooser]="true" (onRowRemoved)="(false)" [height]="'100%'"
        [rowAlternationEnabled]="false" [columnsHeader]="columnsHeader" [allowColumnReordering]="false"
        [allowDeleting]="false" [enableLoadPanel]="true" [allowAdding]="false" [pageable]="false" [groupable]="false"
        (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)">
      </ww-grid>
    </div>
    <dx-popup #createSubProjectPopup class="window-title-background" [showTitle]="true" [showCloseButton]="true"
      [width]="'50%'" [height]="'auto'">
      <div class="dx-card popup-card-paddings card-radius">
        <div fxLayout="column" fxFlexFill fxLayoutGap="10px">
          <form action="post" (submit)="createSubProject($event)">
            <dx-form #form id="form" [(formData)]="subProject" [colCount]="1">
              <dxi-item [isRequired]="true">
                <dx-text-box [(value)]="subProject.Label" [stylingMode]="'outlined'" [width]="'100%'"
                  [label]="dataService.res('Ubt-MeasureTask-Label')" [labelMode]="'floating'">
                </dx-text-box>
                <dxi-validation-rule type="required"
                  message="{{ dataService.res('Ubt-validator-RequiredField') }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                <dxi-item>
                  <dx-select-box #responsible fxFill class="drop-down-style" [searchMode]="'contains'"
                    [(value)]="subProject.IdRefResponsible" valueExpr="Id" [searchEnabled]="true"
                    [label]="dataService.res('Ubt-CreateMeasure-Responsible')"
                    [displayExpr]="dataService.getResponsibleFullName"
                    [dataSource]="dataService.cachedResponsiblesResponse" [labelMode]="'floating'">
                  </dx-select-box>
                </dxi-item>
                <dxi-item>
                  <dx-select-box #support fxFill class="drop-down-style" [searchMode]="'contains'"
                    [(value)]="subProject.IdRefResponsibleSupport" valueExpr="Id" [searchEnabled]="true" [label]="
                      dataService.res(
                        'Ubt-SubProject-Create-Responsible-Support'
                      )
                    " [displayExpr]="dataService.getResponsibleFullName"
                    [dataSource]="dataService.cachedResponsiblesResponse" [labelMode]="'floating'">
                  </dx-select-box>
                </dxi-item>
              </dxi-item>
              <dxi-item itemType="group" [colCount]="2" [cssClass]="'form-fields-row'">
                <!-- waiting for clarification for those 2 fields -->
                <!-- <dxi-item>
                                    <dx-select-box fxFill class="drop-down-style" [searchMode]="'contains'"
                                        [(value)]="subProject.Company" [searchEnabled]="true"
                                        (onValueChanged)="prepareDepartments($event.value)"
                                        [label]=" dataService.res('Ubt-SubProject-Create-Company')" valueExpr='Id'
                                        [dataSource]="dataService.cachedCompany" [displayExpr]="'Name'"
                                        [labelMode]="'floating'">
                                    </dx-select-box>
                                </dxi-item>
                                <dxi-item>
                                    <dx-select-box #departmentSelectBox fxFill class="drop-down-style"
                                        [searchMode]="'contains'" [disabled]="disableDepartment"
                                        [(value)]="subProject.Department" valueExpr="Id" [searchEnabled]="true"
                                        [label]=" dataService.res('Ubt-SubProject-Create-Department')"
                                        [dataSource]="dataService.cachedDepartement" [displayExpr]="'Designation'"
                                        [labelMode]="'floating'">
                                    </dx-select-box>
                                </dxi-item> -->
              </dxi-item>
              <dxi-item>
                <dx-text-area [minHeight]="'100px'" [height]="'100px'" fxFill [stylingMode]="'outlined'"
                  [(value)]="subProject.Highlights" [labelMode]="'floating'"
                  [label]="dataService.res('Ubt-SubProject-Create-HighLights')">
                </dx-text-area>
              </dxi-item>
              <dxi-item>
                <dx-text-area [minHeight]="'100px'" [height]="'100px'" fxFill [stylingMode]="'outlined'"
                  [(value)]="subProject.Lowlights" [labelMode]="'floating'"
                  [label]="dataService.res('Ubt-SubProject-Create-Lowlights')">
                </dx-text-area>
              </dxi-item>
              <dxi-item>
                <dx-select-box [dataSource]="subProjectStates" [displayExpr]="'text'" [valueExpr]="'value'"
                  [(value)]="subProject.ImplementationStatus" fieldTemplate="field" [label]="
                    dataService.res(
                      'Ubt-CreateSubproject-Implementation-Status'
                    )
                  ">
                  <div *dxTemplate="let data of 'field'">
                    <div class="custom-item">
                      <div class="circle" [ngClass]="getRadioStyle(data)"></div>
                      <dx-text-box class="product-name" [value]="data && data.text"
                        [inputAttr]="{ 'aria-label': 'Name' }" [readOnly]="true"></dx-text-box>
                    </div>
                  </div>
                  <div *dxTemplate="let data of 'item'">
                    <div class="custom-item">
                      <div class="circle" [ngClass]="getRadioStyle(data)"></div>

                      <div class="product-name">

                        {{ data.text }}
                      </div>
                    </div>
                  </div>
                </dx-select-box>
              </dxi-item>
              <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                <dxi-item itemType="button" [cssClass]="'submit-button'" [buttonOptions]="{
                    useSubmitBehavior: true,
                    text: this.dataService.res(
                      'Ubt-Form-' + this.submitButtonText
                    )
                  }">
                </dxi-item>
                <dxi-item itemType="button" [cssClass]="'cancel-button'" [buttonOptions]="cancelButtonOptions">
                </dxi-item>
              </dxi-item>
            </dx-form>
          </form>
        </div>
      </div>
    </dx-popup>
  </div>
</div>

<dx-popup #confirmSubProjectPopup [width]="'25%'" fxLayout="column" fxLayoutAlign="center center" [height]="'auto'"
  [title]="dataService.res('Ubt-Company-Title-Confirm-Delete')" [showCloseButton]="true">
  <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      {{ dataService.res('Ubt-MeasureList-Delete-Message') }}
    </div>
    <dxi-item itemType="group" [cssClass]="'form-buttons-container'" fxLayout="row" fxLayoutAlign="end end"
      fxLayoutGap="20px">
      <dx-button class="submit-button" [text]="dataService.res('Ubt-Company-Icon-Delete')"
        (onClick)="removeRow($event)">
      </dx-button>
      <dx-button class="cancel-button" [text]="dataService.res('Ubt-Company-Button-Cancel')"
        (onClick)="abortTaskDelete()">
      </dx-button>
    </dxi-item>
  </div>
</dx-popup>
<ubt-loader *ngIf="showLoader"></ubt-loader>

<dx-context-menu #contextMenu id="contextMenu" class="abc-context-menu" showEvent="abcclick" [items]="contextMenuItems"
  [position]="{ my: 'top right', at: 'bottom right' }">
</dx-context-menu>
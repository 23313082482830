<div class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row">
        <div fxLayout="column">
            <h2 class="title-style">{{dataService.res('Ubt-TreeList-Reports')}}</h2>
        </div>
    </div>
    <div class="content-block">
        <div class="dx-card responsive-paddings card-radius">
            <dx-tabs [dataSource]="tabs" [selectedIndex]="0" (onItemClick)="openTab($event)"></dx-tabs>
            <dx-data-grid #allReportsGrid id="gridContainer" (onExporting)="onExporting($event)" [showBorders]="true"
                class="grid-group-by-Style custome-export-style icons-report-style" [dataSource]="allReports"
                [height]="'93%'" [showRowLines]="false" (onCellPrepared)="cellPrepared($event)" [showRowLines]="true"
                [showColumnLines]="true" [filterPanel]="false" [rowAlternationEnabled]="false">
                <dxo-export [enabled]="true" [formats]="['pdf', 'excel']"
                    [allowExportSelectedData]="false"></dxo-export>
                <dxi-column caption="{{ dataService.res('Ubt-MeasureDetail-Progress') }}" dataField="progress"
                    visibleIndex="0" width="20%" dataType="string" alignment="left">
                </dxi-column>
                <dxi-column caption="{{ dataService.res('Ubt-MeasureDetail-Next-Steps') }}" dataField="nextSteps"
                    visibleIndex="1" width="20%" dataType="string" alignment="left">
                </dxi-column>
                <dxi-column caption="{{ dataService.res('Ubt-TreeList-Decision') }}" dataField="decision"
                    visibleIndex="2" width="20%" dataType="string" alignment="left">
                </dxi-column>
                <dxi-column caption="{{ dataService.res('Ubt-MeasureDetail-Opportunities') }}" dataField="opportunities"
                    visibleIndex="4" width="20%" dataType="string" alignment="left" [visible]="false">
                </dxi-column>
                <dxi-column caption="{{ dataService.res('Ubt-MeasureDetail-Risks-and-Chances') }}"
                    dataField="riskAndChance" visibleIndex="3" width="20%" dataType="string" alignment="left">
                </dxi-column>
                <dxi-column caption="{{ dataService.res('Ubt-MeasureTask-SubProject') }}" dataField="subProject"
                    width="auto" groupIndex="0" dataType="string" alignment="center">
                </dxi-column>
                <dxi-column caption="{{ dataService.res('Ubt-CreateMeasureTask-Measure') }}" dataField="measure"
                    width="auto" groupIndex="1" dataType="string" alignment="center">
                </dxi-column>
                <dxi-column caption="Status" dataField="status" visibleIndex="5" width="10%" dataType="string"
                    [allowGrouping]="false" cellTemplate="diffCellTemplate" alignment="center">
                </dxi-column>
                <dxi-column type="buttons" [width]="'70px'" visibleIndex="10">
                    <dxi-button hint="{{ dataService.res('Ubt-Go-To-Measure') }}" icon="edit" [visible]="true"
                        [onClick]="openMeasureDetail">
                    </dxi-button>
                </dxi-column>
                <div *dxTemplate="let statut of 'diffCellTemplate'" class=" status-align"
                    [class]="(statut | gridCellData).state === true ? 'inc' : 'dec'">
                    <div sclass=" status-bold" class="current-value">{{(statut | gridCellData).date}}</div>
                    <div class="diff">{{ (statut | gridCellData).progress }}</div>
                    <div class="status-grouped-by ">
                        {{(statut | gridCellData).maturity}}
                    </div>
                </div>
                <dxo-pager [visible]="false">
                </dxo-pager>
                <dxo-column-chooser [enabled]="true">
                </dxo-column-chooser>
                <dxo-header-filter #dxHeaderFilter [visible]="false"></dxo-header-filter>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [expandMode]="'rowClick'"></dxo-grouping>
                <dxo-search-panel [visible]="true">
                </dxo-search-panel>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
                <dxo-summary>
                    <dxi-group-item column="subProject" summaryType="count">
                    </dxi-group-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>
</div>
<ubt-loader *ngIf="showLoader"></ubt-loader>
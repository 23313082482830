<div class="project-card" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px"
    *ngFor="let project of projectList;" (click)="selectProject(project)">
        <div fxFill fxLayout="column" fxFlex="60">
            <div fxFill fxLayout="row">
                <div fxLayout="column" fxFlex="70">
                    <div fxLayout="row" fxFlex="50">
                        <div class="campany-title"><span>{{project.ParentAddress}}</span></div>
                    </div>
                    <div fxLayout="row" fxFlex="50">
                        <div class="project-title"><span> {{project.Label}}</span></div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="30">
                    <div class="date-style"><span>{{project.StartDate}}</span></div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxFlex="40">
            <div fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
                <div class="item-style"> {{res('Company-Sub-Project')}} {{project.subProjectsCount}}</div>
            </div>
            <div fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
                <div class="item-style"> {{res('Company-Measures')}} {{project.measuresCount}}</div>
            </div>
            <div fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
                <div class="item-style"> {{res('Company-Milestones')}} {{project.tasksCount}}</div>
            </div>
        </div>
</div>
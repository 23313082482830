<dx-accordion class="accordion-style description-container" [collapsible]="true" [selectedIndex]="0" [width]="'100%'"
    [animationDuration]="300" [visible]="true" [multiple]="false" [dataSource]="['Test']">
    <div *dxTemplate="let description of 'title'" class="dx-accordion-item-title">
        {{this.dataService.res("Ubt-MeasureDetail-Description")}}
    </div>
    <div fxLayout="column" class="card-paddings container-card" fxLayoutGap="15px"
        *dxTemplate="let description of 'item'">
        <div fxLayout="row" fxLayoutGap="15px">
            <div fxFlex="50" fxLayout="column">
                <div>
                    <span
                        class="html-editor-title-style">{{dataService.res('Ubt-MeasureDetail-Initial-Situation')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(currentMeasure.InitialSituation,'InitialSituation')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='200px' [(value)]="currentMeasure.InitialSituation"
                    (onFocusIn)="focusIn(currentMeasure.InitialSituation)" (onFocusOut)="focusOut('InitialSituation')"
                    (onValueChanged)="verifyChanges(currentMeasure.InitialSituation)">
                </dx-html-editor>
            </div>

            <div fxFlex="50" fxLayout="column">
                <div>
                    <span
                        class="html-editor-title-style">{{dataService.res('Ubt-MeasureDetail-Expected-Results')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(currentMeasure.ExpectedResults,'ExpectedResults')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='200px' [(value)]="currentMeasure.ExpectedResults"
                    (onFocusIn)="focusIn(currentMeasure.ExpectedResults)" (onFocusOut)="focusOut('ExpectedResults')"
                    (onValueChanged)="verifyChanges(currentMeasure.ExpectedResults)">
                </dx-html-editor>
            </div>

        </div>
        <div fxLayout="row" fxLayoutGap="15px">
            <div fxFlex="50" fxLayout="column">
                <div>
                    <span class="html-editor-title-style">{{dataService.res('Ubt-CreateMeasure-Objective')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(currentMeasure.Goal,'Goal')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='200px' [(value)]="currentMeasure.Goal"
                    (onFocusIn)="focusIn(currentMeasure.Goal)" (onFocusOut)="focusOut('Goal')"
                    (onValueChanged)="verifyChanges(currentMeasure.Goal)">
                </dx-html-editor>
            </div>
            <div fxFlex="50" fxLayout="column">
                <div>
                    <span class="html-editor-title-style">{{dataService.res('Ubt-MeasureDetail-Dependencies')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(currentMeasure.Dependencies,'Dependencies')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='200px' [(value)]="currentMeasure.Dependencies"
                    (onFocusIn)="focusIn(currentMeasure.Dependencies)" (onFocusOut)="focusOut('Dependencies')"
                    (onValueChanged)="verifyChanges(currentMeasure.Dependencies)">
                </dx-html-editor>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
            <div fxFlex="50" fxLayout="column">
                <div>
                    <span class="html-editor-title-style">{{dataService.res('Ubt-Measure-List-Concept')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(currentMeasure.Concept,'Concept')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='200px' [(value)]="currentMeasure.Concept"
                    (onFocusIn)="focusIn(currentMeasure.Concept)" (onFocusOut)="focusOut('Concept')"
                    (onValueChanged)="verifyChanges(currentMeasure.Concept)">
                </dx-html-editor>
            </div>
            <div fxFlex="50" fxLayout="column">
                <div>
                    <span
                        class="html-editor-title-style">{{dataService.res('Ubt-MeasureDetail-Defense-Measures')}}</span>
                    <span fxLayout="row" class="dx-icon-edit edit-button-style"
                        (click)="openPopup(currentMeasure.DefenseMeasures, 'DefenseMeasures')"></span>
                </div>
                <dx-html-editor fxLayout="row" height='200px' [(value)]="currentMeasure.DefenseMeasures"
                    (onFocusIn)="focusIn(currentMeasure.DefenseMeasures)" (onFocusOut)="focusOut('DefenseMeasures')"
                    (onValueChanged)="verifyChanges(currentMeasure.DefenseMeasures)">
                </dx-html-editor>
            </div>
        </div>
    </div>
</dx-accordion>

<dx-popup [height]="540" [width]="'50%'" [(visible)]="toogleTitleVisible" [resizeEnabled]="true"
    title="{{dataService.res('Ubt-Measure-Detail-Html-Editor-Title')}}">
    <div class="dx-card popup-card-paddings card-radius" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="95">
                {{dataService.res('Ubt-Html-Editor-'+propertyName)}}
            </div>
            <div fxLayout="column" fxFlex="5">
                <dx-button class="edit-text" type="default" [icon]="'save'" [hint]="'Save'"
                    (onClick)="saveChanges(propertyName, fieldValue)">
                </dx-button>
            </div>
        </div>
        <div fxLayout="row">
            <dx-html-editor #htmlEditor height="350px" [width]="'100%'" [(value)]="fieldValue"
                class="html-editor-style">
                <dxo-toolbar [multiline]="false">
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']">
                    </dxi-item>
                    <dxi-item name="font" [acceptedValues]="[
                      'Arial',
                      'Courier New',
                      'Georgia',
                      'Impact',
                      'Lucida Console',
                      'Tahoma',
                      'Times New Roman',
                      'Verdana'
                    ]"></dxi-item>
                    <dxi-item name="color"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="insertTable"></dxi-item>
                    <dxi-item name="deleteTable"></dxi-item>
                    <dxi-item name="insertRowBelow"></dxi-item>
                </dxo-toolbar>
            </dx-html-editor>
        </div>
    </div>
</dx-popup>
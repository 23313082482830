import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel, QueryColumnSortOrder, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Measure, MeasureTask } from '@xmt-models';
import { DxDataGridComponent, DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { AppService } from '../../../../services/app.service';
import { DataService, ObjectKeys } from '../../../../services/data.service';
import { MeasureService } from '../../measure.service';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ubt-geods-implementation-plan',
  templateUrl: './implementation-plan.component.html',
  styleUrls: ['./implementation-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImplementationPlanComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('collaboratorsTotalTimeGrid') collaboratorsTotalTimeGrid: GridComponent;
  @ViewChild('meausreTaskTree') meausreTaskTree: DxDataGridComponent;
  @ViewChild('confirmTaskPopup') confirmTaskPopup: DxPopupComponent;
  @ViewChild('milestonePopup') milestonePopup: DxPopupComponent;
  @ViewChild('milestoneForm') milestoneForm: DxFormComponent;
  @ViewChild('taskPopup') taskPopup: DxPopupComponent;
  @ViewChild('taskForm') taskForm: DxFormComponent;
  @ViewChild('collaboratorGrid') collaboratorGrid: CollaboratorsComponent;
  @Input() currentMeasure: Measure;
  public taskColumns: Column[] = [];
  public allowedTaskParent: MeasureTask[] = [];
  public measureTask: MeasureTask = new MeasureTask(null);
  public task: MeasureTask = new MeasureTask(null);
  public submitButtonText: string;
  private measureTaskId: string;
  private isUpdatingTask = false;
  private isUpdatingSubTask = false;
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };
  private subscriptions: Subscription[] = [];
  public measureTaskStatus = [
    { name: this.dataService.res('Ubt-Measure-Detail-Status-Open'), value: 0 },
    { name: this.dataService.res('Ubt-Measure-Detail-Status-In-Progress'), value: 1 },
    { name: this.dataService.res('Ubt-Measure-Detail-Status-Done'), value: 2 }
  ]

  constructor(
    public dataService: DataService,
    public measureService: MeasureService,
    private coreDataService: GeoDsCoreDataService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private persistenceService: GeoDsPersistenceService,
    private datePipe: DatePipe,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentMeasure.MeasureTasks) {
      this.prepareAllowedMeasureTask();
    } else {
      this.currentMeasure.MeasureTasks = [];
    }

    this.cdr.markForCheck();
  }

  public ngOnInit(): void {
    if (this.dataService.cachedResponsiblesResponseWithImages.length === 0) {
      this.coreDataService.executeReadObjectsQuery(this.dataService.prepareStaffMembersWithDocuments())
        .subscribe(staffMembers => {
          this.dataService.cachedResponsiblesResponseWithImages = staffMembers;
          this.taskColumns[1].lookup.dataSource = staffMembers;
        })
    }
    this.taskColumns = [
      {
        caption: this.dataService.res('Ubt-MeasureTask-Label'),
        dataField: 'Label',
        width: '30%',
        dataType: 'string'
      },
      {
        caption: this.dataService.res('Ubt-MeasureDetail-Responsible'),
        dataField: 'Responsible',
        dataType: 'string',
        width: '20%',
        lookup: {
          dataSource: this.dataService.cachedResponsiblesResponseWithImages,
          displayExpr: this.dataService.getResponsibleFullName,
          valueExpr: 'Id'
        }
      },
      {
        caption: this.dataService.res('Ubt-MeasureTask-Target-date'),
        dataField: 'EndDate',
        dataType: 'date',
        width: '20%',
        alignment: 'center',
        format: 'dd.MM.yyyy',
        sortOrder: 'desc'
      },
      {
        caption: this.dataService.res('Ubt-MeasureDetail-Description'),
        dataField: 'Description',
        dataType: 'string',
        alignment: 'center',
        width: '15%',
      },
      {
        caption: this.dataService.res('Ubt-MeasureTask-DegreeOfImplementation'),
        dataField: 'DegreeOfImplementation',
        width: '10%',
        alignment: 'center',
      },
      {
        type: "buttons",
        caption: '',
        width: 'auto',
        alignment: 'right',
        dataField: '',
        buttons: [{
          icon: 'edit',
          onClick: (e) => { this.openEditMeasureTask(e); }
        }, {
          icon: "trash",
          onClick: (e) => { this.openConfirmTaskDialog(e); }
        }]
      }
    ];

  }

  ngAfterViewInit(): void {
  }

  public cellPrepared(e): void {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'Responsible') {
        const responsible = this.dataService.cachedResponsiblesResponseWithImages.find(x => x.Id === e.data?.Responsible);
        const image = new Image();
        image.style.float = "left";
        image.style.borderRadius = '50%';
        image.style.marginRight = '10px';
        image.style.position = 'relative';
        image.style.boxShadow = ' 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)';
        if (responsible?.Documents) {
          const imageToAssgn = responsible.Documents[0].Object;
          image.src = 'data:image/png;base64,' + imageToAssgn;
        } else {
          const image = new Image();
          image.src = 'assets/images/Avatar.svg';;
          image.style.width = '30px';
          image.style.height = '30px';
        }
        e.cellElement.append(image);
      } else if (e.column.dataField === 'Description') {
        e.cellElement.style.cursor = 'pointer';
        if (e.data?.Type === 0) {
          e.cellElement.textContent = this.dataService.res('Ubt-MeasureDetail-Milestone');
          const tooltipContent = e.cellElement.textContent;
          e.cellElement.setAttribute('title', tooltipContent);
          e.cellElement.innerHTML = DefaultIcons.MILESTONE;
        } else {
          e.cellElement.innerText = this.dataService.res('Ubt-MeasureDetail-Task');
          const tooltipContent = e.cellElement.textContent;
          e.cellElement.setAttribute('title', tooltipContent);
          e.cellElement.innerHTML = DefaultIcons.MEASURETASK;
        }
      } else if (e.column.dataField === 'DegreeOfImplementation') {
        if (e.data.Type === 1) {
          e.cellElement.innerText = e.data.DegreeOfImplementation ? e.data.DegreeOfImplementation + ' %' : '0 %';
        } else if (e.data.Type === 0) {
          e.cellElement.innerText = ' ';
        }
      }
    }
  }

  public rowPrepared(e) {
    if (e.rowType === 'data') {
      e.rowElement.style.position = 'relative';
    }
  }


  private prepareAllowedMeasureTask() {
    this.currentMeasure.MeasureTasks.sort((a, b) => a.Label.localeCompare(b.Label));
    this.currentMeasure.MeasureTasks.forEach(measureTask => {
      if (measureTask.Task === null) this.allowedTaskParent.push(measureTask);
    })
  }

  public persistMeasureTask = (event) => {
    if (this.isUpdatingTask === false) {
      event.preventDefault();
      this.persistTask(PersistMode.Insert);
    } else {
      event.preventDefault();
      this.persistTask(PersistMode.Update);
    }
    this.cdr.markForCheck();
  }

  public persistMeasureSubTask = (event) => {
    if (this.isUpdatingSubTask === false) {
      event.preventDefault();
      this.persistTask(PersistMode.Insert);
    } else {
      event.preventDefault();
      this.persistTask(PersistMode.Update);
    }
  }

  private persistTask(mode: PersistMode): void {
    const measureTaskPersistQuery: TargetObjectData = new TargetObjectData();
    measureTaskPersistQuery.ObjectKey = new ObjectKey();
    measureTaskPersistQuery.ObjectKey.ObjectType = ObjectKeys.TASK;

    const measureTaskColumns: TargetColumnValue[] = [
      { Name: 'Type', Value: this.measureTask.Type },
      { Name: 'ParentId', Value: this.currentMeasure.Id },
      { Name: 'Subject', Value: this.measureTask.Label },
      { Name: 'IdRefResponsible', Value: this.measureTask.Responsible },
      { Name: 'TargetMaturityLevel', Value: this.measureTask.TargetMaturityLevel },
      { Name: 'StartDate', Value: this.datePipe.transform(this.measureTask.StartDate, "yyyy-MM-dd") },
      { Name: 'EndDate', Value: this.datePipe.transform(this.measureTask.EndDate, "yyyy-MM-dd") },
      { Name: 'DegreeOfImplementation', Value: this.measureTask.DegreeOfImplementation },
      { Name: 'TotalTimeRequired', Value: this.measureTask.TotalTimeRequired }
    ];

    measureTaskPersistQuery.Mode = mode;
    if (mode == PersistMode.Update) measureTaskPersistQuery.ObjectKey.Id = this.measureTaskId;
    measureTaskPersistQuery.TargetColumns = measureTaskColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measureTaskPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((task) => {
      if (mode === PersistMode.Insert) {
        if (this.measureTask.Collaborators?.length > 0) this.prepareCollaborators(task.Id)
        this.measureTask.Id = task.Id;
        this.currentMeasure.MeasureTasks.push(this.measureTask);
        this.allowedTaskParent.push(this.measureTask);
        this.dataService.updateGridData(this.measureTask);
        this.cdr.markForCheck();
      } else {
        const taskIndex = this.currentMeasure.MeasureTasks.findIndex(item => item.Id === task.Id);
        this.currentMeasure.MeasureTasks[taskIndex] = this.measureTask;
      }
      this.measureService.realTimecalculateTotalTime(true);
      this.clearWindow();
      this.cdr.markForCheck();
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.measureTask = new MeasureTask(null);
    }));
  }

  public deleteTask(event): void {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.TASK, this.measureTaskId).subscribe(() => {
      this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      const index = this.currentMeasure.MeasureTasks.findIndex(item => item.Id === this.measureTaskId);
      this.currentMeasure.MeasureTasks.splice(index, 1);
      this.confirmTaskPopup.instance.hide();
      this.cdr.markForCheck();
    }, error => {
      console.error(error)
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public addMilestone = (): void => {
    this.submitButtonText = 'Create';
    this.milestonePopup.title = this.dataService.res('Ubt-MeasureTask-Create-Milestone');
    this.isUpdatingTask = false;
    this.cdr.markForCheck();
    this.milestonePopup.instance.show();
  }

  public addTask = (e): void => {
    this.measureService.isCreatingSubTask = true;
    this.submitButtonText = 'Create';
    this.isUpdatingSubTask = false;
    this.taskForm?.instance.resetValues();
    this.measureTask = new MeasureTask(null);
    this.measureTask.Type = 1;
    this.taskPopup.title = this.dataService.res('Ubt-MeasureTask-Create-Task');
    this.taskPopup.instance.show();
    this.cdr.markForCheck();
  }

  public openEditMeasureTask = (event) => {
    this.measureTask = new MeasureTask(event.row.data);
    this.measureTaskId = event.row.data.Id;
    this.submitButtonText = 'Update';
    if (event.row.data?.Type === 0) {
      this.isUpdatingTask = true;
      this.milestonePopup.title = this.dataService.res('Ubt-MeasureTask-Update-Milestone-Task');
      this.milestonePopup.instance.show();
    } else {
      this.measureService.isCreatingSubTask = false;
      this.isUpdatingSubTask = true;
      this.taskPopup.title = this.dataService.res('Ubt-MeasureTask-Update-Task');
      this.taskPopup.instance.show();
    }
    this.cdr.markForCheck();
  }

  public openConfirmTaskDialog(event): void {
    this.measureTaskId = event.row.data.Id;
    this.confirmTaskPopup.instance.show();
  }

  public clearWindow(clearMeasureTask: boolean = true): void {
    if (clearMeasureTask) {
      this.measureTask = new MeasureTask(null);
      this.taskForm?.instance.resetValues();
    }
    this.milestoneForm?.instance.resetValues();
    this.milestonePopup.instance.hide();
    this.taskPopup.instance.hide();
    this.collaboratorGrid.collaboratorGrid.dxDataGrid.instance.cancelEditData();
  }

  public abortTaskDelete() {
    this.confirmTaskPopup.instance.hide();
  }

  public prepareCollaborators(measureTaskId: string): void {
    const query: PersistObjectsModel = new PersistObjectsModel();
    const collaborattorPersistQuery: TargetObjectData[][] = [[]];
    this.measureTask.Collaborators.forEach((newCollaborator) => {
      const collaboratorsPersistObjectData: TargetObjectData = new TargetObjectData();
      collaboratorsPersistObjectData.Mode = PersistMode.Insert;
      collaboratorsPersistObjectData.ObjectKey = new ObjectKey();
      collaboratorsPersistObjectData.ObjectKey.ObjectType = ObjectKeys.COLLABORATOR;
      const collaboratorColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: measureTaskId },
        { Name: 'IdRefMeasureResource', Value: newCollaborator['IdRefMember'] },
        { Name: 'TimeExecuted', Value: newCollaborator.TimeExecuted },
        { Name: 'TimePlanned', Value: newCollaborator.TimePlanned },
        { Name: 'Period', Value: new Date().toDateString() }
      ];
      collaboratorsPersistObjectData.TargetColumns = collaboratorColumns;
      collaborattorPersistQuery[0].push(collaboratorsPersistObjectData);
    });
    query.Objects = collaborattorPersistQuery;
    this.persistenceService.executePersistObjectsQuery(query).subscribe(data => {
      this.appService.callNotification({ message: this.dataService.res('Ubt-Collaborator-Assigned'), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.cdr.markForCheck();
    });
  }

  public handleFinishChange(event, Id): void {
    const measureTaskPersistQuery: TargetObjectData = new TargetObjectData();
    measureTaskPersistQuery.ObjectKey = new ObjectKey();
    measureTaskPersistQuery.ObjectKey.ObjectType = ObjectKeys.TASK;
    const measureTaskColumns: TargetColumnValue[] = [
      { Name: 'FinishedOn', Value: event ? new Date() : null },
    ]
    measureTaskPersistQuery.Mode = PersistMode.Update;
    measureTaskPersistQuery.ObjectKey.Id = Id;
    measureTaskPersistQuery.TargetColumns = measureTaskColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measureTaskPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      this.currentMeasure.MeasureTasks.find((task) => task.Id === Id).FinishedOn = event ? new Date() : null;
      this.cdr.markForCheck();
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
    }, (error) => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }))
  }
  public onCalculateOverallTime(event): void {
    this.measureTask.TotalTimeRequired = event;
  }

  public ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}

export enum DefaultIcons {
  CLOSE = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0,0,256,256">
  <g fill="#6e6e7d" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M9.15625,6.3125l-2.84375,2.84375l15.84375,15.84375l-15.9375,15.96875l2.8125,2.8125l15.96875,-15.9375l15.9375,15.9375l2.84375,-2.84375l-15.9375,-15.9375l15.84375,-15.84375l-2.84375,-2.84375l-15.84375,15.84375z"></path></g></g>
  </svg>`,
  CONTEXTMENU = `<svg id="context-menu-icon" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.context-menu-icon{fill:#6e6e7d;}</style></defs><circle class="context-menu-icon" cx="200" cy="60" r="40"/><circle class="context-menu-icon" cx="200" cy="340" r="40"/><circle class="context-menu-icon" cx="200" cy="200" r="40"/></svg>`,
  ASSIGNED = `<svg id="cls-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><defs><style>.cls-1{fill:#afafaf;}</style></defs><path class="cls-1" d="m22.95,12.54c.64-.71,1.03-1.64,1.03-2.66,0-2.2-1.79-3.99-3.99-3.99s-3.99,1.79-3.99,3.99c0,1.02.39,1.96,1.02,2.66-2.29.37-3.96,1.48-4.23,4.74-.04.41.27.78.68.81.41.04.78-.27.81-.68.26-3.03,1.65-3.54,5.7-3.54s5.44.51,5.7,3.54c.03.39.36.69.75.69.02,0,.04,0,.06,0,.41-.04.72-.4.68-.81-.28-3.26-1.95-4.37-4.24-4.74Zm-2.96-5.15c1.37,0,2.49,1.11,2.49,2.49s-1.12,2.49-2.49,2.49-2.49-1.12-2.49-2.49,1.12-2.49,2.49-2.49Z"/><path class="cls-1" d="m34.25,23.26c-.84,0-1.61.26-2.26.7l-2.97-2.95c1.7-2.04,2.72-4.66,2.72-7.51,0-6.48-5.27-11.75-11.75-11.75s-11.75,5.27-11.75,11.75c0,2.85,1.03,5.47,2.73,7.51l-2.97,2.95c-.64-.44-1.42-.7-2.26-.7-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4c0-.83-.25-1.6-.68-2.24l2.95-2.93c1.21,1.12,2.64,1.99,4.23,2.53l-1.46,5.65c-.09,0-.17-.03-.25-.03-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4c0-1.59-.94-2.96-2.29-3.6l1.45-5.63c.75.15,1.52.23,2.3.23s1.56-.08,2.3-.23l1.46,5.63c-1.35.64-2.29,2.01-2.29,3.6,0,2.21,1.79,4,4,4s4-1.79,4-4-1.79-4-4-4c-.09,0-.17.02-.25.03l-1.46-5.65c1.59-.54,3.03-1.41,4.23-2.53l2.95,2.93c-.43.64-.68,1.41-.68,2.24,0,2.21,1.79,4,4,4s4-1.79,4-4-1.79-4-4-4Zm-17.22,10.99c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5,1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5Zm10.93,0c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5,1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5Zm-22.22-4.49c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm14.25-6.01c-5.65,0-10.25-4.6-10.25-10.25S14.35,3.25,20,3.25s10.25,4.6,10.25,10.25-4.6,10.25-10.25,10.25Zm14.25,6.01c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Z"/></svg>`,
  MEASURETASK = '<svg id="cls-2" xmlns="http://www.w3.org/2000/svg" style="width:25px" viewBox="0 0 40 40"><defs><style>.cls-2 {fill: #00569c;}</style></defs><path class="cls-1" d="m15.57,11.05l-3.68,3.68-1.36-1.36c-.29-.29-.77-.29-1.06,0s-.29.77,0,1.06l1.89,1.89c.15.15.34.22.53.22s.38-.07.53-.22l4.21-4.21c.29-.29.29-.77,0-1.06s-.77-.29-1.06,0Z"/><path class="cls-1" d="m26.7,19.55h-7.31c-.41,0-.75.34-.75.75s.34.75.75.75h7.31c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z"/><path class="cls-1" d="m19.39,24.27h4.67c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-4.67c-.41,0-.75.34-.75.75s.34.75.75.75Z"/><path class="cls-1" d="m15.57,19.05l-3.68,3.68-1.36-1.36c-.29-.29-.77-.29-1.06,0s-.29.77,0,1.06l1.89,1.89c.15.15.34.22.53.22s.38-.07.53-.22l4.21-4.21c.29-.29.29-.77,0-1.06s-.77-.29-1.06,0Z"/><path class="cls-1" d="m26.7,27.55h-7.31c-.41,0-.75.34-.75.75s.34.75.75.75h7.31c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z"/><path class="cls-1" d="m24.06,30.78h-4.67c-.41,0-.75.34-.75.75s.34.75.75.75h4.67c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z"/><path class="cls-1" d="m15.57,27.05l-3.68,3.68-1.36-1.36c-.29-.29-.77-.29-1.06,0s-.29.77,0,1.06l1.89,1.89c.15.15.34.22.53.22s.38-.07.53-.22l4.21-4.21c.29-.29.29-.77,0-1.06s-.77-.29-1.06,0Z"/><path class="cls-1" d="m33.36,1.75H9.94c-1.2,0-2.17.97-2.17,2.17v1.13h-1.13c-1.2,0-2.17.97-2.17,2.17v28.87c0,1.2.97,2.17,2.17,2.17h23.42c1.2,0,2.17-.97,2.17-2.17v-1.13h1.13c1.2,0,2.17-.97,2.17-2.17V3.92c0-1.19-.97-2.17-2.17-2.17Zm-2.63,34.33c0,.37-.3.67-.67.67H6.64c-.37,0-.67-.3-.67-.67V7.22c0-.37.3-.67.67-.67h13.7c.37,0,.67.3.67.67v4.33h-1.61c-.41,0-.75.34-.75.75s.34.75.75.75h1.61v.36c0,.5.14.96.36,1.37h-1.98c-.41,0-.75.34-.75.75s.34.75.75.75h4.47s0,0,0,0h6.19c.37,0,.67.3.67.67v19.14Zm-1.89-21.31h-4.97c-.76,0-1.37-.62-1.37-1.37v-4.96l6.34,6.34Zm5.19,18.01c0,.37-.3.67-.67.67h-1.13v-16.51c0-.62-.26-1.18-.68-1.57h0s-9.64-9.64-9.64-9.64h0c-.4-.41-.95-.68-1.57-.68h-11.06v-1.13c0-.37.3-.67.67-.67h23.42c.37,0,.67.3.67.67v28.87Z"/></svg>',
  MILESTONE = '<svg id="cls-2" xmlns="http://www.w3.org/2000/svg" style="width:25px"  viewBox="0 0 40 40"><defs><style>.cls-2{fill:#00569c;}</style></defs><path class="cls-1" d="m23.98,13.05l-5.48,5.48-2.48-2.48c-.29-.29-.77-.29-1.06,0s-.29.77,0,1.06l3.01,3.01c.15.15.34.22.53.22s.38-.07.53-.22l6.01-6.01c.29-.29.29-.77,0-1.06s-.77-.29-1.06,0Z"/><path class="cls-1" d="m23.98,23.65l-5.48,5.48-2.48-2.48c-.29-.29-.77-.29-1.06,0s-.29.77,0,1.06l3.01,3.01c.15.15.34.22.53.22s.38-.07.53-.22l6.01-6.01c.29-.29.29-.77,0-1.06s-.77-.29-1.06,0Z"/><path class="cls-1" d="m31.08,5.04h-4.23c-.3-1.03-1.24-1.8-2.37-1.8h-.51c-.58,0-1.15-.23-1.72-.7-1.27-1.04-3.23-1.04-4.5,0-.39.32-1,.7-1.72.7h-.51c-1.13,0-2.07.76-2.37,1.8h-4.23c-1.2,0-2.17.97-2.17,2.17v28.87c0,1.2.97,2.17,2.17,2.17h22.16c1.2,0,2.17-.97,2.17-2.17V7.2c0-1.2-.97-2.17-2.17-2.17Zm-16.54.68c0-.54.44-.98.98-.98h.51c.92,0,1.84-.36,2.67-1.04.74-.61,1.86-.61,2.6,0,.83.68,1.75,1.04,2.67,1.04h.51c.54,0,.98.44.98.98v1.66c0,.21-.17.38-.38.38h-10.14c-.21,0-.38-.17-.38-.38v-1.66Zm17.2,30.35c0,.37-.3.67-.67.67H8.92c-.37,0-.67-.3-.67-.67V7.2c0-.37.3-.67.67-.67h4.13v.84c0,1.04.85,1.88,1.88,1.88h10.14c1.04,0,1.88-.85,1.88-1.88v-.84h4.13c.37,0,.67.3.67.67v28.87Z"/></svg>'
}
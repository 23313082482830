import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Measure } from '@xmt-models';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { DataService } from '../../../../services/data.service';
import { MeasureService } from '../../measure.service';

@Component({
  selector: 'ubt-geods-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionComponent {
  @ViewChild('htmlEditor') htmlEditor: DxHtmlEditorComponent;
  @Input() currentMeasure: Measure;
  public toogleTitleVisible = false;
  public fieldValue: string;
  public propertyName: string;
  public currentDescriptionField: string;

  constructor(public dataService: DataService,
    private cdr: ChangeDetectorRef,
    public measureService: MeasureService) { }

  ngOnInit(): void {
  }

  public openPopup(fieldValue: string, propertyName: string): void {
    this.propertyName = propertyName;
    this.htmlEditor.value = fieldValue
    this.toogleTitleVisible = true;
    this.cdr.markForCheck()
  }

  public saveChanges(propertyName: string, fieldValue: string) {
    this.currentMeasure[propertyName] = fieldValue;
    this.measureService.updateMeasure(propertyName, fieldValue, true);
    this.toogleTitleVisible = false;
  }

  public verifyChanges(fieldValue): void {
    this.currentDescriptionField = fieldValue;
  }

  public focusIn(fieldValue): void {
    this.currentDescriptionField = fieldValue;
  }

  public focusOut(columnName): void {
    this.measureService.updateMeasure(columnName, this.currentDescriptionField, true);
  }

  public updateMeasure() {
    //TODO: update measure data
    // delete this.currentMeasure.meta.updated;
  }
}

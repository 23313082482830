import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BACKEND_SYSTEM, ConfigService } from 'libs/core/configuration/src/lib/config.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoginInfo } from '../models/login-info.model';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const EXPIRES = 'expires';
const EXPIRY_DATE = 'expiry_date';

@Injectable({
  providedIn: 'root'
})
export class TokenService {


  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  getExpiryDate(): string {
    return localStorage.getItem(EXPIRY_DATE);
  }

  saveToken(loginInfo: any): void {
    if (this.configService.backEndSystem === BACKEND_SYSTEM.SPACEONE) {
      localStorage.setItem(ACCESS_TOKEN, loginInfo.accessToken);
      localStorage.setItem(REFRESH_TOKEN, loginInfo.refreshToken);
      localStorage.setItem(EXPIRES, loginInfo.expiresIn.toString());
      localStorage.setItem(EXPIRY_DATE, loginInfo.expiryDate.toString());
    } else {
      localStorage.setItem(ACCESS_TOKEN, loginInfo.access_token);
      localStorage.setItem(REFRESH_TOKEN, loginInfo.refresh_token);
      localStorage.setItem(EXPIRES, loginInfo.expires_in);
      localStorage.setItem(EXPIRY_DATE, loginInfo['.expires']);
    }
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  clearLoginInfo(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES);
    localStorage.removeItem(EXPIRY_DATE);
  }

  public renewTokenAnRetryRequest(request, baseUrl): Observable<any> {
    const headers = {
      Authorization: 'noAuth',
      'Content-type': 'application/x-www-form-urlencoded'
    };
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.getRefreshToken())
      .set('name', 'webApp');
    return this.httpClient.post<any>(`${baseUrl}/api/auth/renew`, body, { headers })
      .pipe(
        switchMap(
          data => {
            const loginInfo: LoginInfo = new LoginInfo(data.access_token, data.expires_in, data.refresh_token, data.token_type);
            this.saveToken(loginInfo);
            return this.httpClient.request(request);
          }
        )
      );
  }
}

<dx-scroll-view>
    <div fxFlexFill fxLayout="column" fxLayout.sm="column" fxLayout.xs="column"
        class="content-style-class measure-details-container"
        [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
        <div fxFlexFill fxLayout="row" class="status-report-padding">
            <div fxLayout="column" fxFlex="65" class="header">
                <!-- Accordions Section -->
                <div class="accordion-style status-report-margin" fxLayout="column" fxLayoutGap="5px">
                    <ubt-geods-status-report #statusReport [measureProgress]="measure.MeasureProgress"
                        [lastMeasureProgress]="lastMeasureProgress"
                        (assignReportToMeasure)="assignReportToMeasure($event)"
                        (updateReportData)="updateReportData($event)" (deleteReport)="deleteReport($event)">
                    </ubt-geods-status-report>

                    <div class="accordion-style">
                        <ubt-geods-description [currentMeasure]="measure"></ubt-geods-description>
                    </div>

                    <div class="accordion-style">
                        <ubt-geods-implementation-plan #implementationPlan [currentMeasure]="measure">
                        </ubt-geods-implementation-plan>
                    </div>

                    <div class="accordion-style">
                        <ubt-geods-document [showAllDocuments]="false" [parentId]="measureId" [documents]="documents"
                            [documentOpath]="true">
                        </ubt-geods-document>
                    </div>
                </div>
            </div>

            <!-- Chart | Details | Comments Section -->
            <div fxLayout="column" fxFlex="35" class="details-fields">

                <div fxLayout="row" fxLayoutAlign="end center">
                    <div>
                        <dx-button stylingMode="text" class="btn-navigation" icon="chevronleft" type="default"
                            [disabled]="measureService.currentMeasureIndex === 0" (onClick)="navigateToMeasure(-1)"
                            [hoverStateEnabled]="false" [hint]="previousMeasureDesignation">
                        </dx-button>
                    </div>
                    <div>
                        <dx-button stylingMode="text" class="btn-navigation" icon="chevronnext" type="default"
                            [disabled]="measureService.currentMeasureIndex == this.appService.measureIds.length - 1"
                            (onClick)="navigateToMeasure(1)" [hoverStateEnabled]="false"
                            [hint]="nextMeasureDesignation">
                        </dx-button>
                    </div>
                </div>
                <div fxLayout="column" class="dx-card card-container card-paddings responsive-paddings progress-style"
                    fxLayoutGap="10px">
                    <div>
                        <span
                            class="meausre-progress-title">{{dataService.res('Ubt-Measure-Detail-Progress-Title')}}</span>
                    </div>

                    <div class="container-card">
                        <ubt-geods-chart #chart [progressChartData]="measure?.MeasureProgress"></ubt-geods-chart>
                    </div>
                    <div class="container-card">
                        <div fxLayout="row" class="right-fields-container">
                            <dx-text-box #measureTitle [width]="'100%'" [(value)]="measure.Title"
                                [stylingMode]="'outlined'" (onValueChanged)="verifyChanges($event, 'Designation')"
                                [label]="dataService.res('Ubt-Measure-List-Title')" [labelMode]="'floating'">
                            </dx-text-box>
                        </div>

                        <div fxLayout="row" class="right-fields-container ">
                            <div fxLayout="column" fxFlex="47">
                                <dx-select-box class="drop-down-style" valueExpr='Id' displayExpr="Label"
                                    [stylingMode]="'outlined'" [width]="'100%'" [(value)]="measure.ParentId"
                                    [dataSource]="subProjects" [label]="dataService.res('Ubt-Measure-List-SubProject')"
                                    (onValueChanged)="measureService.updateMeasure('ParentId', measure.ParentId, allowUpdating)"
                                    [labelMode]="'floating'">
                                </dx-select-box>
                            </div>
                            <div fxLayout="column" fxFlex="5"></div>
                            <div fxLayout="column" fxFlex="48">
                                <dx-select-box class="drop-down-style" valueExpr='name' displayExpr="value"
                                    [stylingMode]="'outlined'" [width]="'100%'" [(value)]="measure.Status"
                                    [dataSource]="measureService.measureStatus"
                                    [label]="dataService.res('Ubt-Measure-List-Status')"
                                    (onValueChanged)="measureService.updateMeasure('Status', measure.Status, allowUpdating)"
                                    [labelMode]="'floating'">
                                </dx-select-box>
                            </div>
                        </div>

                        <div fxLayout="row" class="right-fields-container">
                            <div fxLayout="column" fxFlex="47">
                                <dx-select-box fxFill class="drop-down-style" valueExpr="Id"
                                    [displayExpr]="dataService.getResponsibleFullName"
                                    (onValueChanged)="measureService.updateMeasure('IdRefPersonResponsible', measure.Responsible, allowUpdating)"
                                    [dataSource]="dataService.cachedResponsiblesResponse"
                                    [(value)]="measure.Responsible"
                                    [label]="dataService.res('Ubt-CreateMeasure-Responsible')" [labelMode]="'floating'">
                                </dx-select-box>
                            </div>
                            <div fxLayout="column" fxFlex="5"></div>
                            <div fxLayout="column" fxFlex="48">
                                <dx-select-box fxFill class="drop-down-style" valueExpr="Id"
                                    [displayExpr]="dataService.getResponsibleFullName"
                                    (onValueChanged)="measureService.updateMeasure('IdRefPersonAreaManager', measure.AreaManager, allowUpdating)"
                                    [dataSource]="dataService.cachedResponsiblesResponse"
                                    [(value)]="measure.AreaManager"
                                    [label]="dataService.res('Ubt-MeasureDetail-Division-Manager')"
                                    [labelMode]="'floating'">
                                </dx-select-box>
                            </div>
                        </div>

                        <div fxLayout="row" class="right-fields-container">
                            <div fxLayout="column" fxFlex="32">
                                <dx-date-box fxLayout="column" fxFill class="date-box-style" class="date-box-style"
                                    [(value)]="measure.ReleaseDate" [displayFormat]="'dd.MM.yyyy'"
                                    [label]="dataService.res('Ubt-MeasureDetail-Release-On')" [labelMode]="'floating'">
                                </dx-date-box>
                            </div>
                            <div fxLayout="column" fxFlex="2"></div>
                            <div fxLayout="column" fxFlex="32">
                                <dx-date-box fxLayout="column" fxFill class="date-box-style" class="date-box-style"
                                    [displayFormat]="'dd.MM.yyyy'"
                                    (onValueChanged)="measureService.updateMeasure('StartDate', measure.StartDate, allowUpdating)"
                                    [(value)]="measure.StartDate" [label]="dataService.res('Ubt-MeasureTask-StartDate')"
                                    [labelMode]="'floating'">
                                </dx-date-box>
                            </div>
                            <div fxLayout="column" fxFlex="2"></div>
                            <div fxLayout="column" fxFlex="32">
                                <dx-date-box fxFill class="date-box-style" [(value)]="measure.EndDate"
                                    [displayFormat]="'dd.MM.yyyy'" (onValueChanged)="verifyChanges($event, 'EndDate')"
                                    [min]="measure.StartDate" [label]="dataService.res('Ubt-Measure-List-EndDate')"
                                    [labelMode]="'floating'">
                                </dx-date-box>
                            </div>
                        </div>

                        <div fxLayout="row" class="right-fields-container">
                            <dx-tag-box #assignedMembers fxFill [dataSource]="dataService.cachedResponsiblesResponse"
                                [height]="'100px'" [(value)]="assignedMembersIds"
                                [displayExpr]="dataService.getResponsibleFullName" valueExpr="Id"
                                [label]="dataService.res('Ubt-CreateMeasure-Members')" [labelMode]="'floating'"
                                [showSelectionControls]="true" (onSelectionChanged)="addDeleteResource($event)">
                            </dx-tag-box>
                        </div>
                    </div>
                </div>
                <div class="comment-row">
                    <dx-speed-dial-action icon="add" (onClick)="getComments()"
                        [icon]="'assets/images/comment-speed-dial.svg'">
                    </dx-speed-dial-action>
                </div>

                <div class="dx-card card-container card-paddings responsive-paddings" class="progress-style">
                    <div class="accordion-style">
                        <ubt-geods-total-time [collaborators]="collaboratorsTotalTime"
                            [taskList]="measure"></ubt-geods-total-time>
                    </div>
                </div>
            </div>
        </div>
    </div>
</dx-scroll-view>

<dx-popup #commentsPopup [width]="600" [height]="650" [showTitle]="true"
    [title]="dataService.res('Ubt-Measure-Detail-Comments')" [dragEnabled]="false" [hideOnOutsideClick]="true"
    [showCloseButton]="true">
    <div class="dx-card popup-card-paddings card-radius">
        <dxo-position at="right bottom" my="right bottom" of="window"> </dxo-position>
        <comments-popup fxFill [comments]="measureComments" [itemId]="measureId" [geoDs]="true"></comments-popup>
    </div>

</dx-popup>

<ubt-loader *ngIf="showLoader"></ubt-loader>
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Person } from '@geods/base';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel,
  QueryColumnSortOrder, TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { Measure, Status, SubProject } from '@xmt-models';
import { DxFormComponent, DxHtmlEditorComponent } from 'devextreme-angular';
import { Observable, Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MeasureService } from '../measure.service';

@Component({
  selector: 'ubt-geods-create-measure',
  templateUrl: './create-measure.component.html',
  styleUrls: ['./create-measure.component.scss']
})
export class CreateMeasureComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('htmlEditor') htmlEditor: DxHtmlEditorComponent;

  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  public measure: Measure = new Measure(null);
  public subProject: SubProject = new SubProject(null);
  public createSecondMeasure = false;
  public staffmembers: Person[] = [];
  public responsibles: Observable<Person[]>;
  public subProjects: SubProject[] = [];
  public toogleTitleVisible = false;
  public propertyName: string;
  public fieldValue: string;
  public currentDescriptionField: string;

  public createButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Create'),
    onClick: () => this._goToDetail = false,
    useSubmitBehavior: true
  };
  public createAndOpenDetailsButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Create-And-Open'),
    onClick: () => this._goToDetail = true,
    useSubmitBehavior: true
  };
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.cancel()
  };
  private subscriptions: Subscription[] = [];
  private _goToDetail: boolean;

  constructor(
    private coreDataService: GeoDsCoreDataService,
    public dataService: DataService,
    private measureService: MeasureService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public ngOnInit(): void {
    this.measure = new Measure(null);

    const subProjectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None)
    ];
    const subProjectOPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    this.subscriptions.push(this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, subProjectColumns, subProjectOPath).subscribe(subProjects => {
      this.subProjects = subProjects.sort((a, b) => a.Label.localeCompare(b.Label));
    }));
  }

  public onValueChanged = (e) => {
    const subProjectId = e.value
    this.getSubProjectById(subProjectId)
  }

  private getSubProjectById = (subProjectId: string) => {
    const subProjectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None)
    ];
    const subProjectOPath = 'Id=' + "'" + subProjectId + "'";
    this.subscriptions.push(this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, subProjectColumns, subProjectOPath).subscribe(subProjects => {
      this.subProject = subProjects[0]
    }));
  }

  public createMeasure(event): void {
    event.preventDefault();
    const measurePersistQuery: TargetObjectData = new TargetObjectData();
    measurePersistQuery.ObjectKey = new ObjectKey();
    measurePersistQuery.ObjectKey.ObjectType = ObjectKeys.MEASURE;
    const measureColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.measure.SubProject.toString() },
      { Name: 'Designation', Value: this.measure.Title },
      { Name: 'StartDate', Value: this.measure.StartDate?.toDateString() },
      { Name: 'EndDate', Value: this.measure.EndDate?.toDateString() },
      { Name: 'IdRefPersonResponsible', Value: this.measure.Responsible?.toString() },
      { Name: 'IdRefPersonAreaManager', Value: this.measure.AreaManager?.toString() },
      { Name: 'InitialSituation', Value: this.measure.InitialSituation },
      { Name: 'Goal', Value: this.measure.Goal },
      { Name: 'CurrentGrade', Value: this.measureService.maturityLevel[0].name },
      { Name: 'Concept', Value: this.measure.Concept },
      { Name: 'Status', Value: this.measureService.measureStatus[2].name }
    ];
    measurePersistQuery.Mode = PersistMode.Insert;
    measurePersistQuery.TargetColumns = measureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measurePersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      this.dataService.updateGridData(data);
      this.assignResourcesToMeasure(data.Id);
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      if (this._goToDetail === true) {
        this.dataService.sendMeasureDetail(data);
        this.dataService.appService.showMeasureIcon = true;
        this.router.navigate(['measureDetail', 'measures', data.Id]);
      }
      if (this.createSecondMeasure === true) {
        this.measure = new Measure(null);
      } else {
        this.closePopup.emit(true);
      }
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.measure = new Measure(null);
    }));
  }

  private assignResourcesToMeasure(measureId: string) {
    const query: PersistObjectsModel = new PersistObjectsModel();
    const resourcePersistQuery: TargetObjectData[][] = [[]];
    this.measure.MeasureResources?.forEach((resource) => {
      const reourcesPersistObjectData: TargetObjectData = new TargetObjectData();
      reourcesPersistObjectData.Mode = PersistMode.Insert;
      reourcesPersistObjectData.ObjectKey = new ObjectKey();
      reourcesPersistObjectData.ObjectKey.ObjectType = ObjectKeys.RESOURCE;
      const resourceColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: measureId },
        { Name: 'IdRefPerson', Value: resource.toString() }
      ];
      reourcesPersistObjectData.TargetColumns = resourceColumns;
      resourcePersistQuery[0].push(reourcesPersistObjectData);
    });
    query.Objects = resourcePersistQuery;
    this.persistenceService.executePersistObjectsQuery(query).subscribe();
  }

  public verifyChanges(fieldValue): void {
    this.currentDescriptionField = fieldValue;
  }

  public openPopup(fieldValue: string, propertyName: string): void {
    this.propertyName = propertyName;
    this.htmlEditor.value = fieldValue
    this.toogleTitleVisible = true;
    this.cdr.markForCheck()
  }

  public saveChanges(propertyName: string, fieldValue: string) {
    this.measure[propertyName] = fieldValue
    this.toogleTitleVisible = false;
  }

  public cancel(): void {
    this.closePopup.emit(this.createSecondMeasure === true ? false : true);
    this.form.instance.resetValues();
    this.measure = new Measure(null);
    this.createSecondMeasure = false;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
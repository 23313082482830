import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelComponent, DxFilterBuilderComponent } from 'devextreme-angular';
import { ColumnChooserMode, ColumnChooserSort, EditingMode, FormatExport, Mode, RowMode, ScrollMode, ShowMode, ShowScrollbar, StateStorageType } from './grid.model';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridPDF } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridExcel } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

@Component({
    selector: 'ww-grid',
    template: `<dx-data-grid [id]="id" #dxDataGrid style="max-height: {{maxHeight}};"
                [dataSource]="gridData"
                [allowColumnReordering]="allowColumnReordering"
                [showBorders]="showBorders"
                [columnHidingEnabled]="columnHidingEnabled"
                [rowAlternationEnabled]="rowAlternationEnabled"
                [allowColumnResizing]="allowColumnResizing"
                [remoteOperations]="false" 
                [rtlEnabled]="rtlEnabled"
                [hoverStateEnabled]="hoverStateEnabled"
                [showColumnLines]="showColumnLines"
                [showRowLines]="showRowLines"
                [showColumnHeaders]="showColumnHeaders"
                [height]="height"
                [ngClass]="class"
                [keyExpr]="keyExpr"
                [noDataText]="noDataText"
                [width]="width" 
                [loadPanel]="showdefaultloadelement"
                [columns]="columnsHeader"             
                [disabled]="disabled"
                [visible]="visible"
                [summary]= "summaryData"
                [selectedRowKeys]= "selectedRowKeys"
                [columnAutoWidth]="columnAutoWidth"
                (onExporting)="exporting($event)"
                (onEditorPreparing)="customizeColumns($event)"
                (onOptionChanged)="optionChanged($event)"
                (onInitialized)="initialized($event)"
                (onContentReady)="contentReady($event)"
                (onEditorPreparing)="editorPreparing($event)"
                (onEditorPrepared)="editorprepared($event)"
                (onRowPrepared)="rowPrepared($event)"
                (onCellPrepared)="cellPrepared($event)"
                (onCellDblClick)="cellDblClick($event)"
                (onRowClick)="rowSelect($event)"
                (onRowDblClick)="rowDbClick($event)"
                (onRowInserted)="rowInserted($event)"
                (onRowUpdating)="rowUpdating($event)"
                (onRowUpdated)="rowUpdated($event)"
                (onSaving)="savingRow($event)"
                (onSaved)="saveRow($event)"
                (onCellHoverChanged) = "cellHoverChanged($event)"
                (onEditCanceled)="editCancelRow($event)"
                (onEditCanceling)="editCancelingRow($event)"
                (onSelectionChanged)="selectionChanged($event)"
                (onRowRemoved)="removedRow($event)"
                (onRowRemoving)="removingRow($event)"
                (onToolbarPreparing)="toolBarPreparing($event)"
                (onEditingStart)="editingStart($event)"
                (onCellClick)="cellClick($event)"
                (onContextMenuPreparing)="contextMenuPreparing($event)">
                <dxo-group-panel #dxGroup [visible]="groupable"></dxo-group-panel>
                <dxo-header-filter #dxHeaderFilter [visible]="enableHeaderFilter"></dxo-header-filter>
                <dxo-filter-row #dxFilterRow [visible]="showfilterrow"></dxo-filter-row>
                <dxo-filter-panel #dxFilterPanel [visible]="filterPanel" ></dxo-filter-panel>
                <dxo-search-panel
                  [visible]="searchPanel"
                  [highlightCaseSensitive]="true"
                  [text]="text"
                  [width]="widthSearch"
                  [highlightSearchText]="highlightSearchText">
                </dxo-search-panel>
                <dxo-column-chooser [allowSearch]="columnChooserSearch" 
                [enabled]="enableColumnChooser"
                [emptyPanelText]="emptyPanelText" 
                [mode]= "columnChooserMode"
                [searchTimeout]= "columnChooserSearchTimeout"
                [sortOrder]="'asc'"
                >
                </dxo-column-chooser>
                <dxo-state-storing [enabled]="enableStateStorage" type={{stateStorageType}} storageKey={{id}} savingTimeout="500"></dxo-state-storing> 
                <dxo-paging [enabled]="pageable" [pageSize]="pageSize" [pageIndex]="0"></dxo-paging>
                <dxo-pager 
                [showPageSizeSelector]="showPageSizeSelector"
                [allowedPageSizes]="allowedPageSizes"
                [showInfo]="true">
                </dxo-pager>
                <dxo-editing  [mode]="editingMode"
                [allowUpdating]="allowUpdating"
                [allowDeleting]="allowDeleting"
                [allowAdding]="allowAdding"
                [useIcons]="useIcons">
                    <dxo-popup
                    [title]="popUpTitle"
                    [showTitle]="showTitle"
                    [width]="popUpWidth"
                    [height]="popUpHeight"
                    [position]="{ my: 'center', at: 'center' }">
                    </dxo-popup>
                </dxo-editing>
                <dxo-selection
                  [mode]="selectionMode"
                  [allowSelectAll]="allowSelectAll"
                  [showCheckBoxesMode]="showCheckBoxesMode">
                </dxo-selection>
                <dxo-export [enabled]="enableExport" [allowExportSelectedData]="allowExportSelectedData" [formats]="exportFormat" ></dxo-export>
                <dxo-scrolling [mode]= "scrollMode"
                 [columnRenderingMode]="rowRenderingMode"
                 [rowRenderingMode]="rowRenderingMode" 
                 [preloadEnabled]="preloadEnabled" 
                 [scrollByContent]="scrollByContent" 
                 [scrollByThumb]="scrollByThumb" 
                 [showScrollbar]="showScrollBar">
                </dxo-scrolling>
                <dxo-load-panel [enabled]="enableLoadPanel" [height]="loadPanelHeight" [width]="loadPanelWidth">
                </dxo-load-panel>
                <dxo-filter-builder [allowHierarchicalFields]="true">
                      </dxo-filter-builder>
                <dxo-grouping [autoExpandAll]="autoExpandAll" [contextMenuEnabled]="groupingContextMenu"> </dxo-grouping>
                <dxo-summary>
                    <dxi-group-item
                        summaryType="count">
                    </dxi-group-item>
                </dxo-summary> 
                <div *dxTemplate="let data of 'cellTemplate'">
                    <a class="linkStyle"> {{data[linkProperty]}} </a>
                </div>
                    <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
                        <dx-tag-box
                        [dataSource]="cellInfo.column.lookup.dataSource"
                        [value]="cellInfo.value"
                        [valueExpr]="cellInfo.column.lookup.valueExpr"
                        [displayExpr]="cellInfo.column.lookup.displayExpr"
                        [showSelectionControls]="true"
                        [showMultiTagOnly]="false"
                        applyValueMode="instantly"
                        [searchEnabled]="false"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        >
                        <!-- causes a problem in digitalization tool update question cells methode
                         (onSelectionChanged)="cellInfo.component.updateDimensions()" -->
                        </dx-tag-box>
                    </div>
              </dx-data-grid>`,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./grid.component.scss']
})

export class GridComponent implements OnInit {
    @ViewChild('dxDataGrid', { static: true }) dxDataGrid: DxDataGridComponent;
    @ViewChild('dxGroup', { static: true }) dxGroup: DxTabPanelComponent;
    @ViewChild('dxHeaderFilter', { static: true }) dxHeaderFilter: DxFilterBuilderComponent;
    @ViewChild('dxFilterPanel', { static: true }) dxFilterPanel: DxFilterBuilderComponent;
    @ViewChild('dxFilterRow', { static: true }) dxFilterRow: DxFilterBuilderComponent;
    @Input() fileComponent: string;
    @Input() fileDate: string;
    @Input() id: string;
    @Input() class: string;
    @Input() height: string | number = '100%';
    @Input() width: string | number;
    @Input() remoteOperations: boolean;
    @Input() pageable: boolean = true;
    @Input() pageSize: number = 20;
    @Input() showColumnHeaders: boolean = true;
    @Input() allowColumnReordering: boolean = true;
    @Input() showBorders: boolean = true;
    @Input() columnHidingEnabled: boolean = false;
    @Input() columnFilter: boolean = true;
    @Input() showfilterrow: boolean = true;
    @Input() showdefaultloadelement: boolean;
    @Input() filterPanel: boolean = true;
    @Input() maxHeight: string | number = '100%';
    @Input() groupable: boolean = true;
    @Input() showColumnLines: boolean = true;
    @Input() showRowLines: boolean = true;
    @Input() rowAlternationEnabled: boolean = true;
    @Input() exportButton: boolean = true;
    @Input() dropDownDataSource: any;
    @Input() editable: boolean = true;
    @Input() keyExpr: string;
    @Input() disabled: boolean;
    @Input() visible: boolean = true;
    @Input() selectedRowKeys: string[] = [];
    @Input() autoExpandAll: boolean = true;
    @Input() columnAutoWidth: boolean;
    @Input() allowColumnResizing: boolean = true;
    @Input() hoverStateEnabled: boolean = true;
    @Input() searchPanel: boolean = true;
    @Input() placeholder: string = 'Search...';
    @Input() text: string;
    @Input() widthSearch: string;
    @Input() highlightSearchText: boolean;
    @Input() enablePaging: boolean = true;
    @Input() allowSelectAll: boolean = true;
    @Input() selectionMode: Mode = Mode.single;
    @Input() showCheckBoxesMode: ShowMode = ShowMode.Always;
    @Input() scrollMode: string = ScrollMode.Virtual;
    @Input() rowRenderingMode: RowMode.Virtual;
    @Input() showScrollBar: ShowScrollbar = ShowScrollbar.OnHover;
    @Input() autoExpand: boolean;
    @Input() enableExport: boolean = true;
    @Input() stateStorageKey: string;
    @Input() enableStateStorage: boolean = true;
    @Input() stateStorageType: StateStorageType = StateStorageType.LocalStorage;
    @Input() savingStateTimeOut: string = '500';
    @Input() enableColumnChooser: boolean = true;
    @Input() emptyPanelText: boolean = true;
    @Input() columnChooserHeight: number;
    @Input() columnChooserMode: ColumnChooserMode = ColumnChooserMode.DragAndDrop;
    @Input() columnChooserSearchTimeout: number;
    @Input() columnChooserSearch: boolean = true;
    @Input() columnChooserSort: string = ColumnChooserSort.Ascendant;
    @Input() columnChooserTitle: string = 'Column Chooser';
    @Input() columnChooserwidth: number;
    @Input() groupingContextMenu: boolean = true;
    @Input() preloadEnabled: boolean = true;
    @Input() scrollByContent: boolean = true;
    @Input() scrollByThumb: boolean = false;
    @Input() allowUpdating: boolean = true;
    @Input() allowDeleting: boolean = true;
    @Input() allowAdding: boolean = true;
    @Input() useIcons: boolean = true;
    @Input() allowExportSelectedData: boolean = true;
    @Input() useNative: boolean = true;
    @Input() showPageSizeSelector: boolean = true;
    @Input() allowedPageSizes: Array<number> = [5, 10, 20, 50, 100];
    @Input() showInfo: boolean = true;
    @Input() rtlEnabled: boolean = false;
    @Input() showTitle: boolean = true;
    @Input() popUpWidth: string | number = 'auto';
    @Input() popUpHeight: string | number = 500;
    @Input() popUpTitle: string = 'Edit';
    @Input() editingMode: EditingMode = EditingMode.PopUp;
    @Input() enableHeaderFilter: boolean = true;
    @Input() setCustomIcons: boolean = true;
    @Input() linkProperty: string;
    @Input() enableLoadPanel: boolean = false;
    @Input() loadPanelHeight: number = 100;
    @Input() loadPanelWidth: number = 250;
    @Input() noDataText: string;
    @Input() exportFormat: FormatExport[] = [FormatExport.EXCEL];

    @Output() onInitialized = new EventEmitter();
    @Output() onCellPrepared = new EventEmitter();
    @Output() onCellClick = new EventEmitter();
    @Output() onSelectionChanged = new EventEmitter();
    @Output() onContentReady = new EventEmitter();
    @Output() onRowDblClick = new EventEmitter();
    @Output() onContextMenuPreparing = new EventEmitter();
    @Output() onRowInserted = new EventEmitter();
    @Output() onRowUpdating = new EventEmitter();
    @Output() onRowUpdated = new EventEmitter();
    @Output() onValueChanged = new EventEmitter();
    @Output() onColumnUpdate = new EventEmitter();
    @Output() onColumnSorting = new EventEmitter();
    @Output() onEditorPrepared = new EventEmitter();
    @Output() onGridScrolling = new EventEmitter();
    @Output() onOptionChanged = new EventEmitter();
    @Output() onEditorPreparing = new EventEmitter();
    @Output() onRowPrepared = new EventEmitter();
    @Output() onToolBarPreparing = new EventEmitter();
    @Output() onRowRemoved = new EventEmitter();
    @Output() onRowRemoving = new EventEmitter();
    @Output() onCustomizeColumns = new EventEmitter();
    @Output() onEditingStart = new EventEmitter();
    @Output() onSaved = new EventEmitter();
    @Output() onCellHoverChanged = new EventEmitter();
    @Output() onEditCanceled = new EventEmitter();
    @Output() onEditCanceling = new EventEmitter();
    @Output() onSaving = new EventEmitter();
    @Output() onCellDblClick = new EventEmitter();
    @Output() onExporting = new EventEmitter();


    // @Output() onSelectionChanged = new EventEmitter();


    public summaryData: Object = {};
    private _gridData;
    @Input()
    get gridData() { return this._gridData; }
    set gridData(gridData) {
        if (this.dxDataGrid.instance) this.dxDataGrid.instance.refresh();
        this._gridData = gridData;
        this.changeDetectorRef.detectChanges();
    }

    private _columns;
    @Input()
    get columnsHeader() { return this._columns; }
    set columnsHeader(columns) {
        this._columns = columns;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    public removedRow(event) {
        this.onRowRemoved.emit(event);
    }

    public removingRow(event) {
        this.onRowRemoving.emit(event);
    }

    public initialized(event): void {
        this.onInitialized.emit(event);
    }

    public optionChanged(event): void {
        this.onOptionChanged.emit(event);
    }

    public customizeColumns = (event): void => {
        this.onCustomizeColumns.emit(event);
    }

    public addRow(): void {
        this.dxDataGrid.instance.addRow();
    }

    public editorPreparing(event): void {
        this.onEditorPreparing.emit(event);
    }

    public editorprepared(event): void {
        this.onEditorPrepared.emit(event);
    }

    public contentReady(event): void {
        this.onContentReady.emit(event);
    }

    public rowPrepared(event): void {
        this.onRowPrepared.emit(event);
    }

    public cellClick(event) {
        this.onCellClick.emit(event);
    }

    public cellDblClick(event) {
        this.onCellDblClick.emit(event);
    }

    public rowInserted(event): void {
        this.onRowInserted.emit(event);
    }

    public editingStart(event): void {
        this.onEditingStart.emit(event);
    }

    public rowUpdating(event): void {
        this.onRowUpdating.emit(event);
    }
    public rowUpdated(event): void {
        this.onRowUpdated.emit(event);
    }

    public rowDbClick(event): void {
        this.onRowDblClick.emit(event);
    }

    public savingRow(event): void {
        this.onSaving.emit(event);
    }

    public saveRow(event): void {
        this.onSaved.emit(event);
    }

    public cellHoverChanged(event): void {
        this.onCellHoverChanged.emit(event);
    }

    public editCancelRow(event): void {
        this.onEditCanceled.emit(event);
    }

    public editCancelingRow(event): void {
        this.onEditCanceling.emit(event);
    }

    public cellPrepared(event): void {
        this.onCellPrepared.emit(event);
    }

    public contextMenuPreparing(event): void {
        this.onContextMenuPreparing.emit(event);
    }

    public updateDataGrid(data): void {
        this.dxDataGrid.instance.option('dataSource', data);
    }

    public rowSelect(event): void {
        this.onSelectionChanged.emit(event);
    }

    public valueChanged(event): void {
        this.onValueChanged.emit(event);
    }

    public toolBarPreparing(event) {
        // if (this.setCustomIcons) {
        //     event.toolbarOptions.items.find(item => {
        //         if (item.name === "columnChooserButton") {
        //             //item.options.icon = GridIcons.COLUMNCHOOSER;
        //         }
        //         if (item.name === "exportButton") {
        //             const exportBtnIndex = event.toolbarOptions.items.indexOf(item);
        //             event.toolbarOptions.items[exportBtnIndex] = {
        //                 location: "after",
        //                 locateInMenu: "auto",
        //                 sortIndex: 30,
        //                 widget: "dxButton",
        //                 // options: {
        //                 //     //icon: GridIcons.EXPORTTOEXCEL,
        //                 //     hint: "Exportieren",
        //                 //     // elementAttr: {
        //                 //     //     "class": "dx-datagrid-export-button"
        //                 //     // },
        //                 //     onClick: () => {
        //                 //         event.component.exportToExcel(false);
        //                 //     }
        //                 // }
        //             };
        //         }
        //     });
        // }
        this.onToolBarPreparing.emit(event);
    }

    public addEmptyRow() {
        this.dxDataGrid.instance.addRow();
    }

    public repaintGrid() {
        this.dxDataGrid.instance.repaint();
    }

    public refreshGrid() {
        this.dxDataGrid.instance.refresh();
    }

    public getVisibleRows() {
        return this.dxDataGrid.instance.getVisibleRows();
    }

    public selectionChanged(event) {
        this.onSelectionChanged.emit(event);
    }

    public selectAll() {
        return this.dxDataGrid.instance.selectAll();
    }

    public editCell(rowIndex: number, columnIndex: number) {
        this.dxDataGrid.instance.editCell(rowIndex, columnIndex)
    }

    public exporting(e) {
        this.onExporting.emit(e);
        if (e.format == 'pdf') {
            const doc = new jsPDF();
            exportDataGridPDF({
                jsPDFDocument: doc,
                component: e.component,
                indent: 5,
            }).then(() => {
                if (this.fileDate) {
                    doc.save(this.fileComponent + '/' + this.fileDate + '.pdf');
                }
                else { doc.save('DataGrid.pdf'); }
            });
        }
        else {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGridExcel({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    if (this.fileDate) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.fileComponent + '/' + this.fileDate + '.xlsx');
                    }
                    else { saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx'); }

                });
            });
            e.cancel = true;
        }
    }

}
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentKind, GeoDsDocument, Person, AddressPerson } from '@geods/base';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, QueryColumn, QueryColumnSortOrder,
  TargetObjectData, ObjectKey, TargetColumnValue, PersistMode, PersistObjectModel, QueryObjectsModel, Query, PersistObjectsModel
} from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { DxContextMenuComponent, DxDataGridComponent, DxFileUploaderComponent, DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { PersonViewModel } from '../../view-models/person.view-model';
import { switchMap } from 'rxjs/operators';
import { DefaultIcons } from '../measure/measure-detail/implementation-plan/implementation-plan.component';
import { StaffMembersService } from './staff-members.service';

@Component({
  selector: 'ubt-geods-staff-member',
  templateUrl: './staff-members.component.html',
  styleUrls: ['./staff-members.component.scss']
})
export class StaffMembersComponent implements OnInit, OnDestroy {
  @ViewChild('staffMembersGrid') staffMembersGrid: DxDataGridComponent;
  @ViewChild('fileUpload') staffMembersUploader: DxFileUploaderComponent;
  @ViewChild('staffMemberPopup') staffMemberPopup: DxPopupComponent;
  @ViewChild('confirmDeletePopup') confirmDeletePopup: DxPopupComponent;
  @ViewChild('contextMenu') contextMenu: DxContextMenuComponent;
  @ViewChild('form') form: DxFormComponent;
  public data$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public uploadDocument: GeoDsDocument = new GeoDsDocument(null);
  public staffMember: Person = new Person(null);
  public staffMemberPosition: AddressPerson = new AddressPerson(null);
  public staffMembers: Person[] = [];
  public documents: GeoDsDocument[] = [];
  public showLoader = false;
  public uploaded = false;
  public availableRoles: IRole[] = [];
  public availablePosition: IRole[] = [];
  public personColumns: QueryColumn[] = [];
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Ubt-StaffMembers-Login'),
      dataField: 'ProfilePicture',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-StaffMembers-Login'),
      dataField: 'FirstName',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-StaffMembers-Login'),
      dataField: 'LastName',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-StaffMembers-Login'),
      dataField: 'EMail',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-StaffMembers-Login'),
      dataField: 'Function',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      dataField: '',
      visibleIndex: 6,
      minWidth: 50,
      width: '50px',

    }
  ];
  public submitText: string;
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };
  public contextMenuItems: any[] = [
    {
      text: this.dataService.res('Ubt-StaffMember-Edit-Staff-Member'),
      onClick: () => {
        this.openStaffMemberDialog();
      },
    },
    {
      text: this.dataService.res('Ubt-StaffMember-Delete-Staff-Member'),
      onClick: () => {
        this.openConfirmDialog()
      },
    },
  ];

  private subscriptions: Subscription[] = [];
  private staffMemberId: string;

  constructor(
    public dataService: DataService,
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    private cdr: ChangeDetectorRef,
    private staffMembersService: StaffMembersService,
    public appService: AppService
  ) { }

  public ngOnInit(): void {
    this.dataService.floatingButtonConfig();
    this.showLoader = true;

    const queries = forkJoin([
      this.dataService.loadListDataSource(ObjectKeys.PERSON, 'Function'),
      this.coreDataService.executeReadObjectsQuery(this.staffMembersService.getDocumentQuery()),
      this.dataService.loadListDataSource(ObjectKeys.ADDRESSPERSON, 'Position'),
    ]);
    queries.subscribe(data => {
      this.availableRoles = data[0].Data;
      this.availablePosition = data[2].Data;
      if (Array.isArray(data[1]) && data[1].length > 0) {
        this.staffMembers = data[1]
        const updatedData = this.staffMembers
        this.data$.next(updatedData);
      } else {
        this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      this.showLoader = false;
    }, () => {
      this.staffMembers.sort((a, b) => a.FirstName.localeCompare(b.FirstName))
      this.showLoader = false;
    });

    this.dataService.uploadData$.subscribe((uploadData: GeoDsDocument) => {
      this.uploadDocument = uploadData;
      this.uploaded = true;
    });

    this.subscriptions.push(this.dataService.updateGridData$.subscribe(() => {
      this.getStaffMemberById(this.staffMemberId);
    }));
  }

  public onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'ProfilePicture') {
        const img = document.createElement('IMG');
        e.cellElement.style.textAlign = 'center';

        if (e.data?.Documents) {
          const object = e.data.Documents[0].Object;
          img.setAttribute('src', 'data:image/png;base64,' + object);
          this.cdr.markForCheck();
        } else {
          e.cellElement.innerHTML = '';
          img.setAttribute('src', 'assets/images/Avatar.svg');
          this.cdr.markForCheck();
        }
        img.style.borderRadius = '50%';
        img.style.width = '50%';
        e.cellElement.appendChild(img);

      } else if (e.column.dataField === '') {
        e.cellElement.style.cursor = 'pointer';
        return (e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU);
      }
    }
  }

  public onCellClick = (e) => {
    this.staffMemberId = e.data.Id;
    this.staffMember = e.data;
    if (e.columnIndex === 5) {
      e.cellElement.className = 'context-menu-icon' + e.rowIndex;
      this.contextMenu.target = '.context-menu-icon' + e.rowIndex;
      e.cellElement.innerHTML = this.contextMenu.visible ? DefaultIcons.CLOSE : DefaultIcons.CONTEXTMENU;
      this.contextMenu.instance.show();
    }
  }



  public openStaffMemberDialog(): void {
    if (this.staffMember) {
      this.staffMember = new Person(this.staffMember);
      if (this.staffMember['AddressPerson']) {
        this.staffMemberPosition.Id = this.staffMember['AddressPerson'][0]?.Id;
        this.staffMemberPosition.Position = this.staffMember['AddressPerson']?.Position;
      }
      this.uploadDocument.Object = this.staffMember.Documents ? this.staffMember.Documents[0]["Object"] : null;
      this.staffMemberPopup.title = this.dataService.res('Ubt-StaffMembers-Update-StaffMembers');
      this.submitText = 'Update';
    } else {
      this.staffMember = new Person(null);
      this.staffMemberPosition.Position = 'Maßnahmenbeteiligter';
      this.staffMemberPopup.title = this.dataService.res('Ubt-StaffMembers-Create-StaffMembers');
      this.submitText = 'Create';
    }
    this.staffMemberPopup.instance.show();
  }

  public persistStaffMember(event, staffMember): void {
    event.preventDefault();
    this.subscriptions.push(this.staffMembersService.persistStaffMember(staffMember).pipe(switchMap(data => {
      this.staffMemberId = data.Id;
      const index = this.staffMembers.findIndex(member => member.Id === this.staffMember.Id);
      this.staffMembers[index] = this.staffMember;
      const document = this.getDocument(data.Id, this.staffMember.Documents ? this.staffMember.Documents[0]["Id"] : null);
      return forkJoin([
        this.createDocument(document),
        this.createPosition(this.staffMemberPosition, sessionStorage.getItem("currentCompany"), this.staffMemberId)
      ])
    })).subscribe((data) => {
    }, error => {
      this.showLoader = false;
    }, () => {
      this.clearWindow();
      this.cdr.detectChanges();
    }));
  }

  private createDocument = (document): Observable<any> => {
    if (this.uploadDocument.Object && this.uploadDocument.FileName?.length > 0) {
      return of(this.dataService.persistUploadDocument(document));
    } else {
      this.getStaffMemberById(this.staffMemberId);
    }
  }

  private createPosition = (position: AddressPerson, idCompany: string, idPerson: string): Observable<any> => {
    return of(this.persistStafMemeberPosition(position, idCompany, idPerson));
  }

  public persistStafMemeberPosition = (
    position: AddressPerson,
    idCompany: string,
    idPerson: string
  ): void => {
    this.staffMembersService.persistStafMemeberPosition(position, idCompany, idPerson).subscribe((_position) => {
      this.staffMemberPosition.Id = _position.Id
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      this.showLoader = false;
    }, () => {
      this.clearWindow();
    })
  };

  public getDocument(parentId: string, documentId?: string): GeoDsDocument {
    this.uploadDocument.Id = documentId;
    this.uploadDocument.ParentId = parentId;
    this.uploadDocument.Designation = 'imgStaffMember';
    this.uploadDocument.ParentObjectType = ObjectKeys.PERSON;
    this.uploadDocument.Kind = DocumentKind.Bild;
    return this.uploadDocument;
  }

  private getStaffMemberById(id: string): void {
    const opath = 'Id=' + "'" + this.staffMemberId + "'";
    this.subscriptions.push(this.coreDataService.executeReadObjectsQuery(this.staffMembersService.getDocumentQuery(opath)).subscribe((data) => {
      if (this.staffMembersService.isUpdating === true) {
        let selectedIndex: number;
        this.staffMembers.forEach((item, index) => {
          if (item.Id.toString() == id) {
            selectedIndex = index;
          }
        });
        this.staffMembers[selectedIndex] = new Person(data[0]);
        this.staffMembersGrid.instance.refresh();
      } else {
        this.staffMembers.push(new Person(data[0]));
      }
      this.appService.callNotification({ message: this.dataService.res('Ubt-Notification-Success'), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public clearWindow(): void {
    this.staffMemberPopup.instance.hide();
    this.staffMember = new Person(null);
    this.uploadDocument = new GeoDsDocument(null);
    this.staffMembersUploader.instance.reset();
    this.form.instance.resetValues();
    this.showLoader = false;
    this.cdr.detectChanges();
  }

  public onFileUpload(e, document): void {
    this.dataService.onFileChanged(e, document);
  }

  public removeStaffMember(): void {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.PERSON, this.staffMemberId).subscribe(() => {
      this.confirmDeletePopup.instance.hide();
      const index = this.staffMembers.findIndex(item => item.Id === this.staffMemberId);
      this.staffMembers.splice(index, 1);
      this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public openConfirmDialog(): void {
    this.confirmDeletePopup.instance.show();
  }

  public abortDelete(): void {
    this.confirmDeletePopup.instance.hide();
  }

  public ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
}

interface IRole {
  Description: string;
  Value: string;
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  GeoDsPersistenceService, GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder, ObjectKey,
  PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData, GUID
} from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxContextMenuComponent, DxFormComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { forkJoin, Subscription } from 'rxjs';
import { AppService } from '../../../services/app.service';
import { DataService } from '../../../services/data.service';
import { SubProjectViewModel, ProjectStateIcons, ProjectState } from '../../../view-models/sub-project.view-model';
import { SubProjectService } from './../sub-project.service';
import { ObjectKeys } from "../../../services/data.service";
import { Project, SubProject } from '@xmt-models';
import { Address, Department, Person } from '@geods/base';
import { first } from 'rxjs/operators';
import { exportDataGrid as exportDataGridPDF } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridExcel } from 'devextreme/excel_exporter';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { FavoritesService } from 'libs/favorites/src/lib/favorites.service';
import { ObjectTypeNames } from '../../../favorite-list/favorite-list.component';
import { loadMessages } from 'devextreme/localization';
import { DefaultIcons } from '../../measure/measure-detail/implementation-plan/implementation-plan.component';

@Component({
  selector: 'ubt-geods-sub-project-list',
  templateUrl: './sub-project-list.component.html',
  styleUrls: ['./sub-project-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubProjectListComponent implements OnInit, OnDestroy {
  @ViewChild('createSubProjectPopup') createSubProjectPopup: DxPopupComponent;
  @ViewChild('event') eventSelectBox: DxSelectBoxComponent;
  @ViewChild('responsible') responsibleSelectBox: DxSelectBoxComponent;
  @ViewChild('support') supportSelectBox: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('confirmSubProjectPopup') confirmSubProjectPopup: DxPopupComponent;
  @ViewChild('subProjectGrid') subProjectGrid: GridComponent;
  @ViewChild('contextMenu') contextMenu: DxContextMenuComponent;

  public subProject: SubProject = new SubProject(null);
  public project: Project = new Project(null);
  public subProjectDesignation: string;
  public pluralTitle: string;
  public subProjectsViewModel: SubProjectViewModel[] = [];
  public subProjects: SubProject[] = [];
  public projects: Project[] = [];
  private subscriptions: Subscription[] = [];
  public contextMenuItems: any[] = [
    {
      text: this.dataService.res('Ubt-SubProject-Edit-Subproject'),
      onClick: (event) => {
        this.openEditSubProject(event);
      },
    },
    {
      text: this.dataService.res('Ubt-SubProject-Delete-Subprojet'),

      onClick: () => {
        this.openConfirmTaskDialog()
      },
    },
    {
      text: this.dataService.res('Ubt-SubProject-Add-to-favorites'),

      onClick: (e) => {

        this.updateFavorite();
      },
    },
  ];
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Ubt-CreateSubproject-Implementation-Status'),
      dataField: "implementationStatus",
      minWidth: 50,
      width: 50,
      alignment: 'center',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Ubt-CreateMeasure-Title'),
      dataField: "label",
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Ubt-MeasureDetail-Responsible'),
      dataField: 'responsible',
      visibleIndex: 2,
      lookup: { valueExpr: 'Id', displayExpr: this.dataService.getResponsibleFullName, dataSource: [] }
    },
    {
      caption: this.dataService.res('Ubt-SubProject-Create-HighLights'),
      dataField: 'highlights',
      visibleIndex: 3,
    },
    {
      caption: this.dataService.res('Ubt-SubProject-Create-Lowlights'),
      dataField: "lowlights",
      visibleIndex: 4,
    },
    {
      type: "buttons",
      caption: '',
      alignment: 'left',
      minWidth: 50,
      width: '50px',
      dataField: '',
      visibleIndex: 5,
    }
  ];
  public responsibles: Person[] = [];
  private subProjectId: string;
  public disableDepartment = true;
  public showLoader = false;
  public isFavorite = true;
  public noDataText = "Keine Daten";

  public readonly subProjectStates = [
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Risk'),
      value: ProjectState.NORISK
    },
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Behind-Schedule'),
      value: ProjectState.BEHINDSCHEDUEL
    },
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Countless'),
      value: ProjectState.COUNTMEASURES
    },
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Critical'),
      value: ProjectState.CRITICAL
    }
  ]
  public isUpdating = false;
  public submitButtonText = 'Create';

  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };
  public personColumns: QueryColumn[] = [];
  public subProjectColumns: QueryColumn[] = [];
  constructor(
    private persistenceService: GeoDsPersistenceService,
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public subProjectService: SubProjectService,
    private coreDataService: GeoDsCoreDataService,
    public appService: AppService,
    private datePipe: DatePipe,
    public favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this.dataService.floatingButtonConfig();

    this.showLoader = true;
    this.subProject = new SubProject(null);
    this.subProjectColumns.push(
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('ParentId', 'ParentId', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Label', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('IdRefResponsible', 'IdRefResponsible', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('IdRefResponsibleSupport', 'IdRefResponsibleSupport', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Highlights', 'Highlights', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Lowlights', 'Lowlights', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('ImplementationStatus', 'ImplementationStatus', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('IdRefDepartment', 'Department', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('IdRefAddress', 'Company', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Count(measures)', 'measuresCount', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn(
        "Exists(Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id])",
        'IsFavorite'
      ),
      this.coreDataService.createQueryColumn(
        "Type(BoxDetail)[Box.ParentId = $CurrentUserId AND Box.Designation = 'Favoriten' AND ParentId = ^.Id].Id",
        'BoxDetailId'
      )
    )
    const oPath = 'ParentId=' + "'" + sessionStorage.getItem('projectId') + "'";
    const addressColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Name', 'Name', QueryColumnSortOrder.None)
    ];

    const projectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None),
    ];
    const oPathProject = 'Id=' + "'" + sessionStorage.getItem('projectId') + "'";

    forkJoin([
      this.dataService.prepareStaffMembers(),
      this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, this.subProjectColumns, oPath),
      this.dataService.readObjects<Address[]>(ObjectKeys.ADDRESS, addressColumns),
      this.dataService.readObjects<Project[]>(ObjectKeys.PROJECT, projectColumns, oPathProject),

    ]).pipe(first()).subscribe(data => {
      if (Array.isArray(data[3]) && data[3].length > 0) {
        this.project = data[3][0];
      }
      if (Array.isArray(data[0]) && data[0].length > 0) {
        this.dataService.cachedResponsiblesResponse = data[0].sort((a, b) => a.LastName.localeCompare(b.LastName))
        this.subProjectGrid.dxDataGrid.instance.columnOption(2, 'lookup.dataSource', this.dataService.cachedResponsiblesResponse);
      }
      this.dataService.cachedCompany = data[2].sort((a, b) => a.Name.localeCompare(b.Name));
      if (Array.isArray(data[1]) && data[1].length > 0) {
        this.appService.subProjectIds = [];
        this.appService.subProjects = data[1];
        data[1].forEach((subProject) => {
          this.appService.subProjectIds.push(subProject.Id);
          const subProjectViewModel = new SubProjectViewModel(subProject);
          this.subProjectsViewModel.push(subProjectViewModel);
        });
      } else {
        this.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
      this.cdr.markForCheck();
    }, () => {
      this.cdr.markForCheck();
      this.showLoader = false;
    });


    this.subscriptions.push(this.dataService.updateGridData$.subscribe((subProject: SubProjectViewModel) => {
      this.subProjectsViewModel.push(subProject);
      this.cdr.markForCheck();
    }));
  }

  public updateFavorite() {

    if (this.subProject['IsFavorite'] === 1) {
      return this.deleteFavorite();
    } else {
      return this.addFavorite();
    }
  }


  public isFavoriteVisible(e) {
    if (e.row?.data.subProject.IsFavorite === 1) {
      return true;
    } else {
      return false;
    }
  }

  public getRadioStyle(data: any): string {
    switch (data?.value) {
      case ProjectState.NORISK:
        return 'green-circle';
      case ProjectState.COUNTMEASURES:
        return 'orange-circle';
      case ProjectState.BEHINDSCHEDUEL:
        return 'yellow-circle';
      case ProjectState.CRITICAL:
        return 'red-circle';
      default:
        return 'default-circle';
    }
  }

  public isRepportDisabled(e): boolean {
    if (e.row.data.subProject.measuresCount === 0 || e.row.data.subProject.measuresCount === undefined) {
      return true;
    } else {
      return false;
    }
  }

  public onRowPrepared = (e) => {
    if (e.rowType === 'data') {
      this.getFullName(e.data.subProject?.Responsible)
    }
  }

  public addFavorite(): void {
    this.favoritesService.persistFavoriten(GUID.newGUID(), ObjectTypeNames.SubProject, this.subProject.Id, this.subProject.Label).subscribe((data) => {
      this.subProjectsUpdateState(this.subProject, 'add', data);
    });
  }

  public deleteFavorite(): void {
    this.favoritesService.deleteFavoriten(this.subProject['BoxDetailId']);
    this.subProjectsUpdateState(this.subProject, 'delete')
  }

  public onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === '') {
        e.cellElement.style.cursor = 'pointer';
        return (e.cellElement.innerHTML = DefaultIcons.CONTEXTMENU);
      }
    }

    if (e.rowType === 'header') {
      e.cellElement.style.textAlign = 'left';
    } else if (e.rowType === 'data') {

      switch (e.column.dataField) {
        case '':
          e.cellElement.style.width = '50px !important';
          break;
        case 'label':
        case 'implementationStatus':
          if (e.column.dataField === 'implementationStatus') {
            e.cellElement.style.paddingTop = '20px';
            e.cellElement.style.width = '5%';
            e.cellElement.style.textAlign = 'center';
            const tooltipContent = e.cellElement.textContent
            e.cellElement.textContent = ''
            e.cellElement.setAttribute('title', tooltipContent);
            const statusDiv = document.createElement('div');
            const statusSpan = document.createElement('span');
            const statutIcon = document.createElement('div');
            statutIcon.setAttribute('class', 'filled-character');
            statusDiv.style.display = 'flex';
            statusDiv.style.placeContent = 'center';
            statusSpan.style.flex = "1";
            statusSpan.style.overflow = "hidden";
            statusSpan.style.whiteSpace = "nowrap";
            statusSpan.style.textOverflow = "ellipsis";
            statusSpan.style.display = "inline-block";

            switch (e.data?.implementationStatus) {
              case 'Critical':
                statutIcon.style.background = 'red';
                break;
              case 'Behind schedule':
                statutIcon.style.background = 'yellow';
                break;
              case 'Counter measures necessary':
                statutIcon.style.background = '#ff5722';
                break;
              case 'No Risk':
                statutIcon.style.background = '#3faf6c';
                break;
            }

            statusDiv.append(statutIcon);
            e.cellElement.append(statusDiv);
          }
      }
    }
  }

  public onCellClick = (e) => {
    this.subProject = e.data.subProject;
    if (e.columnIndex === 5) {
      e.cellElement.className = 'context-menu-icon' + e.rowIndex;
      this.contextMenu.target = '.context-menu-icon' + e.rowIndex;
      e.cellElement.innerHTML = this.contextMenu.visible ? DefaultIcons.CLOSE : DefaultIcons.CONTEXTMENU;
      this.contextMenu.instance.show();
    }

  }

  public subProjectsUpdateState(e, state: string, favoriteData?): void {
    let selectedIndex = this.subProjectsViewModel.findIndex((subProject) => {
      return subProject.id === e.Id
    });
    if (state === 'add') {
      const clonedItem = { ...e, IsFavorite: 1, BoxDetailId: favoriteData.Id };
      this.subProjectsViewModel[selectedIndex]
      this.subProjectsViewModel[selectedIndex] = new SubProjectViewModel(clonedItem);

      this.cdr.markForCheck();
    } else {
      const clonedItem = { ...e, IsFavorite: 0 };
      this.subProjectsViewModel[selectedIndex] = new SubProjectViewModel(clonedItem);
      this.cdr.markForCheck();
    }
  }

  public prepareDepartments(id: string): void {
    const departmentColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
    ];
    const departmentOpath = 'ParentId=' + "'" + id + "'";
    this.dataService.readObjects<Department[]>(ObjectKeys.DEPARTMENT, departmentColumns, departmentOpath).subscribe(departments => {
      this.dataService.cachedDepartement = departments;
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
    }, () => {
      this.disableDepartment = false;
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public onExporting(e): void {
    const myDate = this.datePipe.transform(new Date(), '/yyyy-MM-dd');
    if (e.format == 'pdf') {
      const doc = new jsPDF();
      exportDataGridPDF({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      }).then(() => {
        doc.save('subProject/' + myDate + '.pdf');
      });
    }
    else {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGridExcel({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'subProject/' + myDate + '.xlsx');
        });
      });
      e.cancel = true;
    }
  }

  public openEditSubProject = (e) => {
    this.isUpdating = true;
    this.disableDepartment = false;
    this.submitButtonText = 'Update';
    this.createSubProjectPopup.title = this.dataService.res('Ubt-SubProject-Update-SubProject');
    this.createSubProjectPopup.instance.show();
  }


  public openConfirmTaskDialog = () => {
    this.subProjectDesignation = this.subProject.Label;
    this.subProjectId = this.subProject.Id;
    loadMessages({
      de: {
        'Ubt-message-delete': 'Sind Sie sicher, dass Sie das "' + this.subProjectDesignation + '" löschen wollen?',
      },
      en: {
        'Ubt-message-delete': 'Are you sure you want to delete "' + this.subProjectDesignation + '" this record?',
      }
    });
    this.confirmSubProjectPopup.instance.show();
  }

  public removeRow = (event): void => {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.SUBPROJECT, this.subProjectId).subscribe(() => {
      const index = this.subProjectsViewModel.findIndex(item => item.id === this.subProjectId);
      this.subProjectsViewModel.splice(index, 1);
      this.subProjectGrid.refreshGrid();
      this.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.cdr.markForCheck();
      this.confirmSubProjectPopup.instance.hide();
    }, error => {
      console.error(error)
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public rowUpdated(event): void {
    if (this.subProject?.Label !== '') {
      event.preventDefault();
      this.persistSubProject(PersistMode.Update);
    } else {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Empty"), type: ToastType.INFO });
      event.cancel = true;
    }
  }

  public getFullName(id: string) {
    const responsible = this.dataService.cachedResponsiblesResponse.find(x => x.Id === id);
    if (responsible) return responsible.FirstName + ', ' + responsible.LastName;
  }

  public openSubProjectDialog(): void {
    this.isUpdating = false;
    this.subProject = new SubProject(null);
    this.subProject.ImplementationStatus = ProjectState.NORISK;
    this.submitButtonText = 'Create';
    this.createSubProjectPopup.title = this.dataService.res('Ubt-SubProject-Create-SubProject');
    this.createSubProjectPopup.instance.show();
  }

  public abortTaskDelete() {
    this.confirmSubProjectPopup.instance.hide();
  }

  public createSubProject(event): void {
    if (this.isUpdating === false) {
      event.preventDefault();
      this.persistSubProject(PersistMode.Insert);
    }
    else {
      this.rowUpdated(event);
      event.preventDefault();
    }
  }

  private persistSubProject(mode: PersistMode) {
    const subProjectPersistQuery: TargetObjectData = new TargetObjectData();
    subProjectPersistQuery.ObjectKey = new ObjectKey();
    subProjectPersistQuery.ObjectKey.ObjectType = ObjectKeys.SUBPROJECT;
    const currentCompanyId = sessionStorage.getItem('currentCompany');
    const subProjectColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: sessionStorage.getItem("projectId") },
      { Name: 'Designation', Value: this.subProject.Label },
      { Name: 'IdRefResponsible', Value: this.subProject.IdRefResponsible ? this.subProject.IdRefResponsible : null },
      { Name: 'IdRefResponsibleSupport', Value: this.subProject.IdRefResponsibleSupport ? this.subProject.IdRefResponsibleSupport : null },
      { Name: 'Highlights', Value: this.subProject.Highlights },
      { Name: 'Lowlights', Value: this.subProject.Lowlights },
      { Name: 'StartDate', Value: new Date(this.subProject.StartDate) },
      { Name: 'EndDate', Value: new Date(this.subProject.EndDate) },
      { Name: 'IdRefAddress', Value: currentCompanyId },
      { Name: 'IdRefDepartment', Value: this.subProject.Department?.toString() },
      { Name: 'ImplementationStatus', Value: this.subProject.ImplementationStatus }
    ];
    subProjectPersistQuery.Mode = mode;
    if (mode == PersistMode.Update) subProjectPersistQuery.ObjectKey.Id = this.subProject.Id;
    subProjectPersistQuery.TargetColumns = subProjectColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = subProjectPersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      const subProjectViewMOdel = new SubProjectViewModel(this.subProject)
      if (mode == PersistMode.Insert) {
        this.subProject.Id = data.Id
        this.disableDepartment = true;
        this.dataService.updateGridData(subProjectViewMOdel);
        this.isFavorite = false;
        this.cdr.markForCheck();
      }
      this.clearWindow();
    }, error => {
      if (!sessionStorage.getItem("projectId")) {
        this.appService.callNotification({ message: this.dataService.res('Ubt-Project-Not-Selected'), type: ToastType.ERROR });
      } else {
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      }
    }, () => {
      this.subProject = new SubProject(null);
      this.cdr.markForCheck();
    }));
  }
  public clearWindow(): void {
    this.createSubProjectPopup.instance.hide();
  }

  public onGridCellPrepared(event): string {
    if (event.column?.dataField === 'implementationStatus') {
      event.cellElement.style.textAlign = "center";
      const value: string = event?.value;
      if (event.rowType === 'data') {
        if (value === ProjectState.NORISK) {
          return event.cellElement.innerHTML = ProjectStateIcons.GREEN;
        } else if (value === ProjectState.BEHINDSCHEDUEL) {
          return event.cellElement.innerHTML = ProjectStateIcons.YELLOW;
        } else if (value === ProjectState.CRITICAL) {
          return event.cellElement.innerHTML = ProjectStateIcons.RED;
        } else if (value === ProjectState.COUNTMEASURES) {
          return event.cellElement.innerHTML = ProjectStateIcons.GRAY;
        } else {
          return event.cellElement.innerHTML = ProjectStateIcons.GRAY;
        }
      }
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { QueryColumn } from '@wissenswerft/core/data';
import { DataService } from '../services/data.service';

@Component({
  selector: 'ubt-geods-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss']
})
export class FavoriteListComponent implements OnInit {
  @Output() closebtn: EventEmitter<any> = new EventEmitter();
  public subProjectColumns: QueryColumn[] = [];
  public appObjectTypesKeys = ['330001152', '330001131'];
  constructor(
    public dataService: DataService,
    private router: Router
  ) { }
  public ObjectTypeNames = ObjectTypeNames;

  ngOnInit(): void { }

  public itemClicked(event): void {
    switch (event.itemData.ObjectType) {
      case ObjectTypeNames.SubProject:
        localStorage.setItem('subProjectId', event.itemData.ParentId);
        this.router.navigate(['subProjectDetail', event.itemData.ParentId]);
        this.closeFavorites();
        break;
      case ObjectTypeNames.Measure:
        this.dataService.favoriteMeasureId.next(event.itemData.ParentId);
        this.router.navigate(['measureDetail', 'measures', event.itemData.ParentId]);
        this.closeFavorites();
        break;
      default: {
        this.router.navigateByUrl('');
        this.closeFavorites();
      }
    }
  }

  public openFavorites(): void {
    document.getElementById('favouritesContainer').style.width = '400px';
    this.changeFavouriteStyle(['container'], '0.2', 'auto');
  }

  public closeFavorites(): void {
    document.getElementById('closeFavoritebtn').style.visibility = 'hidden';
    document.getElementById('favouritesContainer').style.width = '0';
    this.changeFavouriteStyle(['container'], '1', 'auto');
    this.closebtn.emit();
  }

  public changeFavouriteStyle(listIds: Array<string>, opacity: string, pointerEvents: string) {
    listIds.forEach((ids) => {
      document.getElementById(ids).style.visibility = 'true';
      document.getElementById(ids).style.opacity = opacity;
      document.getElementById(ids).style.pointerEvents = pointerEvents;
    });
  }
}
export enum ObjectTypeNames {
  SubProject = 330001152,
  Measure = 330001131
}


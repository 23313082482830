import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ResourceManager } from '@wissenswerft/core/resources';
import DataSource from 'devextreme/data/data_source';
import { FavoriteFilterListComponent } from './favorite-filter-list/favorite-filter-list.component';
import { FavoriteDataSource } from './favorite-dataSource';
import { FavoritesService } from './favorites.service';
import { BoxDetailViewModel, TypeFavorite } from './Model/box.viewModel';
import { SelectionModeList, SreachModeList } from '@wissenswerft/core/data';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FavoritesComponent implements OnInit {
  @Output() onItemClicked: EventEmitter<any> = new EventEmitter();
  @Input() ObjectTypeNames;
  @Input() appObjectTypesKeys: string[];
  public ComponentRef: ComponentRef<FavoriteFilterListComponent>;
  public favoriteData: BoxDetailViewModel[];
  public dataSource: DataSource;
  public sreachModeList = SreachModeList.Contains;
  public selectionModeList = SelectionModeList.Single;
  private filterList: Map<string | number, any>
  private islistOpened: boolean = false;
  public res = ResourceManager.getResources('favorites');
  public showLoader: boolean;

  constructor(
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private favoritesService: FavoritesService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {
    iconRegistry.addSvgIcon('fav', sanitizer.bypassSecurityTrustResourceUrl('assets/images/all_favorites.svg'));
    iconRegistry.addSvgIcon('company', sanitizer.bypassSecurityTrustResourceUrl('assets/images/company_fav.svg'));
    iconRegistry.addSvgIcon('audAudit', sanitizer.bypassSecurityTrustResourceUrl('assets/images/audit_fav.svg'));
    iconRegistry.addSvgIcon('audBibMeasure', sanitizer.bypassSecurityTrustResourceUrl('assets/images/measure.svg'));
    iconRegistry.addSvgIcon('audBibResponse', sanitizer.bypassSecurityTrustResourceUrl('assets/images/answer.svg'));
    iconRegistry.addSvgIcon('audBibQuestion', sanitizer.bypassSecurityTrustResourceUrl('assets/images/question.svg'));
    iconRegistry.addSvgIcon('audLibrary', sanitizer.bypassSecurityTrustResourceUrl('assets/images/library.svg'));
    iconRegistry.addSvgIcon('audBibCategory', sanitizer.bypassSecurityTrustResourceUrl('assets/images/category.svg'));
    iconRegistry.addSvgIcon('subProject', sanitizer.bypassSecurityTrustResourceUrl('assets/images/subProject.svg'));
    iconRegistry.addSvgIcon('measure', sanitizer.bypassSecurityTrustResourceUrl('assets/images/measure.svg'));

  }

  ngOnInit(): void {
    if (Array.isArray(this.appObjectTypesKeys) && this.appObjectTypesKeys.length > 0) {
      this.favoritesService.appObjectTypesKeys = this.appObjectTypesKeys;
    }
    this.showLoader = true;
    this.favoritesService.getDataSource().subscribe((allFavorites: FavoriteDataSource) => {
      this.favoriteData = Array.from(allFavorites.favorites.values());
      this.fillDataSource(this.favoriteData);
    });

  }

  private fillDataSource(listFavorites: BoxDetailViewModel[]): void {
    if (listFavorites.length > 0) {
      listFavorites = listFavorites.filter((favorite) => {
        if (this.ObjectTypeNames[favorite.ParentObjectType] !== undefined) {
          return favorite;
        }
      })
    }
    this.filterList = this.fillObjectTypeName(listFavorites);

    this.showLoader = false;
    this.dataSource = new DataSource({
      store: new ArrayStore({
        data: listFavorites,
        key: 'ParentObjectTypeName',
      }),
      sort: ['DisplayText', 'key'],
      group: 'ParentObjectTypeName',
      searchExpr: ['DisplayText', 'ParentObjectTypeName']
    });

  }

  private fillObjectTypeName(listFavorites: BoxDetailViewModel[]): Map<string | number, any> {
    const filterList: Map<string | number, any> = new Map<string | number, any>();
    filterList.set('All', { Name: 'All', Icon: 'fav' });
    if (listFavorites.length > 0) {
      listFavorites.forEach((favorite) => {
        if (this.ObjectTypeNames[favorite.ParentObjectType] !== undefined) {
          if ((!filterList.has(favorite.ParentObjectType))) {
            filterList.set(this.ObjectTypeNames[favorite.ParentObjectType], { Name: this.ObjectTypeNames[favorite.ParentObjectType], Icon: TypeFavorite[this.ObjectTypeNames[favorite.ParentObjectType]] })
          }
          favorite.ObjectType = favorite.ParentObjectType;
          favorite.ParentObjectTypeName = this.res('Favorites-' + this.ObjectTypeNames[favorite.ParentObjectType]);
        }
      })
    }
    return filterList;
  }

  private createComponent(): void {
    const compFactory = this.resolver.resolveComponentFactory(FavoriteFilterListComponent);
    this.ComponentRef = compFactory.create(this.injector, undefined, '#forViewRef');
    this.appRef.attachView(this.ComponentRef.hostView);
    const filterList = this.ComponentRef.instance;
    filterList.filteredBy = 'All';
    filterList.filterList = this.filterList;
    filterList.onButtonClick.subscribe((event) => {
      filterList.filteredBy = event;
      this.onFilterListClicked(this.res('Favorites-' + event));
    });
  }

  private onFilterListClicked(event: string): void {
    if (event !== this.res('Favorites-All')) {
      this.dataSource.filter(['ParentObjectTypeName', '=', event]);
    } else {
      this.dataSource.filter(null);
    }
    this.dataSource.load();
  }

  public contentReady(): void {
    if (!this.islistOpened) {
      this.islistOpened = true;
      const listBoxContent = this.elementRef.nativeElement.querySelector('.dx-scrollview-content');
      const parent = document.createElement('div');
      parent.className = 'filterList';
      parent.setAttribute('id', 'forViewRef');
      listBoxContent.appendChild(parent);
      listBoxContent.insertAdjacentElement('beforebegin', parent);
      this.createComponent();
    }
  }

  public itemClicked(event: any): void {
    this.onItemClicked.emit(event);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, LoginComponent } from '@wissenswerft/core/authentication';
import { AppComponent } from './app.component';
import { MeasureDetailComponent } from './core/customer-space/measure/measure-detail/measure-detail.component';
import { MeasureListComponent } from './core/customer-space/measure/measure-list/measure-list.component';
import { MeasureTaskComponent } from './core/customer-space/measure/measure-task/measure-task.component';
import { SubProjectListComponent } from './core/customer-space/sub-project/sub-project-list/sub-project-list.component';
import { StaffMembersComponent } from './core/customer-space/staff-members/staff-members.component';
import { DocumentComponent } from './core/shared/document/document.component';
import { RolesComponent } from './core/customer-space/roles/roles.component';
import { ReportsComponent } from './core/customer-space/reports/reports.component';
import { GanttComponent } from './core/customer-space/gantt/gantt.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { HomeComponent } from './core/home/home.component';
import { SubProjectDetailComponent } from './core/customer-space/sub-project/sub-project-detail/sub-project-detail.component';
import { CompaniesComponent } from './core/companies/companies.component';
import { CompaniesListComponent } from './core/companies/companies-list/companies-list.component';

const routes: Routes = [
  {
    path: 'app',
    component: AppComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'companies',
    component: CompaniesComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('libs/companies/src/lib/companies.module')
      .then(module => module.CompaniesModule)
  },
  {
    path: 'project',
    component: HomeComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/home/home.module')
      .then(module => module.HomeModule)
  },
  {
    path: 'companiesList',
    component: CompaniesListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/companies/companies-list/companies.module')
      .then(module => module.companiesModule)
  },
  {
    path: 'dashboard',
    component: DashboardComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/dashboard/dashboard.module')
      .then(module => module.DashboardModule)
  },
  {
    path: 'measures',
    component: MeasureListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure/measure.module')
      .then(module => module.MeasureListModule)
  },
  {
    path: 'measureDetail/:from/:id',
    component: MeasureDetailComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure/measure-detail/measure-detail.module')
      .then(module => module.MeasureDetailModule)
  },
  {
    path: 'documents',
    component: DocumentComponent, canActivate: [AuthenticationGuard],
  },
  {
    path: 'tasks',
    component: MeasureTaskComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure/measure-task/measure-task.module')
      .then(module => module.MeasureTaskModule)
  },
  {
    path: 'reports',
    component: ReportsComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/reports/reports.module')
      .then(module => module.ReportsModule)
  },
  {
    path: 'gantt',
    component: GanttComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/gantt/gantt.module')
      .then(module => module.GanttModule)
  },
  {
    path: 'staffMembers',
    component: StaffMembersComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/staff-members/staff-members.module')
      .then(module => module.StaffMembersModule)
  },
  {
    path: 'roles',
    component: RolesComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/roles/roles.module')
      .then(module => module.RolesModule)
  },
  {
    path: 'subProject',
    component: SubProjectListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/sub-project/sub-project.module')
      .then(module => module.SubProjectModule)
  },
  {
    path: 'subProjectDetail/:id',
    component: SubProjectDetailComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/sub-project/sub-project-detail/sub-project-detail.module')
      .then(module => module.SubProjectDetailModule)
  },
  {
    path: '**',
    redirectTo: 'companies'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class AppRoutingModule { }
export enum GanttTaskColor {
    Green = 'Green',
    LightGreen = 'light Green',
    LightBlue = 'light blue',
}

export enum GanttExportMode {
    All = 'all',
    Chart = 'chart',
    TreeList = 'treeList'
}

export enum GanttDateRange {
    All = 'all',
    Visible = 'visible',
    Custom = 'custom'
}

export enum GanttExportDocumentFormat {
    A0 = 'a0',
    A1 = 'a1',
    A2 = 'a2',
    A3 = 'a3',
    A4 = 'a4',
    Auto = 'auto'
}

export enum ScaleType {
    Auto = 'auto',
    Weeks = 'weeks',
    Months = 'months',
    Quarters = 'quarters',
    Years = 'years'
}

export interface IGanttDataSource {
    id: string;
    parentId?;
    title: string;
    start?: Date;
    end?: Date;
    progress?: number;
    color: string;
    isTaskMilestone?: boolean;
}

export interface IResource {
    id: number;
    text: string;
}

export class ResourceAssignment {
    id: number;
    taskId: number;
    resourceId: number;
}

export class ContextMenuItem {
    name: string;
    text: string;
    icon?: string;
}

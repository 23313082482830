import { GeoDsMeta } from "./meta-data.model";

export class MailAndWebAddress extends GeoDsMeta {
  Type: string;
  MainAddress: string;
  Value: string;
  refAddress: string;

  constructor(data: MailAndWebAddress) {
    super();
    Object.assign(this, data);
  }

}

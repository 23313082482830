import { GeoDsMeta } from "./meta-data.model";

export class GeoDsDocument extends GeoDsMeta {
    ParentId: string;
    Designation: string;
    FileName: string;
    Kind: DocumentKind;
    Object: string;
    ParentObjectType: string;
    Comment?: string

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

export enum DocumentKind {
    Dokument = "DOC",
    ELOLink = "ELO-Link",
    Datei = "FILE",
    Bild = "IMAGE",
    PFAD = "PATH",
    Video = "VIDEO"
}